const Permits = {
  HOME: ['admin', 'operator', 'packing_manager', 'reception_dispatch_manager'],
  PURCHASES: ['admin', 'packing_manager'],
  PROCESSES: ['admin', 'operator', 'packing_manager'],
  RECEIPTS: ['admin', 'packing_manager', 'reception_dispatch_manager'],
  PROCESS: ['admin', 'operator', 'packing_manager'],
  LOTS: ['admin', 'operator', 'packing_manager', 'reception_dispatch_manager'],
  DISPATCHES: ['admin', 'packing_manager', 'reception_dispatch_manager'],
  RETURNS: ['admin', 'packing_manager', 'reception_dispatch_manager'],
  // Rerports
  INVENTORY_LINES: ['admin', 'packing_manager'],
  LOT_RESUME: ['admin', 'packing_manager'],
  LOT_TRACEABILITY: ['admin', 'packing_manager'],
  PRODUCTS_REPORT: ['admin'],
  BALANCE: ['admin', 'operator', 'packing_manager', 'reception_dispatch_manager'],
  VERSION_HISTORY: ['admin'],
  // Mantainers
  PRODUCTS: ['admin'],
  SUPPLIERS: ['admin'],
  CLIENTS: ['admin'],
  WORKSHIFTS: ['admin'],
  PROCESS_CATEGORIES: ['admin'],
  PADDOCKS: ['admin'],
  PROCESS_TYPES: ['admin'],
  SUPPLY_PRODUCTS: ['admin'],
  EMPLOYEES: ['admin'],
  UNITS: ['admin'],
  VARIETIES: ['admin'],
  WAREHOUSES: ['admin'],
  PACKAGING_TYPES: ['admin'],
  FRUITS: ['admin'],
  EQUIPMENTS: ['admin'],
};

export default Permits;
