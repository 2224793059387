/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { CardHeader, Card, CardContent, Grid, TextField } from '@material-ui/core';
import DatePicker from 'commons/components/DatePicker';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TableActionMenu from 'commons/components/TableActionMenu';
// icons
import SearchIcon from '@material-ui/icons/Search';

import produce from 'immer';
import { falsy, clone } from '../../../../utils/functions';
import saga from '../../saga';
import connectSaga from '../../../../utils/saga';
import { lotsActions } from '../../reducer';
import { selectState, actionProps } from '../../../../utils/reduxActions';
import { MultipleSelectorFreeSolo } from 'commons/components/MultipleSelectorFreeSolo';
import MultipleSelector from 'commons/components/MultipleSelector';
import lotExportExcel from 'utils/services/lotExportExcel';

const Filters = (props) => {
  const { defaultFilters, defaultPagination, classes, objects, actions, inputSearch, socketId } = props;

  const [filter, setFilter] = useState({ ...inputSearch });

  useEffect(() => {}, [filter]);

  useEffect(() => {
    setFilter({ ...inputSearch });
  }, [inputSearch]);

  const onFilterChange = (event, date) => {
    let input;
    let value;
    if (event.autocomplete) {
      input = event.input;
      value = event.value;
    } else {
      event.persist();
      input = falsy(event.target.type) ? event.currentTarget.id : event.target.id;
      value = event.target.type === 'date' ? date : event.target.value;
    }

    setFilter(
      produce((draft) => {
        draft[input] = value;
      }),
    );
  };
  const onLotNumberChange = (event, lots) => {
    setFilter(
      produce((draft) => {
        draft.receiptLotNumber = lots;
      }),
    );
  };

  const onProductChange = (event) => {
    setFilter(
      produce((draft) => {
        draft.productId = event.value;
      }),
    );
  };

  const submitFilter = () => {
    actions.changeFilters(filter);
    actions.getStateFromApi(filter, { ...defaultPagination });
  };

  const clearFilters = () => {
    setFilter(defaultFilters);
    actions.changeFilters({ ...defaultFilters });
    actions.paginationControls(defaultFilters, { ...defaultPagination });
  };

  const getLotReport = () => {
    actions.getLotReport(filter);
  };

  const statusOptions = [
    '',
    { value: 1, label: 'En bodega' },
    { value: 2, label: 'Despachado' },
    { value: 3, label: 'Procesado' },
    { value: 4, label: 'Procesado sin destino' },
  ];

  const paddocksOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.paddocks).sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      ),
    ];
  }, [objects.paddocks]);

  const fruitsOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.fruits).sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      ),
    ];
  }, [objects.fruits]);

  const varietiesOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.varieties).sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      ),
    ];
  }, [objects.varieties]);

  const processTypesOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.processTypes).sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      ),
    ];
  }, [objects.processTypes]);

  const productsOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.products).sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      ),
    ];
  }, [objects.products]);

  return (
    <Grid item container spacing={3}>
      <Grid item xs={12}>
        <Card className={classes.root}>
          <CardHeader title="Filtro" />
          <CardContent>
            <Grid container spacing={3} className={classes.container} justifyContent="flex-end">
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <DatePicker
                    inputId="fromDate"
                    label="Desde"
                    fullWidth
                    value={filter.fromDate}
                    onChange={onFilterChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <DatePicker
                    inputId="toDate"
                    label="Hasta"
                    fullWidth
                    value={filter.toDate}
                    onChange={onFilterChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    value={
                      filter.paddockId && paddocksOptions.find((p) => p.id === filter.paddockId)
                    }
                    id="autocomplete-paddock-filters-lots"
                    options={paddocksOptions}
                    getOptionLabel={(paddock) => paddock && paddock.name}
                    renderInput={(params) => (
                      <TextField
                        style={{ margin: 0 }}
                        {...params}
                        label="Cuartel"
                        margin="normal"
                      />
                    )}
                    onChange={(event, newValue) => {
                      const e = {
                        autocomplete: true,
                        input: 'paddockId',
                        value: newValue ? newValue.id : '',
                      };
                      onFilterChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    value={
                      filter.fruitId && fruitsOptions.find((fruit) => fruit.id === filter.fruitId)
                    }
                    id="autocomplete-fruit-filters-lots"
                    options={fruitsOptions}
                    getOptionLabel={(fruit) => fruit && fruit.name}
                    renderInput={(params) => (
                      <TextField
                        style={{ margin: 0 }}
                        {...params}
                        label="Especie"
                        margin="normal"
                      />
                    )}
                    onChange={(event, newValue) => {
                      const e = {
                        autocomplete: true,
                        input: 'fruitId',
                        value: newValue ? newValue.id : '',
                      };
                      onFilterChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    value={
                      filter.varietyId &&
                      varietiesOptions.find((variety) => variety.id === filter.varietyId)
                    }
                    id="autocomplete-variety-filters-lots"
                    options={varietiesOptions}
                    getOptionLabel={(variety) => variety && variety.name}
                    renderInput={(params) => (
                      <TextField
                        style={{ margin: 0 }}
                        {...params}
                        label="Variedad"
                        margin="normal"
                      />
                    )}
                    onChange={(event, newValue) => {
                      const e = {
                        autocomplete: true,
                        input: 'varietyId',
                        value: newValue ? newValue.id : '',
                      };
                      onFilterChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    value={
                      filter.processTypeId &&
                      processTypesOptions.find(
                        (processType) => processType.id === filter.processTypeId,
                      )
                    }
                    id="autocomplete-process-type-filters-lots"
                    options={processTypesOptions}
                    getOptionLabel={(processType) => processType && processType.name}
                    renderInput={(params) => (
                      <TextField
                        style={{ margin: 0 }}
                        {...params}
                        label="Tipo de proceso de origen"
                        margin="normal"
                      />
                    )}
                    onChange={(event, newValue) => {
                      const e = {
                        autocomplete: true,
                        input: 'processTypeId',
                        value: newValue ? newValue.id : '',
                      };
                      onFilterChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <MultipleSelector
                    id="autocomplete-product-filters-lots"
                    selectedValues={filter.productId}
                    options={productsOptions.filter((product) => product.name)}
                    label="Producto"
                    title="name"
                    valueName="id"
                    onChange={(event) => {
                      const e = {
                        autocomplete: true,
                        input: 'productId',
                        value: event.value || [],
                      };
                      onProductChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    value={
                      filter.statusId &&
                      statusOptions.find((status) => status.value === filter.statusId)
                    }
                    id="autocomplete-status-filters-lots"
                    options={statusOptions}
                    getOptionLabel={(status) => status && status.label}
                    renderInput={(params) => (
                      <TextField style={{ margin: 0 }} {...params} label="Estado" margin="normal" />
                    )}
                    onChange={(event, elem) => {
                      const e = {
                        autocomplete: true,
                        input: 'statusId',
                        value: elem?.value || '',
                      };
                      onFilterChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <MultipleSelectorFreeSolo
                    id="receiptLotNumber"
                    label="Número de lote"
                    placeholder="Número de lote"
                    value={filter.receiptLotNumber}
                    defaultValue={defaultFilters.receiptLotNumber}
                    onChange={onLotNumberChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    type="number"
                    id="lotId"
                    label="Identificador de pak"
                    value={filter.lotId}
                    onChange={onFilterChange}
                  />
                </Grid>
              </Grid>
              <Grid item container justifyContent="flex-end" spacing={3}>
                <Grid item xs={12} sm={4} md={3}>
                  <ButtonWithLoader
                    fullWidth
                    onClick={clearFilters}
                    autoFocus
                    buttonId="get-lots"
                    className={classes.filterButton}
                    size="small">
                    Limpiar
                  </ButtonWithLoader>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <ButtonWithLoader
                    fullWidth
                    onClick={submitFilter}
                    autoFocus
                    buttonId="get-lots"
                    className={classes.filterButton}
                    size="small"
                    startIcon={<SearchIcon />}>
                    Buscar
                  </ButtonWithLoader>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={12} sm={4} md={3}>
          <TableActionMenu
            menuItems={[
              {
                label: 'Exportar',
                listItems: [
                  {
                    label: 'Archivo Excel',
                    onClick: lotExportExcel,
                    params: [filter, socketId]
                  },
                ],
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const withSaga = connectSaga({ key: 'sagaLots', saga });

const withConnect = connect(
  selectState('lots.inputSearch', 'app.objects', 'app.socketId'),
  actionProps(
    clone(clone.OBJECT, {
      getLotReport: lotsActions.getLotReport,
      getStateFromApi: lotsActions.getStateFromApi,
      changeFilters: lotsActions.changeFilters,
      paginationControls: lotsActions.paginationControls,
    }),
  ),
);

export default compose(withConnect)(withSaga(React.memo(Filters)));
