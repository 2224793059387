const styles = theme => ({
  totalLabel: {
    ...theme.typography.button,
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.info.contrastText,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  tableRoot: {
    minHeight: '15vh',
    maxHeight: '25vh',
  },
  filterButton: {
    marginTop: '16px'
  }
});

export default styles;
