import camelize from 'camelcase-keys-deep';
export default class User {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  role() {
    let response = null;
    if (this.roleId) {
      response = this.env().Role.findBy('id', this.roleId);
    }
    return response;
  }
}
