/* eslint-disable no-param-reassign */
// @ts-nocheck
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
import { selectState, actionProps } from 'utils/reduxActions';
import { ReactReduxContext } from 'config/configureStore';
import connectSaga from 'utils/saga';
import saga from './saga';
// utils
import { clone, falsy, onChangeFnc, truthty } from 'utils/functions';
import { productsReportActions } from 'screens/ProductsReport/reducer';
import produce from 'immer';
import moment from 'moment';
// components
import { defaultProcesses } from 'utils/defaultProcesses';
import ProductsReportComponent from './components';
// styles
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import styles from './styles';

const useStyles = makeStyles(styles);

const defaultState = {
  productLots: {},
  processesIds: [],
  totalDeparture: 0,
  totalEntries: 0,
  productionGraphData: {},
  productionTable: {},
  marginData: {},
  productCosts: {},
  productCostsLoading: true,
  products: {},
  loading: true,
};

const ProductsReport = (props) => {
  const { actions, loading, objects, receiptLotIds, productionTableControls, socketId } =
    props;

  const [filter, setFilter] = useState({
    date: moment().format('YYYY-MM'),
    receiptLotNumber: '',
    socketId: socketId,
  });

  const [state, setState] = useState({ ...defaultState });

  useEffect(() => {
    actions.getStateFromApi({
      date: moment().format('YYYY-MM'),
      socketId: socketId,
    });
  }, []);

  useEffect(() => {
    if (!loading) {
      setState(
        produce((draft) => {
          draft.loading = false;
        }),
      );
    }
  }, [loading]);

  useEffect(() => {
    if (falsy(productionTableControls.processTypeId)) {
      if (
        truthty(
          Object.values(objects.processTypes).filter((p) => !defaultProcesses.includes(p.name))[0],
        )
      ) {
        actions.changeControls('productionTableControls', {
          processTypeId: Object.values(objects.processTypes).filter(
            (p) => !defaultProcesses.includes(p.name),
          )[0].id,
        });
      }
    }
  }, [productionTableControls.processTypeId, objects.processTypes, objects.processes, actions]);

  function changeControlsProduction(event, date) {
    onChangeFnc(actions.changeControls, event, date, true);
  }

  function onFilterChange(event, date) {
    let input;
    let value;
    if (event.autocomplete) {
      input = event.input;
      value = event.value;
    } else {
      event.persist();
      input = falsy(event.target.type) ? event.currentTarget.id : event.target.id;
      value = event.target.type === 'month' ? event.target.value : Number(event.target.value);
    }
    setFilter(
      produce((draft) => {
        draft[input] = value;
      }),
    );
  }

  function clearFilters() {
    setFilter({ date: moment().format('YYYY-MM'), socketId: socketId });
  }

  function filterFnc() {
    setState({ ...defaultState });
    actions.getStateFromApi({ ...filter });
  }

  const filteredRls = Object.values(objects.receiptLots).filter((rl) =>
    receiptLotIds.includes(rl.id),
  );

  const classes = useStyles();

  return (
    <ProductsReportComponent
      classes={classes}
      objects={objects}
      changeControlsProduction={changeControlsProduction}
      state={state}
      filter={filter}
      onFilterChange={onFilterChange}
      clearFilters={clearFilters}
      filterFnc={filterFnc}
      filteredRls={filteredRls}
    />
  );
};

const withSaga = connectSaga({ key: 'sagaProductsReport', saga });

const withConnect = connect(
  selectState(
    'productsReports.productionTableControls',
    'productsReports.processIds',
    'productsReports.receiptLotIds',
    'productsReports.lotIds',
    'productsReports.loading',
    'productsReports.objects',
    'app.socketId',
  ),
  actionProps(
    clone(clone.OBJECT, {
      getStateFromApi: productsReportActions.getStateFromApi,
      changeControls: productsReportActions.changeControls,
    }),
  ),
  null,
  { context: ReactReduxContext },
);

export default compose(withStyles(styles), withRouter, withConnect)(withSaga(ProductsReport));
