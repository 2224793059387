/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';


// Local constants
const PATH = 'lots/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const TOGGLE_WAREHOUSE_MOVEMENT_FORM = `${PATH}TOGGLE_WAREHOUSE_MOVEMENT_FORM`;

// Global constants for saga
export const LOTS = {
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  SUBMIT_WAREHOUSE_MOVEMENT: `${PATH}SUBMIT_WAREHOUSE_MOVEMENT`,
  SUBMIT_WAREHOUSE_MOVEMENT_SUCCESS: `${PATH}SUBMIT_WAREHOUSE_MOVEMENT_SUCCESS`,
  GET_LOT_REPORT: `${PATH}GET_LOT_REPORT`,
  GET_LOT_REPORT_SUCCESS: `${PATH}GET_LOT_REPORT_SUCCESS`,
  CHANGE_PAGINATION: `${PATH}CHANGE_PAGINATION`,
  CHANGE_FILTERS: `${PATH}CHANGE_FILTERS`
};

// actions
export const lotsActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls'),
  toggleWarehouseMovementForm: createAction(TOGGLE_WAREHOUSE_MOVEMENT_FORM, 'id', 'lot'),
  // api
  changeFilters: createAction(LOTS.CHANGE_FILTERS, 'filter'),
  paginationControls: createAction(LOTS.CHANGE_PAGINATION, 'filters', 'pagination'),
  submitWarehouseMovement: createAction(LOTS.SUBMIT_WAREHOUSE_MOVEMENT, 'id', 'controls'),
  getStateFromApi: createAction(LOTS.GET_STATE_FROM_API, 'filters', 'pagination'),
  getLotReport: createAction(LOTS.GET_LOT_REPORT, 'filters'),
};

const initialState = {
  formControls: { warehouseId: '' },
  lotList: [],
  formIsOpen: false,
  lotId: [],
  error: false,
  statusId: '',
  pagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0
  },
  loadingLots: false,
  inputSearch: {}
};

const lots = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },
  [TOGGLE_FORM](state, action) {
    state.formIsOpen = !state.formIsOpen;
    state.lotId = [];
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls
    };
  },
  [TOGGLE_WAREHOUSE_MOVEMENT_FORM](state, action) {
    state.formIsOpen = !state.formIsOpen;
    state.lotId = action.id;
    state.formControls.warehouseId = action.lot.warehouseId || '';
  },
  // api
  [LOTS.CHANGE_PAGINATION](state, action) {
    state.loadingLots = true;
    state.pagination = {
      ...state.pagination,
      ...action.pagination
    };
  },
  [LOTS.CHANGE_FILTERS](state, action) {
    state.inputSearch = { 
      ...state.inputSearch, 
      ...action.filter 
    }
  },
  [LOTS.GET_STATE_FROM_API](state, action) {
    state.loadingLots = true;
  },
  [LOTS.GET_STATE_FROM_API_SUCCESS](state, action) {
    state.loadingLots = false;
    state.lotList = action.payload.lots;
    state.pagination = {
      ...state.pagination,
      ...(action.pagination || {}),
      count: action.payload.count
    };
  },
  [LOTS.SUBMIT_WAREHOUSE_MOVEMENT_SUCCESS](state, action) {
    state.formIsOpen = false;
    state.formControls.warehouseId = '';
    state.lotId = [];
  },
});

export default lots;
