import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';

const Filters = props => (
  <Grid item xs={12}>
    <Card className={props.classes.root}>
      <CardHeader title="Filtro" />
      <CardContent>
        <Grid container spacing={3} className={props.classes.container}>
          <Grid container item sm={4} xs={12}>
            <TextField fullWidth id="taxNumber" label={props.t("identifier:name")} value={props.filter.taxNumber} onChange={props.onFilterChange} />
          </Grid>
          <Grid container item sm={4} xs={12}>
            <TextField fullWidth id="name" label="Nombre" value={props.filter.name} onChange={props.onFilterChange} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);

export default withTranslation()(Filters);
