/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';


// Local constants
const PATH = 'varieties/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_EDIT_VARIETY = `${PATH}TOGGLE_EDIT_VARIETY`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;

// Global constants for saga
export const VARIETIES = {
  CREATE_VARIETY: `${PATH}CREATE_VARIETY`,
  CREATE_VARIETY_SUCCESS: `${PATH}CREATE_VARIETY_SUCCESS`,
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  DELETE_VARIETY: `${PATH}DELETE_VARIETY`,
  DELETE_VARIETY_SUCCESS: `${PATH}DELETE_VARIETY_SUCCESS`,
  EDIT_VARIETY_SUCCESS: `${PATH}EDIT_VARIETY_SUCCESS`,
  EDIT_VARIETY: `${PATH}EDIT_VARIETY`,
  EXPORT_VARIETIES: `${PATH}EXPORT_VARIETIES`,
  EXPORT_VARIETIES_SUCCESS: `${PATH}EXPORT_VARIETIES_SUCCESS`,
};

// actions
export const varietiesActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  toggleEdit: createAction(TOGGLE_EDIT_VARIETY, 'item'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  // api
  getStateFromApi: createAction(VARIETIES.GET_STATE_FROM_API),
  createVariety: createAction(VARIETIES.CREATE_VARIETY, 'controls'),
  editVariety: createAction(VARIETIES.EDIT_VARIETY, 'id', 'controls'),
  deleteVariety: createAction(VARIETIES.DELETE_VARIETY, 'id'),

  exportVarieties: createAction(VARIETIES.EXPORT_VARIETIES),
};

const defaultInvalidInputs = {
  name: false,
};

const initialState = {
  formControls: {
    name: '',
    fruitId: '',
  },
  invalidControls: {
    name: false,
    fruitId: false,
  },
  formIsOpen: false,
  deleteIsOpen: false,
  toDeleteId: '',
  toEditId: '',
  edit: false,
  error: false,
};

const varieties = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.edit = false;
    state.formControls.name = '';
    state.formControls.fruitId = '';
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls,
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    };
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [TOGGLE_EDIT_VARIETY](state, action) {
    state.formIsOpen = true;
    state.toDeleteId = '';
    state.toEditId = action.item.id;
    state.formControls.name = action.item.name;
    state.formControls.fruitId = action.item.fruitId;
    state.edit = true;
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    };
  },
  // api
  [VARIETIES.CREATE_VARIETY_SUCCESS](state) {
    state.formIsOpen = false;
    state.formControls.name = '';
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [VARIETIES.DELETE_VARIETY_SUCCESS](state) {
    state.deleteIsOpen = false;
    state.toDeleteId = '';
  },
  [VARIETIES.EDIT_VARIETY_SUCCESS](state) {
    state.formControls.name = '';
    state.formControls.fruitId = '';
    state.invalidControls = { ...defaultInvalidInputs };
    state.formIsOpen = false;
    state.toDeleteId = '';
    state.toEditId = '';
    state.edit = false;
  },
});

export default varieties;
