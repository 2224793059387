// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';

import apiRequest, { apiSuccess, objectToQueryString } from 'utils/api';
import { DISPATCHES } from '../reducer';
import { truthty } from 'utils/functions';
import { appActions } from 'commons/reducer';

function* getStateFromApi() {
  yield takeLatest([DISPATCHES.GET_STATE_FROM_API, DISPATCHES.CHANGE_PAGINATION], function* (action) {
    yield put(appActions.setLoader('filter-dispatches', true));
    const response = yield apiRequest(`api/v1/dispatches?${objectToQueryString(
      {
        page: action.pagination.page,
        rowsPerPage: action.pagination.rowsPerPage,
        ...action.filters
      })}`, { method: 'get' });
    yield put(apiSuccess(DISPATCHES.GET_STATE_FROM_API_SUCCESS, response, { pagination: action.pagination }));
    yield put(appActions.setLoader('filter-dispatches', false));
  });
}

function* newDispatch() {
  yield takeLatest(DISPATCHES.NEW_DISPATCH, function* (action) {
    const response = yield apiRequest('api/v1/dispatches/new', { method: 'get' });

    yield put(apiSuccess((DISPATCHES.NEW_DISPATCH_SUCCESS), response));
  })
}

function* createDispatch() {
  yield takeLatest(DISPATCHES.CREATE_DISPATCH, function* (action) {
    yield put(appActions.setLoader('create-edit-dispatch', true));

    const response = yield apiRequest(
      'api/v1/dispatches',
      {
        method: 'post',
        body: JSON.stringify(action.controls),
      }
    );

    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(DISPATCHES.CREATE_DISPATCH_SUCCESS, response));
    }

    yield put(appActions.setLoader('create-edit-dispatch', false));
  });
}

function* editDispatch() {
  yield takeLatest(DISPATCHES.EDIT_DISPATCH, function* (action) {
    yield put(appActions.setLoader('create-edit-dispatch', true));
    const response = yield apiRequest(`api/v1/dispatches/${action.id}`,
      {
        method: 'put',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(DISPATCHES.EDIT_DISPATCH_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-dispatch', false));
  });
}

function* deleteDispatch() {
  yield takeLatest(DISPATCHES.DELETE_DISPATCH, function* (action) {
    yield put(appActions.setLoader('delete-dispatch', true));
    const response = yield apiRequest(`api/v1/dispatches/${action.id}`, { method: 'delete' });
    yield put(apiSuccess(DISPATCHES.DELETE_DISPATCH_SUCCESS, response));
    yield put(appActions.setLoader('delete-dispatch', false));
  });
}

function* exportDispatches() {
  yield takeLatest(DISPATCHES.EXPORT_DISPATCHES, function* (action) {
    yield put(appActions.setLoader('export-dispatches', true));
    const query = objectToQueryString(action.controls);
    const response = yield apiRequest(`api/v1/dispatches/export?${query}`, { method: 'get' }, false, 'despachos.xlsx');
    yield put(apiSuccess(DISPATCHES.EXPORT_DISPATCHES_SUCCESS, response));
    yield put(appActions.setLoader('export-dispatches', false));
  });
}

export default function* saga() {
  yield spawn(getStateFromApi);
  yield spawn(deleteDispatch);
  yield spawn(newDispatch);
  yield spawn(createDispatch);
  yield spawn(editDispatch);
  yield spawn(exportDispatches);
}
