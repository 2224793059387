// @ts-nocheck
/* eslint-disable no-shadow */
import React from 'react';
// ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// utils
import { truthty, formatRut } from '../../../../utils/functions';
import MaintainersPaginator from '../../../../commons/components/MaintainersPaginator';
import {withTranslation} from 'react-i18next';

const ItemList = (props) => {
  const {
    classes,
    clientList,
    toggleEdit,
    toggleDelete,
    anchorEl,
    handleClick,
    handleClose,
    t,
    i18n,
  } = props;

  const getListItem = (client, openMenuHandler, closeMenuHandler, toggleEdit, toggleDelete, anchorEl) => (
    <ListItem key={`client-${client.id}`}>
      <ListItemAvatar>
        <Avatar className={classes.blue}>
          {(client.entity().name || 'p')[0].toUpperCase()}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={client.entity().name}
        secondary={`${t("identifier:name")}: ${formatRut(client.entity().taxNumber, i18n.language)}${truthty(client.email) ? `, email: ${client.email}` : ''}`}
      />
      <ListItemSecondaryAction>
        <IconButton
          onClick={openMenuHandler}
          data-id={client.id}
        >
          <MoreVertIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );

  return (
    <Grid item xs={12}>
      <Card className={classes.root}>
        <CardContent>
          {truthty(clientList) ? (
            <MaintainersPaginator
              classes={classes}
              list={clientList}
              getListItem={getListItem}
              openMenuHandler={handleClick}
              closeMenuHandler={handleClose}
              toggleEdit={toggleEdit}
              toggleDelete={toggleDelete}
              anchorEl={anchorEl}
            />
          ) : (
            <Grid container item xs={12} alignItems="center" justifyContent="center">
              <Typography variant="h6" component="h2">
                No se han ingresado clientes
              </Typography>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default withTranslation()(ItemList);
