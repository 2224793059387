export function vendorWrite(device, index, value) {
  return device.controlTransferIn({
    requestType: 'vendor',
    recipient: 'device',
    request: 0x01,
    value,
    index,
  }, 1);
}

export function vendorRead(device, index, value) {
  return device.controlTransferOut({
    requestType: 'vendor',
    recipient: 'device',
    request: 0x01,
    value,
    index,
  }, new ArrayBuffer());
}

export function setBaudrate(device) {
  const newBaud = 9600;
  return device.controlTransferIn({
    requestType: 'class',
    recipient: 'interface',
    request: 0x21,
    value: 0,
    index: 0,
  }, 7)
    .then(data => {
      const parameters = data.data;
      parameters.setInt32(0, newBaud, true);
      parameters[4] = 1; // 1 stop bit
      parameters[5] = 0; // no parity
      parameters[6] = 8; // 8 bit characters
      return device.controlTransferOut({
        requestType: 'class',
        recipient: 'interface',
        request: 0x20,
        value: 0,
        index: 0,
      }, parameters);
    })
    .then(() => vendorWrite(device, 0x0, 0x0)) // no flow control
    .then(() => vendorWrite(device, 8, 0)) // reset upstream data pipes
    .then(() => vendorWrite(device, 9, 0));
}