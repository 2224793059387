// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';

import apiRequest, { apiSuccess } from 'utils/api';
import { SUPPLY_PRODUCTS } from '../reducer';
import { truthty } from 'utils/functions';
import { appActions } from 'commons/reducer';

function* getStateFromApi() {
  yield takeLatest(SUPPLY_PRODUCTS.GET_STATE_FROM_API, function* (action) {
    const response = yield apiRequest('api/v1/supplyProducts', { method: 'get' });
    yield put(apiSuccess(SUPPLY_PRODUCTS.GET_STATE_FROM_API_SUCCESS, response));
  });
}

function* createSupplyProduct() {
  yield takeLatest(SUPPLY_PRODUCTS.CREATE_SUPPLY_PRODUCT, function* (action) {
    yield put(appActions.setLoader('create-edit-supplyProduct', true));
    const response = yield apiRequest('api/v1/supplyProducts',
      {
        method: 'post',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(SUPPLY_PRODUCTS.CREATE_SUPPLY_PRODUCT_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-supplyProduct', false));
  });
}

function* editSupplyProduct() {
  yield takeLatest(SUPPLY_PRODUCTS.EDIT_SUPPLY_PRODUCT, function* (action) {
    yield put(appActions.setLoader('create-edit-supplyProduct', true));
    const response = yield apiRequest(`api/v1/supplyProducts/${action.id}`,
      {
        method: 'put',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(SUPPLY_PRODUCTS.EDIT_SUPPLY_PRODUCT_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-supplyProduct', false));
  });
}

function* deleteSupplyProduct() {
  yield takeLatest(SUPPLY_PRODUCTS.DELETE_SUPPLY_PRODUCT, function* (action) {
    yield put(appActions.setLoader('delete-supplyProduct', true));
    const response = yield apiRequest(`api/v1/supplyProducts/${action.id}`, { method: 'delete' });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    }
    const item = Object.values(response)[0];
    yield put(apiSuccess(SUPPLY_PRODUCTS.DELETE_SUPPLY_PRODUCT_SUCCESS, { item, type: 'supplyProducts' }));
    yield put(appActions.setLoader('delete-supplyProduct', false));
  });
}

function* exportSupplyProducts() {
  yield takeLatest(SUPPLY_PRODUCTS.EXPORT_SUPPLY_PRODUCT, function* (action) {
    yield put(appActions.setLoader('export-supplyProducts', true));
    const response = yield apiRequest('api/v1/supplyProducts/export', { method: 'get' }, false, 'supplyProducts.xlsx');
    yield put(apiSuccess(SUPPLY_PRODUCTS.EXPORT_SUPPLY_PRODUCT_SUCCESS, response));
    yield put(appActions.setLoader('export-supplyProducts', false));
  });
}

export default function* saga() {
  yield spawn(getStateFromApi);
  yield spawn(deleteSupplyProduct);
  yield spawn(createSupplyProduct);
  yield spawn(editSupplyProduct);
  yield spawn(exportSupplyProducts);
}
