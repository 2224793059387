import camelize from 'camelcase-keys-deep';
export default class Purchase {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  taxableDocument() {
    const response = this.env().TaxableDocument.findBy('purchaseId', this.id);
    return response;
  }

  supplier() {
    let response = null;
    if (this.supplierId) {
      response = this.env().Supplier.findBy('id', this.supplierId);
    }
    return response;
  }

  getNetAmount() {
    const serviceNetAmount = this.env().ServiceLine.findAllBy('purchaseId', this.id)
      .reduce((acum, serviceLine) => acum + (serviceLine.line().quantity * serviceLine.line().price), 0);
    const netAmount = this.env().SupplyProductLine.findAllBy('purchaseId', this.id)
      .reduce((acum, supplyProductLine) => acum + (supplyProductLine.line().quantity * supplyProductLine.line().price), serviceNetAmount);
    return netAmount;
  }

  serviceLines() {
    const serviceLines = this.env().ServiceLine.findAllBy('purchaseId', this.id);
    return serviceLines;
  }

  supplyProductLines() {
    const supplyProductLine = this.env().SupplyProductLine.findAllBy('purchaseId', this.id);
    return supplyProductLine;
  }
}
