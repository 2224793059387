import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { TruncateText } from '../TruncateText';

export const TruncateTextToolTip = ({ text = '', lines = 1, classes = null, style = {} }) => (
  <Tooltip title={text} placement="top">
    <div>
      <TruncateText text={text} lines={lines} classes={classes} style={style} />
    </div>
  </Tooltip>
);
