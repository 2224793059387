import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

export const Header = ({ headerList, isMobileDevice }) => {
  const theme = useTheme();
  return isMobileDevice ? null : (
    <Box
      display="flex"
      justifyContent="flex-between"
      alignItems="center"
      width="100%"
      style={{ background: theme.palette.primary.main }}>
      {headerList.map((header) => (
        <Typography
          style={{
            color: 'white',
            fontSize: '14px',
            textAlign: 'left',
            width: header.width,
            margin: '20px 14px',
          }}>
          {header.name}
        </Typography>
      ))}
    </Box>
  );
};
