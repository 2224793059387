/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'suppliers/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_EDIT_PROVIDER = `${PATH}TOGGLE_EDIT_PROVIDER`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;

// Global constants for saga
export const PROVIDERS = {
  CREATE_PROVIDER: `${PATH}CREATE_PROVIDER`,
  CREATE_PROVIDER_SUCCESS: `${PATH}CREATE_PROVIDER_SUCCESS`,
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  DELETE_PROVIDER: `${PATH}DELETE_PROVIDER`,
  DELETE_PROVIDER_SUCCESS: `${PATH}DELETE_PROVIDER_SUCCESS`,
  EDIT_PROVIDER_SUCCESS: `${PATH}EDIT_PROVIDER_SUCCESS`,
  EDIT_PROVIDER: `${PATH}EDIT_PROVIDER`,
  EXPORT_PROVIDER: `${PATH}EXPORT_PROVIDER`,
  EXPORT_PROVIDER_SUCCESS: `${PATH}EXPORT_PROVIDER_SUCCESS`,
};

// actions
export const suppliersActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  toggleEdit: createAction(TOGGLE_EDIT_PROVIDER, 'entity', 'item'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  // api
  getStateFromApi: createAction(PROVIDERS.GET_STATE_FROM_API),
  createSupplier: createAction(PROVIDERS.CREATE_PROVIDER, 'controls'),
  editSupplier: createAction(PROVIDERS.EDIT_PROVIDER, 'id', 'controls'),
  deleteSupplier: createAction(PROVIDERS.DELETE_PROVIDER, 'id'),
  
  exportSuppliers: createAction(PROVIDERS.EXPORT_PROVIDER),
};

const defaultInvalidControls = {
  name: false,
  taxNumber: false,
  email: false,
};

const initialState = {
  formControls: {
    name: '',
    taxNumber: '',
    email: '',
    code: '',
    region: '',
    province: '',
    sector: ''
  },
  invalidControls: {
    name: false,
    taxNumber: false,
    email: false,
  },
  formIsOpen: false,
  deleteIsOpen: false,
  toDeleteId: '',
  toEditId: '',
  edit: false,
  error: false,
};

const suppliers = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.edit = false;
    state.formControls.name = '';
    state.formControls.taxNumber = '';
    state.formControls.email = '';
    state.formControls.code = '';
    state.formControls.region = '';
    state.formControls.province = '';
    state.formControls.sector = '';
    state.invalidControls = { ...defaultInvalidControls };
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls,
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    };
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [TOGGLE_EDIT_PROVIDER](state, action) {
    state.formIsOpen = true;
    state.toDeleteId = '';
    state.toEditId = action.item.id;
    state.formControls.name = action.entity.name;
    state.formControls.taxNumber = action.entity.taxNumber;
    state.formControls.email = action.item.email;
    state.formControls.code = action.item.code;
    state.formControls.region = action.item.region;
    state.formControls.province = action.item.province;
    state.formControls.sector = action.item.sector;
    state.edit = true;
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    };
  },
  // api
  [PROVIDERS.CREATE_PROVIDER_SUCCESS](state, action) {
    state.formIsOpen = false;
    state.formControls.name = '';
    state.formControls.taxNumber = '';
    state.formControls.email = '';
    state.formControls.code = '';
    state.formControls.region = '';
    state.formControls.province = '';
    state.formControls.sector = '';
    state.invalidControls = { ...defaultInvalidControls };
  },
  [PROVIDERS.DELETE_PROVIDER_SUCCESS](state, action) {
    state.deleteIsOpen = false;
    state.toDeleteId = '';
  },
  [PROVIDERS.EDIT_PROVIDER_SUCCESS](state, action) {
    state.formIsOpen = false;
    state.toDeleteId = '';
    state.toEditId = '';
    state.edit = false;
  },
});

export default suppliers;
