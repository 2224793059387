// @ts-nocheck
import React from 'react';
import { useDispatch } from 'react-redux';
import { balanceActions } from 'screens/Balance/reducer';
import { useSelector } from 'react-redux';
import { useState, useMemo } from "react";
// material ui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
// icons
import SearchIcon from '@material-ui/icons/Search';
// components
import ButtonWithLoader from '../../../../commons/containers/ButtonWithLoader';
import { MultipleSelectorFreeSolo } from 'commons/components/MultipleSelectorFreeSolo';
import MultipleSelector from '../../../../commons/components/MultipleSelector';
import DatePicker from '../../../../commons/components/DatePicker';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import produce from 'immer';
import { falsy } from 'utils/functions';


const Filters = (props) => {
  const { defaultFilters, 
          actions,
          classes, 
          objects, 
        } = props;

  const [filter, setFilter] = useState({ ...defaultFilters });

  const onFilterChange = (event, date) => {
    let input;
    let value;
    if (event.autocomplete) {
      input = event.input;
      value = event.value;
    } else {
      // event.persist();
      input = falsy(event.target.type) ? event.currentTarget.id : event.target.id;
      value = event.target?.type === 'date' ? date : event.target?.value;
    }

    setFilter(
      produce((draft) => {
        draft[input] = value;
      }),
    );
  };

  const filterFnc = () => {
    actions.getStateReport({ ...filter });
  }

  const lotsOptions = useSelector((state) => state.balance.info.lotsInfo)?.map((lot) => ( {
    label: (lot.id).toString(),
    value: parseInt(lot.id),
  }));

  const filteredLots = useSelector((state) => state.balance.formControls.lotNumber);
  

  const dispacher = useDispatch()

  const handleSelectionChange = (event) => {
    dispacher(balanceActions.handleSelectionChange(event.value));
  }

  const [showLotsMultipleSelector, setShowLotsMultipleSelector] = useState(false);

  const searchFunction = () => {
    filterFnc();
    setShowLotsMultipleSelector(true)
  }

  const receiptLotFilter = (event) => {
    // onFilterChange(event)
    setFilter(
      produce((draft) => {
        draft[event.input] = event.value;
      }),
    );
    setShowLotsMultipleSelector(false)
  }

  const columnSelector = showLotsMultipleSelector ? (4):(8);

  const suppliersOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.suppliers).sort((a, b) =>
        a.entity().name.toLowerCase() > b.entity().name.toLowerCase() ? 1 : -1,
      ),
    ];
  }, [objects.suppliers]);

  let fruitsOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.fruits).sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      ),
    ];
  }, [objects.fruits]);

  let varietiesOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.varieties).sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      ),
    ];
  }, [objects.varieties]);

  let productsOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.products).sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      ),
    ];
  }, [objects.products]);

  const clearFilters = () => {
    setFilter({...defaultFilters});
    actions.changeFilters({ ...defaultFilters });
    setShowLotsMultipleSelector(false)
  };

  fruitsOptions = filter.varietyId
    ? fruitsOptions.filter(fruit => varietiesOptions.some(variety => variety.id === filter.varietyId && variety.fruitId === fruit.id))
    : filter.productId 
    ? fruitsOptions.filter(fruit =>
        varietiesOptions.some(variety =>
          productsOptions.some(product => product.varietyId === variety.id && product.id === filter.productId)
        )
      )
    : fruitsOptions;

  varietiesOptions = filter.fruitId
    ? varietiesOptions.filter(variety => variety.fruitId === filter.fruitId)
    : filter.productId 
    ? varietiesOptions.filter(variety =>
        productsOptions.some(product => product.varietyId === variety.id && product.id === filter.productId)
      )
    : varietiesOptions;

  productsOptions = filter.varietyId
    ? productsOptions.filter(product => product.varietyId === filter.varietyId)
    : filter.fruitId
    ? productsOptions.filter(product =>
        varietiesOptions.some(variety => variety.id === product.varietyId)
      )
    : productsOptions;

  if (filter.productId) {
    const selectedVarietyIds = productsOptions.filter(product => product.id === filter.productId).map(product => product.varietyId);
    const selectedFruitIds = varietiesOptions.filter(variety => selectedVarietyIds.includes(variety.id)).map(variety => variety.fruitId);
    fruitsOptions = fruitsOptions.filter(fruit => selectedFruitIds.includes(fruit.id));
  }


  return (
    <Grid item xs={12} className='no-print'>
      <Card className={classes.root}>
        <CardContent>
          <Grid container spacing={3} className={classes.container}>
            <Grid item sm={4} xs={12}>
              <DatePicker
                inputId="fromDate"
                label="Desde"
                fullWidth
                value={filter.fromDate}
                onChange={onFilterChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <DatePicker
                inputId="toDate"
                label="Hasta"
                fullWidth
                value={filter.toDate}
                onChange={onFilterChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Autocomplete
                value={
                  filter.supplierId && suppliersOptions.find((s) => s.id === filter.supplierId)
                }
                id="autocomplete-suppliers-filters-balance"
                options={suppliersOptions}
                getOptionLabel={(supplier) => supplier && supplier.entity().name}
                renderInput={(params) => (
                  <TextField
                    style={{ margin: 0 }}
                    {...params}
                    label="Proveedor"
                    margin="normal"
                  />
                )}
                onChange={(event, newValue) => {
                  const e = {
                    autocomplete: true,
                    input: 'supplierId',
                    value: newValue ? newValue.id : '',
                  };
                  onFilterChange(e);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Autocomplete
                value={filter.fruitId && fruitsOptions.find((f) => f.id === filter.fruitId)}
                id="autocomplete-fruit-filters-balance"
                options={fruitsOptions}
                getOptionLabel={(fruit) => fruit && fruit.name}
                renderInput={(params) => (
                  <TextField
                    style={{ margin: 0 }}
                    {...params}
                    label="Especie"
                    margin="normal"
                  />
                )}
                onChange={(event, newValue) => {
                  const e = {
                    autocomplete: true,
                    input: 'fruitId',
                    value: newValue ? newValue.id : '',
                  };
                  onFilterChange(e);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Autocomplete
                value={
                  filter.varietyId && varietiesOptions.find((v) => v.id === filter.varietyId)
                }
                id="autocomplete-variety-filters-balance"
                options={varietiesOptions}
                getOptionLabel={(variety) => variety && variety.name}
                renderInput={(params) => (
                  <TextField
                    style={{ margin: 0 }}
                    {...params}
                    label="Variedad"
                    margin="normal"
                  />
                )}
                onChange={(event, newValue) => {
                  const e = {
                    autocomplete: true,
                    input: 'varietyId',
                    value: newValue ? newValue.id : '',
                  };
                  onFilterChange(e);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Autocomplete
                value={
                  filter.productId && productsOptions.find((p) => p.id === filter.productId)
                }
                id="autocomplete-product-type-filters-balance"
                options={productsOptions}
                getOptionLabel={(product) => product && product.name}
                renderInput={(params) => (
                  <TextField
                    style={{ margin: 0 }}
                    {...params}
                    label="Producto"
                    margin="normal"
                  />
                )}
                onChange={(event, newValue) => {
                  const e = {
                    autocomplete: true,
                    input: 'productId',
                    value: newValue ? newValue.id : '',
                  };
                  onFilterChange(e);
                }}
              />
            </Grid>
            <Grid item sm={columnSelector} xs={12}>
                <MultipleSelectorFreeSolo
                  id="receiptLotNumber"
                  label="Número de lote"
                  placeholder="Número de lote"
                  value={filter.receiptLotNumber}
                  onChange={(event, value) => {
                    const e = {
                      autocomplete: false,
                      input: 'receiptLotNumber',
                      value: value,
                    };
                    receiptLotFilter(e) 
                  }}
                />
            </Grid>

              {showLotsMultipleSelector && (
            <Grid item sm={4} xs={12}>
              <MultipleSelector
                id="lotNumber"
                selectedValues={filteredLots}
                options={lotsOptions}
                onChange={handleSelectionChange}
                label="Número de pak"
                whereNotOptionLabel="No hay paks"
              />
            </Grid>
              )}
            <Grid item container spacing={3} justifyContent="flex-end">
              <Grid item xs={12} sm={4} md={3}>
                <ButtonWithLoader
                  fullWidth
                  onClick={clearFilters}
                  autoFocus
                  buttonId="get-lots"
                  className={classes.filterButton}
                  size="small">
                  Limpiar
                </ButtonWithLoader>
              </Grid>
              <Grid item sm={4} xs={12}>
                <ButtonWithLoader
                  fullWidth
                  onClick={searchFunction}
                  autoFocus
                  buttonId="get-process"
                  className={classes.filterButton}
                  size="small"
                  startIcon={<SearchIcon />}
                >
                  Buscar
                </ButtonWithLoader>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Filters;