import camelize from 'camelcase-keys-deep';
export default class ReceiptLot {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  paddock() {
    let response = null;
    if (this.paddockId) {
      response = this.env().Paddock.findBy('id', this.paddockId);
    }
    return response;
  }

  receipt() {
    return this.env().Receipt.findBy('receiptLotId', this.id);
  }

  lots() {
    let result = [];
    const receipt = this.receipt();
    if (receipt) {
      const process = this.env().Process.findBy('id', receipt.processId);
      if (process) {
        result = process.departureLots();
      }
    }
    return result;
  }

  totalQuantity() {
    return this.lots().reduce((acum, lot) => acum + lot.quantity, 0);
  }
}
