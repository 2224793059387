/* eslint-disable no-param-reassign */
// @ts-nocheck
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
import { selectState, actionProps } from 'utils/reduxActions';
import { homeActions } from 'screens/Home/reducer';
import { ReactReduxContext } from 'config/configureStore';
import { purchasesActions } from 'screens/Purchases/reducer';
import connectSaga from 'utils/saga';
import saga from './saga';
import { appActions } from 'commons/reducer';
// components
import PurchasesComponent from './components';
// utils
import produce from 'immer';
import moment from 'moment';
import { clone, falsy } from 'utils/functions';
// styles
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import OptionsMenu from './components/OptionsMenu';
import InventoryAdjustmentModal from '../InventoryLines/components/InventoryAdjustmentModal'

const useStyles = makeStyles(styles);

const defaultFilters = {
  dateType: 'issue_date',
  service: '',
  fromDate: moment().startOf('month').toISOString(),
  toDate: moment().endOf('month').toISOString(),
  supplierId: '',
  documentNumber: '',
};

const Purchases = (props) => {
  const [purchaseList, setPurchaseList] = useState([]);
  const [filter, setFilter] = useState({ ...defaultFilters });
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setPurchaseList(Object.values(props.objects.purchases).filter((p) => props.purchasesList.includes(p.id)));
    // setPurchaseMemo();
  }, [props.purchasesList, props.objects.purchases]);

  useEffect(() => {
    props.actions.getStateFromApi({ ...defaultFilters, firstRequest: true });
  }, [props.actions]);

  const setPurchaseMemo = useCallback(() => {
    setPurchaseList(Object.values(props.objects.purchases).filter((p) => props.purchasesList.includes(p.id)));
  }, [props.objects.purchases, props.purchasesList])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function filterPurchases() {
    props.actions.getStateFromApi(filter);
  }

  function clearFilters() {
    setFilter({ ...defaultFilters });
    props.actions.getStateFromApi(defaultFilters);
  }

  function toggleForm() {
    props.actions.toggleForm('formIsOpen');
  }

  function closeForm() {
    props.actions.toggleForm('deleteIsOpen');
  }

  function toggleDelete() {
    props.actions.toggleDelete(anchorEl.dataset.id);
    handleClose();
  }

  function toggleEdit() {
    const item = Object.values(props.objects.purchases)
      .find((item) => item.id === Number(anchorEl.dataset.id));
    props.actions.toggleEdit(item, item.serviceLines(), item.supplyProductLines());
    handleClose();
  }

  function changeControls(event, date) {
    let controls;
    let invalidControls;
    if (event.autocomplete) {
      controls = { [event.input]: event.value };
      invalidControls = { [event.input]: false };
    } else {
      const value = event.target.type === 'date' ? date : (event.target.type === 'checkbox' ? event.target.checked : event.target.value);
      controls = { [event.currentTarget.id]: value };
      invalidControls = { [event.currentTarget.id]: false };
      if (event.currentTarget.id === 'lineForm') {
        controls.supplyProductId = '';
        controls.description = '';
        controls.quantity = '';
        controls.price = '';
        controls.toEditServiceLineIndex = '';
        invalidControls.supplyProductId = false;
        invalidControls.description = false;
        invalidControls.quantity = false;
        invalidControls.price = false;
        invalidControls.toEditServiceLineIndex = false;
      }
    }
    props.actions.changeControls(controls, invalidControls);
  }

  function submit() {
    if (
      falsy(props.formControls.supplierId)
      || falsy(props.formControls.documentNumber)
      || (falsy(props.serviceLines) && falsy(props.supplyProductLines))
    ) {
      props.actions.setInvalidControls({
        supplierId: falsy(props.formControls.supplierId),
        documentNumber: falsy(props.formControls.documentNumber),
      });
    if (falsy(props.serviceLines) && falsy(props.supplyProductLines)){
      props.actions.setError('Debes agregar un insumo o un servicio');
    }
    } else if (props.edit) {
      props.actions.editPurchase(props.toEditId, props.formControls, props.serviceLines, props.supplyProductLines);
    } else {
      props.actions.createPurchase(props.formControls, props.serviceLines, props.supplyProductLines);
    }
  }

  function submitDelete() {
    props.actions.deletePurchase(props.toDeleteId);
  }

  function onFilterChange(event, date) {
    let input;
    let value;
    if (event.autocomplete) {
      input = event.input;
      value = event.value;
    } else {
      event.persist();
      input = falsy(event.target.type) ? event.currentTarget.id : event.target.id;
      value = event.target.type === 'date' ? date : event.target.value;
    }

    setFilter(produce((draft) => {
      draft[input] = value;
    }));
  }

  function addServiceLine() {
    if (
      (props.formControls.lineForm === 'service' && falsy(props.formControls.description))
      || (props.formControls.lineForm !== 'service' && falsy(props.formControls.supplyProductId))
      || (props.formControls.lineForm !== 'service' && falsy(props.formControls.warehouseId))
      || falsy(props.formControls.quantity)
      || props.formControls.quantity < 1
      || falsy(props.formControls.price)
      || props.formControls.price <= 0
    ) {
      props.actions.setInvalidControls({
        supplyProductId: props.formControls.lineForm !== 'service' && falsy(props.formControls.supplyProductId),
        warehouseId: props.formControls.lineForm !== 'service' && falsy(props.formControls.warehouseId),
        description: props.formControls.lineForm === 'service' && falsy(props.formControls.description),
        quantity: falsy(props.formControls.quantity) || props.formControls.quantity < 1,
        price: falsy(props.formControls.price) || props.formControls.price < 1,
      });
    } else if (props.formControls.lineForm === 'service') {
      props.actions.addServiceLine();
    } else {
      props.actions.addSupplyLine();
    }
  }

  function deleteLine(event) {
    props.actions.deleteLine(Number(event.currentTarget.dataset.index), event.currentTarget.dataset.type);
  }

  function setEditLine(event) {
    props.actions.setInvalidControls({
      supplyProductId: false,
      warehouseId: false,
      description: false,
      quantity: false,
      price: false,
    });
    props.actions.setEditLine(Number(event.currentTarget.dataset.index), event.currentTarget.dataset.type);
  }

  function editLine() {
    if (
      (props.formControls.lineForm === 'service' && falsy(props.formControls.description))
      || (props.formControls.lineForm !== 'service' && falsy(props.formControls.supplyProductId))
      || (props.formControls.lineForm !== 'service' && falsy(props.formControls.warehouseId))
      || falsy(props.formControls.quantity)
      || falsy(props.formControls.price)
    ) {
      props.actions.setInvalidControls({
        supplyProductId: props.formControls.lineForm !== 'service' && falsy(props.formControls.supplyProductId),
        warehouseId: props.formControls.lineForm !== 'service' && falsy(props.formControls.warehouseId),
        description: props.formControls.lineForm === 'service' && falsy(props.formControls.description),
        quantity: falsy(props.formControls.quantity),
        price: falsy(props.formControls.price),
      });
    } else {
      props.actions.editLine(Number(props.formControls.toEditServiceLineIndex), props.formControls.lineForm === 'service' ? 'serviceLines' : 'supplyProductLines');
    }
  }

  const classes = useStyles();

  return (
    <>
      <PurchasesComponent
        actions={props.actions}
        addServiceLine={addServiceLine}
        changeControls={changeControls}
        classes={classes}
        clearFilters={clearFilters}
        closeForm={closeForm}
        deleteLine={deleteLine}
        deleteIsOpen={props.deleteIsOpen}
        edit={props.edit}
        editLine={editLine}
        filter={filter}
        filterPurchases={filterPurchases}
        formControls={props.formControls}
        formIsOpen={props.formIsOpen}
        handleClick={handleClick}
        invalidControls={props.invalidControls}
        objects={props.objects}
        onFilterChange={onFilterChange}
        purchaseList={purchaseList}
        serviceLines={props.serviceLines}
        setEditLine={setEditLine}
        submit={submit}
        submitDelete={submitDelete}
        supplyProductLines={props.supplyProductLines}
        toggleForm={toggleForm}
      />
      <OptionsMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        toggleEdit={toggleEdit}
        toggleDelete={toggleDelete}
      />
    </>
  );
};

const withSaga = connectSaga({ key: 'sagaPurchases', saga });

const withConnect = connect(
  selectState(
    'purchases.supplyProductLines',
    'purchases.invalidControls',
    'purchases.purchasesList',
    'purchases.formControls',
    'purchases.deleteIsOpen',
    'purchases.serviceLines',
    'purchases.toDeleteId',
    'purchases.formIsOpen',
    'purchases.toEditId',
    'purchases.edit',
    'app.objects',
  ),
  actionProps(clone(clone.OBJECT, homeActions, purchasesActions, appActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect,
)(withSaga(Purchases));
