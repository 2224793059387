// @ts-nocheck
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Grid, Hidden, TablePagination } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

const CustomPagination = (props) => {
  const {
    loading, count, rowsPerPage, page, handleChangeRowsPerPage, handleChangePage
  } = props;

  return (
    <Grid item style={{ marginTop: -20 }} xs={12}>
      <Hidden smDown>
        <TablePagination
          component="div"
          labelRowsPerPage="Filas por página"
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage || false}
          rowsPerPage={rowsPerPage}
          count={count}
          page={page}
        />
      </Hidden>
      <Hidden mdUp>
        <Pagination
          color="primary"
          style={{ justifyContent: 'center', display: 'flex' }}
          count={Math.ceil(count / rowsPerPage)}
          page={page + 1}
          onChange={(e, v) => props.handleChangePage(e, v - 1)}
          size="large"
          disabled={loading}
        />
      </Hidden>
    </Grid>
  );
};

export default CustomPagination;
