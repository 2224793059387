/* eslint-disable no-param-reassign */
// @ts-nocheck
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';

// Project
import { selectState, actionProps } from 'utils/reduxActions';
import { homeActions } from 'screens/Home/reducer';
import { ReactReduxContext } from 'config/configureStore';
import connectSaga from 'utils/saga';
import saga from './saga';

import { clone, truthty, falsy } from 'utils/functions';
import { Container, withStyles } from '@material-ui/core';
import { unitsActions } from 'screens/Units/reducer';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CardHeader from '@material-ui/core/CardHeader';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
// styles
import styles from './styles';
import UnitForm from './components/UnitForm';
import DeleteModal from 'commons/components/DeleteModal';
import MaintainersPaginator from 'commons/components/MaintainersPaginator';
import TableActionMenu from 'commons/components/TableActionMenu';

const useStyles = makeStyles(styles);

const Units = (props) => {
  const {
    objects,
    actions,
    formIsOpen,
    formControls,
    edit,
    invalidControls,
    deleteIsOpen,
  } = props;
  const [unitList, setUnitList] = useState([]);
  const [filter, setFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setUnitList(Object.values(objects.units).filter((item) => item.name.toLowerCase().includes(filter.toLowerCase())));
  }, [objects.units, filter]);

  useEffect(() => {
    actions.getStateFromApi();
  }, [actions]);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function toggleForm() {
    actions.toggleForm('formIsOpen');
  }

  function closeForm() {
    actions.toggleForm('deleteIsOpen');
  }

  function toggleDelete() {
    handleClose();
    actions.toggleDelete(anchorEl.dataset.id);
  }

  function toggleEdit() {
    handleClose();
    const item = Object.values(objects.units)
      // eslint-disable-next-line no-shadow
      .find((item) => item.id === Number(anchorEl.dataset.id));
    actions.toggleEdit(item);
  }

  function changeControls(event) {
    actions.changeControls({ [event.currentTarget.id]: event.target.value }, { [event.currentTarget.id]: false });
  }

  function submit() {
    if (
      falsy(formControls.name)
      || falsy(formControls.code)
    ) {
      actions.setInvalidControls({
        name: falsy(formControls.name),
        code: falsy(formControls.code),
      });
    } else if (edit) {
      actions.editUnit(props.toEditId, formControls);
    } else {
      actions.createUnit(formControls);
    }
  }

  function submitDelete() {
    actions.deleteUnit(props.toDeleteId);
  }

  function onFilterChange(event) {
    setFilter(event.target.value);
  }

  const classes = useStyles();

  const getListItem = (unit, openMenuHandler, closeMenuHandler, toggleEdit, toggleDelete) => (
    <ListItem key={`unit-${unit.id}`}>
      <ListItemAvatar>
        <Avatar className={classes.blue}>
          {(unit.name || 'p')[0].toUpperCase()}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={unit.name}
        secondary={`[${unit.code}]`}
      />
      <ListItemSecondaryAction>
        <IconButton
          onClick={handleClick}
          data-id={unit.id}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={truthty(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={toggleEdit}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            Editar
          </MenuItem>
          <MenuItem onClick={toggleDelete}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            Eliminar
          </MenuItem>
        </Menu>
      </ListItemSecondaryAction>
    </ListItem>
  );

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h2" className={classes.marginTop}>
            Unidades
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.root}>
            <CardHeader title="Filtro" />
            <CardContent>
              <Grid container spacing={3} className={classes.container}>
                <Grid container item xs={4}>
                  <TextField fullWidth id="standard-basic" label="Nombre" value={filter} onChange={onFilterChange} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid container item>
          <Grid item xs={12} sm={4} md={3}>
            <TableActionMenu
              menuItems={[
                {
                  label: 'Exportar',
                  listItems: [{
                    label: 'Archivo Excel',
                    onClick: actions.exportUnits,
                    params: [unitList],
                  }],
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.root}>
            <CardContent>
              {truthty(objects.units) ? (
                <MaintainersPaginator
                  classes={classes}
                  list={unitList}
                  getListItem={getListItem}
                  openMenuHandler={handleClick}
                  closeMenuHandler={handleClose}
                  toggleEdit={toggleEdit}
                  toggleDelete={toggleDelete}
                />
              ) : (
                <Grid container item xs={12} alignItems="center" justifyContent="center">
                  <Typography variant="h6" component="h2">
                    No se han ingresado unidades
                  </Typography>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <UnitForm
        isOpen={formIsOpen}
        toggleForm={toggleForm}
        changeControls={changeControls}
        controls={formControls}
        units={objects.units}
        edit={edit}
        submit={submit}
        invalidControls={invalidControls}
      />
      <DeleteModal isOpen={deleteIsOpen} toggleForm={closeForm} submit={submitDelete} buttonId="delete-unit" />
      <Fab color="primary" aria-label="add" className={classes.fab} onClick={toggleForm}>
        <AddIcon />
      </Fab>
    </Container>
  );
};

const withSaga = connectSaga({ key: 'sagaUnit', saga });

const withConnect = connect(
  selectState(
    'units.invalidControls',
    'units.formControls',
    'units.deleteIsOpen',
    'units.toDeleteId',
    'units.formIsOpen',
    'units.toEditId',
    'units.edit',
    'app.objects',
  ),
  actionProps(clone(clone.OBJECT, homeActions, unitsActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect,
)(withSaga(Units));
