import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
import { selectState, actionProps } from 'utils/reduxActions';
import { homeActions } from 'screens/Home/reducer';
import { ReactReduxContext } from 'config/configureStore';
import { inventoryLinesActions } from 'screens/InventoryLines/reducer';
import { clone, onChangeFnc, truthty, falsy, validateFile } from 'utils/functions';
import connectSaga from 'utils/saga';
import moment from 'moment';
import saga from './saga';
// components
import InventoryLinesComponent from './components';
// styles
import { withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles, { tableStyles } from './styles';
import { Pagination } from '@material-ui/lab';
import { update } from 'utils/api';

const useStyles = makeStyles(styles);
const useTableStyles = makeStyles(tableStyles);

const defaultProductLine = {
  inputs: 0,
  outputs: 0,
  productId: '',
  warehouseId: '',
};

const defaultProductLinesFilter = { productId: '', warehouseId: '' };

const InventoryLines = props => {
  const [filteredLots, setFilteredLots] = React.useState([]);
  const [productLines, setProductLines] = React.useState([]);
  const [procesingLots, setProcesingLots] = React.useState(null);
  const [productLinesFilter, setProductLinesFilter] = React.useState({ ...defaultProductLinesFilter });
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    props.actions.getStateFromApi({});
  }, [props.actions]);

  useEffect(() => {
    setFilteredLots(Object.values(props.reducerLots)
      .filter(lot => falsy(lot.price) && truthty(lot.warehouseId)));
  }, [props.reducerLots]);

  useEffect(() => {
    if (falsy(productLines) && truthty(filteredLots)) {
      setProductLines(
        filteredLots
          .reduce((acum, lot) => {
            const id = `${lot.productId}${lot.warehouseId}`;
            const prevLine = acum[id] || defaultProductLine;
            const outputs = truthty(lot.destinyProcessId) ? lot.quantity : 0;
            acum[id] = {
              id,
              inputs: prevLine.inputs + lot.quantity,
              outputs: prevLine.outputs + outputs,
              productId: lot.productId,
              warehouseId: lot.warehouseId,
            };
            return acum;
          }, {}),
      );
    }
  }, [filteredLots, productLines]);

  useEffect(() => {
    if (procesingLots && truthty(props.reducerLots)) {
      setProcesingLots(
        Object.values(props.reducerLots)
          .filter(lot => falsy(lot.price) && falsy(lot.warehouseId) && falsy(lot.destinyProcessId))
      );
    }
  }, [props.reducerLots, procesingLots]); 

  function onFilterChange(event, date) {
    onChangeFnc(props.actions.changeControls, event, date);
  }

  function onProductLinesFilterChange(event, date) {
    onChangeFnc((filter) => setProductLinesFilter({ ...productLinesFilter, ...filter }), event, date);
  }

  function clearProductLinesFilter(event, date) {
    setProductLinesFilter({ ...defaultProductLinesFilter });
    props.actions.searchProducts({ ...defaultProductLinesFilter });
  }

  function filterProductLines(event, date) {
    props.actions.searchProducts({ ...productLinesFilter });
  }

  function changeInventory(event) {
    props.actions.setAnchorEl(event.currentTarget);
  };

  function closeInventoryMenu() {
    props.actions.setAnchorEl(null);
  };

  function setInventoryType(event) {
    props.actions.setInventoryType(event.currentTarget.dataset.inventoryType);
  };

  function toggleForm() {
    props.actions.toggleForm('formIsOpen');
  }

  function toggleInventoryModal() {
    if(truthty(props.updatedInventory)) {
      props.actions.getStateFromApi({
        fromDate: moment('01-01-2000').toISOString(),
        toDate: moment().endOf('day').toISOString(),
        supplyProductId: '',
        warehouseId: '',
      });
    }
    props.actions.toggleForm('inventoryModal');
  }

  function closeForm() {
    props.actions.toggleForm('deleteIsOpen');
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  function toggleDelete() {
    handleClose();
    props.actions.toggleDelete(anchorEl.dataset.id);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function clearFilter() {
    props.actions.changeControls({
      fromDate: moment('01-01-2000').toISOString(),
      toDate: moment().endOf('day').toISOString(),
      supplyProductId: '',
      warehouseId: '',
    }, {});
    props.actions.getStateFromApi({
      fromDate: moment('01-01-2000').toISOString(),
      toDate: moment().endOf('day').toISOString(),
      supplyProductId: '',
      warehouseId: '',
    });
  }

  function filterLines() {
    props.actions.getStateFromApi(props.filterControls);
  }

  function getLines(event) {
    props.actions.getLines(
      Number(event.currentTarget.dataset.supplyProductId),
      Number(event.currentTarget.dataset.warehouseId),
      event.currentTarget.dataset.type
    );
  }

  function changeInventoryFile(e) {
    const blob = e.target.files[0];
    const validFile = validateFile(blob, { extensions: ['xlsx', 'xls'] });
    if (validFile.status) {
      props.actions.changeInventoryFile(e.target.files[0]);
    } else {
      props.actions.employeeCostsRejected();
    }
    e.target.value = null;
  }

  function submitInventoryAdjustment() {
    props.actions.submitInventoryAdjustment(props.file, props.inventoryLineIds);
  }

  const classes = useStyles();
  const tableClasses = useTableStyles();

  return (
    <InventoryLinesComponent
      classes={classes}
      tableClasses={tableClasses}
      inventoryLines={props.objects.inventoryLines}
      filterControls={props.filterControls}
      inventoryLineIds={props.inventoryLineIds}
      objects={props.objects}
      onFilterChange={onFilterChange}
      clearFilter={clearFilter}
      filterLines={filterLines}
      getLines={getLines}
      linesDialog={props.linesDialog}
      closeLinesDialog={props.actions.closeLinesDialog}
      loaders={props.loaders}
      changeInventory={changeInventory}
      closeInventoryMenu={closeInventoryMenu}
      setInventoryType={setInventoryType}
      inventoryType={props.inventoryType}
      productLines={productLines}
      onProductLinesFilterChange={onProductLinesFilterChange}
      productLinesFilter={productLinesFilter}
      clearProductLinesFilter={clearProductLinesFilter}
      filterProductLines={filterProductLines}
      filterProductsControls={props.filterProductsControls}
      procesingLots={procesingLots}
      exportLines={props.actions.exportLines}
      exportProductsReport={props.actions.exportProductsReport}
      toggleForm={toggleForm}
      anchorEl={props.anchorEl}
      toggleDelete={toggleDelete}
      handleClick={handleClick}
      handleClose={handleClose}
      toggleInventoryModal={toggleInventoryModal}
      closeForm={closeForm}
      inventoryModal={props.inventoryModal}
      file={props.file}
      submitInventoryAdjustment={submitInventoryAdjustment}
      pagination={props.pagination}
      exportAdjustmentInventoryLines={props.actions.exportAdjustmentInventoryLines}
      changeInventoryFile={changeInventoryFile}
      updatedInventory={props.updatedInventory}
      addAjustmentStock={props.addAjustmentStock}
      deleteAdjustmentStock={props.deleteAdjustmentStock}
      adjustmentSupplierId={props.adjustmentSupplierId}
    />
  );
};

const withSaga = connectSaga({ key: 'sagaInventoryLines', saga });

const withConnect = connect(
  selectState(
    'inventoryLines.filterProductsControls',
    'inventoryLines.inventoryLineIds',
    'inventoryLines.filterControls',
    'inventoryLines.inventoryType',
    'inventoryLines.linesDialog',
    'inventoryLines.anchorEl',
    'app.loaders',
    'app.objects',
    'inventoryLines.reducerLots',
    'inventoryLines.inventoryModal',
    'inventoryLines.file',
    'inventoryLines.updatedInventory',
    'inventoryLines.adjustmentSupplierId',
  ),
  actionProps(clone(clone.OBJECT, homeActions, inventoryLinesActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect,
)(withSaga(InventoryLines));
