// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest,
  spawn,
  put,
  select
} from 'redux-saga/effects';

import { parsedObjects } from 'utils/reduxActions';
import apiRequest, { apiSuccess, objectToQueryString } from 'utils/api';
import { RETURNS } from '../reducer';
import { truthty } from 'utils/functions';
import { appActions, PRINT_LABEL } from 'commons/reducer';

function* getStateFromApi() {
  yield takeLatest(RETURNS.GET_STATE_FROM_API, function* (action) {
    yield put(appActions.setLoader('filterReturns', true));
    const response = yield apiRequest(`api/v1/returns?${objectToQueryString(action.filters)}`, { method: 'get' });
    yield put(apiSuccess(RETURNS.GET_STATE_FROM_API_SUCCESS, response));
    yield put(appActions.setLoader('filterReturns', false));
  });
}

function* createReturn() {
  yield takeLatest(RETURNS.CREATE_RETURN, function* (action) {
    yield put(appActions.setLoader('create-edit-return', true));
    const response = yield apiRequest('api/v1/returns',
      {
        method: 'post',
        body: JSON.stringify({
          date: action.controls.date,
          description: action.controls.description,
          dispatchNumber: action.controls.dispatchNumber,
          returnedLots: action.controls.returnedLots,
        }),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(RETURNS.CREATE_RETURN_SUCCESS, response));

      if (truthty(response.objects.lots) && truthty(response.objects.returns)) {
        const getObjects = (state) => state.app.objects;
        const objects = yield parsedObjects(yield select(getObjects));

        yield put({
          type: PRINT_LABEL,
          device: action.device,
          labelData: {
            lotIds: Object.values(response.objects.lots).map(l => l.id),
            accountId: Object.values(objects.accounts)[0]?.id
          }
        });
      }
    }
    yield put(appActions.setLoader('create-edit-return', false));
  });
}

function* deleteReturn() {
  yield takeLatest(RETURNS.DELETE_RETURN, function* (action) {
    yield put(appActions.setLoader('delete-return', true));
    const response = yield apiRequest(`api/v1/returns/${action.id}`, { method: 'delete' });
    yield put(apiSuccess(RETURNS.DELETE_RETURN_SUCCESS, { response }));
    yield put(appActions.setLoader('delete-return', false));
  });
}

export default function* saga() {
  yield spawn(getStateFromApi);
  yield spawn(deleteReturn);
  yield spawn(createReturn);
}
