/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';
import moment from 'moment';

// Local constants
const PATH = 'returns/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_EDIT_RETURN = `${PATH}TOGGLE_EDIT_RETURN`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;
const SET_DISPATCH_LOTS = `${PATH}SET_DISPATCH_LOTS`;

// Global constants for saga
export const RETURNS = {
  CREATE_RETURN: `${PATH}CREATE_RETURN`,
  CREATE_RETURN_SUCCESS: `${PATH}CREATE_RETURN_SUCCESS`,
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  DELETE_RETURN: `${PATH}DELETE_RETURN`,
  DELETE_RETURN_SUCCESS: `${PATH}DELETE_RETURN_SUCCESS`,
  EDIT_RETURN_SUCCESS: `${PATH}EDIT_RETURN_SUCCESS`,
  EDIT_RETURN: `${PATH}EDIT_RETURN`,
};

// actions
export const returnsActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  toggleEdit: createAction(TOGGLE_EDIT_RETURN, 'item'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  setDispatchLots: createAction(SET_DISPATCH_LOTS, 'lots'),
  // api
  getStateFromApi: createAction(RETURNS.GET_STATE_FROM_API, 'filters'),
  createReturn: createAction(RETURNS.CREATE_RETURN, 'controls', 'device'),
  editReturn: createAction(RETURNS.EDIT_RETURN, 'id', 'controls'),
  deleteReturn: createAction(RETURNS.DELETE_RETURN, 'id')
};

const defaultInvalidInputs = {
  date: false,
  description: false,
  dispatchNumber: false,
  warehouseId: false,
  fruitId: false,
  varietyId: false,
  productId: false,
  packagingTypeId: false,
  packagingQuantity: false,
  quantity: false,
  toReturnLots: false,
  returnedLots: false,
};

const defaultControls = {
  date: new Date().toISOString(),
  description: '',
  dispatchNumber: '',
  warehouseId: '',
  fruitId: '',
  varietyId: '',
  productId: '',
  packagingTypeId: '',
  packagingQuantity: '',
  quantity: '',
  toReturnLots: {},
  returnedLots: [],
};

const initialState = {
  formControls: { ...defaultControls },
  invalidControls: { ...defaultInvalidInputs },
  dispatchLots: [],
  returnIdList: [],
  formIsOpen: false,
  deleteIsOpen: false,
  toDeleteId: '',
  toEditId: '',
  edit: false,
  error: false,
};

const returns = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.edit = false;
    state.formControls = { ...defaultControls };
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {})
    };
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [TOGGLE_EDIT_RETURN](state, action) {
    state.formIsOpen = true;
    state.toDeleteId = '';
    state.toEditId = action.item.id;
    state.formControls = {
      date: moment(action.item.process().date).toISOString(),
      harvestDate: moment(action.item.harvestDate).toISOString(),
      lotNumber: action.item.returnLot().number,
      supplierId: action.item.supplierId,
      harvester: action.item.harvester,
      dispatchOrderNumber: action.item.dispatchOrderNumber,
      paddockId: action.item.returnLot().paddockId,
      fruitId: action.item.process().departureLots()[0].fruitId,
      varietyId: action.item.process().departureLots()[0].varietyId,
      productId: action.item.process().departureLots()[0].productId,
      warehouseId: action.item.process().departureLots()[0].warehouseId || '',
      packagingTypeId: action.item.process().departureLots()[0].packagingTypeId,
      quantity: action.item.process().departureLots()[0].quantity,
      price: action.item.returnLot().price,
      packagingQuantity: action.item.process().departureLots()[0].packagingQuantity,
    };
    state.edit = true;
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    }
  },
  [SET_DISPATCH_LOTS](state, action) {
    state.dispatchLots = action.lots;
  },
  // api
  [RETURNS.GET_STATE_FROM_API_SUCCESS](state, action) {
    state.returnIdList = action.payload.returnsIds;
  },
  [RETURNS.CREATE_RETURN_SUCCESS](state, action) {
    state.formIsOpen = false;
    state.formControls = { ...defaultControls };
    state.invalidControls = { ...defaultInvalidInputs };
    state.dispatchLots = [];
  },
  [RETURNS.DELETE_RETURN_SUCCESS](state, action) {
    state.deleteIsOpen = false;
    state.toDeleteId = '';
    state.returnIdList.push(action.payload.returnId);
  },
  [RETURNS.EDIT_RETURN_SUCCESS](state, action) {
    state.formControls = { ...defaultControls };
    state.invalidControls = { ...defaultInvalidInputs };
    state.formIsOpen = false;
    state.toDeleteId = '';
    state.toEditId = '';
    state.edit = false;
  },
});

export default returns;
