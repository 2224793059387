import React from 'react';
import { ListItemIcon, Menu, MenuItem } from '@material-ui/core';
// icons
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const OptionsMenu = ({
  anchorEl, handleClose, toggleEdit, toggleDelete,
}) => (
  <Menu
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={handleClose}
  >
    <MenuItem onClick={toggleEdit}>
      <ListItemIcon>
        <EditIcon />
      </ListItemIcon>
      Editar
    </MenuItem>
    <MenuItem onClick={toggleDelete}>
      <ListItemIcon>
        <DeleteIcon />
      </ListItemIcon>
      Eliminar
    </MenuItem>
  </Menu>
);

// const areEqual = (preProps, nextProps) => preProps.anchorEl === nextProps.anchorEl;

export default React.memo(OptionsMenu);
