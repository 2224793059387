/* eslint-disable no-param-reassign */
// @ts-nocheck
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
import { selectState, actionProps } from 'utils/reduxActions';
import { homeActions } from 'screens/Home/reducer';
import { ReactReduxContext } from 'config/configureStore';
import connectSaga from 'utils/saga';
import { paddocksActions } from 'screens/Paddocks/reducer';
import saga from './saga';
// utils
import { clone, falsy } from 'utils/functions';
// components
import PaddocksComponent from './components';
// styles
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import styles from './styles';

const useStyles = makeStyles(styles);

const Paddocks = props => {
  const [paddockList, setPaddockList] = useState([]);
  const [filter, setFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setPaddockList(Object.values(props.objects.paddocks).filter(item => item.name.toLowerCase().includes(filter.toLowerCase())));
  }, [props.objects.paddocks, filter]);

  useEffect(() => {
    props.actions.getStateFromApi();
  }, [props.actions]);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  };

  function toggleForm() {
    props.actions.toggleForm('formIsOpen');
  }

  function closeForm() {
    props.actions.toggleForm('deleteIsOpen');
  }

  function toggleDelete() {
    handleClose();
    props.actions.toggleDelete(anchorEl.dataset.id);
  }

  function toggleEdit() {
    handleClose();
    const item = Object.values(props.objects.paddocks)
      .find(item => item.id === Number(anchorEl.dataset.id));
    props.actions.toggleEdit(item);
  }

  function changeControls(event) {
    props.actions.changeControls({ [event.currentTarget.id]: event.target.value }, { [event.currentTarget.id]: false });
  }

  function submit() {
    if (
      falsy(props.formControls.name)
      || falsy(props.formControls.fruitId)
      || falsy(props.formControls.varietyId)
      || falsy(props.formControls.productId)
    ) {
      props.actions.setInvalidControls({
        name: falsy(props.formControls.name),
        fruitId: falsy(props.formControls.fruitId),
        varietyId: falsy(props.formControls.varietyId),
        productId: falsy(props.formControls.productId),
      });
    } else {
      if (props.edit) {
        props.actions.editPaddock(props.toEditId, props.formControls);
      } else {
        props.actions.createPaddock(props.formControls);
      }
    }
  }

  function submitDelete() {
    props.actions.deletePaddock(props.toDeleteId);
  }

  function onFilterChange(event) {
    setFilter(event.target.value);
  }

  const classes = useStyles();

  const fruits = Object.values(props.objects.fruits);
  const varieties = Object.values(props.objects.varieties);
  const products = Object.values(props.objects.products);

  return (
    <PaddocksComponent
      classes={classes}
      filter={filter}
      onFilterChange={onFilterChange}
      toggleEdit={toggleEdit}
      toggleDelete={toggleDelete}
      paddockList={paddockList}
      formIsOpen={props.formIsOpen}
      toggleForm={toggleForm}
      changeControls={changeControls}
      actions={props.actions}
      edit={props.edit}
      submit={submit}
      invalidControls={props.invalidControls}
      deleteIsOpen={props.deleteIsOpen}
      closeForm={closeForm}
      submitDelete={submitDelete}
      formControls={props.formControls}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      fruits={fruits}
      varieties={varieties}
      products={products}
    />
  );
};

const withSaga = connectSaga({ key: 'sagaPaddocks', saga });

const withConnect = connect(
  selectState(
    'paddocks.invalidControls',
    'paddocks.formControls',
    'paddocks.deleteIsOpen',
    'paddocks.toDeleteId',
    'paddocks.formIsOpen',
    'paddocks.toEditId',
    'paddocks.edit',
    'app.objects',
  ),
  actionProps(clone(clone.OBJECT, homeActions, paddocksActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect,
)(withSaga(Paddocks));
