/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'employees/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_EDIT_EMPLOYEE = `${PATH}TOGGLE_EDIT_EMPLOYEE`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;

// Global constants for saga
export const EMPLOYEES = {
  CREATE_EMPLOYEE: `${PATH}CREATE_EMPLOYEE`,
  CREATE_EMPLOYEE_SUCCESS: `${PATH}CREATE_EMPLOYEE_SUCCESS`,
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  DELETE_EMPLOYEE: `${PATH}DELETE_EMPLOYEE`,
  DELETE_EMPLOYEE_SUCCESS: `${PATH}DELETE_EMPLOYEE_SUCCESS`,
  EDIT_EMPLOYEE_SUCCESS: `${PATH}EDIT_EMPLOYEE_SUCCESS`,
  EDIT_EMPLOYEE: `${PATH}EDIT_EMPLOYEE`,
  EXPORT_EMPLOYEES: `${PATH}EXPORT_EMPLOYEES`,
  EXPORT_EMPLOYEES_SUCCESS: `${PATH}EXPORT_EMPLOYEES_SUCCESS`,
};

// actions
export const employeesActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  toggleEdit: createAction(TOGGLE_EDIT_EMPLOYEE, 'entity', 'item'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  // api
  getStateFromApi: createAction(EMPLOYEES.GET_STATE_FROM_API),
  createEmployee: createAction(EMPLOYEES.CREATE_EMPLOYEE, 'controls'),
  editEmployee: createAction(EMPLOYEES.EDIT_EMPLOYEE, 'id', 'controls'),
  deleteEmployee: createAction(EMPLOYEES.DELETE_EMPLOYEE, 'id'),

  exportEmployees: createAction(EMPLOYEES.EXPORT_EMPLOYEES),
};

const defaultInvalidControls = {
  name: false,
  taxNumber: false,
};

const initialState = {
  formControls: {
    name: '',
    taxNumber: '',
    subcontracted: false,
  },
  invalidControls: { ...defaultInvalidControls },
  formIsOpen: false,
  deleteIsOpen: false,
  toDeleteId: '',
  toEditId: '',
  edit: false,
  error: false,
};

const employees = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.edit = false;
    state.formControls.name = '';
    state.formControls.taxNumber = '';
    state.invalidControls = { ...defaultInvalidControls };
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {})
    };
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [TOGGLE_EDIT_EMPLOYEE](state, action) {
    state.formIsOpen = true;
    state.toDeleteId = '';
    state.toEditId = action.item.id;
    state.formControls.name = action.entity.name;
    state.formControls.taxNumber = action.entity.taxNumber;
    state.formControls.subcontracted = action.item.subcontracted;
    state.edit = true;
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    }
  },
  // api
  [EMPLOYEES.CREATE_EMPLOYEE_SUCCESS](state, action) {
    state.formIsOpen = false;
    state.formControls.name = '';
    state.formControls.taxNumber = '';
    state.invalidControls = { ...defaultInvalidControls };
  },
  [EMPLOYEES.DELETE_EMPLOYEE_SUCCESS](state, action) {
    state.deleteIsOpen = false;
    state.toDeleteId = '';
  },
  [EMPLOYEES.EDIT_EMPLOYEE_SUCCESS](state, action) {
    state.formIsOpen = false;
    state.toDeleteId = '';
    state.toEditId = '';
    state.edit = false;
  },
});

export default employees;
