// @ts-nocheck
/* eslint-disable func-names */
export default function () {
  const queryParams = window.location.search;
  let query = '';
  const object = {};

  if (queryParams.indexOf('?') !== -1) {
    [, query] = queryParams.split('?');
  }

  const parseQuery = query.split('&');

  parseQuery.forEach((text) => {
    const [key, value] = text.split('=');

    if (key.length > 0) {
      const parsedKey = key.replace('[]', '');

      if (object[parsedKey] === undefined) {
        if (key.includes('[]')) {
          object[parsedKey] = [value];
        } else {
          object[parsedKey] = value;
        }
      } else {
        if (object[parsedKey] instanceof Array) object[parsedKey].push(value);
      }
    }
  });

  return object;
}
