import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectState, actionProps } from '../../../../utils/reduxActions';
import { lotsActions } from '../../reducer';
import connectSaga from '../../../../utils/saga';
import saga from '../../saga';
// ui
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Avatar,
  Chip,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';
// icons
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LaunchIcon from '@material-ui/icons/Launch';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import InventoryIcon from '../../../../commons/assets/icons/inventory.svg';
import ScaleIcon from '../../../../commons/assets/icons/scale.svg';
import BoxIcon from '../../../../commons/assets/icons/box.svg';
// utils
import moment from 'moment';
import { truthty, clone } from '../../../../utils/functions';
import CustomPagination from '../../../../commons/components/CustomPagination';
import SkeletonList from '../../../../commons/components/SkeletonList';
import ItemMenu from './components/ItemMenu';
import { Header } from 'commons/components/DataTable/Header';
import { TruncateTextToolTip } from 'commons/components/DataTable/TruncateTextToolTip';
import {
  AssignmentOutlined,
  CalendarTodayOutlined,
  EcoOutlined,
  InboxOutlined,
  Label,
  LayersOutlined,
  LocalFloristOutlined,
} from '@material-ui/icons';
// import LocalFloristOutlinedIcon from '@material-ui/icons/LocalFloristOutlined';
import { Row } from 'commons/components/DataTable/Row';

const Item = (props) => {
  const { lot, classes, headerList, isMobileDevice } = props;

  const getNumber = () => {
    return <TruncateTextToolTip text={lot.id || '-'} />;
  };

  const getFruit = () => {
    return <TruncateTextToolTip text={lot.fruit().name || '-'} lines={3} />;
  };

  const getVariety = () => {
    return <TruncateTextToolTip text={lot.variety().name || '-'} lines={3} />;
  };

  const getProduct = () => {
    return <TruncateTextToolTip text={lot.product().name || '-'} lines={3} />;
  };

  const getDate = () => {
    return <TruncateTextToolTip text={moment(lot.datetime).format('DD-MM-YYYY')} />;
  };

  const getQuantity = () => {
    return <TruncateTextToolTip text={`${lot.quantity.toFixed(2)} kg`} lines={3} />;
  };

  const getPackagingQuantity = () => {
    return (
      <TruncateTextToolTip
        text={`${lot.packagingQuantity} [${lot.packagingType().name}]`}
        lines={3}
      />
    );
  };

  const getLots = () => {
    return <TruncateTextToolTip text={lot.lotNumbers().join(', ')} lines={3} />;
  };

  const getStateChip = () => {
    return (
      <>
        {lot.warehouseId && !lot.price && lot.price !== 0 && !lot.destinyProcessId && (
          <Chip
            icon={<ExitToAppIcon />}
            size="small"
            label="En bodega"
            className={classes.blueChip}
            color="primary"
          />
        )}
        {(lot.price || lot.price === 0) && (
          <Chip
            icon={<LaunchIcon />}
            size="small"
            label="Despachado"
            className={classes.greenChip}
            color="primary"
          />
        )}
        {!lot.destinyProcessId && !lot.warehouseId && (
          <Chip
            icon={<ErrorIcon />}
            size="small"
            label="Procesando"
            className={classes.redChip}
            color="primary"
          />
        )}
        {lot.destinyProcessId && !lot.price && lot.price !== 0 && (
          <Chip
            icon={<DoneIcon />}
            size="small"
            label="Procesado"
            className={classes.yellowChip}
            color="primary"
          />
        )}
      </>
    );
  };

  const getOptions = () => {
    // return <h4>options</h4>;
    return <ItemMenu lot={lot} />;
  };

  const getArray = () => {
    return [
      { value: getNumber(), icon: <AssignmentOutlined /> },
      { value: getFruit(), icon: <EcoOutlined /> },
      { value: getVariety(), icon: <LocalFloristOutlined /> },
      { value: getProduct(), icon: <img src={BoxIcon} className="MuiSvgIcon-root"/> },
      { value: getDate(), icon: <CalendarTodayOutlined /> },
      { value: getQuantity(), icon: <img src={ScaleIcon} className="MuiSvgIcon-root"/> },
      { value: getPackagingQuantity(), icon: <InboxOutlined /> },
      { value: getLots(), icon: <LayersOutlined /> },
      { value: getStateChip(), icon: null },
      { value: <Box className="row-options">{getOptions()}</Box>, icon: null },
    ];
  };

  return (
    <Row
      row={getArray()}
      headerList={headerList}
      isMobileDevice={isMobileDevice}
      handleOnClick={() => {}}
    />
  );

  // return (
  //   <ListItem>
  //     <ListItemAvatar>
  //       <Avatar className={classes.blue}>
  //         {lot.fruit().name[0].toUpperCase()}
  //       </Avatar>
  //     </ListItemAvatar>
  //     <ListItemText
  //       primary={(
  //         <Grid container justifyContent="space-between">
  //           <Grid item>{`Número: ${lot.id}, Especie: ${lot.fruit().name}, Variedad: ${lot.variety().name}, Producto: ${lot.product().name}`}</Grid>
  //           <Grid item>
  //             {lot.warehouseId && (!lot.price && lot.price !== 0) && !lot.destinyProcessId && (
  //               <Chip
  //                 icon={<ExitToAppIcon />}
  //                 size="small"
  //                 label="En bodega"
  //                 className={classes.blueChip}
  //                 color="primary"
  //               />
  //             )}
  //             {(lot.price || lot.price === 0) && (
  //               <Chip
  //                 icon={<LaunchIcon />}
  //                 size="small"
  //                 label="Despachado"
  //                 className={classes.greenChip}
  //                 color="primary"
  //               />
  //             )}
  //             {!lot.destinyProcessId && !lot.warehouseId && (
  //               <Chip
  //                 icon={<ErrorIcon />}
  //                 size="small"
  //                 label="Procesando"
  //                 className={classes.redChip}
  //                 color="primary"
  //               />
  //             )}
  //             {lot.destinyProcessId && !lot.price && lot.price !== 0 && (
  //               <Chip
  //                 icon={<DoneIcon />}
  //                 size="small"
  //                 label="Procesado"
  //                 className={classes.yellowChip}
  //                 color="primary"
  //               />
  //             )}
  //           </Grid>
  //         </Grid>
  //       )}
  //       secondary={`Fecha: ${moment(lot.datetime).format('DD-MM-YYYY')}, Cantidad: ${lot.quantity.toFixed(2)} Kg, Envases: ${lot.packagingQuantity} [${lot.packagingType().name}], Lote(s) número: ${lot.lotNumbers().join(', ')}`}
  //     />
  //     <ListItemSecondaryAction>
  //       <ItemMenu lot={lot} />
  //     </ListItemSecondaryAction>
  //   </ListItem>
};

const ItemList = (props) => {
  const {
    classes,
    warehouses,
    lotList,
    pagination,
    loadingLots,
    objects,
    inputSearch,
    actions,
    headerList,
    isMobileDevice,
  } = props;

  const [lots, setLots] = useState([]);

  useEffect(() => {
    setLots(Object.values(objects.lots).filter((item) => lotList.includes(item.id)));
    // eslint-disable-next-line
  }, [objects.lots, lotList]);

  function handleChangeRowsPerPage(e) {
    actions.paginationControls(inputSearch, { ...pagination, rowsPerPage: e.target.value });
  }

  function handleChangePage(e, val) {
    actions.paginationControls(inputSearch, { ...pagination, page: val });
  }

  return (
    <Grid item container>
      <Card className={classes.root}>
        <CardContent>
          {truthty(lots) ? (
            <Grid container spacing={3} className={classes.container}>
              <Grid item xs={12}>
                {loadingLots ? (
                  <SkeletonList times={pagination.rowsPerPage} />
                ) : (
                  <>
                    <Header headerList={headerList} isMobileDevice={isMobileDevice} />
                    <List>
                      {lots
                        .sort((a, b) => b.id - a.id)
                        .map((lot) => (
                          <Item
                            key={`lot-${lot.id}`}
                            lot={lot}
                            classes={classes}
                            warehouses={warehouses}
                            headerList={headerList}
                            isMobileDevice={isMobileDevice}
                          />
                        ))}
                    </List>
                  </>
                )}
              </Grid>
              <CustomPagination
                rowsPerPage={pagination.rowsPerPage}
                page={pagination.page}
                count={pagination.count}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                loading={loadingLots}
              />
            </Grid>
          ) : (
            <Grid container item xs={12} alignItems="center" justifyContent="center">
              <Typography variant="h6" component="h2">
                No se han ingresado paks
              </Typography>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

const withSaga = connectSaga({ key: 'sagaLots', saga });

const withConnect = connect(
  selectState(
    'lots.lotList',
    'lots.pagination',
    'lots.loadingLots',
    'lots.inputSearch',
    'app.objects',
  ),
  actionProps(clone(clone.OBJECT, { paginationControls: lotsActions.paginationControls })),
);

export default compose(withConnect)(withSaga(ItemList));
