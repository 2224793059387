/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';
import moment from 'moment';
import { truthty } from 'utils/functions';

// Local constants
const PATH = 'purchases/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_EDIT_PURCHASE = `${PATH}TOGGLE_EDIT_PURCHASE`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;
const ADD_SERVICE_LINE = `${PATH}ADD_SERVICE_LINE`;
const DELETE_LINE = `${PATH}DELETE_LINE`;
const SET_EDIT_LINE = `${PATH}SET_EDIT_LINE`;
const CLEAR_EDIT_LINE = `${PATH}CLEAR_EDIT_SERVICE_LINE`;
const EDIT_LINE = `${PATH}EDIT_LINE`;
const ADD_SUPPLY_LINE = `${PATH}ADD_SUPPLY_LINE`;

// Global constants for saga
export const PURCHASES = {
  CREATE_PURCHASE: `${PATH}CREATE_PURCHASE`,
  CREATE_PURCHASE_SUCCESS: `${PATH}CREATE_PURCHASE_SUCCESS`,
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  DELETE_PURCHASE: `${PATH}DELETE_PURCHASE`,
  DELETE_PURCHASE_SUCCESS: `${PATH}DELETE_PURCHASE_SUCCESS`,
  EDIT_PURCHASE_SUCCESS: `${PATH}EDIT_PURCHASE_SUCCESS`,
  EDIT_PURCHASE: `${PATH}EDIT_PURCHASE`,
};

// actions
export const purchasesActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  toggleEdit: createAction(TOGGLE_EDIT_PURCHASE, 'item', 'serviceLines', 'supplyProductLines'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  addServiceLine: createAction(ADD_SERVICE_LINE),
  addSupplyLine: createAction(ADD_SUPPLY_LINE),
  deleteLine: createAction(DELETE_LINE, 'index', 'lineType'),
  setEditLine: createAction(SET_EDIT_LINE, 'index', 'lineType'),
  clearEditLine: createAction(CLEAR_EDIT_LINE),
  editLine: createAction(EDIT_LINE, 'index', 'lineType'),
  // api
  getStateFromApi: createAction(PURCHASES.GET_STATE_FROM_API, 'filters'),
  getStateFromApiSuccess: createAction(PURCHASES.GET_STATE_FROM_API_SUCCESS, 'payload'),
  createPurchase: createAction(PURCHASES.CREATE_PURCHASE, 'controls', 'serviceLines', 'supplyProductLines'),
  createPurchaseSuccess: createAction(PURCHASES.CREATE_PURCHASE_SUCCESS, 'payload'),
  editPurchase: createAction(PURCHASES.EDIT_PURCHASE, 'id', 'controls', 'serviceLines', 'supplyProductLines'),
  deletePurchase: createAction(PURCHASES.DELETE_PURCHASE, 'id'),
};

const defaultInvalidInputs = {
  fromDate: false,
  toDate: false,
  supplierId: false,
  documentNumber: false,
};

const defaultForm = {
  issueDate: moment().toISOString(),
  expirationDate: moment().toISOString(),
  supplierId: '',
  documentNumber: '',
  additionalTaxes: 0,
  iva: false,
  lineForm: 'supplyProduct',
  // lineForm
  warehouseId: '',
  supplyProductId: '',
  description: '',
  quantity: '',
  price: '',
  toEditServiceLineIndex: '',
};

const initialState = {
  formControls: { ...defaultForm },
  invalidControls: {
    name: false,
  },
  formIsOpen: false,
  deleteIsOpen: false,
  toDeleteId: '',
  toEditId: '',
  edit: false,
  error: false,
  serviceLines: [],
  supplyProductLines: [],
  purchasesList: [],
};

const purchases = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.edit = false;
    state.formControls = { ...defaultForm };
    state.invalidControls = { ...defaultInvalidInputs };
    state.serviceLines = [];
    state.supplyProductLines = [];
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {})
    };
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [TOGGLE_EDIT_PURCHASE](state, action) {
    state.formIsOpen = true;
    state.toDeleteId = '';
    state.toEditId = action.item.id;
    state.formControls.additionalTaxes = action.item.additionalTaxes;
    state.formControls.expirationDate = moment(action.item.expirationDate).format('YYYY-MM-DD');
    state.formControls.issueDate = moment(action.item.issueDate).format('YYYY-MM-DD');
    state.formControls.iva = truthty(action.item.iva);
    state.formControls.supplierId = action.item.supplierId;
    state.formControls.documentNumber = action.item.taxableDocument().number;
    state.serviceLines = action.serviceLines.map(item => ({
      description: item.description,
      quantity: item.line().quantity,
      price: item.line().price,
      id: item.id
    }));
    state.supplyProductLines = action.supplyProductLines.map(item => ({
      supplyProductId: item.warehouseMovement().supplyProductId,
      warehouseId: item.warehouseMovement().warehouseId,
      quantity: item.line().quantity,
      price: item.line().price,
      id: item.id
    }));
    state.edit = true;
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    }
  },
  [ADD_SERVICE_LINE](state, action) {
    state.serviceLines = [
      ...state.serviceLines,
      {
        description: state.formControls.description,
        quantity: state.formControls.quantity,
        price: state.formControls.price,
      },
    ];
    state.formControls.description = '';
    state.formControls.quantity = '';
    state.formControls.price = '';
  },
  [ADD_SUPPLY_LINE](state, action) {
    state.supplyProductLines = [
      ...state.supplyProductLines,
      {
        supplyProductId: state.formControls.supplyProductId,
        warehouseId: state.formControls.warehouseId,
        quantity: state.formControls.quantity,
        price: state.formControls.price,
      },
    ];
    state.formControls.supplyProductId = '';
    state.formControls.warehouseId = '';
    state.formControls.quantity = '';
    state.formControls.price = '';
  },
  [EDIT_LINE](state, action) {
    state[action.lineType][state.formControls.toEditServiceLineIndex] = action.lineType === 'serviceLines' ? {
      ...state[action.lineType][state.formControls.toEditServiceLineIndex],
      description: state.formControls.description,
      quantity: state.formControls.quantity,
      price: state.formControls.price,
    } : {
      ...state[action.lineType][state.formControls.toEditServiceLineIndex],
      supplyProductId: state.formControls.supplyProductId,
      warehouseId: state.formControls.warehouseId,
      quantity: state.formControls.quantity,
      price: state.formControls.price,
    };
    state.formControls.toEditServiceLineIndex = '';
    state.formControls.supplyProductId = '';
    state.formControls.warehouseId = '';
    state.formControls.description = '';
    state.formControls.quantity = '';
    state.formControls.price = '';
  },
  [DELETE_LINE](state, action) {
    state[action.lineType] = state[action.lineType].filter((line, index) => index !== action.index);
  },
  [SET_EDIT_LINE](state, action) {
    state.formControls.toEditServiceLineIndex = action.index;
    if (action.lineType === 'serviceLines') {
      state.formControls.description = state[action.lineType][action.index].description;
      state.formControls.lineForm = 'service';
    } else {
      state.formControls.supplyProductId = state[action.lineType][action.index].supplyProductId;
      state.formControls.warehouseId = state[action.lineType][action.index].warehouseId;
      state.formControls.lineForm = 'supplyProduct';
    }
    state.formControls.price = state[action.lineType][action.index].price;
    state.formControls.quantity = state[action.lineType][action.index].quantity;
  },
  [CLEAR_EDIT_LINE](state, action) {
    state.formControls.toEditServiceLineIndex = '';
    state.formControls.warehouseId = '';
    state.formControls.supplyProductId = '';
    state.formControls.description = '';
    state.formControls.price = '';
    state.formControls.quantity = '';
  },
  // api
  [PURCHASES.GET_STATE_FROM_API_SUCCESS](state, action) {
    state.purchasesList = action.payload.purchases;
  },
  [PURCHASES.CREATE_PURCHASE_SUCCESS](state, action) {
    state.formIsOpen = false;
    state.formControls = { ...defaultForm };
    state.invalidControls = { ...defaultInvalidInputs };
    state.serviceLines = [];
    state.supplyProductLines = [];
    state.purchasesList = [
      ...state.purchasesList,
      ...action.payload.purchases,
    ];
  },
  [PURCHASES.DELETE_PURCHASE_SUCCESS](state, action) {
    state.deleteIsOpen = false;
    state.toDeleteId = '';
    state.purchasesList = state.purchasesList.filter(p => p !== action.payload.item.id);
  },
  [PURCHASES.EDIT_PURCHASE_SUCCESS](state, action) {
    state.formControls.name = '';
    state.invalidControls = { ...defaultInvalidInputs };
    state.serviceLines = [];
    state.supplyProductLines = [];
    state.formIsOpen = false;
    state.toDeleteId = '';
    state.toEditId = '';
    state.edit = false;
  },
});

export default purchases;
