// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React from 'react';
// MUI
import Grid from '@material-ui/core/Grid';
// CHART
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, ChartDataLabels, zoomPlugin);

export default function (props) {
  const {
    dataSet,
    dataLabels,
    pointOnClick,
  } = props;

  const zoomOptions = {
    limits: {
      x: { min: 0, minRange: 2 },
      y: { min: -1, minRange: 2 },
    },
    pan: {
      enabled: true,
      mode: 'xy',
    },
    zoom: {
      wheel: {
        enabled: true,
        speed: 0.000001,
      },
      pinch: {
        enabled: true,
      },
      mode: 'xy',
      onZoomComplete({ chart }) {
        chart.update('none');
      },
    },
  };

  const data = {
    labels: dataLabels,
    datasets: dataSet,
  };

  const options = {
    legend: {
      display: false,
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    layout: {
      padding: 200,
    },
    tooltips: {
      enabled: false,
    },
    plugins: {
      zoom: zoomOptions,
      legend: {
        display: false,
      },
      datalabels: {
        formatter: (_value, context) => (context.dataset.showLabel ? context.dataset.label : ''),
        color: (context) => (context.dataset.showLabel ? context.dataset.currentColor : ''),
        borderColor: (context) => (context.dataset.showLabel ? context.dataset.currentBorderColor : ''),
        listeners: {
          click: (context) => pointOnClick(context.dataset),
          enter: (context) => {
            context.chart.canvas.style.cursor = 'pointer';
          },
          leave: (context) => {
            context.chart.canvas.style.cursor = 'default';
          },
        },
        borderWidth: 2,
        backgroundColor: (context) => context.dataset.currentBackGroundColor,
        borderRadius: 5,
        weight: 'bold',
      },
    },
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Line data={data} options={options} />
      </Grid>
    </Grid>
  );
}
