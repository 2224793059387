/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-unresolved */
// @ts-nocheck
import React from 'react';
// material
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
// styles
import logo from 'utils/images/Logo-Pakit-big.png';
import styles from './styles';

const useStyles = makeStyles(styles);

const LoadingScreen = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <Card className={classes.card}>
        <Grid className={classes.inputs}>
          <img src={logo} className={classes.image} alt="logo" />
          <CircularProgress className={classes.loading} />
        </Grid>
      </Card>
    </Grid>
  );
};

export default LoadingScreen;
