/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';


// Local constants
const PATH = 'paddocks/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_EDIT_PADDOCK = `${PATH}TOGGLE_EDIT_PADDOCK`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;

// Global constants for saga
export const PADDOCKS = {
  CREATE_PADDOCK: `${PATH}CREATE_PADDOCK`,
  CREATE_PADDOCK_SUCCESS: `${PATH}CREATE_PADDOCK_SUCCESS`,
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  DELETE_PADDOCK: `${PATH}DELETE_PADDOCK`,
  DELETE_PADDOCK_SUCCESS: `${PATH}DELETE_PADDOCK_SUCCESS`,
  EDIT_PADDOCK_SUCCESS: `${PATH}EDIT_PADDOCK_SUCCESS`,
  EDIT_PADDOCK: `${PATH}EDIT_PADDOCK`,
};

// actions
export const paddocksActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  toggleEdit: createAction(TOGGLE_EDIT_PADDOCK, 'item'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  // api
  getStateFromApi: createAction(PADDOCKS.GET_STATE_FROM_API),
  createPaddock: createAction(PADDOCKS.CREATE_PADDOCK, 'controls'),
  editPaddock: createAction(PADDOCKS.EDIT_PADDOCK, 'id', 'controls'),
  deletePaddock: createAction(PADDOCKS.DELETE_PADDOCK, 'id')
};

const defaultInvalidInputs = {
  name: false,
  fruitId: false,
  varietyId: false,
  productId: false,
};

const defaultControls = {
  name: '',
  fruitId: '',
  varietyId: '',
  productId: '',
};

const initialState = {
  formControls: { ...defaultControls },
  invalidControls: { ...defaultInvalidInputs },
  formIsOpen: false,
  deleteIsOpen: false,
  toDeleteId: '',
  toEditId: '',
  edit: false,
  error: false,
};

const paddocks = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.edit = false;
    state.formControls = { ...defaultControls };
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {})
    };
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [TOGGLE_EDIT_PADDOCK](state, action) {
    state.formIsOpen = true;
    state.toDeleteId = '';
    state.toEditId = action.item.id;
    state.formControls.name = action.item.name;
    state.formControls.fruitId = action.item.fruitId || '';
    state.formControls.varietyId = action.item.varietyId || '';
    state.formControls.productId = action.item.productId || '';
    state.edit = true;
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    }
  },
  // api
  [PADDOCKS.CREATE_PADDOCK_SUCCESS](state, action) {
    state.formIsOpen = false;
    state.formControls = { ...defaultControls };
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [PADDOCKS.DELETE_PADDOCK_SUCCESS](state, action) {
    state.deleteIsOpen = false;
    state.toDeleteId = '';
  },
  [PADDOCKS.EDIT_PADDOCK_SUCCESS](state, action) {
    state.formControls = { ...defaultControls };
    state.invalidControls = { ...defaultInvalidInputs };
    state.formIsOpen = false;
    state.toDeleteId = '';
    state.toEditId = '';
    state.edit = false;
  },
});

export default paddocks;
