/* eslint-disable no-param-reassign */
// @ts-nocheck
/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Material
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
// Project
import { ReactReduxContext } from 'config/configureStore';
import { actionProps, selectState } from 'utils/reduxActions';
import connectSaga from 'utils/saga';

import { clone, truthty } from 'utils/functions';
import { appActions } from 'commons/reducer';
import { loginActions } from './reducer';
import saga from './saga';
import styles from './styles';

import logo from '../../utils/images/Logo-Pakit-big.png';

const Login = ({
  // props
  loadingUser,
  user,
  history,
  classes,
}) => {
  // useEffect(() => {
  // const user = window.localStorage.getItem('user');
  // const role = window.localStorage.getItem('role');
  // if (truthty(user)) {
  //   props.actions.setUser(JSON.parse(user), JSON.parse(role));
  // } else {
  // props.actions.getUser();
  // }
  // }, [props.actions]);

  useEffect(() => {
    if (!loadingUser) {
      if (truthty(user)) {
        history.push('/home');
      } else if (process.env.NODE_ENV === 'production') {
        window.location.href = `${window.location.href}auth/oauth2`;
      }
    }
  }, [user, loadingUser, history]);

  return (
    <Grid className={classes.container}>
      <Card className={classes.container}>
        <Grid className={classes.inputs}>
          <img src={logo} className={classes.image} alt="logo" />
          <CircularProgress className={classes.loading} />
        </Grid>
      </Card>
    </Grid>
  );
};

const withSaga = connectSaga({ key: 'sagaLogin', saga });

const withConnect = connect(
  selectState(
    'app.loadingUser',
    'login.error',
    'app.user',
  ),
  actionProps(clone(clone.OBJECT, loginActions, appActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect,
)(withSaga(Login));
