import React from 'react';
// MUI
import { Card, CardHeader, CardContent, Grid, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from "@material-ui/lab/Autocomplete";
// PROJECT
import DatePicker from '../../../../commons/components/DatePicker';
import ButtonWithLoader from '../../../../commons/containers/ButtonWithLoader';
import { ModelNames } from '../../../../utils/ModelNames';

const Filters = (props) => {
  const {
    classes,
    filterControls,
    onFilterChange,
    submitFilter,
    clearFilters,
  } = props;

  const eventTypeOptions = {
    '1': 'Creación',
    '2': 'Actualización',
    '3': 'Eliminación'
  }

  return (
    <Grid item container spacing={3}>
      <Grid item xs={12}>
        <Card className={classes.root}>
          <CardHeader />
          <CardContent>
            <Grid
              container
              spacing={3}
              className={classes.container}
              justifyContent="flex-end"
            >
              <Grid item container spacing={3}>
                <Grid item sm={3} xs={12}>
                  <DatePicker
                    inputId="fromDate"
                    label="Fecha inicio"
                    fullWidth
                    value={filterControls.fromDate}
                    onChange={onFilterChange}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <DatePicker
                    inputId="toDate"
                    label="Fecha término"
                    fullWidth
                    value={filterControls.toDate}
                    onChange={onFilterChange}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <Autocomplete
                    id="autocomplete-modelName-filter-versionHistories"
                    options={Object.keys(ModelNames)}
                    getOptionLabel={(modelName) => ModelNames[modelName]}
                    renderInput={(params) => (
                      <TextField
                        style={{ margin: 0 }}
                        {...params}
                        label="Módulo"
                        margin="normal"
                      />
                    )}
                    onChange={(event, elem) => {
                      const e = {
                        autocomplete: true,
                        input: "modelName",
                        value: elem || "",
                      };
                      onFilterChange(e);
                    }}
                    value={filterControls.modelName}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <Autocomplete
                    id="autocomplete-eventType-filter-versionHistories"
                    options={Object.keys(eventTypeOptions)}
                    getOptionLabel={(eventType) => eventTypeOptions[eventType] }
                    renderInput={(params) => (
                      <TextField
                        style={{ margin: 0 }}
                        {...params}
                        label="Acción"
                        margin="normal"
                      />
                    )}
                    onChange={(event, elem) => {
                      const e = {
                        autocomplete: true,
                        input: "eventType",
                        value: elem || "",
                      };
                      onFilterChange(e);
                    }}
                    value={filterControls.eventType}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={3} justifyContent="flex-end">
                <Grid item xs={12} sm={4} md={3}>
                  <ButtonWithLoader
                    fullWidth
                    onClick={clearFilters}
                    autoFocus
                    buttonId="filter-version-histories"
                    className={classes.filterButton}
                    size="small"
                  >
                    Limpiar
                  </ButtonWithLoader>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <ButtonWithLoader
                    fullWidth
                    onClick={submitFilter}
                    autoFocus
                    buttonId="filter-version-histories"
                    className={classes.filterButton}
                    size="small"
                    startIcon={<SearchIcon />}
                  >
                    Buscar
                  </ButtonWithLoader>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Filters;