// @ts-nocheck
import React from 'react';
import {useSelector} from 'react-redux'
// material ui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
// icons
import SearchIcon from '@material-ui/icons/Search';
// components
import Autocomplete from '@material-ui/lab/Autocomplete';
import ButtonWithLoader from '../../../../commons/containers/ButtonWithLoader';
import DatePicker from '../../../../commons/components/DatePicker';
import MultipleSelector from '../../../../commons/components/MultipleSelector';
import { sortArrayBy } from '../../../../utils/arrayFunctions';
import TableActionMenu from '../../../../commons/components/TableActionMenu';
import exportExcel from '../../../../utils/services/exportExcel';

const Filters = (props) => {
  const {
    classes,
    filter,
    onFilterChange,
    objects,
    clearFilters,
    filterProcesses,
    toggleEmployeeCostsModal,
    socketId,
  } = props;

  const statusOptions = [
    { value: 'open', label: 'Abierto' },
    { value: 'closed', label: 'Cerrado' },
  ];

  const employeeOptions = Object.values(objects.employees).map((emp) => ({
    label: emp.entity().name,
    value: emp.id,
  }));

  const exportButtonState = useSelector((state) => state.app.exportExcelButton)

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card className={classes.root}>
            <CardHeader title="Filtro" />
            <CardContent>
              <Grid container spacing={3} className={classes.container}>
                <Grid container item spacing={3}>
                  <Grid item sm={4} xs={12}>
                    <DatePicker
                      inputId="fromDate"
                      label="Desde"
                      fullWidth
                      value={filter.fromDate}
                      onChange={onFilterChange}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <DatePicker
                      inputId="toDate"
                      label="Hasta"
                      fullWidth
                      value={filter.toDate}
                      onChange={onFilterChange}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <TextField
                      fullWidth
                      type="number"
                      id="number"
                      label="N°"
                      value={filter.number}
                      onChange={onFilterChange}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="process-category-label">Categoría</InputLabel>
                      <Select
                        labelId="process-category-label"
                        fullWidth
                        value={filter.processCategoryId}
                        onChange={onFilterChange}>
                        <MenuItem id="processCategoryId" value="">
                          Todos
                        </MenuItem>
                        {Object.values(objects.processCategories).map((processCategory) => (
                          <MenuItem
                            key={`process-category-filter-option-${processCategory.id}`}
                            id="processCategoryId"
                            value={processCategory.id}>
                            {processCategory.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Autocomplete
                      value={
                        filter.processTypeId &&
                        Object.values(objects.processTypes).find(
                          (processType) => processType.id === filter.processTypeId,
                        )
                      }
                      id="autocomplete-processType-filters-processes"
                      options={sortArrayBy(Object.values(objects.processTypes), 'ASC', 'name')}
                      getOptionLabel={(processType) => processType && processType.name}
                      renderInput={(params) => (
                        <TextField
                          style={{ margin: 0 }}
                          {...params}
                          label="Tipo de proceso"
                          margin="normal"
                        />
                      )}
                      onChange={(event, newValue) => {
                        const e = {
                          autocomplete: true,
                          input: 'processTypeId',
                          value: newValue ? newValue.id : '',
                        };
                        onFilterChange(e);
                      }}
                    />
                  </Grid>

                  <Grid item sm={4} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="work-shift-type-label">Turno</InputLabel>
                      <Select
                        labelId="work-shift-type-label"
                        fullWidth
                        value={filter.workShiftTypeId}
                        onChange={onFilterChange}>
                        <MenuItem id="workShiftTypeId" value="">
                          Todos
                        </MenuItem>
                        {Object.values(objects.workShiftTypes).map((workShiftType) => (
                          <MenuItem
                            key={`work-shift-type-filter-option-${workShiftType.id}`}
                            id="workShiftTypeId"
                            value={workShiftType.id}>
                            {workShiftType.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Autocomplete
                      value={filter.productId && objects.products[filter.productId]}
                      id="autocomplete-product-filters-processes"
                      options={sortArrayBy(Object.values(objects.products), 'ASC', 'name')}
                      getOptionLabel={(processType) => processType && processType.name}
                      renderInput={(params) => (
                        <TextField
                          style={{ margin: 0 }}
                          {...params}
                          label="Producto"
                          margin="normal"
                        />
                      )}
                      onChange={(event, newValue) => {
                        const e = {
                          autocomplete: true,
                          input: 'productId',
                          value: newValue ? newValue.id : '',
                        };
                        onFilterChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Autocomplete
                      value={
                        filter.status && statusOptions.filter((opt) => filter.status === opt.value)[0]
                      }
                      id="autocomplete-status-filters-processes"
                      options={statusOptions}
                      getOptionLabel={(statusOpt) => statusOpt && statusOpt.label}
                      renderInput={(params) => (
                        <TextField
                          style={{ margin: 0 }}
                          {...params}
                          label="Estado"
                          margin="normal"
                        />
                      )}
                      onChange={(event, newValue) => {
                        const e = {
                          autocomplete: true,
                          input: 'status',
                          value: newValue ? newValue.value : '',
                        };
                        onFilterChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Autocomplete
                      value={filter.equipmentId && objects.equipments[filter.equipmentId]}
                      id="autocomplete-fruit-filters-lots"
                      options={sortArrayBy(Object.values(objects.equipments), 'ASC', 'name')}
                      getOptionLabel={(equipment) => equipment && equipment.name}
                      renderInput={(params) => (
                        <TextField
                          style={{ margin: 0 }}
                          {...params}
                          label="Equipamiento"
                          margin="normal"
                        />
                      )}
                      onChange={(event, elem) => {
                        const e = {
                          autocomplete: true,
                          input: 'equipmentId',
                          value: elem?.id || '',
                        };
                        onFilterChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MultipleSelector
                      id="employeeIds"
                      selectedValues={filter.employeeIds}
                      options={employeeOptions}
                      onChange={onFilterChange}
                      label="Personal"
                      whereNotOptionLabel="Sin personal"
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={3} justifyContent="flex-end">
                  <Grid item xs={12} sm={4} md={3}>
                    <ButtonWithLoader
                      fullWidth
                      onClick={clearFilters}
                      autoFocus
                      buttonId="get-process"
                      className={classes.filterButton}
                      size="small">
                      Limpiar
                    </ButtonWithLoader>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <ButtonWithLoader
                      fullWidth
                      onClick={filterProcesses}
                      autoFocus
                      buttonId="get-process"
                      className={classes.filterButton}
                      size="small"
                      startIcon={<SearchIcon />}>
                      Buscar
                    </ButtonWithLoader>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TableActionMenu
            menuItems={[
              {
                label: 'Importar',
                listItems: [
                  {
                    label: 'Costos empresa empleados',
                    onClick: toggleEmployeeCostsModal,
                  },
                ],
              },
              {
                label: 'Exportar',
                listItems: [
                  {
                    label: 'Archivo Excel',
                    onClick: exportExcel,
                    params: [filter, 'processes/export', 'Procesos', socketId],
                    disabled: !exportButtonState
                  },
                ],
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Filters;
