import { blueGrey } from '@material-ui/core/colors';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(10),
      right: theme.spacing(6),
    },
    blue: {
      color: theme.palette.getContrastText(blueGrey[500]),
      backgroundColor: blueGrey[500],
      fontFamily: [
        'Roboto'
      ]
    },
    marginTop: {
      marginTop: '24px'
    },
    totalLabel: {
      ...theme.typography.button,
      backgroundColor: theme.palette.info.dark,
      color: theme.palette.info.contrastText,
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
    },
    filterButton: {
      marginTop: '16px'
    },
    tableContainer: {
      overflow: 'auto',
      maxWidth: '98vw',
    }
  });
  
  export default styles;
  