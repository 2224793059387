// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest, spawn, put, select
} from 'redux-saga/effects';

import apiRequest, { apiSuccess, post, get, objectToQueryString } from 'utils/api';
import { truthty, falsy } from 'utils/functions';
import { appActions, PRINT_LABEL, PRINT_LABEL_PAKS } from 'commons/reducer';
import controlledCall from 'utils/services/controlledSaga';
import { PROCESS, processActions } from '../reducer';
import { parsedObjects } from 'utils/reduxActions';

function* getStateFromApi() {
  yield takeLatest(PROCESS.GET_STATE_FROM_API, function* (action) {
    const response = yield apiRequest(`api/v1/processes?id=${action.id}`, { method: 'get' });
    yield put(apiSuccess(PROCESS.GET_STATE_FROM_API_SUCCESS, response));
  });
}

function* createProcessType() {
  yield takeLatest(PROCESS.CREATE_PROCESS, function* (action) {
    yield put(appActions.setLoader('create-edit-process', true));
    const response = yield apiRequest('api/v1/processes',
      {
        method: 'post',
        body: JSON.stringify(action.controls)
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PROCESS.CREATE_PROCESS_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-process', false));
  });
}

function* editProcessType() {
  yield takeLatest(PROCESS.EDIT_PROCESS, function* (action) {
    yield put(appActions.setLoader('create-edit-process', true));
    const response = yield apiRequest(`api/v1/processes/${action.id}`,
      {
        method: 'put',
        body: JSON.stringify(action.controls)
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PROCESS.EDIT_PROCESS_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-process', false));
  });
}

function* deleteProcessStaff() {
  yield takeLatest(PROCESS.DELETE_PROCESS_STAFF, function* (action) {
    yield put(appActions.setLoader('delete-process-item', true));
    const response = yield apiRequest(`api/v1/processStaffLines/${action.id}`, { method: 'delete' });
    if (falsy(response.error)) {
      yield put(apiSuccess(PROCESS.DELETE_PROCESS_ITEM_SUCCESS, { item: response, type: 'processStaffLines' }));
    } else {
      yield put(appActions.setError(response.errorMsg));
    }
    yield put(appActions.setLoader('delete-process-item', false));
  });
}

function* addProcessStaff() {
  yield takeLatest(PROCESS.ADD_PROCESS_STAFF, function* (action) {
    yield put(appActions.setLoader('add-process-staff', true));
    const response = yield apiRequest('api/v1/processes/addProcessStaff',
      {
        method: 'post',
        body: JSON.stringify(action),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PROCESS.ADD_PROCESS_ITEM_SUCCESS, { ...response, module: 'staff' }));
    }
    yield put(appActions.setLoader('add-process-staff', false));
  });
}

function* addDeparture() {
  yield takeLatest(PROCESS.ADD_DEPARTURE, function* (action) {
    yield put(appActions.setLoader('add-departure-lot', true));

    const response = yield apiRequest('api/v1/processes/addDeparture',
      {
        method: 'post',
        body: JSON.stringify({
          processId: action.processId,
          ...action.controls,
          quantity: action.totalWeight
        })
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PROCESS.ADD_PROCESS_ITEM_SUCCESS, { ...response, module: 'departure' }));
      if (truthty(response.objects.lots)) {
        const getObjects = (state) => state.app.objects;
        const objects = yield parsedObjects(yield select(getObjects));
        yield put({
          type: PRINT_LABEL,
          device: action.device,
          labelData: {
            lotIds: [Object.values(response.objects.lots)[0].id],
            accountId: Object.values(objects.accounts)[0]?.id
          }
        });
      }
    }

    yield put(appActions.setLoader('add-departure-lot', false));
  });
}

function* deleteLot() {
  yield takeLatest(PROCESS.DELETE_LOT, function* (action) {
    yield put(appActions.setLoader('delete-process-item', true));
    const response = yield apiRequest(`api/v1/lots/${action.id}`, { method: 'delete' });
    const { lotToDelete } = response;
    if (falsy(response.error)) {
      yield put(apiSuccess(PROCESS.DELETE_PROCESS_ITEM_SUCCESS, { item: lotToDelete, type: 'lots' }));
    } else {
      yield put(appActions.setError(response.errorMsg));
    }
    yield put(appActions.setLoader('delete-process-item', false));
  });
}

function* addEntry() {
  yield takeLatest(PROCESS.ADD_ENTRY, function* (action) {
    yield put(appActions.setLoader('add-entry', true));
    const response = yield apiRequest(`api/v1/lots/${action.lotId}`,
      {
        method: 'put',
        body: JSON.stringify({
          processId: action.processId,
        }),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PROCESS.EDIT_LOT_SUCCESS, { ...response, module: 'entry' }));
    }
    yield put(appActions.setLoader('add-entry', false));
  });
}

function* deleteEntry() {
  yield takeLatest(PROCESS.DELETE_ENTRY, function* (action) {
    yield put(appActions.setLoader('delete-process-item', true));
    const response = yield apiRequest(`api/v1/lots/${action.lotId}`,
      {
        method: 'put',
        body: JSON.stringify({
          processId: null
        })
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PROCESS.EDIT_LOT_SUCCESS, { ...response }));
    }
    yield put(appActions.setLoader('delete-process-item', false));
  });
}

function* addSupplyLine() {
  yield takeLatest(PROCESS.ADD_SUPPLY_LINE, function* (action) {
    yield put(appActions.setLoader('add-entry', true));
    const body = {
      quantity: action.controls.quantity,
      supplyProductId: action.controls.supplyProductId,
      warehouseId: action.controls.warehouseId,
      purchaseId: action.controls.purchaseId,
      processId: action.processId,
      inventoryLine: action.inventoryLine
    };
    yield controlledCall(post, 'api/v1/processes/addSupplyLine', body, processActions.addSupplyLineSuccess);
    yield put(appActions.setLoader('add-entry', false));
  });
}

function* deleteSupplyLine() {
  yield takeLatest(PROCESS.DELETE_SUPPLY_LINE, function* (action) {
    yield put(appActions.setLoader('delete-process-item', true));
    const response = yield apiRequest(
      `api/v1/processes/supplyLine/${action.supplyLineId}`,
      { method: 'delete' }
    );
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PROCESS.DELETE_SUPPLY_LINE_SUCCESS, { response }));
    }
    yield put(appActions.setLoader('delete-process-item', false));
  });
}

function* closeProcess() {
  yield takeLatest(PROCESS.CLOSE_PROCESS, function* (action) {
    yield put(appActions.setLoader('close-process', true));
    const response = yield apiRequest(
      `api/v1/processes/close/${action.id}`,
      {
        method: 'put',
        body: JSON.stringify({ closedAt: action.closedAt }),
      },
    );
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PROCESS.CLOSE_PROCESS_SUCCESS, response));
    }
    yield put(appActions.setLoader('delete-process-item', false));
  });
}

function* loadProcessResources() {
  yield takeLatest(PROCESS.LOAD_PROCESS_RESOURCES, function* (action) {
    const query = objectToQueryString({ id: action.id });
    yield controlledCall(get, 'api/v1/processes/resources', query, PROCESS.LOAD_PROCESS_RESOURCES_SUCCESS);
  });
}

function* addProcessEquipments() {
  yield takeLatest(PROCESS.ADD_PROCESS_EQUIPMENTS, function* (action) {
    yield put(appActions.setLoader('add-process-staff', true));
    const response = yield apiRequest('api/v1/processes/addProcessEquipments',
      {
        method: 'post',
        body: JSON.stringify(action),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PROCESS.ADD_PROCESS_ITEM_SUCCESS, { ...response, module: 'processEquipments' }));
    }
    yield put(appActions.setLoader('add-process-staff', false));
  });
}

function* deleteProcessEquipment() {
  yield takeLatest(PROCESS.DELETE_PROCESS_EQUIPMENT, function* (action) {
    yield put(appActions.setLoader('delete-process-equipment', true));
    const response = yield apiRequest(
      `api/v1/processes/processEquipment/${action.processEquipmentId}`,
      { method: 'delete' },
    );

    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PROCESS.DELETE_PROCESS_EQUIPMENT_SUCCESS, { response }));
    }
    yield put(appActions.setLoader('delete-process-equipment', false));
  });
}

function* infoPaksToPrint() {
  yield takeLatest(PROCESS.CREATE_INFO_PAKS, function* (action) {
    yield put(appActions.setLoader('create-process-paks', true));
    const response = yield apiRequest(`api/v1/lots/generateLabelsPaks`, {
      method: 'post',
      body: JSON.stringify({
            accountId: action.accountId,
            lotIds: action.lotIds,
            numPaks: action.numPaks,
      })
    });
    const labelsToPrint = response.printPaks.join('')
    if (response.error) {
      yield put(appActions.setError('Error al generar las etiquetas'));
    } else {
      yield put(apiSuccess(PROCESS.CREATE_INFO_PAKS_SUCCESS, response));
      yield put({
        type: PRINT_LABEL_PAKS,
        device: action.device,
        labelData: {
          lotIds: labelsToPrint
        }
      })
      };
      yield put(appActions.setLoader('create-process-paks', false));
  });
}

function* infoEntryLots() {
  yield takeLatest(PROCESS.INFO_ENTRY_LOTS, function* (action) {
    const query = objectToQueryString({ lotId: Number(action.lotId), processId: action.processId})
    const response = yield apiRequest(`api/v1/lots/infoLots?${query}`, { method: 'get' });
    yield put(apiSuccess(PROCESS.INFO_ENTRY_LOTS_SUCCESS, response));
    return response;
  });
}


export default function* saga() {
  yield spawn(deleteProcessStaff);
  yield spawn(createProcessType);
  yield spawn(deleteSupplyLine);
  yield spawn(getStateFromApi);
  yield spawn(editProcessType);
  yield spawn(addProcessStaff);
  yield spawn(addSupplyLine);
  yield spawn(addDeparture);
  yield spawn(deleteEntry);
  yield spawn(deleteLot);
  yield spawn(addEntry);
  yield spawn(closeProcess);
  yield spawn(loadProcessResources);
  yield spawn(addProcessEquipments);
  yield spawn(deleteProcessEquipment);
  yield spawn(infoPaksToPrint);
  yield spawn(infoEntryLots);
}
