/* eslint-disable no-param-reassign */
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'lotTraceability/';
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

export const LOT_TRACEABILITY = {
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
};

export const lotTraceabilityActions = {
  getStateFromApi: createAction(LOT_TRACEABILITY.GET_STATE_FROM_API, 'controls'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls'),
};

const initialState = {
  traceabilityReport: [],
  loading: true,
  controls: {
    lotIds: [],
  },
};

const reducer = createReducer(initialState, {
  [LOT_TRACEABILITY.GET_STATE_FROM_API](state) {
    state.traceabilityReport = initialState.traceabilityReport;
    state.loading = true;
  },
  [LOT_TRACEABILITY.GET_STATE_FROM_API_SUCCESS](state, action) {
    console.log({ action });
    state.traceabilityReport = action.payload.traceabilityReport;
    state.loading = !!!action.payload.traceabilityReport.length;
  },
  [CHANGE_CONTROLS](state, action) {
    state.controls = { ...state.controls, ...action.controls };
  },
});

export default reducer;
