import withStyles from '@material-ui/styles/withStyles';
import React, { useState, useEffect } from 'react';
// Material
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Tooltip } from '@material-ui/core';

const StyledAutocomplete = withStyles({
  option: {
    padding: 0,
  },
})(Autocomplete);

const MultipleSelector = (props) => {
  const [open, setOpen] = useState(false);

  const {
    id,
    selectedValues = [],
    options = [],
    onChange,
    label,
    disabled,
    noOptionsMessage,
    preventChangeOnRender,
    onChangeSelectAll,
    module,
    error,
    helperText,
    noCheckBox,
    noSort,
    title = 'label',
    valueName = 'value',
  } = props;

  const defaultOptions = noCheckBox ? [] : [{ [valueName]: 0, [title]: 'Seleccionar todos' }];
  const handleOpen = () => setOpen(!open);
  const handleClose = () => setOpen(false);
  const sortedOptions = noSort
    ? options
    : options.sort((a, b) => String(a[title]).localeCompare(String(b[title])));

  const createEvent = (value) => ({
      autocomplete: true,
      input: id,
      value,
      module,
  });

  useEffect(() => {
    if (!preventChangeOnRender) {
      if (sortedOptions.length && onChangeSelectAll) {
        onChange(createEvent(sortedOptions.map((option) => Number(option[valueName]))));
      } else {
        onChange(createEvent([]));
      }
    }
  }, [sortedOptions.length]);

  const parsedSelectedValues = Array.isArray(selectedValues)
    ? selectedValues.map(Number)
    : [selectedValues].map(Number);

  const filterOnChange = (currentOptions, state) => {
    const newOptions = currentOptions.filter(
      (option) =>
        option[valueName] === 0 ||
        option[title].toLowerCase().includes(state.inputValue.toLocaleLowerCase()),
    );
    return newOptions.length === 1 && newOptions[0][valueName] === 0 ? [] : newOptions;
  };

  const optionOnChange = (checked, option) => {
    let newSelectedValues = [];
    if (checked) {
      newSelectedValues = parsedSelectedValues.filter(
        (value) => option[valueName] !== Number(value),
      );
    } else {
      newSelectedValues = [...new Set([...parsedSelectedValues, Number(option[valueName])])];
    }
    onChange(createEvent(newSelectedValues));
  };

  const selectAllOptions = (hasAllOptionsSelected, filteredOptions) => {
    let newSelectedValues = [];
    const filteredOptionsValues = filteredOptions.map((option) => Number(option[valueName]));

    if (hasAllOptionsSelected) {
      newSelectedValues = parsedSelectedValues.filter(
        (value) => !filteredOptionsValues.includes(Number(value)),
      );
    } else {
      newSelectedValues = [...new Set([...parsedSelectedValues, ...filteredOptionsValues])];
    }

    onChange(createEvent(newSelectedValues));
  };

  const renderOption = (option, params) => {
    const isAllOption = option[valueName] === 0;
    const filterText = params.inputValue;
    let checked = parsedSelectedValues.includes(Number(option[valueName]));
    let filteredOptions = sortedOptions;

    if (isAllOption) {
      filteredOptions = sortedOptions.filter((opt) => {
        if (filterText) {
          return opt[title].toLowerCase().includes(filterText.toLowerCase());
        }
        return opt;
      });
      checked = filteredOptions
        .filter((opt) => opt[valueName] !== 0)
        .every((opt) => parsedSelectedValues.includes(Number(opt[valueName])));
    }

    return (
      <Tooltip arrow title={option.toolTip || ''}>
        <ListItem
          button
          onClick={() => {
            if (isAllOption) {
              selectAllOptions(checked, filteredOptions);
            } else {
              optionOnChange(checked, option);
            }
          }}
          style={(() => {
            let styles = {
              width: '100%',
              height: '100%',
              padding: 10,
            };

            if (noCheckBox && checked) {
              styles = {
                ...styles,
                ...{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.04)' },
              };
            }
            return styles;
          })()}>
          {!noCheckBox && (
            <ListItemIcon>
              <Checkbox checked={checked} disabled={disabled} />
            </ListItemIcon>
          )}
          <ListItemText primary={option[title]} />
          {option.endIcon && <ListItemIcon>{option.endIcon}</ListItemIcon>}
        </ListItem>
      </Tooltip>
    );
  };

  const renderInput = (params) => (
    <TextField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...params}
      InputProps={{ ...params.InputProps }}
      error={error}
      helperText={helperText}
      label={`${label}  ${parsedSelectedValues.length} / ${sortedOptions.length}`}
      type="text"
      disabled={disabled}
    />
  );

  return (
    <StyledAutocomplete
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      onBlur={handleClose}
      multiple
      id="multiple-selector"
      disableCloseOnSelect
      disableClearable
      options={sortedOptions.length === 0 ? [] : [...defaultOptions, ...sortedOptions]}
      value={[]}
      getOptionLabel={(option) => option[title]}
      noOptionsText={noOptionsMessage || 'No se encontraron coincidencias'}
      filterOptions={filterOnChange}
      renderOption={renderOption}
      renderInput={renderInput}
      renderTags={() => null}
    />
  );
};

export default MultipleSelector;
