import React, { useState, useEffect } from 'react';
import produce from 'immer';
import connectScreen from '../../utils/connectScreen';
import saga from './saga';

import { versionHistoryActions } from './reducer';
import { selectState } from "../../utils/reduxActions";
import VersionHistoryComponents from './components';
import { falsy } from '../../utils/functions';
// utils
import moment from "moment";

// styles
import styles from "./styles";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(styles)

const defaultFilterControls = {
  fromDate: moment().startOf('month').toISOString(),
  toDate: moment().endOf('month').toISOString(),
  modelName: null,
  eventType: null
}

const defaultPagination = {
  page: 0,
  rowsPerPage: 10,
  count: 0
}

const VersionHistory = (props) => {
  const {
    actions,
    objects,
    pagination,
    loading,
    versionHistoriesIdList,
  } = props;

  const [filterControls, setFilterControls] = useState({ ...defaultFilterControls });
  const [versionHistoriesList, setVersionHistoriesList] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    actions.getStateFromApi(defaultFilterControls, defaultPagination);
  }, [actions]);

  useEffect(() => {
    setVersionHistoriesList(Object.values(objects.versionHistories)
      .filter(item => versionHistoriesIdList.includes(item.id)));

  }, [objects.versionHistories, versionHistoriesIdList])

  // Pagination

  function onFilterChange(event, date) {
    let input;
    let value;

    if (event.autocomplete) {
      input = event.input;
      value = event.value;
    } else {
      event.persist();
      input = falsy(event.target.type) ? event.currentTarget.id : event.target.id;
      value = event.target.type === 'date' ? date : event.target.value;
    }

    setFilterControls(produce((draft) => {
      draft[input] = value;
    }));
  }

  function clearFilters() {
    setFilterControls({ ...defaultFilterControls });
    actions.paginationControls(defaultFilterControls, { ...defaultPagination });
  }

  function handleChangeRowsPerPage(e) {
    actions.paginationControls(filterControls, {
      ...pagination,
      rowsPerPage: e.target.value,
    });
  }

  function handleChangePage(e, val) {
    actions.paginationControls(filterControls, { ...pagination, page: val });
  }

  function submitFilter() {
    actions.getStateFromApi(filterControls, { ...pagination, page: 0 });
  }

  return (
    <VersionHistoryComponents
      classes={classes}
      objects={objects}
      actions={actions}
      filterControls={filterControls}
      versionHistoriesList={versionHistoriesList}
      pagination={pagination}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
      onFilterChange={onFilterChange}
      submitFilter={submitFilter}
      clearFilters={clearFilters}
      loading={loading}
    />
  );
}

export default connectScreen(
  'VersionHistory',
  VersionHistory,
  saga,
  versionHistoryActions,
  selectState(
    'versionHistories.versionHistoriesIdList',
    'versionHistories.pagination',
    'versionHistories.loading',
    'app.objects',
    'app.loaders'
  ),
  styles,
)