// @ts-nocheck
/* eslint-disable no-shadow */
import React from 'react';
// ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
// utils
import { truthty } from '../../../../utils/functions';
import MaintainersPaginator from '../../../../commons/components/MaintainersPaginator';

const ItemList = (props) => {
  const {
    classes,
    fruitList,
    toggleEdit,
    toggleDelete,
    handleClick,
    handleClose,
    anchorEl,
  } = props;

  const getListItem = (fruit, openMenuHandler, closeMenuHandler, toggleEdit, toggleDelete, anchorEl) => (
    <ListItem key={`fruit-${fruit.id}`}>
      <ListItemAvatar>
        <Avatar className={classes.blue}>
          {(fruit.name || 'p')[0].toUpperCase()}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={fruit.name}
      />
      <ListItemSecondaryAction>
        <IconButton
          onClick={openMenuHandler}
          data-id={fruit.id}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={truthty(anchorEl)}
          onClose={closeMenuHandler}
        >
          <MenuItem onClick={toggleEdit}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            Editar
          </MenuItem>
          <MenuItem onClick={toggleDelete}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            Eliminar
          </MenuItem>
        </Menu>
      </ListItemSecondaryAction>
    </ListItem>
  );

  return (
    <Grid item xs={12}>
      <Card className={classes.root}>
        <CardContent>
          {truthty(fruitList) ? (
            <MaintainersPaginator
              classes={classes}
              list={fruitList}
              getListItem={getListItem}
              openMenuHandler={handleClick}
              closeMenuHandler={handleClose}
              toggleEdit={toggleEdit}
              toggleDelete={toggleDelete}
              anchorEl={anchorEl}
            />
          ) : (
            <Grid container item xs={12} alignItems="center" justifyContent="center">
              <Typography variant="h6" component="h2">
                No se han ingresado especies
              </Typography>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ItemList;
