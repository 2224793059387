import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const SkeletonTable = (props) => (
  <>
    {[...Array(props.times)].map((row, index) => (
      <TableRow key={`skeleton-table-row-${index}`}>
        {[...Array(props.columns)].map((col, i) => (
          <TableCell key={`skeleton-table-cell-${index}-${i}`}>
            <Skeleton animation="wave" />
          </TableCell>
        ))}
      </TableRow>
    ))}
  </>
);

export default SkeletonTable;