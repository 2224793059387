// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';

import apiRequest, { apiSuccess } from 'utils/api';
import { PACKAGING_TYPES } from '../reducer';
import { truthty } from 'utils/functions';
import { appActions } from 'commons/reducer';

function* getStateFromApi() {
  yield takeLatest(PACKAGING_TYPES.GET_STATE_FROM_API, function* (action) {
    const response = yield apiRequest('api/v1/packagingTypes', { method: 'get' });
    yield put(apiSuccess(PACKAGING_TYPES.GET_STATE_FROM_API_SUCCESS, response));
  });
}

function* createPackagingType() {
  yield takeLatest(PACKAGING_TYPES.CREATE_PACKAGING_TYPE, function* (action) {
    yield put(appActions.setLoader('create-edit-packagingType', true));
    const response = yield apiRequest('api/v1/packagingTypes',
      {
        method: 'post',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PACKAGING_TYPES.CREATE_PACKAGING_TYPE_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-packagingType', false));
  });
}

function* editPackagingType() {
  yield takeLatest(PACKAGING_TYPES.EDIT_PACKAGING_TYPE, function* (action) {
    yield put(appActions.setLoader('create-edit-packagingType', true));
    const response = yield apiRequest(`api/v1/packagingTypes/${action.id}`,
      {
        method: 'put',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PACKAGING_TYPES.EDIT_PACKAGING_TYPE_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-packagingType', false));
  });
}

function* deletePackagingType() {
  yield takeLatest(PACKAGING_TYPES.DELETE_PACKAGING_TYPE, function* (action) {
    yield put(appActions.setLoader('delete-packagingType', true));
    const response = yield apiRequest(`api/v1/packagingTypes/${action.id}`, { method: 'delete' });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    }
    const item = Object.values(response)[0];
    yield put(apiSuccess(PACKAGING_TYPES.DELETE_PACKAGING_TYPE_SUCCESS, { item, type: 'packagingTypes' }));
    yield put(appActions.setLoader('delete-packagingType', false));
  });
}

function* exportPackagingTypes() {
  yield takeLatest(PACKAGING_TYPES.EXPORT_PACKAGING_TYPE, function* (action) {
    yield put(appActions.setLoader('export-packagingTypes', true));
    const response = yield apiRequest('api/v1/packagingTypes/export', { method: 'get' }, false, 'packagingTypes.xlsx');
    yield put(apiSuccess(PACKAGING_TYPES.EXPORT_PACKAGING_TYPE_SUCCESS, response));
    yield put(appActions.setLoader('export-packagingTypes', false));
  });
}

export default function* saga() {
  yield spawn(getStateFromApi);
  yield spawn(deletePackagingType);
  yield spawn(createPackagingType);
  yield spawn(editPackagingType);
  yield spawn(exportPackagingTypes);
}
