
const padStartDate = (value, maxLength) => '0'.repeat(maxLength - String(value).length).concat(String(value));

export const fDateForCountry = (date) => {
  const dateCountryCode = 'es-CL';
  const dateOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  };

  const dateFormat = new Intl.DateTimeFormat(dateCountryCode, dateOptions);
  const timeFormat = new Intl.DateTimeFormat(dateCountryCode, timeOptions);
  const datetimeFormat = new Intl.DateTimeFormat(dateCountryCode, { ...dateOptions, ...timeOptions });

  return {
    date: dateFormat.format(date),
    time: timeFormat.format(date),
    datetime: datetimeFormat.format(date),
  };
};

const parseToDate = (dateStr) => {
  let parsedDate = dateStr === undefined ? new Date() : dateStr;

  if (!(dateStr instanceof Date) && typeof dateStr === 'string') {
    if (dateStr.includes('T')) {
      parsedDate = new Date(dateStr);
    } else {
      const [year, month, day] = dateStr.split('-');
      parsedDate = new Date(Number(year), Number(month) - 1, Number(day));
    }
  }

  const beginningOfMonth = new Date(parsedDate.getFullYear(), parsedDate.getMonth(), 1);
  const endOfMonth = new Date(parsedDate.getFullYear(), parsedDate.getMonth() + 1, 0);
  const beginningOfDay = new Date(new Date(parsedDate).setHours(0, 0, 0, 0));
  const endOfDay = new Date(new Date(parsedDate).setHours(23, 59, 59, 999));

  return {
    date: parsedDate,
    beginningOfMonth,
    endOfMonth,
    beginningOfDay,
    endOfDay,
  };
};
const toISOString = (dateStr) => {
  const parsedDate = parseToDate(dateStr).date;
  return parsedDate.toISOString();
};

const getLocalDate = (dateStr) => {
  const parsedDate = parseToDate(dateStr).date;
  const { date, time, datetime } = fDateForCountry(parsedDate);

  const day = padStartDate(parsedDate.getDate(), 2);
  const month = padStartDate(parsedDate.getMonth() + 1, 2);
  const year = padStartDate(parsedDate.getFullYear(), 4);

  return {
    isoLocaleDate: `${year}-${month}-${day}`,
    localeDate: date,
    localeTime: time,
    localeDateTime: datetime,
  };
};

export {
  toISOString,
  getLocalDate,
  parseToDate,
};
