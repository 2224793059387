// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';

import apiRequest, { apiSuccess } from 'utils/api';
import { WORKSHIFTS } from '../reducer';
import { truthty, falsy } from 'utils/functions';
import { appActions } from 'commons/reducer';

function* getStateFromApi() {
  yield takeLatest(WORKSHIFTS.GET_STATE_FROM_API, function* (action) {
    const response = yield apiRequest('api/v1/workShiftTypes', { method: 'get' });
    yield put(apiSuccess(WORKSHIFTS.GET_STATE_FROM_API_SUCCESS, response));
  });
}

function* createWorkShiftType() {
  yield takeLatest(WORKSHIFTS.CREATE_WORKSHIFT, function* (action) {
    yield put(appActions.setLoader('create-edit-workShiftType', true));
    const response = yield apiRequest('api/v1/workShiftTypes',
      {
        method: 'post',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(WORKSHIFTS.CREATE_WORKSHIFT_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-workShiftType', false));
  });
}

function* editWorkShiftType() {
  yield takeLatest(WORKSHIFTS.EDIT_WORKSHIFT, function* (action) {
    yield put(appActions.setLoader('create-edit-workShiftType', true));
    const response = yield apiRequest(`api/v1/workShiftTypes/${action.id}`,
      {
        method: 'put',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(WORKSHIFTS.EDIT_WORKSHIFT_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-workShiftType', false));
  });
}

function* deleteWorkShiftType() {
  yield takeLatest(WORKSHIFTS.DELETE_WORKSHIFT, function* (action) {
    yield put(appActions.setLoader('delete-workShiftType', true));
    const response = yield apiRequest(`api/v1/workShiftTypes/${action.id}`, { method: 'delete' });
    if (falsy(response.error)) {
      const item = Object.values(response)[0];
      yield put(apiSuccess(WORKSHIFTS.DELETE_WORKSHIFT_SUCCESS, { item, type: 'workShiftTypes' }));
    } else {
      yield put(appActions.setError(response.errorMsg));
    }
    yield put(appActions.setLoader('delete-workShiftType', false));
  });
}

export default function* saga() {
  yield spawn(getStateFromApi);
  yield spawn(deleteWorkShiftType);
  yield spawn(createWorkShiftType);
  yield spawn(editWorkShiftType);
}
