/* eslint-disable import/no-unresolved */
import React from 'react';
// material
import {
  Typography, TableContainer, Table, TableHead, TableRow, TableBody, Paper,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
// components
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import StyledTableCell from 'commons/components/StyledTableCell';
import FileInput from 'commons/components/FileInput';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// utils
import { truthty, fCurrency } from 'utils/functions';
import moment from 'moment';

const EmployeeCostsModal = (props) => {
  const {
    toggleModal,
    isOpen,
    classes,
    processListTotal,
    changeEmployeeCostsFile,
    file,
    updatedProcesses,
    processes,
    submitEmployeeCosts,
  } = props;
  return (
    <Dialog fullWidth onClose={toggleModal} aria-labelledby="simple-dialog-title" open={isOpen}>
      <DialogTitle>Importar costos</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Typography>
              Cantidad de procesos
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid item container justifyContent="center">
              <Grid item>
                <Button
                  startIcon={<CloudDownloadIcon />}
                  href="https://drive.google.com/uc?id=1LFB5xKwNx232KdUU1sQmchTE10EImSBq&export=download"
                  target="_blank"
                  color="primary"
                  variant="contained"
                >
                  DESCARGAR PLANILLA
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item container justifyContent="center">
              <Grid item>
                <div className={classes.labelItem}>{processListTotal}</div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FileInput
              onChange={changeEmployeeCostsFile}
              name="fileUpload"
              id="fileUpload"
              accept=".xlsx,.xls,.csv"
              text="Importar Excel con Costos"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ textAlign: 'center', marginBottom: 16 }}>
              {file ? file.name : ''}
            </Typography>
          </Grid>
        </Grid>
        {truthty(updatedProcesses) && (
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Nº Proceso</StyledTableCell>
                    <StyledTableCell>Proceso</StyledTableCell>
                    <StyledTableCell align="right">Fecha</StyledTableCell>
                    <StyledTableCell align="right">Costo empleado</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(processes)
                    .filter((p) => updatedProcesses.includes(p.id))
                    .map((p) => (
                      <TableRow key={`updated-process-${p.id}`}>
                        <StyledTableCell component="th">
                          {p.id}
                        </StyledTableCell>
                        <StyledTableCell scope="process">
                          {p.processType().name}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {moment(p.date).format('DD-MM-YYYY')}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {fCurrency.format(p.employeeCost)}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModal} color="primary" variant="outlined">
          Cerrar
        </Button>
        <ButtonWithLoader
          onClick={submitEmployeeCosts}
          autoFocus
          buttonId="submit-employee-costs"
          disabled={!file}
        >
          Enviar
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  );
};

export default EmployeeCostsModal;
