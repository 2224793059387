import { truthty } from "utils/functions";

// @ts-nocheck
export const apiSuccess = (entity, payload, args) => ({
  type: entity,
  payload,
  ...args,
});

const defaultHeader = {
  headers: {
    'Content-Type': 'application/json'
  },
};

export default function* apiRequest(url, options = defaultHeader, login, fileName) {
  const res = yield fetch(`${window.location.origin}/${url}`, {
    ...defaultHeader,
    ...options
  })
    .then(response => {
      if (fileName) {
        response.blob().then(blob => {
          const data = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = data;
          link.download = fileName;
          link.click();
        });
      } else if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        localStorage.clear();
        if (!login) {
          window.location.replace('/logout');
        }
      } else {
        return response.text().then(errorMsg => ({ error: true, errorMsg }));
      }
    })
    .catch(response => {
      console.error(response);
    });
  return res;
}

export function objectToQueryString(obj) {
  return Object.keys(obj).map((k) => {
    if (truthty(obj[k])) {
      return Array.isArray(obj[k]) ?
        obj[k].map((o) => `${encodeURIComponent(k)}[]=${encodeURIComponent(o)}`).join('&') :
        `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`;
    } else {
      return '';
    }
  }).join('&');
}

export function* post(path, body) {
  const options = {
    method: 'post',
    body: JSON.stringify(body),
  };

  return yield apiRequest(path, options);
}

export function* update(path, body) {
  const options = {
    method: 'put',
    body: JSON.stringify(body),
  };

  return yield apiRequest(path, options);
}

export function* get(path, query) {
  const options = {
    method: 'get',
  };

  return yield apiRequest(`${path}?${query}`, options);
}

export function* del(path, query) {
  const options = {
    method: 'delete',
  };

  return yield apiRequest(path, options);
}

export function objectToFormData(obj, rootName, ignoreList) {
  const formData = new FormData();
  function appendFormData(data, root) {
    if (!ignore(root)) {
      root = root || '';
      if (data instanceof File) {
        formData.append(root, data);
      } else if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          appendFormData(data[i], `${root}[${i}]`);
        }
      } else if (typeof data === 'object' && data) {
        Object.keys(data).map(
          key => {
            if (data.hasOwnProperty(key)) {
              if (root === '') {
                appendFormData(data[key], key);
              } else {
                appendFormData(data[key], `${root}[${key}]`);
              }
            }
            return data;
          }
        );
      } else if (data !== null && typeof data !== 'undefined') {
        formData.append(root, data);
      }
    }
  }

  function ignore(root) {
    return Array.isArray(ignoreList) && ignoreList.some(x => x === root);
  }

  appendFormData(obj, rootName);

  return formData;
}
