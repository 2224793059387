// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';

import apiRequest, { apiSuccess, objectToQueryString } from 'utils/api';
import { LOTS } from '../reducer';
import { truthty } from 'utils/functions';
import { appActions } from 'commons/reducer';

function* getStateFromApi() {
  yield takeLatest([LOTS.GET_STATE_FROM_API, LOTS.CHANGE_PAGINATION], function* (action) {
    yield put(appActions.setLoader('get-lots', true));
    const response = yield apiRequest(`api/v1/lots?${objectToQueryString(
      {
        page: action.pagination.page,
        rowsPerPage: action.pagination.rowsPerPage,
        ...action.filters
      })}`, { method: 'get' });
    yield put(apiSuccess(LOTS.GET_STATE_FROM_API_SUCCESS, response, { pagination: action.pagination }));
    yield put(appActions.setLoader('get-lots', false));
  });
}

function* submitWarehouseMovement() {
  yield takeLatest(LOTS.SUBMIT_WAREHOUSE_MOVEMENT, function* (action) {
    yield put(appActions.setLoader('lot-warehouse-movement', true));
    const response = yield apiRequest(`api/v1/lots/warehouse_movement/${action.id}`,
      {
        method: 'put',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(LOTS.SUBMIT_WAREHOUSE_MOVEMENT_SUCCESS, response));
    }
    yield put(appActions.setLoader('lot-warehouse-movement', false));
  });
}

function* getLotReport() {
  yield takeLatest(LOTS.GET_LOT_REPORT, function* (action) {
    yield put(appActions.setLoader('get-lots', true));
    const query = objectToQueryString({...action.filter, socketId: action.socketId})
    const response = yield apiRequest(
      `api/v1/lots/report?${query}`,
       { method: 'get' },
        false,
        //  'reporte_tarjas.xlsx'
    );
    yield put(apiSuccess(LOTS.GET_LOT_REPORT_SUCCESS, response));
    yield put(appActions.setLoader('get-lots', false));
  });
}

export default function* saga() {
  yield spawn(getLotReport);
  yield spawn(getStateFromApi);
  yield spawn(submitWarehouseMovement);
}
