// @ts-nocheck
import { compose, bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import connectSaga from './saga';
import { clone } from './functions';
import { actionProps } from './reduxActions';
import { ReactReduxContext } from '../config/configureStore';

export default function(screenName, container, saga, actions, state, styleObj = {}) {
  const withSaga = connectSaga({ key: `saga${screenName}`, saga });

  const withConnect = connect(
    state,
    actionProps(clone(clone.OBJECT, actions)),
    null,
    { context: ReactReduxContext },
  );

  return compose(
    withStyles(styleObj),
    withRouter,
    withConnect,
  )(withSaga(container));
}
