/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-param-reassign */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Container,
  Fab,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import produce from 'immer';
import DeleteModal from '../../commons/components/DeleteModal';
import { homeActions } from '../Home/reducer';
import { ReactReduxContext } from '../../config/configureStore';
import { suppliersActions } from './reducer';
import styles from './styles';
import SupplierForm from './components/SupplierForm';
import {
  clone, truthty, falsy, formatRut, validateRut,
} from '../../utils/functions';
import saga from './saga';
import connectSaga from '../../utils/saga';
import { selectState, actionProps } from '../../utils/reduxActions';
import MaintainersPaginator from '../../commons/components/MaintainersPaginator';
import {withTranslation} from 'react-i18next';
import TableActionMenu from 'commons/components/TableActionMenu';

const useStyles = makeStyles(styles);

const Suppliers = (props) => {
  const {
    objects,
    actions,
    formIsOpen,
    formControls,
    edit,
    invalidControls,
    deleteIsOpen,
    t,
    i18n,
  } = props;
  const account = Object.values(objects.accounts)[0] || {};
  const [supplierList, setSupplierList] = useState([]);
  const [filter, setFilter] = useState({ name: '', taxNumber: '' });
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const filteredEntitiesIds = Object.values(objects.entities)
      .filter((item) => item.name.toLowerCase().includes(filter.name.toLowerCase()) && item.taxNumber.toLowerCase().includes(filter.taxNumber.toLowerCase()))
      .map((item) => item.id);
    const filteredSuppliers = Object.values(objects.suppliers).filter((item) => filteredEntitiesIds.includes(item.entityId)).sort((a, b) => a.entity().name.toLowerCase().localeCompare(b.entity().name.toLowerCase()));
    setSupplierList(filteredSuppliers);
  }, [objects.entities, objects.suppliers, filter]);

  useEffect(() => {
    actions.getStateFromApi();
  }, [actions]);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function toggleForm() {
    actions.toggleForm('formIsOpen');
  }

  function closeForm() {
    actions.toggleForm('deleteIsOpen');
  }

  function toggleDelete() {
    handleClose();
    actions.toggleDelete(anchorEl.dataset.id);
  }

  function toggleEdit() {
    handleClose();
    const supplier = Object.values(objects.suppliers)
      .find((item) => item.id === Number(anchorEl.dataset.id));
    const entity = objects.entities[supplier.entityId];
    actions.toggleEdit(entity, supplier);
  }

  function changeControls(event) {
    actions.changeControls({ [event.target.id]: event.target.value }, { [event.target.id]: false });
  }

  function submit() {
    if (
      falsy(formControls.name)
      || falsy(formControls.taxNumber)
    || validateRut(props.formControls.taxNumber, account?.country)) {
      actions.setInvalidControls({
        name: falsy(formControls.name),
        taxNumber: falsy(props.formControls.taxNumber)
          ? true
          : validateRut(props.formControls.taxNumber, account?.country),
      });
    } else if (edit) {
      actions.editSupplier(props.toEditId, formControls);
    } else {
      actions.createSupplier(formControls);
    }
  }

  function submitDelete() {
    actions.deleteSupplier(props.toDeleteId);
  }

  function onFilterChange(event) {
    event.persist();
    setFilter(produce((draft) => {
      draft[event.target.id] = event.target.value;
    }));
  }

  const classes = useStyles();

  const getListItem = (supplier, openMenuHandler, closeMenuHandler, toggleEdit, toggleDelete) => (
    <ListItem key={`supplier-${supplier.id}`}>
      <ListItemAvatar>
        <Avatar className={classes.blue}>
          {(supplier.entity().name || 'p')[0].toUpperCase()}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={supplier.entity().name}
        secondary={`${t("identifier:name")}: ${formatRut(supplier.entity().taxNumber, i18n.language)}${truthty(supplier.email) ? `, email: ${supplier.email}` : ''}`}
      />
      <ListItemSecondaryAction>
        <IconButton
          onClick={openMenuHandler}
          data-id={supplier.id}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={truthty(anchorEl)}
          onClose={closeMenuHandler}
        >
          <MenuItem onClick={toggleEdit}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            Editar
          </MenuItem>
          <MenuItem onClick={toggleDelete}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            Eliminar
          </MenuItem>
        </Menu>
      </ListItemSecondaryAction>
    </ListItem>
  );

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h2" className={classes.marginTop}>
            Proveedores
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.root}>
            <CardHeader title="Filtro" />
            <CardContent>
              <Grid container spacing={3} className={classes.container}>
                <Grid container item xs={4}>
                  <TextField fullWidth id="taxNumber" label={t("identifier:name")} value={filter.taxNumber} onChange={onFilterChange} />
                </Grid>
                <Grid container item xs={4}>
                  <TextField fullWidth id="name" label="Nombre" value={filter.name} onChange={onFilterChange} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid container item>
          <Grid item xs={12} sm={4} md={3}>
            <TableActionMenu
              menuItems={[
                {
                  label: 'Exportar',
                  listItems: [{
                    label: 'Archivo Excel',
                    onClick: actions.exportSuppliers,
                    params: [supplierList],
                  }],
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.root}>
            <CardContent>
              {truthty(objects.suppliers) ? (
                <MaintainersPaginator
                  classes={classes}
                  list={supplierList}
                  getListItem={getListItem}
                  openMenuHandler={handleClick}
                  closeMenuHandler={handleClose}
                  toggleEdit={toggleEdit}
                  toggleDelete={toggleDelete}
                />
              ) : (
                <Grid container item xs={12} alignItems="center" justifyContent="center">
                  <Typography variant="h6" component="h2">
                    No se han ingresado proveedores
                  </Typography>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SupplierForm
        isOpen={formIsOpen}
        toggleForm={toggleForm}
        changeControls={changeControls}
        onChange={actions.changeControls}
        controls={formControls}
        edit={edit}
        submit={submit}
        invalidControls={invalidControls}
      />
      <DeleteModal isOpen={deleteIsOpen} toggleForm={closeForm} submit={submitDelete} buttonId="delete-supplier" />
      <Fab color="primary" aria-label="add" className={classes.fab} onClick={toggleForm}>
        <AddIcon />
      </Fab>
    </Container>
  );
};

const withSaga = connectSaga({ key: 'sagaSuppliers', saga });

const withConnect = connect(
  selectState(
    'suppliers.invalidControls',
    'suppliers.formControls',
    'suppliers.deleteIsOpen',
    'suppliers.toDeleteId',
    'suppliers.formIsOpen',
    'suppliers.toEditId',
    'suppliers.edit',
    'app.objects',
  ),
  actionProps(clone(clone.OBJECT, homeActions, suppliersActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withTranslation(),
  withStyles(styles),
  withRouter,
  withConnect,
)(withSaga(Suppliers));
