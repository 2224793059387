// @ts-nocheck
import React from 'react';
// material ui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// icons
import SearchIcon from '@material-ui/icons/Search';
// components
import ButtonWithLoader from '../../../../commons/containers/ButtonWithLoader';
import {MultipleSelectorFreeSolo} from 'commons/components/MultipleSelectorFreeSolo';
import {useEffect} from 'react';
import {useState} from "react";
import DatePicker from 'commons/components/DatePicker';
import { Today } from '@material-ui/icons';



const InfoForm = (props) => {
  const { filter, objects, classes, filterFnc, onFilterChange, productorsName, varieties, productsName, suppliers } = props;
  const nameSuppliers = [...new Set(suppliers.map((supplier) => supplier.name))]


  const [formValues, setFormValues] = useState({
    numberSupplier: [...new Set(suppliers.map((supplier) => supplier.name))].join(', '),
    taxSupplier: [...new Set(suppliers.map((supplier) => supplier.taxNumber ? supplier.taxNumber.replace(/^(\d{1,2})(\d{3})(\d{3})([\dkK])$/, "$1.$2.$3-$4") : ''))].join(', '),
    Variety: [...new Set(varieties)].join(', '),
    numberProduct: [...new Set(productsName)].join(', '),
    nameClient: '',
    taxClient: '',
    date: '',
    plant: '',
    
    // Agrega aquí los ID de los demás TextFields
  });
  

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormValues({ ...formValues, [id]: value });
  };

  return (
      <Card className={classes.root}>
        <div className='info-print'>
        <CardContent className='info-print'>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  id="numberSupplier"
                  label="Nombre de productor"
                  placeholder="Nombre de productor"
                  value={formValues.numberSupplier}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="nameClient"
                  label="Nombre de cliente"
                  placeholder="Nombre de cliente"
                  value={formValues.nameClient}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="numberProduct"
                  label="Producto"
                  placeholder="Producto"
                  value={formValues.numberProduct}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                  <DatePicker
                    id="date"
                    label="Fecha"
                    fullWidth
                    value={formValues.date}
                    onChange={handleInputChange}
                  />
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  id="taxSupplier"
                  label="RUT de productor"
                  placeholder="RUT de productor"
                  value={formValues.taxSupplier}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="taxClient"
                  label="RUT de cliente"
                  placeholder="RUT de cliente"
                  value={formValues.taxClient}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="Variety"
                  label="Variedad"
                  placeholder="Variedad"
                  value={formValues.Variety}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="plant"
                  label="Planta"
                  placeholder="Planta"
                  value={formValues.plant}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        </div>
      </Card>
  );
};

export default InfoForm;
