import React from 'react';

// ui
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
// components
import PaddockForm from './PaddockForm';
import DeleteModal from 'commons/components/DeleteModal';
import ItemList from 'screens/Paddocks/components/ItemList';
import Filters from 'screens/Paddocks/components/Filters';

const PaddocksComponent = props => (
  <Container maxWidth={false}>
    <Grid container spacing={3} className={props.classes.container}>
      <Grid item xs={12}>
        <Typography variant="h3" component="h2" className={props.classes.marginTop}>
          Cuarteles
        </Typography>
      </Grid>
      <Filters
        classes={props.classes}
        filter={props.filter}
        onFilterChange={props.onFilterChange}
      />
      <ItemList
        classes={props.classes}
        toggleEdit={props.toggleEdit}
        toggleDelete={props.toggleDelete}
        paddockList={props.paddockList}
        anchorEl={props.anchorEl}
        handleClick={props.handleClick}
        handleClose={props.handleClose}
      />
    </Grid>
    <PaddockForm
      isOpen={props.formIsOpen}
      toggleForm={props.toggleForm}
      changeControls={props.changeControls}
      onChange={props.actions.changeControls}
      controls={props.formControls}
      edit={props.edit}
      submit={props.submit}
      invalidControls={props.invalidControls}
      fruits={props.fruits}
      varieties={props.varieties}
      products={props.products}
    />
    <DeleteModal isOpen={props.deleteIsOpen} toggleForm={props.closeForm} submit={props.submitDelete} buttonId="delete-paddock" />
    <Fab color="primary" aria-label="add" className={props.classes.fab} onClick={props.toggleForm} >
      <AddIcon />
    </Fab>
  </Container >
);

export default PaddocksComponent;
