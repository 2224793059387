// @ts-nocheck
/* eslint-disable import/no-unresolved */
import React from 'react';
import DatePicker from 'commons/components/DatePicker';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
// icons
import SearchIcon from '@material-ui/icons/Search';

const Filters = (props) => {
  const {
    classes,
    filter,
    onFilterChange,
    supplyProducts,
    warehouses,
    clearFilter,
    filterLines,
  } = props;

  return (
    <Grid item xs={12}>
      <Card className={classes.root}>
        <CardHeader title="Filtro" />
        <CardContent>
          <Grid container spacing={3} className={classes.container} justifyContent="flex-end">
            <Grid item container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <DatePicker
                  inputId="fromDate"
                  label="Desde"
                  fullWidth
                  value={filter.fromDate}
                  onChange={onFilterChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <DatePicker
                  inputId="toDate"
                  label="Hasta"
                  fullWidth
                  value={filter.toDate}
                  onChange={onFilterChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                {/* <FormControl fullWidth>
                  <InputLabel id="supply-product-label">Insumo</InputLabel>
                  <Select
                    labelId="supply-product-label"
                    fullWidth
                    value={filter.supplyProductId}
                    onChange={onFilterChange}
                  >
                    <MenuItem id="supplyProductId" value="">Todos</MenuItem>
                    {Object.values(supplyProducts).map((sp) => (
                      <MenuItem
                        key={`supply-product-filter-option-${sp.id}`}
                        id="supplyProductId"
                        value={sp.id}
                      >
                        {sp.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                <Autocomplete
                  value={filter.supplyProductId && Object.values(supplyProducts).find((sp) => sp.id === filter.supplyProductId)}
                  id="autocomplete-suppliesProduct-inventoryLines"
                  options={Object.values(supplyProducts).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))}
                  getOptionLabel={(supplyProduct) => supplyProduct && supplyProduct.name}
                  renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Insumo" margin="normal" />}
                  onChange={(event, newValue) => {
                    const e = {
                      autocomplete: true,
                      input: 'supplyProductId',
                      value: newValue ? newValue.id : '',
                    };
                    onFilterChange(e);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="warehouse-label">Bodega</InputLabel>
                  <Select
                    labelId="warehouse-label"
                    fullWidth
                    value={filter.warehouseId}
                    onChange={onFilterChange}
                  >
                    <MenuItem id="warehouseId" value="">Todos</MenuItem>
                    {Object.values(warehouses).map((warehouse) => (
                      <MenuItem
                        key={`process-type-filter-option-${warehouse.id}`}
                        id="warehouseId"
                        value={warehouse.id}
                      >
                        {warehouse.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container spacing={3} justifyContent="flex-end">
              <Grid item xs={12} sm={4} md={3}>
                <ButtonWithLoader
                  fullWidth
                  onClick={clearFilter}
                  autoFocus
                  buttonId="get-inventory-lines"
                  className={classes.filterButton}
                  size="small"
                >
                  Limpiar
                </ButtonWithLoader>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <ButtonWithLoader
                  fullWidth
                  onClick={filterLines}
                  autoFocus
                  buttonId="get-inventory-lines"
                  className={classes.filterButton}
                  size="small"
                  startIcon={<SearchIcon />}
                >
                  Buscar
                </ButtonWithLoader>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default Filters;
