// @ts-nocheck
import React from 'react';
// material ui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// icons
import SearchIcon from '@material-ui/icons/Search';
// components
import ButtonWithLoader from '../../../../commons/containers/ButtonWithLoader';

const Filters = (props) => {
  const { filter, objects, classes, filterFnc, onFilterChange } = props;

  const buttonDisabled = filter.date ? false : true;

  return (
    <Grid item xs={12}>
      <Card className={classes.root}>
        <CardHeader title="Filtro" />
        <CardContent>
          <Grid container spacing={3} className={classes.container}>
            <Grid item sm={4} xs={12}>
              <TextField
                id="date"
                label="Mes"
                type="month"
                onChange={onFilterChange}
                value={filter.date}
                fullWidth
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Autocomplete
                value={
                  filter.receiptLotNumber &&
                  Object.values(objects.receiptLots).find(
                    (rl) => rl.number === filter.receiptLotNumber,
                  )
                }
                id="autocomplete-receiptLotNumber-filters-productsReport"
                options={[
                  '',
                  ...Object.values(objects.receiptLots).sort((a, b) =>
                    a.number > b.number ? 1 : -1,
                  ),
                ]}
                getOptionLabel={(rl) => rl && rl.number.toString()}
                renderInput={(params) => (
                  <TextField
                    style={{ margin: 0 }}
                    {...params}
                    label="Número de lote"
                    margin="normal"
                  />
                )}
                onChange={(event, elem) => {
                  const e = {
                    autocomplete: true,
                    input: 'receiptLotNumber',
                    value: elem ? elem.number : '',
                  };
                  onFilterChange(e);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <ButtonWithLoader
                fullWidth
                onClick={filterFnc}
                disabled={buttonDisabled}
                autoFocus
                buttonId="get-process"
                className={classes.filterButton}
                size="small"
                startIcon={<SearchIcon />}>
                Buscar
              </ButtonWithLoader>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Filters;
