import React from 'react';
// ui
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// incons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// components
import { truthty } from '../../../utils/functions';
import Filters from './Filters';
import InventoryTable from './InventoryTable';
import LineTable from './LineTable';
import TableActionMenu from '../../../commons/components/TableActionMenu';
import TableAdjustmentMenu from '../../../commons/components/TableAdjustmentMenu';
// icons
import ProductTable from './ProductTable';
import ProductsFilters from './productsFilters';
import ProcessingProducts from './ProcessingProducts';
import InventoryAdjustmentModal from 'screens/InventoryLines/components/InventoryAdjustmentModal';

const InventoryLinesComponent = (props) => {
  const {
    classes,
    inventoryType,
    changeInventory,
    anchorEl,
    closeInventoryMenu,
    setInventoryType,
    onFilterChange,
    filterControls,
    productLinesFilter,
    clearFilter,
    filterLines,
    toggleEdit,
    objects,
    toggleDelete,
    onProductLinesFilterChange,
    clearProductLinesFilter,
    filterProductLines,
    procesingLots,
    tableClasses,
    inventoryLines,
    productLines,
    filterProductsControls,
    inventoryLineIds,
    getLines,
    loaders,
    linesDialog,
    closeLinesDialog,
    exportLines,
    exportProductsReport,
    toggleInventoryModal,
    inventoryModal,
    file,
    submitInventoryAdjustment,
    exportAdjustmentInventoryLines,
    changeInventoryFile,
    updatedInventory,
    adjustmentSupplierId,
    setDownloading,
  } = props;

  return (
    <Container>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h2" className={classes.marginTop}>
            Reporte de inventario de
            {inventoryType === 'supply' ? ' insumos' : ' productos'}
            <IconButton aria-label="delete" onClick={changeInventory}>
              <ExpandMoreIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={closeInventoryMenu}
            >
              <MenuItem
                selected={inventoryType === 'supply'}
                onClick={setInventoryType}
                data-inventory-type="supply"
              >
                Insumos
              </MenuItem>
              <MenuItem
                selected={inventoryType === 'product'}
                data-inventory-type="product"
                onClick={setInventoryType}
              >
                Productos
              </MenuItem>
            </Menu>
          </Typography>
        </Grid>
        {inventoryType === 'supply' ? (
          <Filters
            onFilterChange={onFilterChange}
            clearFilter={clearFilter}
            filterLines={filterLines}
            classes={classes}
            filter={filterControls}
            toggleEdit={toggleEdit}
            onCtoggleDeletelick={toggleDelete}
            warehouses={objects.warehouses}
            supplyProducts={objects.supplyProducts}
          />
        ) : (
          <>
            <ProductsFilters
              onFilterChange={onProductLinesFilterChange}
              clearFilter={clearProductLinesFilter}
              filterLines={filterProductLines}
              classes={classes}
              filter={productLinesFilter}
              toggleEdit={toggleEdit}
              onCtoggleDeletelick={toggleDelete}
              warehouses={objects.warehouses}
              products={objects.products}
            />
          </>
        )}
        <Grid
          container
          item
          justifyContent="flex-start"
        >
          <Grid item xs={12} sm={3}>
            <TableActionMenu
              menuItems={[
                {
                  label: 'Importar',
                  listItems: [
                    {
                      label: 'Ajuste de inventario',
                      onClick: toggleInventoryModal,
                    },
                  ],
                },
                {
                  label: 'Exportar',
                  listItems: [{
                    label: inventoryType === 'product' ? 'Reporte de productos' : 'Reporte de insumos',
                    onClick: inventoryType === 'product' ? exportProductsReport : exportLines,
                    params: [inventoryType === 'product' ? productLinesFilter : filterControls],
                  }],
                },
              ]}
            />
          </Grid>
        </Grid>
        {inventoryType !== 'supply' && truthty(procesingLots) && (
          <ProcessingProducts
            lots={procesingLots}
          />
        )}
        {inventoryType === 'supply' ? (
          <InventoryTable
            tableClasses={tableClasses}
            classes={classes}
            inventoryLines={inventoryLines}
            inventoryLineIds={inventoryLineIds}
            getLines={getLines}
            loaders={loaders}
          />
        ) : (
          <ProductTable
            tableClasses={tableClasses}
            classes={classes}
            products={objects.products}
            warehouses={objects.warehouses}
            productLines={productLines}
            filterProductsControls={filterProductsControls}
          />
        )}
        <LineTable
          linesDialog={linesDialog}
          classes={classes}
          warehouseMovements={objects.warehouseMovements}
          closeLinesDialog={closeLinesDialog}
          adjustmentSupplierId={adjustmentSupplierId}
          filterControls={filterControls}
          getLines={getLines}
          actions={props.actions}
          inventoryLines={inventoryLines}
          inventoryLineIds={inventoryLineIds}
          setDownloading={setDownloading}
        />
        
        <InventoryAdjustmentModal
          classes={classes}
          toggleModal={toggleInventoryModal}
          isOpen={inventoryModal}
          file={file}
          processes={objects.processes}
          inventoryType={inventoryType}
          exportProductsReport={exportProductsReport}
          filterControls={filterControls}
          exportAdjustmentInventoryLines={exportAdjustmentInventoryLines}
          changeInventoryFile={changeInventoryFile}
          updatedInventory={updatedInventory}
          submitInventoryAdjustment={submitInventoryAdjustment}
        />
      </Grid>
    </Container>
  );
};

export default InventoryLinesComponent;