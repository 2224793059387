/* eslint-disable no-shadow */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
// @ts-nocheck
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
import { selectState, actionProps } from 'utils/reduxActions';
import { homeActions } from 'screens/Home/reducer';
import { appActions } from 'commons/reducer';
import { ReactReduxContext } from 'config/configureStore';
import connectSaga from 'utils/saga';
import { processActions } from 'screens/Process/reducer';
import saga from './saga';
import WarningIcon from '@material-ui/icons/Warning';
// utils
import {
  clone, falsy, onChangeFnc, truthty,
} from 'utils/functions';
// components
import ProcessComponent from './components';
// styles
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsPhone } from '@material-ui/icons';

const useStyles = makeStyles(styles);

const Process = (props) => {
  const [dialButtonOpen, setDialButtonOpen] = useState(false);
  const [process, setProcess] = useState({});
  const [qrCode, setQrCode] = useState('');
  const [id, setId] = useState('')

  const {
    match, actions, objects, formControls, lotId, invalidControls, formIsOpen, device,
    account, processResources,history, infoLot, usedLot, stopLoading
  } = props;

  const { params } = match;

  // const formControlQRState = useSelector((state) => state.process.formControls)
  const formControlQRState = useSelector((state) => state.process.formControls)

  useEffect(() => {
    if (params.id) {
      actions.getStateFromApi(params.id);
      actions.loadProcessResources(params.id);
    }
  }, [actions, params.id]);

  useEffect(() => {
    setProcess(objects.processes[Number(params.id)] || {});
  }, [objects, params.id]);

  useEffect(() => {
    if (params.id) {
      actions.loadProcessResources(params.id);
    }
  }, [objects.procceses, Object.values(objects.processEquipments).length, Object.values(objects.processStaffLines).length, Object.values(objects.supplyLines).length]);

  const speedDialToggle = () => {
    setDialButtonOpen(!dialButtonOpen);
  };

  function toggleForm(event) {
    if (dialButtonOpen) {
      speedDialToggle();
    }
    const { module } = event.currentTarget.dataset;
    if (module) {
      const lots = Object.values(objects.lots)
        .filter((lot) => lot.destinyProcessId === lotId);
      const lot = lots[lots.length - 1] || {};
      const departureLots = Object.values(objects.lots)
        .filter((dLot) => dLot.originProcessId === lotId);
      const departureLot = departureLots[departureLots.length - 1] || {};
      actions.changeControls(module, {
        fruitId: lot.fruitId || '',
        varietyId: lot.varietyId || '',
        productId: departureLot.productId || '',
        packagingTypeId: departureLot.packagingTypeId || '',
        warehouseId: departureLot.warehouseId || '',
        packagingQuantity: departureLot.packagingQuantity || '',
      }, 'invalidControls');
    }
    actions.toggleForm(module);
  }

  function changeControls(event, date, withModule = true) {
    onChangeFnc(actions.changeControls, event, date, withModule);
  }

  function submitStaff() {
    if (
      falsy(formControls.staff.employeeIds)
    ) {
      actions.setInvalidControls('staff', {
        employeeIds: falsy(formControls.staff.employeeIds),
      });
    } else {
      actions.addProcessStaff(process.id, formControls.staff.employeeIds);
    }
  }

  function submitProcessEquipments() {
    if (
      falsy(formControls.processEquipments.equipmentIds)
    ) {
      actions.setInvalidControls('processEquipments', {
        equipmentIds: falsy(formControls.processEquipments.equipmentIds),
      });
    } else {
      actions.addProcessEquipments(process.id, formControls.processEquipments.equipmentIds);
    }
  }

  function submitLot() {
    const { quantity, packagingTypeId, packagingQuantity } = formControls.departure;
    let totalWeight = 0;
    if (truthty(quantity) && truthty(packagingTypeId) && truthty(packagingQuantity)) {
      totalWeight = quantity - objects.packagingTypes[packagingTypeId].weight * packagingQuantity;
    }

    if (
      falsy(formControls.departure.productId)
      || falsy(formControls.departure.varietyId)
      || falsy(formControls.departure.quantity)
      || falsy(formControls.departure.packagingQuantity)
      || falsy(formControls.departure.packagingTypeId)
      || totalWeight <= 0
    ) {
      actions.setInvalidControls('departure', {
        productId: falsy(formControls.departure.productId),
        varietyId: falsy(formControls.departure.varietyId),
        quantity: falsy(formControls.departure.quantity),
        packagingQuantity: falsy(formControls.departure.packagingQuantity),
        packagingTypeId: falsy(formControls.departure.packagingTypeId),
      });
    } else {
      actions.addDeparture(process.id, formControls.departure, totalWeight, device, account?.zplCustomCode);
    }
  }

  function submitSupplyProduct() {
    const { supplyProductId, warehouseId, quantity, purchaseId } = formControls.supplyProducts;

    const inventoryLine = truthty(supplyProductId) && truthty(warehouseId)
      ? objects.supplyProducts[supplyProductId].inventoryLine(warehouseId, purchaseId)
      : {};

    const stock = truthty(supplyProductId) && truthty(warehouseId)
      ? inventoryLine.initial + inventoryLine.inputs - inventoryLine.outputs
      : 0;

    if (
      falsy(supplyProductId)
      || falsy(warehouseId)
      || falsy(quantity)
      || stock < quantity
    ) {
      actions.setInvalidControls('supplyProducts', {
        supplyProductId: falsy(supplyProductId),
        warehouseId: falsy(warehouseId),
        quantity: falsy(quantity) || stock < quantity,
      });
    } else {
      actions.addSupplyLine(process.id, formControls.supplyProducts, inventoryLine);
    }
  }

  function submitEntry(lotId) {
    const entryLotId = formControls.entry.lotId || lotId;
    if (!entryLotId) {
      actions.setInvalidControls('entry', {
        productId: entryLotId,
      });
    } else {
      actions.addEntry(entryLotId, process.id);
    }
  }

  function submitDelete() {
    actions[props.delete.actionFnc](props.delete.id);
  }

  function printFnc(lotId) {
    return () => {
      const labelData = { lotIds: [lotId], accountId: account?.id }
      actions.printLabel(device, labelData);
    };
  }

  const printFncPaks = (lotId, numPaks) => {
    const lotIds = [lotId];
    actions.infoPaksToPrint(account?.id, lotIds, numPaks, device);
  }

  function printAllFnc(lotIds) {
    return () => {
      const labelData = { lotIds, accountId: account?.id }
      actions.printLabel(device, labelData);
    };
  }

  function qrReader(event, qrCode) {
    setQrCode(truthty(event.target) ? event.target.value : qrCode);
  }

  function changeWeight(weight) {
    actions.changeControls('departure', { quantity: weight }, { quantity: false });
  }

  function onBackAction() {
    history.goBack('/processes');
  }

  const processStaffLines = Object.values(objects.processStaffLines)
    .filter((line) => line.processId === process.id);

  const employeeOptions = Object.values(objects.employees)
    .filter((emp) => !processResources.usedEmployeesIds.includes(emp.id))
    .map((emp) => {
      const used = processResources.usedEmployeesIds.includes(emp.id);
      const toolTip = used && 'Personal ingresado en otro proceso activo';
      const endIcon = used && <WarningIcon style={{ color: '#ffc107' }} />;
      return {
        label: emp.entity().name,
        value: emp.id,
        toolTip,
        endIcon,
      };
    });

  const equipmentOptions = Object.values(objects.equipments)
    .filter((emp) => !processResources.usedEquipmentsIds.includes(emp.id))
    .map((equipment) => ({ label: equipment.name, value: equipment.id }));

  const classes = useStyles();

  // eslint-disable-next-line react/destructuring-assignment
  const [role] = Object.values(props.role);
  const multipleLotsProcess = truthty(process) && process.processType().allowMultipleLots;
  const uniqueLot = !multipleLotsProcess && lotId
    ? Object.values(objects.lots)
      .filter((lot) => lot.destinyProcessId === process.id)[0] || {}
    : {};

  // departureForm
  const { quantity, packagingTypeId, packagingQuantity } = formControls.departure;
  let totalWeight = 0;
  if (truthty(quantity) && truthty(packagingTypeId) && truthty(packagingQuantity)) {
    totalWeight = quantity - objects.packagingTypes[packagingTypeId].weight * packagingQuantity;
  }

  function closeProcess(id, date) {
    actions.closeProcess(id, date);
  }
   
  const isAdmin = role && props.objects.roles[role].name === 'admin';
  const resultPaks = Object.values(objects.lots).filter((lot) => lot.originProcessId === process.id)
  const entriesPaks = Object.values(objects.lots).filter((lot) => lot.destinyProcessId === process.id)

  function toggleDelete(event) {
    if (event.currentTarget.dataset.module === 'deleteEntry' && isAdmin) {
      if (entriesPaks.length > 1 && resultPaks.length > 0 && resultPaks.some(lot => lot.destinyProcessId !== null)) {
        actions.setError('No es posible eliminar paks cuando un pak salida se encuentra en otro proceso.');
      } else if (entriesPaks.length === 1 && resultPaks.length > 0) {
        actions.setError('No es posible eliminar paks y dejar las entradas vacías.');
      }else if (entriesPaks.length > 1 && resultPaks.length > 0 && !resultPaks.some(lot => lot.destinyProcessId !== null)) {
        actions.toggleDelete(event.currentTarget.dataset.module, event.currentTarget.dataset.id);
      } else if (resultPaks.length === 0) {
        actions.toggleDelete(event.currentTarget.dataset.module, event.currentTarget.dataset.id);
      }
    } else if (!isAdmin && event.currentTarget.dataset.module === 'deleteEntry' && resultPaks.length > 0) {
      actions.setError('No es posible eliminar paks cuando el proceso tiene asignadas paks de salida');
    } else {
      actions.toggleDelete(event.currentTarget.dataset.module, event.currentTarget.dataset.id);
    }
  }

  return (
    <ProcessComponent
      classes={classes}
      speedDialToggle={speedDialToggle}
      dialButtonOpen={dialButtonOpen}
      process={process}
      processStaffLines={processStaffLines}
      objects={objects}
      toggleForm={toggleForm}
      formControls={formControls}
      invalidControls={invalidControls}
      formIsOpen={formIsOpen}
      changeControls={changeControls}
      submitStaff={submitStaff}
      submitProcessEquipments={submitProcessEquipments}
      employeeOptions={employeeOptions}
      equipmentOptions={equipmentOptions}
      toggleDelete={toggleDelete}
      // eslint-disable-next-line react/destructuring-assignment
      delete={props.delete}
      submitDelete={submitDelete}
      submitLot={submitLot}
      submitEntry={submitEntry}
      submitSupplyProduct={submitSupplyProduct}
      printFnc={printFnc}
      printFncPaks={printFncPaks}
      // printPakLabels={printPakLabels}
      printAllFnc={printAllFnc}
      lotId={lotId}
      qrReader={qrReader}
      onChangeFnc={actions.changeControls}
      qrCode={qrCode}
      changeWeight={changeWeight}
      multipleLotsProcess={multipleLotsProcess}
      uniqueLot={uniqueLot}
      role={role || {}}
      totalWeight={totalWeight}
      closeProcess={closeProcess}
      toggleClose={actions.toggleClose}
      processResources={processResources}
      onBackAction={onBackAction}
      isAdmin={isAdmin}
      infoEntryLots={actions.infoEntryLots}
      infoLot={infoLot}
      usedLot={usedLot}
      actions={actions}
      stopLoading={stopLoading}
    />
  );
};

const withSaga = connectSaga({ key: 'sagaProcess', saga });

const withConnect = connect(
  selectState(
    'process.invalidControls',
    'process.formControls',
    'process.deleteIsOpen',
    'process.toDeleteId',
    'process.formIsOpen',
    'process.toEditId',
    'process.delete',
    'process.lotId',
    'process.edit',
    'process.processResources',
    'app.objects',
    'app.device',
    'app.role',
    'app.account',
    'process.infoLot',
    'process.usedLot',
    'process.stopLoading'
  ),
  actionProps(clone(clone.OBJECT, homeActions, processActions, appActions)),
  null,
  { context: ReactReduxContext }
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect
)(withSaga(Process));
