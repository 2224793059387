import { createTheme } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';

const AppTheme = createTheme({
  palette: {
    primary: {
      main: teal[600],
    },
    secondary: {
      main: teal[900],
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontFamily: ['Roboto'],
      },
    },
    MuiChip: {
      label: {
        fontFamily: ['Roboto'],
      },
    },
  },
});

AppTheme.typography.fontFamily = {
  useNextVariants: true,
  fontFamily: ['Roboto'],
};
AppTheme.typography.body1 = {
  fontFamily: ['Roboto'],
  color: '#545d65',
};
AppTheme.typography.body2 = {
  fontFamily: ['Roboto'],
  color: '#545d65',
};
AppTheme.typography.h3 = {
  fontFamily: ['Roboto'],
  color: '#545d65',
  fontSize: '40px',
};
AppTheme.typography.h5 = {
  fontFamily: ['Roboto'],
  color: '#545d65',
  fontSize: '20px',
};

export default AppTheme;
