// @ts-nocheck
import React from 'react';
// material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// containers
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';

const UnitForm = props => (
  <Dialog fullWidth onClose={props.toggleForm} aria-labelledby="simple-dialog-title" open={props.isOpen}>
    <DialogTitle>{`${props.edit ? 'Editar' : 'Crear'} unidad`}</DialogTitle>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            value={props.controls.name}
            onChange={props.changeControls}
            fullWidth
            id="name"
            label="Nombre"
            error={props.invalidControls.name}
            helperText={props.invalidControls.name && 'Debe ingresar un nombre'}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={props.controls.code}
            onChange={props.changeControls}
            fullWidth
            id="code"
            label="Símbolo"
            error={props.invalidControls.code}
            helperText={props.invalidControls.code && 'Debe ingresar un símbolo'}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.toggleForm} color="primary">
        Cancelar
        </Button>
      <ButtonWithLoader onClick={props.submit} autoFocus buttonId="create-edit-unit">
        {props.edit ? 'Editar' : 'Agregar'}
      </ButtonWithLoader>
    </DialogActions>
  </Dialog >
);

export default UnitForm;
