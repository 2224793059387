/* eslint-disable no-param-reassign */
// @ts-nocheck
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
import { selectState, actionProps } from 'utils/reduxActions';
import { ReactReduxContext } from 'config/configureStore';
import connectSaga from 'utils/saga';
import saga from './saga';
// utils
import { clone } from 'utils/functions';
import { appActions } from '../../commons/reducer';
import { balanceActions } from 'screens/Balance/reducer';
// components
import BalanceComponent from './components';
// styles
import { makeStyles } from '@material-ui/core/styles';
import { Fab, withStyles } from '@material-ui/core';
import styles from './styles';
import { Print } from '@material-ui/icons';
import moment from 'moment';
import getQueryParams from '../../utils/services/getQueryParams';

const useStyles = makeStyles(styles);

const defaultFilters = {
  fromDate: moment().startOf('month').toISOString(),
  toDate: moment().endOf('month').toISOString(),
  supplierId: '',
  varietyId: '',
  fruitId: '',
  productId: '',
  lotNumber: [],
  receiptLotNumber: [],
};

const Balance = (props) => {
  const { actions, loading, objects} = props;

  useEffect(() => {
    const params = getQueryParams();
    const newFilters = defaultFilters;

    if (params.lotNumber) {
      newFilters.lotNumber = params.lotNumber.split(',');
    }

    actions.changeFilters(newFilters);
    actions.getStateFromApi(newFilters);
  }, []);

  const classes = useStyles();

  return (
    <>
      <BalanceComponent
        actions={actions}
        classes={classes}
        objects={objects}
        loading={loading}
        defaultFilters={defaultFilters}
      />
      <div className='no-print'>
        <Fab color="primary" aria-label="print" className={classes.fab} onClick={print}>
          <Print />
        </Fab>
      </div>
    </>
  );
};

const withSaga = connectSaga({ key: 'sagaBalance', saga });

const withConnect = connect(
  selectState(
    'balance.loading', 
    'balance.objects'
    ),
  actionProps(
    clone(clone.OBJECT, 
      balanceActions, 
      appActions,
      )
  ),
  null,
  { context: ReactReduxContext },
);

export default compose(withStyles(styles), withRouter, withConnect)(withSaga(Balance));
