// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import { takeLatest, spawn, put } from 'redux-saga/effects';

import apiRequest, { apiSuccess, objectToQueryString } from 'utils/api';
import { PRODUCTS_REPORT } from '../reducer';
import { appActions } from 'commons/reducer';

function* getStateFromApi() {
  yield takeLatest(PRODUCTS_REPORT.GET_STATE_FROM_API, function* (action) {
    yield put(appActions.setLoader('get-reports', true));
    const response = yield apiRequest(`api/v1/reports?${objectToQueryString(action.filters)}`, {
      method: 'get',
    });
    yield put(apiSuccess(PRODUCTS_REPORT.GET_STATE_FROM_API_SUCCESS, response));
    yield put(appActions.setLoader('get-reports', false));
  });
}

export default function* saga() {
  yield spawn(getStateFromApi);
}
