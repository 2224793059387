/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';
import moment from 'moment';

// Local constants
const PATH = 'inventoryLines/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const CLOSE_LINES_DIALOG = `${PATH}CLOSE_LINES_DIALOG`;
const SET_ANCHOR_EL = `${PATH}SET_ANCHOR_EL`;
const SET_INVENTORY_TYPE = `${PATH}SET_INVENTORY_TYPE`;
const SEARCH_PRODUCTS = `${PATH}SEARCH_PRODUCTS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const CHANGE_INVENTORY_FILE = `${PATH}CHANGE_INVENTORY_FILE`;

// Global constants for saga
export const INVENTORY_LINES = {
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  GET_LINES: `${PATH}GET_LINES`,
  GET_LINES_SUCCESS: `${PATH}GET_LINES_SUCCESS`,
  EXPORT_LINES: `${PATH}EXPORT_LINES`,
  EXPORT_LINES_SUCCESS: `${PATH}EXPORT_LINES_SUCCESS`,
  EXPORT_ADJUSTMENT_INVENTORY_LINES: `${PATH}EXPORT_ADJUSTMENT_INVENTORY_LINES`,
  EXPORT_ADJUSTMENT_INVENTORY_LINES_SUCCESS: `${PATH}EXPORT_ADJUSTMENT_INVENTORY_LINES_SUCCESS`,
  EXPORT_PRODUCTS_REPORT: `${PATH}EXPORT_PRODUCTS_REPORT`,
  EXPORT_PRODUCTS_REPORT_SUCCESS: `${PATH}EXPORT_PRODUCTS_REPORT_SUCCESS`,
  SUBMIT_INVENTORY_ADJUSTMENT: `${PATH}SUBMIT_INVENTORY_ADJUSTMENT`,
  SUBMIT_INVENTORY_ADJUSTMENT_SUCCESS: `${PATH}SUBMIT_INVENTORY_ADJUSTMENT_SUCCESS`,
  SUBMIT_INVENTORY_ADJUSTMENT_FAIL: `${PATH}SUBMIT_INVENTORY_ADJUSTMENT_FAIL`,
};

// actions
export const inventoryLinesActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  closeLinesDialog: createAction(CLOSE_LINES_DIALOG),
  setAnchorEl: createAction(SET_ANCHOR_EL, 'anchorEl'),
  setInventoryType: createAction(SET_INVENTORY_TYPE, 'inventoryType'),
  searchProducts: createAction(SEARCH_PRODUCTS, 'filters'),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  changeInventoryFile: createAction(CHANGE_INVENTORY_FILE, 'file'),
  // api
  getStateFromApi: createAction(INVENTORY_LINES.GET_STATE_FROM_API, 'filters'),
  getStateFromApiSuccess: createAction(INVENTORY_LINES.GET_STATE_FROM_API_SUCCESS, 'payload'),
  getLines: createAction(INVENTORY_LINES.GET_LINES, 'supplyProductId', 'warehouseId', 'lineType'),
  getLinesSuccess: createAction(INVENTORY_LINES.GET_LINES_SUCCESS, 'payload'),
  exportLines: createAction(INVENTORY_LINES.EXPORT_LINES, 'controls'),
  exportLinesSuccess: createAction(INVENTORY_LINES.EXPORT_LINES_SUCCESS, 'payload'),
  exportAdjustmentInventoryLines: createAction(INVENTORY_LINES.EXPORT_ADJUSTMENT_INVENTORY_LINES, 'controls'),
  exportAdjustmentInventoryLinesSuccess: createAction(INVENTORY_LINES.EXPORT_ADJUSTMENT_INVENTORY_LINES_SUCCESS, 'payload'),
  exportProductsReport: createAction(INVENTORY_LINES.EXPORT_PRODUCTS_REPORT, 'controls'),
  exportProductsReportSuccess: createAction(INVENTORY_LINES.EXPORT_PRODUCTS_REPORT_SUCCESS, 'payload'),
  submitInventoryAdjustment: createAction(INVENTORY_LINES.SUBMIT_INVENTORY_ADJUSTMENT, 'file', 'inventoryLineIds'),
  inventoryRejected: createAction(INVENTORY_LINES.SUBMIT_INVENTORY_ADJUSTMENT_FAIL),
};

const defaultInvalidInputs = {
  date: false
};

const initialState = {
  loading: false,
  filterControls: {
    fromDate: moment('01-01-2000', 'MM-DD-YYYY').toISOString(),
    toDate: moment().endOf('day').toISOString(),
    supplyProductId: '',
    warehouseId: '',
  },
  filterProductsControls: { productId: '', warehouseId: '' },
  inventoryLineIds: [],
  linesDialog: {
    isOpen: false,
    warehouseId: '',
    supplyProductId: '',
    type: '',
  },
  inventoryType: 'supply',
  anchorEl: null,
  error: false,
  inventoryModal: false,
  edit: false,
  file: null,
  pagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0,
  },
  formIsOpen: false,
  deleteIsOpen: false,
  formControls: {
    date: new Date().toISOString(),
  },
  updatedInventory: [],
  addAjustmentStock: [],
  deleteAdjustmentStock: [],
  adjustmentSupplierId: '',
  reducerLots: [],
};

const inventoryLines = createReducer(initialState, {
  [CLOSE_LINES_DIALOG](state, action) {
    state.linesDialog.type = '';
    state.linesDialog.supplyProductId = '';
    state.linesDialog.warehouseId = '';
    state.linesDialog.isOpen = false;
    state.adjustmentSupplierId = '';
  },
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },
  [SET_ANCHOR_EL](state, action) {
    state.anchorEl = action.anchorEl;
  },
  [SEARCH_PRODUCTS](state, action) {
    state.filterProductsControls = action.filters;
  },
  [SET_INVENTORY_TYPE](state, action) {
    state.inventoryType = action.inventoryType;
    state.anchorEl = null;
  },
  [CHANGE_CONTROLS](state, action) {
    state.filterControls = {
      ...state.filterControls,
      ...action.controls
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {})
    };
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.edit = false;
    state.formControls = { ...initialState.formControls };
    state.invalidControls = { ...defaultInvalidInputs };
    state.updatedInventory = [];
    state.addAjustmentStock = [];
    state.deleteAdjustmentStock = [];
    state.file = null;
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [CHANGE_INVENTORY_FILE](state, action) {
    state.file = action.file;
  },
  // api
  [INVENTORY_LINES.GET_STATE_FROM_API_SUCCESS](state, action) {
    state.inventoryLineIds = action.payload.inventoryLineIds;
    state.reducerLots = action.payload.objects.lots;
  },
  [INVENTORY_LINES.GET_LINES](state, action) {
    state.linesDialog.type = action.lineType;
    state.linesDialog.supplyProductId = action.supplyProductId;
    state.linesDialog.warehouseId = action.warehouseId;
  },
  [INVENTORY_LINES.GET_LINES_SUCCESS](state, action) {
    state.linesDialog.isOpen = true;
    state.adjustmentSupplierId = action.payload.adjustmentSupplierId;

  },
  [INVENTORY_LINES.EXPORT_LINES](state, action) {
    state.loading = true;
  },
  [INVENTORY_LINES.EXPORT_LINES_SUCCESS](state, action) {
    state.loading = false;
  },
  [INVENTORY_LINES.EXPORT_ADJUSTMENT_INVENTORY_LINES](state, action) {
    state.loading = true;
  },
  [INVENTORY_LINES.EXPORT_ADJUSTMENT_INVENTORY_LINES_SUCCESS](state, action) {
    state.loading = false;
  },
  [INVENTORY_LINES.EXPORT_PRODUCTS_REPORT](state, action) {
    state.loading = true;
  },
  [INVENTORY_LINES.EXPORT_PRODUCTS_REPORT_SUCCESS](state, action) {
    state.loading = false;
  },
  [INVENTORY_LINES.SUBMIT_INVENTORY_ADJUSTMENT_SUCCESS](state, action) {
    state.updatedInventory = action.payload.objects.updatedInventory;
    state.addAjustmentStock = action.payload.objects.addAjustmentStock;
    state.deleteAdjustmentStock = action.payload.objects.deleteAdjustmentStock;
    state.file = null;
  },
  [INVENTORY_LINES.SUBMIT_INVENTORY_ADJUSTMENT_FAIL](state, action) {
    state.file = null;
  },
});

export default inventoryLines;