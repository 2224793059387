// @ts-nocheck
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-unresolved */
import React from 'react';
// ui
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import MultipleSelector from 'commons/components/MultipleSelector';

const ProcessEquipmentForm = (props) => {
  const {
    toggleForm, isOpen, controls, changeControls, invalidControls, equipmentOptions, submit, processResources,
  } = props;

  return (
    <Dialog fullWidth onClose={toggleForm} aria-labelledby="simple-dialog-title" open={isOpen}>
      <DialogTitle>Agregar equipamiento</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justifyContent="center">
          <Grid item sm={10} xs={12}>
            <MultipleSelector
              id="equipmentIds"
              module="processEquipments"
              selectedValues={controls.equipmentIds}
              options={equipmentOptions}
              onChange={changeControls}
              label="Equipamientos"
              noCheckBox
              error={invalidControls.equipmentIds}
              helperText={invalidControls.equipmentIds && 'Debes seleccionar un equipamiento'}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleForm} data-module="processEquipments" color="primary">
          Cancelar
        </Button>
        <ButtonWithLoader onClick={submit} autoFocus buttonId="add-process-process-equipments">
          Agregar
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  );
};

export default ProcessEquipmentForm;
