// @ts-nocheck
import React from 'react';
// material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import TaxNumberField from '../../../commons/components/TaxNumberField';
import ButtonWithLoader from '../../../commons/containers/ButtonWithLoader';
import { withTranslation } from 'react-i18next';

const SupplierForm = ({
  // props
  controls,
  changeControls,
  edit,
  invalidControls,
  isOpen,
  onChange,
  submit,
  toggleForm,
  t,
}) => (
  <Dialog fullWidth onClose={toggleForm} aria-labelledby="simple-dialog-title" open={isOpen}>
    <DialogTitle>{`${edit ? 'Editar' : 'Crear'} proveedor`}</DialogTitle>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            value={controls.name}
            onChange={changeControls}
            fullWidth
            id="name"
            label="Nombre"
            error={invalidControls.name}
            helperText={invalidControls.name && 'Debe ingresar un nombre'}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TaxNumberField
            value={controls.taxNumber}
            onChange={onChange}
            fullWidth
            error={invalidControls.taxNumber}
            helperText={invalidControls.taxNumber && t("identifier:validate_message")}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={controls.email}
            onChange={changeControls}
            fullWidth
            id="email"
            label="Email"
            error={invalidControls.email}
            helperText={invalidControls.email && 'Debe ingresar un email'}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={controls.code}
            onChange={changeControls}
            fullWidth
            id="code"
            label="Código"
            // error={invalidControls.code}
            // helperText={invalidControls.email && 'Debe ingresar un email'}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={controls.region}
            onChange={changeControls}
            fullWidth
            id="region"
            label="Región"
            // error={invalidControls.email}
            // helperText={invalidControls.email && 'Debe ingresar un email'}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={controls.province}
            onChange={changeControls}
            fullWidth
            id="province"
            label="Provincia"
            // error={invalidControls.email}
            // helperText={invalidControls.email && 'Debe ingresar un email'}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={controls.sector}
            onChange={changeControls}
            fullWidth
            id="sector"
            label="Sector"
            // error={invalidControls.email}
            // helperText={invalidControls.email && 'Debe ingresar un email'}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={toggleForm} color="primary">
        Cancelar
      </Button>
      <ButtonWithLoader onClick={submit} autoFocus buttonId="create-edit-supplier">
        {edit ? 'Editar' : 'Agregar'}
      </ButtonWithLoader>
    </DialogActions>
  </Dialog>
);

export default withTranslation()(SupplierForm);
