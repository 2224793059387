import React from 'react';
// MUI
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
// PROJECT
import ConfirmDialog from '../../../../commons/components/ConfirmDialog';

const Form = (props) => {
  const {
    formIsOpen,
    formControls,
    invalidFormControls,
    formControlsChanged,
    toggleForm,
    submitForm,
  } = props;

  return (
    <ConfirmDialog
      open={formIsOpen}
      maxWidth="sm"
      title="Crear equipamiento"
      cancel={toggleForm}
      accept={submitForm}
    >
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            value={formControls.name}
            onChange={formControlsChanged}
            fullWidth
            id="name"
            label="Nombre"
            error={invalidFormControls.name}
            helperText={invalidFormControls.name && 'Debe ingresar un nombre'}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={formControls.code}
            onChange={formControlsChanged}
            fullWidth
            id="code"
            label="Código"
            error={invalidFormControls.code}
            helperText={invalidFormControls.code && 'Debe ingresar un código'}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
};

export default Form;
