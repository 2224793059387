import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { selectState, actionProps } from '../../../../../../utils/reduxActions';
import { lotsActions } from '../../../../reducer';
import { appActions } from '../../../../../../commons/reducer';
import connectSaga from '../../../../../../utils/saga';
import saga from '../../../../saga';
// ui
import { Menu, MenuItem, IconButton, CircularProgress, ListItemIcon, ListItemText } from '@material-ui/core';
// icons
import MoreVertIcon from '@material-ui/icons/MoreVert';
// utils
import { truthty, clone } from '../../../../../../utils/functions';
import { objectToQueryString } from '../../../../../../utils/api';

const ItemMenu = (props) => {
  const {
    lot,
    actions,
    device,
    objects,
    history,
    controls: { lotIds },
    loading: traceabilityInProgress,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const loadingTraceability = traceabilityInProgress && lotIds.includes(lot.id.toString())

  const handleClick = (event) => {
    setAnchorEl(event.target)
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const printFnc = () => {
    const labelData = { lotIds: [lot.id], accountId: Object.values(objects.accounts)[0]?.id }
    actions.printLabel(device, labelData);
  };

  const toggleWarehouseMovementForm = () => {
    actions.toggleWarehouseMovementForm(lot.id, objects.lots[lot.id]);
  }

  const handleMenuOnClick = (event) => {
    let query = `/process/${event.target.dataset.id}`;
    if (event.target.dataset.type === 'lotResume') {
      query = `lotResume?${objectToQueryString({ lotId: event.target.dataset.id })}`;
    }
    if (event.target.dataset.type === 'lotTraceability') {
      query = `lotTraceability?${objectToQueryString({ lotIds: [event.target.dataset.id] })}`;
    }

    history.push(query);
  }

  return (<>
      <IconButton
        onClick={handleClick}
        data-id={lot.id}
      >
        <MoreVertIcon data-id={lot.id} />
      </IconButton>
      {truthty(anchorEl) && Number(anchorEl.dataset.id) === lot.id && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={truthty(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={printFnc}>
            Imprimir
          </MenuItem>
          <MenuItem onClick={toggleWarehouseMovementForm} data-id={lot.destinyProcessId}>
            Cambiar de bodega
          </MenuItem>
          {truthty(lot.originProcessId) && (
            <MenuItem onClick={handleMenuOnClick} data-id={lot.originProcessId}>
              Ir a proceso de origen
            </MenuItem>
          )}
          {truthty(lot.destinyProcessId) && (
            <MenuItem onClick={handleMenuOnClick} data-id={lot.destinyProcessId}>
              Ir a proceso de destino
            </MenuItem>
          )}
          <MenuItem onClick={handleMenuOnClick} data-id={lot.id} data-type="lotResume">
            Resumen de pak
          </MenuItem>
          <MenuItem onClick={handleMenuOnClick} data-id={lot.id} data-type="lotTraceability" disabled={loadingTraceability}>
            <ListItemText data-id={lot.id} data-type="lotTraceability" disableTypography={true} primary="Trazabilidad"/>
            {loadingTraceability && (<ListItemIcon><CircularProgress size={14}/></ListItemIcon>)}
          </MenuItem>
        </Menu>
      )}</>
  )
}

const withSaga = connectSaga({ key: 'sagaLots', saga });

const withConnect = connect(
  selectState('app.objects', 'app.device', 'lotTraceability.controls', 'lotTraceability.loading'),
  actionProps(clone(clone.OBJECT, { printLabel: appActions.printLabel, toggleWarehouseMovementForm: lotsActions.toggleWarehouseMovementForm }))
);

export default compose(withRouter, withConnect)(withSaga(ItemMenu));
