import camelize from 'camelcase-keys-deep';
export default class Receipt {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  receiptLot() {
    let response = null;
    if (this.receiptLotId) {
      response = this.env().ReceiptLot.findBy('id', this.receiptLotId);
    }
    return response;
  }

  supplier() {
    let response = null;
    if (this.supplierId) {
      response = this.env().Supplier.findBy('id', this.supplierId);
    }
    return response;
  }

  process() {
    let response = null;
    if (this.processId) {
      response = this.env().Process.findBy('id', this.processId);
    }
    return response;
  }

  lots() {
    let response = null;
    if (this.processId) {
      response = this.env().Lot.findAllBy('originProcessId', this.processId);
    }
    return response;
  }

  client() {
    let response = null;
    if (this.clientId) {
      response = this.env().Client.findBy('id', this.clientId);
    }
    return response;
  }
}
