export const sortArrayBy = (list = [], sortType = 'ASC', key = null) => list.sort((a, b) => {
  const aValue = key ? a[key] : a;
  const bValue = key ? b[key] : b;

  if (aValue > bValue) {
    return sortType === 'ASC' ? 1 : -1;
  }

  if (aValue < bValue) {
    return sortType === 'ASC' ? -1 : 1;
  }

  return 0;
});