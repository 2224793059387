// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import { takeLatest, spawn, put } from 'redux-saga/effects';

import apiRequest, { apiSuccess, objectToQueryString } from 'utils/api';
import { BALANCE } from '../reducer';
import { appActions } from 'commons/reducer';
import { truthty } from 'utils/functions';

function* getStateFromApi() {
  yield takeLatest(BALANCE.GET_STATE_FROM_API, function* (action) {
    yield put(appActions.setLoader('filter-reports', true));
    const response = yield apiRequest(`api/v1/balance?${objectToQueryString(
      action.filters
    )}`, { method: 'get' }
    );
    if (truthty(response.error)) {
      yield put({ type: BALANCE.GET_STATE_FROM_API_FAIL });
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(BALANCE.GET_STATE_FROM_API_SUCCESS, response));
    }
    yield put(appActions.setLoader('filter-reports', false));
  });
}

function* getStateReport() {
  yield takeLatest(BALANCE.GET_STATE_FROM_API2, function* (action) {
    yield put(appActions.setLoader('get-reports', true));
    const response = yield apiRequest(`api/v1/balance/report?${objectToQueryString(action.filters)}`, 
    { method: 'get' }
    );
    if (truthty(response.error)) {
      yield put({ type: BALANCE.GET_STATE_FROM_API_FAIL2 });
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(BALANCE.GET_STATE_FROM_API_SUCCESS2, response));
    }
    yield put(appActions.setLoader('get-reports', false));
  });
}

export default function* saga() {
  yield spawn(getStateFromApi);
  yield spawn(getStateReport);
}