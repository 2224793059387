import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  "CHILE": {
    identifier: {
      "validate_message": "Debe ingresar un RUT válido",
      "name": "RUT",
    }
  },
  "ECUADOR": {
    identifier: {
      "validate_message": "Debe ingresar un RUC válido",
      "name": "RUC",
    }
  },
  "COLOMBIA": {
    identifier: {
      "validate_message": "Debe ingresar un RUT válido",
      "name": "RUT",
    }
  }
};

i18n
  .use(initReactI18next) 
  .init({
    resources,
    fallbackLng: "CHILE",
    interpolation: {
      escapeValue: false 
    }
  });

  export default i18n;
