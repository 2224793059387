export const ModelNames = {
  Client: "Clientes",
  Dispatch: "Despachos",
  Employee: "Empleados",
  Equipment: "Equipamientos",
  Fruit: "Especies",
  Lot: "Paks",
  PackagingType: "Envases",
  Paddock: "Cuarteles",
  Process: "Procesos",
  ProcessType: "Tipos de proceso",
  Product: "Productos",
  Purchase: "Adquisiciones y egresos",
  Receipt: "Recepciones",
  Return: "Devoluciones",
  Supplier: "Proveedores",
  SupplyProduct: "Productos de insumo",
  Unit: "Unidades",
  Variety: "Variedades",
  Warehouse: "Bodegas",
  WorkShiftType: "Turnos",

  // Pending Translate
  Line: "Line",
  SupplyLine: "SupplyLine",
  Entity: "Datos de entidad",
  // Associations
  ProcessStaffLine: "Asignacion de personal",
  LotReceiptLot: "Distribución pak",
  ProcessEquipment: "ProcessEquipment",
  PurchaseLine: "PurchaseLine",
  ReceiptLot: "Lotes",
  ServiceLine: "ServiceLine",
  SupplyProductLine: "SupplyProductLine",
  TaxableDocument: "TaxableDocument",
  TaxableDocumentType: "TaxableDocumentType",
  WarehouseMovement: "Movimiento de bodega",
};
