import { blueGrey } from '@material-ui/core/colors';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(10),
    right: theme.spacing(6),
  },
  blue: {
    color: theme.palette.getContrastText(blueGrey[500]),
    backgroundColor: blueGrey[500],
    fontFamily: [
      'Roboto',
    ],
  },
  marginTop: {
    marginTop: '24px',
  },
  subModule: {
    height: 'calc(60vh - 142px)',
  },
  cardContent: {
    height: 'calc(60vh - 210px)',
    overflow: 'auto',
  },
  processCardContent: {
    height: 'calc(60vh - 210px)',
    overflow: 'auto',
    padding: 4,
  },
  closeProcessButton: {
    backgroundColor: '#e57373',
    color: '#ffffff'
  }
});

export default styles;
