import camelize from 'camelcase-keys-deep';

function findSupplier() {
  const entity = env().Entity.findBy('name', 'Proveedor ajuste de inventario')
  return entity
}
export default class WarehouseMovement {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }
  
  warehouse() {
    let response = null;
    if (this.warehouseId) {
      response =  this.env().Warehouse.findBy('id', this.warehouseId);
    }
    return response;
  }
  
  supplyProduct() {
    let response = null;
    if (this.supplyProductId) {
      response =  this.env().SupplyProduct.findBy('id', this.supplyProductId);
    }
    return response;
  }

  type(adjustmentSupplierId) {
    let response = null;
    const supplyProductLines = this.env().SupplyProductLine.findBy('warehouseMovementId', this.id);
    if (supplyProductLines) {
      const purchase = this.env().Purchase.findBy('id', supplyProductLines.purchaseId);
      if (purchase && purchase.supplierId === adjustmentSupplierId) {
        response = 'Ajuste: Aumento de Inventario';
      } else {
        response = 'Compra';
      }
    } else {
      const supplyLine = this.env().SupplyLine.findBy('warehouseMovementId', this.id);
      response = supplyLine?.process()?.processType()?.name || 'Sin proceso';
    }
    return response;
  }

  date() {
    let response = null;
    const supplyProductLines = this.env().SupplyProductLine.findBy('warehouseMovementId', this.id);
    if (supplyProductLines) {
      const purchase = this.env().Purchase.findBy('id', supplyProductLines.purchaseId);
      response = purchase.issueDate;
    } else {
      const supplyLine = this.env().SupplyLine.findBy('warehouseMovementId', this.id);
      response = supplyLine?.process()?.date || 'Sin proceso';
    }
    return response;
  }

  line() {
    let response = null;
    if (this.lineId) {
      response = this.env().Line.findBy('id', this.lineId);
    }
    return response;
  }

  supplyProductLine() {
    return this.env().SupplyProductLine.findBy('warehouseMovementId', this.id);
  }

  supplyLine() { 
    return this.env().SupplyLine.findBy('warehouseMovementId', this.id);
  }
}
