import React from 'react';
// MUI
import { Grid, Typography, CircularProgress, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import RefreshIcon from '@material-ui/icons/Refresh';

// Project
import TraceabilityChart from './TraceabilityChart';

// Project
// eslint-disable-next-line func-names
export default function (props) {
  const { dataSet, dataLabels, controls, classes, pointOnClick, queueProcess, loading, refreshData } = props;
  return (
    <Grid container spacing={3}>
      <Grid item>
        <Typography variant="h3" component="h2" className={classes.marginTop}>
          {`TRAZABILIDAD PAK ${controls?.lotIds.join(', ') || ''}`}
        </Typography>
        { loading && (
          <Alert severity="info">
            <Grid className={classes.alert}>
              <span style={{ marginRight: 20 }}>El sistema está generando el reporte</span>
              <CircularProgress size={20} thickness={5.0} />
            </Grid>
          </Alert>
        )}
      </Grid>
      <Grid item>
        { !loading && (<IconButton className={classes.marginTop} component='span' onClick={refreshData}><RefreshIcon/></IconButton>)}
      </Grid>
      { !loading && (
        <Grid item xs={12}>
          <TraceabilityChart dataSet={dataSet} dataLabels={dataLabels} pointOnClick={pointOnClick} />
        </Grid>
      )}
    </Grid>
  );
}
