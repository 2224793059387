// @ts-nocheck
/* eslint-disable func-names */
import {
  takeLatest,
  spawn,
  put,
  select,
} from 'redux-saga/effects';

import { parsedObjects } from 'utils/reduxActions';
import apiRequest, { apiSuccess, objectToQueryString } from '../../../utils/api';
import { truthty } from '../../../utils/functions';
import { appActions, PRINT_LABEL, PRINT_RECEPTION_LABEL } from '../../../commons/reducer';
import { RECEIPTS } from '../reducer';

function* getStateFromApi() {
  yield takeLatest([RECEIPTS.GET_STATE_FROM_API, RECEIPTS.CHANGE_PAGINATION], function* (action) {
    yield put(appActions.setLoader('filter-receipts', true));
    const response = yield apiRequest(`api/v1/receipts?${objectToQueryString({
      page: action.pagination.page,
      rowsPerPage: action.pagination.rowsPerPage,
      ...action.filters,
    })}`, { method: 'get' });
    yield put(apiSuccess(RECEIPTS.GET_STATE_FROM_API_SUCCESS, response, { pagination: action.pagination }));
    yield put(appActions.setLoader('filter-receipts', false));
  });
}

function* createReceipt() {
  yield takeLatest(RECEIPTS.CREATE_RECEIPT, function* (action) {
    yield put(appActions.setLoader('create-edit-receipt', true));
    const response = yield apiRequest('api/v1/receipts',
      {
        method: 'post',
        body: JSON.stringify({
          ...action.controls,
        }),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(RECEIPTS.CREATE_RECEIPT_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-receipt', false));
  });
}

function* createReceiptLots() {
  yield takeLatest(RECEIPTS.CREATE_RECEIPT_LOTS, function* (action) {
    yield put(appActions.setLoader('create-receipt-lots', true));
    
    const response = yield apiRequest('api/v1/receipts/receiptLots',
      {
        method: 'post',
        body: JSON.stringify({
          lots: action.lots,
          receiptId: action.receiptId,
        }),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(RECEIPTS.CREATE_RECEIPT_LOTS_SUCCESS, response));

      if (truthty(response.objects.lots)) {
        const getObjects = (state) => state.app.objects;
        const objects = yield parsedObjects(yield select(getObjects));

        yield put({
          type: PRINT_RECEPTION_LABEL,
          device: action.device,
          labelData: {
            lotIds: Object.values(response.objects.lots).map(l => l.id),
            accountId: Object.values(objects.accounts)[0]?.id
          }
        });
      }
    }
    yield put(appActions.setLoader('create-receipt-lots', false));
  });
}

function* editReceipt() {
  yield takeLatest(RECEIPTS.EDIT_RECEIPT, function* (action) {
    yield put(appActions.setLoader('create-edit-receipt', true));
    const response = yield apiRequest(`api/v1/receipts/${action.id}`,
      {
        method: 'put',
        body: JSON.stringify({
          ...action.controls,
          quantity: action.totalWeight,
        }),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(RECEIPTS.EDIT_RECEIPT_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-receipt', false));
  });
}

function* editReceiptData() {
  yield takeLatest(RECEIPTS.EDIT_RECEIPT_DATA, function* (action) {
    yield put(appActions.setLoader('create-edit-receipt-data', true));
    const response = yield apiRequest(`api/v1/receipts/${action.id}/data`,
    {
      method: 'put',
      body: JSON.stringify({
        ...action.controls,
      }),
    });

    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put (apiSuccess(RECEIPTS.EDIT_RECEIPT_DATA_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-receipt-data', false));
  });
}

function* deleteReceipt() {
  yield takeLatest(RECEIPTS.DELETE_RECEIPT, function* (action) {
    yield put(appActions.setLoader('delete-receipt', true));
    const response = yield apiRequest(`api/v1/receipts/${action.id}`, { method: 'delete' });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(RECEIPTS.DELETE_RECEIPT_SUCCESS, { item: response, type: 'receipts' }));
    }
    yield put(appActions.setLoader('delete-receipt', false));
  });
}

function* deleteLot() {
  yield takeLatest(RECEIPTS.DELETE_LOT, function* (action) {
    yield put(appActions.setLoader('delete-lot', true));
    const response = yield apiRequest(`api/v1/lots/${action.id}`, { method: 'delete' });
    const { lotToDelete } = response;
    yield put(apiSuccess(RECEIPTS.DELETE_LOT_SUCCESS, { item: lotToDelete, type: 'lots' }));
    yield put(appActions.setLoader('delete-lot', false));
  });
}

export default function* saga() {
  yield spawn(createReceiptLots);
  yield spawn(getStateFromApi);
  yield spawn(deleteReceipt);
  yield spawn(createReceipt);
  yield spawn(editReceipt);
  yield spawn(editReceiptData);
  yield spawn(deleteLot);
}
