// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';

import apiRequest, { apiSuccess } from 'utils/api';
import { UNITS } from '../reducer';
import { truthty, falsy } from 'utils/functions';
import { appActions } from 'commons/reducer';

function* getStateFromApi() {
  yield takeLatest(UNITS.GET_STATE_FROM_API, function* (action) {
    const response = yield apiRequest('api/v1/units', { method: 'get' });
    yield put(apiSuccess(UNITS.GET_STATE_FROM_API_SUCCESS, response));
  });
}

function* createUnitType() {
  yield takeLatest(UNITS.CREATE_UNIT, function* (action) {
    yield put(appActions.setLoader('create-edit-unit', true));
    const response = yield apiRequest('api/v1/units',
      {
        method: 'post',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(UNITS.CREATE_UNIT_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-unit', false));
  });
}

function* editUnitType() {
  yield takeLatest(UNITS.EDIT_UNIT, function* (action) {
    yield put(appActions.setLoader('create-edit-unit', true));
    const response = yield apiRequest(`api/v1/units/${action.id}`,
      {
        method: 'put',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(UNITS.EDIT_UNIT_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-unit', false));
  });
}

function* deleteUnitType() {
  yield takeLatest(UNITS.DELETE_UNIT, function* (action) {
    yield put(appActions.setLoader('delete-unit', true));
    const response = yield apiRequest(`api/v1/units/${action.id}`, { method: 'delete' });
    if (falsy(response.error)) {
      const item = Object.values(response)[0];
      yield put(apiSuccess(UNITS.DELETE_UNIT_SUCCESS, { item, type: 'units' }));
    } else {
      yield put(appActions.setError(response.errorMsg));
    }
    yield put(appActions.setLoader('delete-unit', false));
  });
}

function* exportUnits() {
  yield takeLatest(UNITS.EXPORT_UNIT, function* (action) {
    yield put(appActions.setLoader('export-units', true));
    const response = yield apiRequest('api/v1/units/export', { method: 'get' }, false, 'units.xlsx');
    yield put(apiSuccess(UNITS.EXPORT_UNIT_SUCCESS, response));
    yield put(appActions.setLoader('export-units', false));
  });
}

export default function* saga() {
  yield spawn(getStateFromApi);
  yield spawn(deleteUnitType);
  yield spawn(createUnitType);
  yield spawn(editUnitType);
  yield spawn(exportUnits);
}
