import React from 'react';
import { List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const SkeletonList = props => (
  <List>
    {[...Array(props.times)].map((e, i) => (
      <ListItem key={`skeleton-list-item-${i}`}>
        <ListItemAvatar>
          <Skeleton animation="wave" width={50} height={50} variant="circle" />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Skeleton animation="wave" width="80%" />
          }
          secondary={
            <Skeleton animation="wave" width="60%" />
          }
        />
      </ListItem>
    ))}
  </List>
);

export default SkeletonList;
