// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectState, actionProps } from '../../../../utils/reduxActions';
import { lotsActions } from '../../reducer';
import connectSaga from '../../../../utils/saga';
import saga from '../../saga';
// material
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  InputLabel, 
  Select, 
  MenuItem, 
  FormControl, 
  Grid 
} from '@material-ui/core';

// components
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import { clone } from '../../../../utils/functions';

const WarehouseMovementForm = (props) => {
  const {
    warehouses,
    lotId,
    formControls,
    actions,
    formIsOpen: isOpen,
  } = props;

  const toggleForm = () => {
    actions.toggleForm();
  }

  const changeControls = (event) => {
    actions.changeControls({ [event.currentTarget.id]: event.target.value });
  }

  const submit = () => {
    actions.submitWarehouseMovement(lotId, formControls);
  }

  return (
    <Dialog fullWidth onClose={toggleForm} open={isOpen}>
      <DialogTitle>Cambio de bodega</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="warehouse-input-label">Bodega</InputLabel>
              <Select
                labelId="warehouse-input-label"
                fullWidth
                value={formControls.warehouseId}
                onChange={changeControls}
              >
                <MenuItem id="warehouseId" value="">Ninguna</MenuItem>
                {warehouses
                  .map(warehouse => (
                    <MenuItem
                      key={`warehouse-form-option-${warehouse.id}`}
                      id="warehouseId"
                      value={warehouse.id}
                    >
                      {warehouse.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleForm} color="primary">
          Cancelar
        </Button>
        <ButtonWithLoader onClick={submit} autoFocus buttonId="lot-warehouse-movement">
          Modificar
        </ButtonWithLoader>
      </DialogActions>
    </Dialog >
)};

const withSaga = connectSaga({ key: 'sagaLots', saga });

const withConnect = connect(
  selectState('lots.lotId', 'lots.formControls', 'lots.formIsOpen'),
  actionProps(clone(clone.OBJECT, { submitWarehouseMovement: lotsActions.submitWarehouseMovement, changeControls: lotsActions.changeControls, toggleForm: lotsActions.toggleForm }))
);

export default compose(withConnect)(withSaga(WarehouseMovementForm));