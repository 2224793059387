import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

const Filters = props => (
  <Grid item xs={12}>
    <Card className={props.classes.root}>
      <CardHeader title="Filtro" />
      <CardContent>
        <Grid container spacing={3} className={props.classes.container}>
          <Grid container item xs={4}>
            <TextField fullWidth id="standard-basic" label="Nombre" value={props.filter} onChange={props.onFilterChange} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);

export default Filters
