/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '../../../utils/reducer';

// Local constants
const PATH = 'lotResume/';
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

export const LOT_RESUME = {
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
};

export const lotResumeActions = {
  getStateFromApi: createAction(LOT_RESUME.GET_STATE_FROM_API, 'controls'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls'),
};

const initialState = {
  loading: true,
  controls: {
    lotId: '',
  },
  resumeInfo: [],
};

const reducer = createReducer(initialState, {
  [LOT_RESUME.GET_STATE_FROM_API](state) {
    state.loading = true;
  },
  [LOT_RESUME.GET_STATE_FROM_API_SUCCESS](state, action) {
    state.resumeInfo = action.payload.resumeInfo;
    state.loading = false;
  },
  [CHANGE_CONTROLS](state, action) {
    state.controls = { ...state.controls, ...action.controls };
  },
});

export default reducer;
