import React from 'react';
// MUI
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Typography } from '@material-ui/core';
// Project
import { roundNumber } from '../../../../utils/functions';

export default function Header(props) {
  const {
    lot,
    history,
  } = props;

  return (
    <Grid item container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>{`PESO: ${roundNumber(lot?.quantity || 0) || ''} KG`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{`PRODUCTO: ${lot?.product()?.name || ''}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{`ENVASE: ${lot?.packagingType()?.name || ''} (${lot?.packagingType()?.weight || 0} KG)`}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>{`ESPECIE: ${lot?.fruit()?.name || ''}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{`VARIEDAD: ${lot?.variety()?.name || ''}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{`BODEGA: ${lot?.warehouse()?.name || 'SIN BODEGA'}`}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={() => history.push(`process/${lot?.originProcess()?.id}`)}
        >
          {`PROCESO DE ORIGEN: ${lot?.originProcess()?.id} ${lot?.originProcess()?.processType()?.name || ''}`}
        </Button>
      </Grid>
      <Grid item xs={12} sm={6}>
        {
          lot?.destinyProcess() && (
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => history.push(`process/${lot?.destinyProcess()?.id}`)}
            >
              {`PROCESO DE DESTINO: ${lot?.destinyProcess()?.id || ''} ${lot?.destinyProcess()?.processType()?.name || ''}`}
            </Button>
          )
        }
      </Grid>
    </Grid>
  );
}