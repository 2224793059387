// @ts-nocheck
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
import { actionProps, selectState } from 'utils/reduxActions';
import { ReactReduxContext } from 'config/configureStore';
// material
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import { appActions } from 'commons/reducer';
import { receiptsActions } from '../../../screens/Receipts/reducer'
// icons
import SpaceBarIcon from '@material-ui/icons/SpaceBar';
import { clone } from 'utils/functions';

const WeightField = props => {
  function editWeightScale() {
    props.actions.editWeightScale(props.scaleWeight, props.changeWeight);
  }

  return (
    <Fragment>
      <TextField
        value={props.quantity}
        onChange={props.changeControls}
        id={props.inputId || 'quantity'}
        label={props.label || 'Kilos'}
        error={props.error || props.totalWeight <= 0}
        helperText={`Destare: ${props.totalWeight} [Kg]`}
        style={{ width: 'calc(100% - 68px)' }}
        inputProps={props.inputProps}
        disabled={props.readingScale}
      />
      <Button
        size="small"
        color={props.color || 'primary'}
        variant="contained"
        style={{ marginTop: 13, marginLeft: 4 }}
        onClick={editWeightScale}
        disabled={props.readingScale}
      >
        {props.readingScale ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
            <SpaceBarIcon />
          )}
      </Button>
    </Fragment>
  );
};

// ------------------ CONFIG CONTAINER ------------------

const withConnect = connect(
  selectState(
    'app.readingScale',
    'app.scaleDevice',
    'app.loaders',
    'app.scaleWeight'
  ),
  // actionProps(appActions, receiptsActions),
  actionProps(clone(clone.OBJECT, appActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withRouter,
  withConnect,
)(WeightField);
