// @ts-nocheck
import React from 'react';
// Project
import Table from '../../../../commons/components/Table';

export default function ResumeTable(props) {
  const {
    tableRows,
    tableHeaders,
  } = props;

  return (
    <Table
      headers={tableHeaders}
      rows={tableRows}
    />
  );
}
