import React from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import './styles.css';

export const Row = ({ row, headerList, handleOnClick, isMobileDevice }) => {
  return (
    <Box className="table-row" onClick={() => handleOnClick()}>
      {row.map((info, index) => (
        <Box
          className="table-row__item"
          style={{
            order: isMobileDevice ? headerList[index].mobileOrder : 0,
            width: headerList[index].width,
          }}>
          {info.icon ? (
            <Tooltip title={headerList[index].name} placement="top">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  marginRight: '5px',
                }}>
                {info.icon}
              </div>
            </Tooltip>
          ) : null}
          <Box
            style={{
              overflow: 'hidden',
            }}>
            {info.value}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
