/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { PlaylistAddOutlined } from '@material-ui/icons';
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'productsReports/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const CHANGE_PRODUCTS_FILTERED = `${PATH}CHANGE_PRODUCTS_FILTERED`;
const CHANGE_PROCESS_FILTERED = `${PATH}CHANGE_PROCESS_FILTERED`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const CLOSE_LINES_DIALOG = `${PATH}CLOSE_LINES_DIALOG`;

// Global constants for saga
export const PRODUCTS_REPORT = {
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  GET_LINES: `${PATH}GET_LINES`,
  GET_LINES_SUCCESS: `${PATH}GET_LINES_SUCCESS`,
};

// actions
export const productsReportActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  changeControls: createAction(CHANGE_CONTROLS, 'module', 'controls'),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  closeLinesDialog: createAction(CLOSE_LINES_DIALOG),
  // api
  getStateFromApi: createAction(PRODUCTS_REPORT.GET_STATE_FROM_API, 'filters'),
  handleProcessSelection: createAction(CHANGE_PROCESS_FILTERED, 'processSelected'),
  handleSelectionChange: (value) => ({type: CHANGE_PRODUCTS_FILTERED , payload: {value}}),
  getLines: createAction(PRODUCTS_REPORT.GET_LINES, 'supplyProductId', 'warehouseId', 'lineType'),
  getLinesSuccess: createAction(PRODUCTS_REPORT.GET_LINES_SUCCESS, 'payload'),
}

const defaultInvalidInputs = {
  date: false
};

const initialState = {
  productionTableControls: {
    processTypeId: '',
  },
  loading: true,
  lotIds: [],
  receiptLotIds: [],
  processIds: [],
  productionGraphData: {},
  productionTable: {},
  marginData: {},
  productCosts: {},
  accumulatedCost: {},
  objects: {},
  productsFiltered: [],
  processFiltered: [],
  costReportModal: false,
  formIsOpen: false,
  deleteIsOpen: false,
  edit: false,
  formControls: {
    date: new Date().toISOString(),
  },
  linesDialog: {
    isOpen: false,
  },
};

const productsReports = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },
  [CHANGE_CONTROLS](state, action) {
    state[action.module] = {
      ...state.formControls,
      ...action.controls
    };
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.edit = false;
    state.formControls = { ...initialState.formControls };
    state.invalidControls = { ...defaultInvalidInputs };
    // state.updatedProcesses = [];
    // state.file = null;
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [CLOSE_LINES_DIALOG](state, action) {
    state.linesDialog.isOpen = false
  },
  // api
  [PRODUCTS_REPORT.GET_STATE_FROM_API](state, action) {
    state.loading = true;
  },
  [PRODUCTS_REPORT.GET_STATE_FROM_API_SUCCESS](state, action) {
    state.objects = { ...action.payload.productsReport.objects };
    state.productionGraphData = action.payload.productsReport.productionGraphData;
    state.productionTable = action.payload.productsReport.productionTable;
    state.marginData = action.payload.productsReport.marginData;
    state.productCosts = action.payload.productsReport.productCosts;
    state.accumulatedCost = action.payload.productsReport.accumulatedCost;
    state.loading = !!!Object.keys(action.payload.productsReport).length;
  },
  [CHANGE_PRODUCTS_FILTERED](state, action) {
    state.productsFiltered = action.payload.value
  },

  [CHANGE_PROCESS_FILTERED](state, action) {
    state.processFiltered = action.processSelected;
  },
  [PRODUCTS_REPORT.GET_LINES](state, action) {
    state.linesDialog.type = action.lineType;
    state.linesDialog.supplyProductId = action.supplyProductId;
    state.linesDialog.warehouseId = action.warehouseId;
  },
  [PRODUCTS_REPORT.GET_LINES_SUCCESS](state, action) {
    state.linesDialog.isOpen = true;
  },
});

export default productsReports;
