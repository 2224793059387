// @ts-nocheck
import React, { Fragment, useState } from 'react';
// material
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
// Untils
import { sortArrayBy } from 'utils/arrayFunctions';
// styles
import styles from './styles';
// components
import NavBar from '../NavBar';
import Paths from 'utils/paths';
// icons
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import WorkIcon from '@material-ui/icons/Work';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import AssessmentIcon from '@material-ui/icons/Assessment';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import Permits from 'utils/permits';

const useStyles = makeStyles(styles);
const useStylesList = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(7),
  },
}));

const SideBar = (props) => {
  const [open, setOpen] = useState(false);
  const [reportsOpen, setReportsOpen] = useState(false);
  const classes = useStyles();
  const listClasses = useStylesList();

  const { isOpen, toggleDrawer, role, handleItemClick } = props;

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickReports = () => {
    setReportsOpen(!reportsOpen);
  };

  const createMenu = (
    label = undefined,
    module = undefined,
    icon = undefined,
    permits = undefined,
  ) => ({
    label,
    module,
    icon,
    permits,
  });

  const views = [
    createMenu('Adquisiciones y egresos', Paths.PURCHASES, <ReceiptIcon />, Permits.PURCHASES),
    createMenu('Recepciones', Paths.RECEIPTS, <OpenInBrowserIcon />, Permits.RECEIPTS),
    createMenu('Procesos', Paths.PROCESSES, <DynamicFeedIcon />, Permits.PROCESSES),
    createMenu('Despachos', Paths.DISPATCHES, <LocalShippingIcon />, Permits.DISPATCHES),
    createMenu('Devoluciones', Paths.RETURNS, <AssignmentLateIcon />, Permits.RETURNS),
    createMenu('Paks', Paths.LOTS, <AllInboxIcon />, Permits.LOTS),
  ];

  const maintainers = [
    createMenu('Bodegas', Paths.WAREHOUSES),
    createMenu('Clientes', Paths.CLIENTS),
    createMenu('Cuarteles', Paths.PADDOCKS),
    createMenu('Empleados', Paths.EMPLOYEES),
    createMenu('Envases', Paths.PACKAGING_TYPES),
    createMenu('Especies', Paths.FRUITS),
    createMenu('Productos', Paths.PRODUCTS),
    createMenu('Productos de insumo', Paths.SUPPLY_PRODUCTS),
    createMenu('Categorías de proceso', Paths.PROCESS_CATEGORIES),
    createMenu('Proveedores', Paths.SUPPLIERS),
    createMenu('Tipos de proceso', Paths.PROCESS_TYPES),
    createMenu('Turnos', Paths.WORKSHIFTS),
    createMenu('Unidades', Paths.UNITS),
    createMenu('Variedades', Paths.VARIETIES),
    createMenu('Equipamientos', Paths.EQUIPMENTS),
  ];

  return (
    <Drawer classes={classes} open={isOpen} onClose={toggleDrawer}>
      <NavBar toggleDrawer={toggleDrawer} withoutLogout />
      <List>
        {views
          .filter((view) => view.permits.includes(role.name))
          .map((view) => (
            <ListItem onClick={handleItemClick(view.module)} button key={`menu-${view.label}`}>
              <ListItemIcon>{view.icon}</ListItemIcon>
              <ListItemText primary={view.label} />
            </ListItem>
          ))}
        {['admin', 'operator', 'packing_manager', 'reception_dispatch_manager'].includes(role.name) && (
          <>
            <ListItem button onClick={handleClickReports}>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary="Reportes" />
              {reportsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={reportsOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  onClick={handleItemClick(Paths.INVENTORY_LINES)}
                  button
                  className={listClasses.nested}
                  key="sub-menu-inventory">
                  <ListItemText primary="Inventario" />
                </ListItem>
                {['admin'].includes(role.name) && (
                  <>
                    <ListItem
                      onClick={handleItemClick(Paths.PRODUCTS_REPORT)}
                      button
                      className={listClasses.nested}
                      key="sub-menu-products">
                      <ListItemText primary="Margen y producción" />
                    </ListItem>
                  </>
                )}
                {['admin', 'operator', 'packing_manager', 'reception_dispatch_manager'].includes(role.name) && (
                  <>
                    <ListItem
                      onClick={handleItemClick(Paths.BALANCE)}
                      button
                      className={listClasses.nested}
                      key="sub-menu-balance">
                      <ListItemText primary="Balance de masa" />
                    </ListItem>
                  </>
                )}
                {['admin'].includes(role.name) && (
                  <ListItem
                    onClick={handleItemClick(Paths.VERSION_HISTORY)}
                    button
                    className={listClasses.nested}
                    key="sub-menu-history">
                    <ListItemText primary="Historial de cambios" />
                  </ListItem>
                )}
              </List>
            </Collapse>
          </>
        )}
        {['admin'].includes(role.name) && (
          <>
            <ListItem button onClick={handleClick}>
              <ListItemIcon>
                <WorkIcon />
              </ListItemIcon>
              <ListItemText primary="Mantenedores" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {sortArrayBy(maintainers, 'ASC', 'label').map((maintainer) => (
                  <ListItem
                    onClick={handleItemClick(maintainer.module)}
                    button
                    className={listClasses.nested}
                    key={`sub-menu-${maintainer.label}`}>
                    <ListItemText primary={maintainer.label} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </>
        )}
      </List>
    </Drawer>
  );
};

export default SideBar;
