// @ts-nocheck
import React from 'react';
// material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
// Project
import ButtonWithLoader from '../../../commons/containers/ButtonWithLoader';

const ProductForm = (props) => {
  const {
    isOpen,
    toggleForm,
    changeControls,
    controls,
    edit,
    submit,
    invalidControls,
    varieties,
  } = props;

  return (
    <Dialog fullWidth onClose={toggleForm} aria-labelledby="simple-dialog-title" open={isOpen}>
      <DialogTitle>{`${edit ? 'Editar' : 'Crear'} producto`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <TextField
              value={controls.name}
              onChange={changeControls}
              fullWidth
              id="name"
              label="Nombre"
              error={invalidControls.name}
              helperText={invalidControls.name && 'Debe ingresar un nombre'}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              value={controls.code}
              onChange={changeControls}
              fullWidth
              id="code"
              label="Código"
              error={invalidControls.code}
              helperText={invalidControls.code && 'Debe ingresar un código'}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              value={controls.varietyId ? varieties[controls.varietyId] : ''}
              id="autocomplete-varieties-products"
              options={Object.values(varieties).sort((a, b) => a.name - b.name)}
              getOptionLabel={(variety) => variety?.name || ''}
              renderInput={(params) => (
                <TextField
                  style={{ margin: 0 }}
                  {...params}
                  label="Variedad"
                  margin="normal"
                  // error={invalidControls.fruitId}
                  // helperText={invalidControls.fruitId && 'Debe ingresar una variedad'}
                />
              )}
              onChange={(event, newValue) => {
                const e = {
                  autocomplete: true,
                  input: 'varietyId',
                  value: newValue ? newValue.id : null,
                };
                changeControls(e);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleForm} color="primary">
          Cancelar
        </Button>
        <ButtonWithLoader onClick={submit} autoFocus buttonId="create-edit-product">
          {edit ? 'Editar' : 'Agregar'}
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  );
};

export default ProductForm;
