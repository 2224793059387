/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';
import { parseToDate } from '../../../utils/DateFormat';

// Local constants
const PATH = 'balance/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

// Global constants for saga
export const BALANCE = {
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${PATH}GET_STATE_FROM_API_FAIL`,
  CHANGE_FILTERS: `${PATH}CHANGE_FILTERS`,
  GET_STATE_FROM_API2: `${PATH}GET_STATE_FROM_API2`,
  GET_STATE_FROM_API_SUCCESS2: `${PATH}GET_STATE_FROM_API_SUCCESS2`,
  GET_STATE_FROM_API_FAIL2: `${PATH}GET_STATE_FROM_API_FAIL2`,
};

// actions
export const balanceActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  changeControls: createAction(CHANGE_CONTROLS, 'module', 'controls'),
  // api
  changeFilters: createAction(BALANCE.CHANGE_FILTERS, 'filter'),
  getStateFromApi: createAction(BALANCE.GET_STATE_FROM_API, 'filters'),
  getStateReport: createAction(BALANCE.GET_STATE_FROM_API2, 'filters'),
  handleSelectionChange: (value) => {
    return {
      type: 'id_received',
      payload: { value },
    }
  },
};

const defaultControls = {
  receiptLotNumber: [],
  lotNumber: [],
  fromDate: parseToDate(new Date()).beginningOfMonth.toISOString(),
  toDate: parseToDate(new Date()).endOfMonth.toISOString(),
  supplierId: '',
  varietyId: '',
  fruitId: '',
  productId: '',
};

const initialState = {
  formControls: { ... defaultControls },
  loading: false,
  objects: {},
  tables: [],
  formIsOpen: false,
  info: {},
  error: false,
  inputSearch: {}
};

const balance = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },
  [CHANGE_CONTROLS](state, action) {
    state[action.module] = {
      ...state.formControls,
      ...action.controls
    };
  },
  // api
  [BALANCE.CHANGE_FILTERS](state, action) {
    state.inputSearch = { 
      ...state.inputSearch, 
      ...action.filter,
    };
  },
  [BALANCE.GET_STATE_FROM_API](state, action) {
    state.loading = true;
  },
  [BALANCE.GET_STATE_FROM_API_SUCCESS](state, action) {
    state.info = action.payload.info;
    state.loading = false;
    state.objects = action.payload.objects;
  },
  [BALANCE.GET_STATE_FROM_API_FAIL](state, action) {
    console.log('Esta es la action del errror', action);
    state.error = true; // Establecer el estado de error en true en caso de que ocurra un error en la llamada a la API
    state.loading = false;
  },
  'id_received' (state, action) {
    state.formControls.lotNumber = action.payload.value
  },
  [BALANCE.GET_STATE_FROM_API2](state, action) {
    state.loading = true;
  },
  [BALANCE.GET_STATE_FROM_API_SUCCESS2](state, action) {
    state.tables = action.payload.tables;
    state.info = action.payload.info;
    state.loading = false;
  },
  [BALANCE.GET_STATE_FROM_API_FAIL2](state, action) {
    console.log('Esta es la action del errror', action);
    state.error = true;
    state.loading = false;
  }
});

export default balance;
