const {
  REACT_APP_SOCKET_HOST: WS_ENDPOINT,
  REACT_APP_USE_CONSOLE_LOGGER: CONSOLE_LOGGER,
  REACT_APP_SENTRY_DSN: SENTRY_DSN,
  REACT_APP_TRACES_SAMPLE_RATE: TRACES_SAMPLE_RATE,
} = process.env;

const environments = {
  WS_ENDPOINT,
  CONSOLE_LOGGER,
  SENTRY_DSN,
  TRACES_SAMPLE_RATE: Number.parseFloat(TRACES_SAMPLE_RATE),
};

export default environments;
