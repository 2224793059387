import camelize from 'camelcase-keys-deep';
export default class Return {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  dispatch() {
    let response = null;
    if (this.dispatchProcessId) {
      response = this.env().Dispatch.findBy('processId', this.dispatchProcessId);
    }
    return response;
  }

  returnProcess() {
    let response = null;
    if (this.returnProcessId) {
      response = this.env().Process.findBy('id', this.returnProcessId);
    }
    return response;
  }

  returnedlots() {
    let response = null;
    if (this.returnProcessId) {
      response = this.env().Process.findBy('id', this.returnProcessId).departureLots();
    }
    return response;
  }

  returnProductQuantity(productId) {
    let response = null;
    if (this.returnProcessId) {
      const returnedLots = this.env().Lot.findAllBy('originProcessId', this.returnProcessId);
      response = returnedLots.reduce((acum, lot) => (lot.productId === productId ? acum + lot.quantity : acum), 0);
    }
    return response;
  }
}
