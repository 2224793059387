/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
// @ts-nocheck
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
import { selectState, actionProps } from 'utils/reduxActions';
import { ReactReduxContext } from 'config/configureStore';
import connectSaga from 'utils/saga';
import { dispatchesActions } from 'screens/Dispatches/reducer';
import { appActions } from 'commons/reducer';
import saga from './saga';
// utils
import { clone } from 'utils/functions';
import moment from 'moment';
// components
import DispatchesComponent from './components';
// styles
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import styles from './styles';

const useStyles = makeStyles(styles);

const defaultFilters = {
  fromDate: moment().startOf('month').toISOString(),
  toDate: moment().endOf('month').toISOString(),
  clientId: '',
  supplierId: '',
  productId: '',
  dispatchNumber: '',
  receiptLotNumber: '',
  originProcessNumber: '',
};

const defaultPagination = {
  page: 0,
  rowsPerPage: 10,
  count: 0,
};

const headerList = [
  { name: 'Despacho', width: '10%', mobileOrder: 1 },
  { name: 'Lote', width: '10%', mobileOrder: 3 },
  { name: 'Productor', width: '25%', mobileOrder: 2 },
  { name: 'Cliente', width: '25%', mobileOrder: 4 },
  { name: 'Fecha despacho', width: '20%', mobileOrder: 5 },
  { name: '', width: '10%', mobileOrder: 6 },
];

const Dispatches = (props) => {
  const { actions, toDeleteId } = props;

  useEffect(() => {
    actions.changeFilters(defaultFilters);
    actions.getStateFromApi(defaultFilters, defaultPagination);
  }, []);

  const toggleForm = () => {
    actions.toggleForm('formIsOpen');
  };

  const closeForm = () => {
    actions.toggleForm('deleteIsOpen');
  };

  const submitDelete = () => {
    actions.deleteDispatch(toDeleteId);
    toggleForm();
  };

  const classes = useStyles();

  return (
    <DispatchesComponent
      classes={classes}
      toggleForm={toggleForm}
      closeForm={closeForm}
      submitDelete={submitDelete}
      defaultFilters={defaultFilters}
      defaultPagination={defaultPagination}
      headerList={headerList}
    />
  );
};

const withSaga = connectSaga({ key: 'sagaDispatches', saga });

const withConnect = connect(
  selectState('dispatches.toDeleteId'),
  actionProps(clone(clone.OBJECT, dispatchesActions, appActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(withStyles(styles), withRouter, withConnect)(withSaga(Dispatches));
