// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';

import apiRequest, { objectToQueryString, get, apiSuccess } from 'utils/api';
import { INVENTORY_LINES, inventoryLinesActions } from '../reducer';
import { appActions } from 'commons/reducer';
import controlledCall from 'utils/services/controlledSaga';
import { truthty } from 'utils/functions';

function* getStateFromApi() {
  yield takeLatest(INVENTORY_LINES.GET_STATE_FROM_API, function* (action) {
    yield put(appActions.setLoader('get-inventory-lines', true));
    yield controlledCall(get, 'api/v1/supplyProducts/getInventoryLines', objectToQueryString(action.filters), inventoryLinesActions.getStateFromApiSuccess);
    yield put(appActions.setLoader('get-inventory-lines', false));
  });
}

function* getLines() {
  yield takeLatest(INVENTORY_LINES.GET_LINES, function* (action) {
    const { warehouseId, supplyProductId } = action;
    yield put(appActions.setLoader(`get-lines-${supplyProductId}${warehouseId}`, true));
    const params = {
      warehouseId,
      supplyProductId,
    };

    try {
      const response = yield apiRequest(`api/v1/supplyProducts/getLines?warehouseId=${warehouseId}&supplyProductId=${supplyProductId}`, {
        method: 'GET',
      });
      yield put(inventoryLinesActions.getLinesSuccess(response));
    } catch (error) {
      yield put(inventoryLinesActions.getLinesFailure(error)); 
      console.log("Error: ", error)
    }

    yield controlledCall(get, 'api/v1/supplyProducts/getLines', objectToQueryString(params), inventoryLinesActions.getLinesSuccess);
    yield put(appActions.setLoader(`get-lines-${supplyProductId}${warehouseId}`, false));
  });
}

function* exportLines() {
  yield takeLatest(INVENTORY_LINES.EXPORT_LINES, function* (action) {
    const {
      warehouseId,
      supplyProductId,
      fromDate,
      toDate,
    } = action.controls;

    const query = {
      warehouseId,
      supplyProductId,
      startDate: fromDate,
      endDate: toDate,
    };

    yield put(appActions.setLoader('export-lines', true));
    const response = yield apiRequest(`api/v1/inventoryLines/export_supply_report?${objectToQueryString(query)}`, { method: 'get' }, false, 'reporte_de_inventario.xlsx');
    yield put(apiSuccess(INVENTORY_LINES.EXPORT_LINES_SUCCESS, response));
    yield put(appActions.setLoader('get-lots', false));
  });
}

function* exportAdjustmentInventoryLines() {
  yield takeLatest(INVENTORY_LINES.EXPORT_ADJUSTMENT_INVENTORY_LINES, function* (action) {
    const {
      warehouseId,
      supplyProductId,
      fromDate,
      toDate,
    } = action.controls;

    const query = {
      warehouseId,
      supplyProductId,
      startDate: fromDate,
      endDate: toDate,
    };

    yield put(appActions.setLoader('export-lines', true));
    const response = yield apiRequest(`api/v1/inventoryLines/export_ai_supply_report?${objectToQueryString(query)}`, { method: 'get' }, false, 'ajuste_de_inventario.xlsx');
    yield put(apiSuccess(INVENTORY_LINES.EXPORT_ADJUSTMENT_INVENTORY_LINES_SUCCESS, response));
    yield put(appActions.setLoader('get-lots', false));
  });
}

function* exportProductReport() {
  yield takeLatest(INVENTORY_LINES.EXPORT_PRODUCTS_REPORT, function* (action) {
    const {
      warehouseId,
      productId,
    } = action.controls;

    const query = {
      warehouseId,
      productId,
    };

    yield put(appActions.setLoader('export-lines', true));
    const response = yield apiRequest(`api/v1/inventoryLines/export_product_report?${objectToQueryString(query)}`, { method: 'get' }, false, 'reporte_de_productos.xlsx');
    yield put(apiSuccess(INVENTORY_LINES.EXPORT_PRODUCTS_REPORT_SUCCESS, response));
    yield put(appActions.setLoader('get-lots', false));
  });
}

function* submitInventoryAdjustment() {
  yield takeLatest(INVENTORY_LINES.SUBMIT_INVENTORY_ADJUSTMENT, function* (action) {
    yield put(appActions.setLoader('submit-inventory-adjustment', true));
    const data = new FormData();
    data.append('files', action.file, action.file.name);
    action.inventoryLineIds.forEach(id => data.append('inventoryLineIds[]', id));
    const response = yield apiRequest('api/v1/inventoryLines/add_inventory_adjustment',
      {
        method: 'post',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      });
    if (truthty(response.error)) {
      yield put({ type: INVENTORY_LINES.SUBMIT_INVENTORY_ADJUSTMENT_FAIL });
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(INVENTORY_LINES.SUBMIT_INVENTORY_ADJUSTMENT_SUCCESS, response));
    }
    yield put(appActions.setLoader('submit-inventory-adjustment', false));
  });
};

export default function* saga() {
  yield spawn(getStateFromApi);
  yield spawn(getLines);
  yield spawn(exportLines);
  yield spawn(exportProductReport);
  yield spawn(exportAdjustmentInventoryLines);
  yield spawn(submitInventoryAdjustment);
}
