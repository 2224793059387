// @ts-nocheck
/* eslint-disable import/no-unresolved */
import React from 'react';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
// icons
import SearchIcon from '@material-ui/icons/Search';

const ProductsFilters = (props) => {
  const {
    classes,
    filter,
    onFilterChange,
    products,
    warehouses,
    clearFilter,
    filterLines,
  } = props;

  return (
    <Grid item xs={12}>
      <Card className={classes.root}>
        <CardHeader title="Filtro" />
        <CardContent>
          <Grid container spacing={3} className={classes.container} justifyContent="flex-end">
            <Grid item sm={3} xs={12}>
              {/* <FormControl fullWidth>
                <InputLabel id="product-label">Producto</InputLabel>
                <Select
                  labelId="product-label"
                  fullWidth
                  value={filter.productId}
                  onChange={onFilterChange}
                >
                  <MenuItem id="productId" value="">Todos</MenuItem>
                  {Object.values(products).map((sp) => (
                    <MenuItem
                      key={`product-filter-option-${sp.id}`}
                      id="productId"
                      value={sp.id}
                    >
                      {sp.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              <Autocomplete
                value={filter.productId && Object.values(products).find((product) => product.id === filter.productId)}
                id="autocomplete-product-receipts"
                options={Object.values(products).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))}
                getOptionLabel={(product) => product && product.name}
                renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Producto" margin="normal" />}
                onChange={(event, newValue) => {
                  const e = {
                    autocomplete: true,
                    input: 'productId',
                    value: newValue ? newValue.id : '',
                  };
                  onFilterChange(e);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="warehouse-label">Bodega</InputLabel>
                <Select
                  labelId="warehouse-label"
                  fullWidth
                  value={filter.warehouseId}
                  onChange={onFilterChange}
                >
                  <MenuItem id="warehouseId" value="">Todos</MenuItem>
                  {Object.values(warehouses).map((warehouse) => (
                    <MenuItem
                      key={`process-type-filter-option-${warehouse.id}`}
                      id="warehouseId"
                      value={warehouse.id}
                    >
                      {warehouse.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={3} xs={12}>
              <ButtonWithLoader
                fullWidth
                onClick={clearFilter}
                autoFocus
                buttonId="get-inventory-lines"
                className={classes.filterButton}
                size="small"
              >
                Limpiar
              </ButtonWithLoader>
            </Grid>
            <Grid item sm={3} xs={12}>
              <ButtonWithLoader
                fullWidth
                onClick={filterLines}
                autoFocus
                buttonId="get-inventory-lines"
                className={classes.filterButton}
                size="small"
                startIcon={<SearchIcon />}
              >
                Buscar
              </ButtonWithLoader>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default ProductsFilters;
