import camelize from 'camelcase-keys-deep';
export default class LotReceiptLot {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  receiptLot() {
    let response = null;
    if (this.receiptLotId) {
      response = this.env().ReceiptLot.findBy('id', this.receiptLotId);
    }
    return response;
  }

  lot() {
    let response = null;
    if (this.lotId) {
      response = this.env().Lot.findBy('id', this.lotId);
    }
    return response;
  }
}
