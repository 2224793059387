const Paths = {
  HOME: '/home',
  PURCHASES: '/purchases',
  PROCESSES: '/processes',
  RECEIPTS: '/receipts',
  PROCESS: '/process/:id',
  LOTS: '/lots',
  DISPATCHES: '/dispatches',
  RETURNS: '/returns',
  // Reports
  INVENTORY_LINES: '/inventoryLines',
  PRODUCTS_REPORT: '/productsReport',
  BALANCE: '/balance',
  VERSION_HISTORY: '/versionHistory',
  // Maintainers
  PRODUCTS: '/products',
  SUPPLIERS: '/suppliers',
  CLIENTS: '/clients',
  WORKSHIFTS: '/workShiftTypes',
  PROCESS_CATEGORIES: '/processCategories',
  PADDOCKS: '/paddocks',
  PROCESS_TYPES: '/processTypes',
  SUPPLY_PRODUCTS: '/supplyProducts',
  EMPLOYEES: '/employees',
  UNITS: '/units',
  VARIETIES: '/varieties',
  WAREHOUSES: '/warehouses',
  PACKAGING_TYPES: '/packagingTypes',
  FRUITS: '/fruits',
  EQUIPMENTS: '/equipments',
  LOT_RESUME: '/lotResume',
  LOT_TRACEABILITY: '/lotTraceability',
};

export default Paths;
