/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
// @ts-nocheck
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';

// Project
import { selectState, actionProps } from 'utils/reduxActions';
import { homeActions } from 'screens/Home/reducer';
import { ReactReduxContext } from 'config/configureStore';
import connectSaga from 'utils/saga';
import saga from './saga';

import {
  clone, truthty, falsy, onChangeFnc,
} from 'utils/functions';
import { Container, withStyles } from '@material-ui/core';
import { processTypesActions } from 'screens/ProcessTypes/reducer';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CardHeader from '@material-ui/core/CardHeader';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
// styles
import styles from './styles';
import ProcessTypeForm from './components/ProcessTypeForm';
import DeleteModal from 'commons/components/DeleteModal';
import MaintainersPaginator from '../../commons/components/MaintainersPaginator';
import TableActionMenu from 'commons/components/TableActionMenu';

const useStyles = makeStyles(styles);

const ProcessTypes = (props) => {
  const {
    objects,
    formIsOpen,
    formControls,
    edit,
    invalidControls,
    deleteIsOpen,
    actions,
  } = props;
  const [processTypeList, setProcessTypeList] = useState([]);
  const [filter, setFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setProcessTypeList(Object.values(objects.processTypes).filter((item) => item.name.toLowerCase().includes(filter.toLowerCase())).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
  }, [objects.processTypes, filter]);

  useEffect(() => {
    actions.getStateFromApi();
  }, [actions]);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function toggleForm() {
    actions.toggleForm('formIsOpen');
  }

  function closeForm() {
    actions.toggleForm('deleteIsOpen');
  }

  function toggleDelete() {
    handleClose();
    actions.toggleDelete(anchorEl.dataset.id);
  }

  function toggleEdit() {
    handleClose();
    const item = Object.values(objects.processTypes)
      .find((item) => item.id === Number(anchorEl.dataset.id));
    actions.toggleEdit(item);
  }

  function changeControls(event) {
    onChangeFnc(actions.changeControls, event);
  }

  function submit() {
    if (
      falsy(formControls.name)
    ) {
      actions.setInvalidControls({
        name: falsy(formControls.name),
      });
    } else if (edit) {
      actions.editProcessType(props.toEditId, formControls);
    } else {
      actions.createProcessType(formControls);
    }
  }

  function submitDelete() {
    actions.deleteProcessType(props.toDeleteId);
  }

  function onFilterChange(event) {
    setFilter(event.target.value);
  }

  const classes = useStyles();

  const getListItem = (processType, openMenuHandler, closeMenuHandler, toggleEdit, toggleDelete) => (
    <ListItem key={`processType-${processType.id}`}>
      <ListItemAvatar>
        <Avatar className={classes.blue}>
          {(processType.name || 'p')[0].toUpperCase()}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={processType.name}
      />
      <ListItemSecondaryAction>
        <IconButton
          onClick={openMenuHandler}
          data-id={processType.id}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={truthty(anchorEl)}
          onClose={closeMenuHandler}
        >
          <MenuItem onClick={toggleEdit}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            Editar
          </MenuItem>
          <MenuItem onClick={toggleDelete}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            Eliminar
          </MenuItem>
        </Menu>
      </ListItemSecondaryAction>
    </ListItem>
  );

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h2" className={classes.marginTop}>
            Tipos de proceso
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.root}>
            <CardHeader title="Filtro" />
            <CardContent>
              <Grid container spacing={3} className={classes.container}>
                <Grid container item xs={4}>
                  <TextField fullWidth id="standard-basic" label="Nombre" value={filter} onChange={onFilterChange} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid container item>
          <Grid item xs={12} sm={4} md={3}>
            <TableActionMenu
              menuItems={[
                {
                  label: 'Exportar',
                  listItems: [{
                    label: 'Archivo Excel',
                    onClick: actions.exportProcessTypes,
                    params: [processTypeList],
                  }],
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.root}>
            <CardContent>
              {truthty(objects.processTypes) ? (
                <MaintainersPaginator
                  classes={classes}
                  list={processTypeList}
                  getListItem={getListItem}
                  openMenuHandler={handleClick}
                  closeMenuHandler={handleClose}
                  toggleEdit={toggleEdit}
                  toggleDelete={toggleDelete}
                />
              ) : (
                <Grid container item xs={12} alignItems="center" justifyContent="center">
                  <Typography variant="h6" component="h2">
                    No se han ingresado turnos de trabajo
                  </Typography>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <ProcessTypeForm
        isOpen={formIsOpen}
        toggleForm={toggleForm}
        changeControls={changeControls}
        controls={formControls}
        units={objects.units}
        edit={edit}
        submit={submit}
        invalidControls={invalidControls}
        classes={classes}
      />
      <DeleteModal isOpen={deleteIsOpen} toggleForm={closeForm} submit={submitDelete} buttonId="delete-processType" />
      <Fab color="primary" aria-label="add" className={classes.fab} onClick={toggleForm}>
        <AddIcon />
      </Fab>
    </Container>
  );
};

const withSaga = connectSaga({ key: 'sagaProcessTypes', saga });

const withConnect = connect(
  selectState(
    'processTypes.invalidControls',
    'processTypes.formControls',
    'processTypes.deleteIsOpen',
    'processTypes.toDeleteId',
    'processTypes.formIsOpen',
    'processTypes.toEditId',
    'processTypes.edit',
    'app.objects',
  ),
  actionProps(clone(clone.OBJECT, homeActions, processTypesActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect,
)(withSaga(ProcessTypes));
