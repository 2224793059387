import React from 'react';
// ui
import Grid from '@material-ui/core/Grid';
// material ui
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// icons
import MoreVertIcon from '@material-ui/icons/MoreVert';
// utils
import moment from 'moment';
import { withTranslation } from 'react-i18next';
// components
import colors from '../../../../utils/colors';
import StyledTableCell from '../../../../commons/components/StyledTableCell';
import { truthty, formatRut, fCurrency } from '../../../../utils/functions';

const row = (purchase, handleClick, language) => (
  <TableRow key={`purchase-${purchase.id}`}>
    <StyledTableCell scope="row" style={{ color: colors.green }}>
      {`#${purchase.id}`}
    </StyledTableCell>
    <StyledTableCell component="th" scope="row">
      {purchase.taxableDocument().number}
    </StyledTableCell>
    <StyledTableCell>
      {purchase.supplier().entity().name}
    </StyledTableCell>
    <StyledTableCell>
      {formatRut(purchase.supplier().entity().taxNumber, language)}
    </StyledTableCell>
    <StyledTableCell>
      {moment(purchase.issueDate).format('DD-MM-YYYY')}
    </StyledTableCell>
    <StyledTableCell>
      {moment(purchase.expirationDate).format('DD-MM-YYYY')}
    </StyledTableCell>
    <StyledTableCell>
      {fCurrency.format(purchase.getNetAmount())}
    </StyledTableCell>
    <StyledTableCell>
      {fCurrency.format(purchase.iva)}
    </StyledTableCell>
    <StyledTableCell>
      {fCurrency.format(purchase.additionalTaxes)}
    </StyledTableCell>
    <StyledTableCell>
      {fCurrency.format(purchase.getNetAmount() + purchase.iva + purchase.additionalTaxes)}
    </StyledTableCell>
    <StyledTableCell align="right">
      <IconButton
        data-id={purchase.id}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
    </StyledTableCell>
  </TableRow>
);

const ItemTable = ({
  // props
  classes,
  handleClick,
  purchaseList,
  t,
  i18n,
}) => (
  <Grid item xs={12}>
    {truthty(purchaseList) ? (
      <Grid container item spacing={3} className={classes.container}>
        <Grid item xs={12} className={classes.tableContainer}>
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>ID adquisición</StyledTableCell>
                  <StyledTableCell>N° Documento</StyledTableCell>
                  <StyledTableCell>Proveedor</StyledTableCell>
                  <StyledTableCell>{t("identifier:name")}</StyledTableCell>
                  <StyledTableCell>Emisión</StyledTableCell>
                  <StyledTableCell>Vencimiento</StyledTableCell>
                  <StyledTableCell>Neto</StyledTableCell>
                  <StyledTableCell>IVA</StyledTableCell>
                  <StyledTableCell>Otros impuestos</StyledTableCell>
                  <StyledTableCell>Total</StyledTableCell>
                  <StyledTableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {purchaseList.map((purchase) => row(purchase, handleClick, i18n.language))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    ) : (
      <Card className={classes.root}>
        <CardContent>
          <Grid container item xs={12} alignItems="center" justifyContent="center">
            <Typography variant="h6" component="h2">
              No se encontraron adquisiciones o egresos para estos filtros.
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    )}
  </Grid>
);

export default withTranslation()(ItemTable);
