import React from 'react';
// MUI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
// ICONS
import AddIcon from '@material-ui/icons/Add';
// PROJECT
import Filter from './Filter';
import Form from './Form';
import List from './List';

const EquipmentComponents = (props) => {
  const {
    classes,
    filterControls,
    onFilterChange,
    toggleForm,
    toggleEdit,
    toggleDelete,
    formIsOpen,
    formControls,
    invalidFormControls,
    submitForm,
    formControlsChanged,
    equipmentsList,
    toggleMenu,
    closeMenu,
    anchorEl,
    deleteIsOpen,
    onDeleteEquipment,
  } = props;

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h2" className={classes.marginTop}>
            Equipamientos
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Filter
            classes={classes}
            filterControls={filterControls}
            onFilterChange={onFilterChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Form
            formIsOpen={formIsOpen}
            formControls={formControls}
            invalidFormControls={invalidFormControls}
            toggleForm={toggleForm}
            submitForm={submitForm}
            formControlsChanged={formControlsChanged}
          />
        </Grid>
        <Grid item xs={12}>
          <List
            classes={classes}
            equipmentsList={equipmentsList}
            toggleMenu={toggleMenu}
            closeMenu={closeMenu}
            toggleEdit={toggleEdit}
            toggleDelete={toggleDelete}
            anchorEl={anchorEl}
            deleteIsOpen={deleteIsOpen}
            onDeleteEquipment={onDeleteEquipment}
          />
        </Grid>
      </Grid>
      <Fab color="primary" aria-label="add" className={classes.fab} onClick={toggleForm}>
        <AddIcon />
      </Fab>
    </Container>
  );
};

export default EquipmentComponents;
