import { blueGrey } from '@material-ui/core/colors';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(10),
    right: theme.spacing(6),
  },
  blue: {
    color: theme.palette.getContrastText(blueGrey[500]),
    backgroundColor: blueGrey[500],
    fontFamily: ['Roboto'],
  },
  marginTop: {
    marginTop: '24px',
  },
  filterButton: {
    marginTop: '16px',
  },
  greenChip: {
    backgroundColor: theme.palette.primary.light,
    marginRight: '8px',
    maxWidth: '100%',
  },
  blueChip: {
    backgroundColor: theme.palette.info.light,
    marginRight: '8px',
    maxWidth: '100%',
  },
  redChip: {
    backgroundColor: theme.palette.error.light,
    marginRight: '24px',
    maxWidth: '100%',
  },
  leftSubModule: {
    height: 'calc(50vh - 142px)',
    minHeight: 400,
    overflow: 'auto',
  },
  subModule: {
    height: 'calc(50vh - 142px)',
    minHeight: 400,
  },
  marginChartSubModule: {
    minHeight: 400,
    height: '100%',
  },
  totalLabel: {
    ...theme.typography.button,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.info.contrastText,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    fontSize: '12px',
    textAlign: 'center',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '20px',
    marginRight: '20px',
    minWidth: 'fit-content',
  },
  totalLabelUnit: {
    display: 'inline',
    textTransform: 'lowercase',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  containerTable: {
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    minWidth: 'fit-content',
  },
  containerPadding: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  leftTableTitle: {
    paddingTop: '16px',
    paddingBottom: '16px',
    maxWidth: '100%',
    backgroundColor: 'white',
  },
  fullWidth: {
    width: '100%',
  },
  paperContainer: {
    width: 'fit-content',
    minWidth: '100%',
    borderRadius: '0px',
  },
});

export default styles;
