/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-unresolved */
import React from 'react';
// ui
import Grid from '@material-ui/core/Grid';
// material ui
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
// icons
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
// utils
import { truthty, falsy } from 'utils/functions';
import SkeletonList from 'commons/components/SkeletonList';
import CustomPagination from 'commons/components/CustomPagination';
import Item from './Item';
import { List } from '@material-ui/core';
import { Header } from 'commons/components/DataTable/Header';

const ItemList = (props) => {
  const {
    actions,
    classes,
    processList,
    toggleEdit,
    toggleDelete,
    handleClick,
    handleClose,
    anchorEl,
    processOnClick,
    loaders,
    pagination,
    filter,
    loadingProcess,
    headerList,
    isMobileDevice,
  } = props;

  const anchorElProcess =
    truthty(anchorEl) && processList.find((elem) => elem.id === Number(anchorEl.dataset.id));

  function handleChangeRowsPerPage(e) {
    actions.paginationControls(filter, { ...pagination, rowsPerPage: e.target.value });
  }

  function handleChangePage(e, val) {
    actions.paginationControls(filter, { ...pagination, page: val });
  }

  return (
    <Grid item xs={12}>
      <Card className={classes.root}>
        <CardContent>
          {truthty(processList) ? (
            <Grid container spacing={3} className={classes.container}>
              <Grid item xs={12}>
                {loadingProcess ? (
                  <SkeletonList times={pagination.rowsPerPage} />
                ) : (
                  <>
                    <Header headerList={headerList} isMobileDevice={isMobileDevice} />
                    <List>
                      {processList.map((process) => (
                        <Item
                          process={process}
                          classes={classes}
                          openMenuHandler={handleClick}
                          closeMenuHandler={handleClose}
                          toggleEdit={toggleEdit}
                          toggleDelete={toggleDelete}
                          anchorEl={anchorEl}
                          processOnClick={processOnClick}
                          headerList={headerList}
                          isMobileDevice={isMobileDevice}
                        />
                      ))}
                    </List>
                  </>
                )}
              </Grid>
              <CustomPagination
                rowsPerPage={pagination.rowsPerPage}
                page={pagination.page}
                count={pagination.count}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                loading={loadingProcess}
              />
            </Grid>
          ) : (
            <Grid container item xs={12} alignItems="center" justifyContent="center">
              <Typography variant="h6" component="h2">
                No se han ingresado procesos
              </Typography>
            </Grid>
          )}
          {truthty(anchorElProcess) && falsy(anchorElProcess.closedAt) && (
            <Menu anchorEl={anchorEl} keepMounted open={truthty(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={toggleEdit}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                Editar
              </MenuItem>
              <MenuItem onClick={toggleDelete}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                Eliminar
              </MenuItem>
            </Menu>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ItemList;
