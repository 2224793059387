/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'clients/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_EDIT_CLIENT = `${PATH}TOGGLE_EDIT_CLIENT`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;

// Global constants for saga
export const CLIENTS = {
  CREATE_CLIENT: `${PATH}CREATE_CLIENT`,
  CREATE_CLIENT_SUCCESS: `${PATH}CREATE_CLIENT_SUCCESS`,
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  DELETE_CLIENT: `${PATH}DELETE_CLIENT`,
  DELETE_CLIENT_SUCCESS: `${PATH}DELETE_CLIENT_SUCCESS`,
  EDIT_CLIENT_SUCCESS: `${PATH}EDIT_CLIENT_SUCCESS`,
  EDIT_CLIENT: `${PATH}EDIT_CLIENT`,
  EXPORT_CLIENT: `${PATH}EXPORT_CLIENT`,
  EXPORT_CLIENT_SUCCESS: `${PATH}EXPORT_CLIENT_SUCCESS`,
};

// actions
export const clientsActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  toggleEdit: createAction(TOGGLE_EDIT_CLIENT, 'entity', 'item'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  // api
  getStateFromApi: createAction(CLIENTS.GET_STATE_FROM_API),
  createClient: createAction(CLIENTS.CREATE_CLIENT, 'controls'),
  editClient: createAction(CLIENTS.EDIT_CLIENT, 'id', 'controls'),
  deleteClient: createAction(CLIENTS.DELETE_CLIENT, 'id'),
  
  exportClients: createAction(CLIENTS.EXPORT_CLIENT, 'id'),
};

const defaultInvalidControls = {
  name: false,
  taxNumber: false,
  email: false,
};

const initialState = {
  formControls: {
    name: '',
    taxNumber: '',
    email: '',
    code: '',
    region: '',
    province: '',
    sector: '',
    address: '',
    isInternalClient: false,
    isDefaultClient: false,
  },
  invalidControls: { ...defaultInvalidControls },
  formIsOpen: false,
  deleteIsOpen: false,
  toDeleteId: '',
  toEditId: '',
  edit: false,
  error: false,
};

const clients = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.edit = false;
    state.formControls.name = '';
    state.formControls.taxNumber = '';
    state.formControls.email = '';
    state.formControls.code = '';
    state.formControls.region = '';
    state.formControls.province = '';
    state.formControls.sector = '';
    state.formControls.address = '';
    state.formControls.isInternalClient = false;
    state.formControls.isDefaultClient = false;
    state.invalidControls = { ...defaultInvalidControls };
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {})
    };
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [TOGGLE_EDIT_CLIENT](state, action) {
    state.formIsOpen = true;
    state.toDeleteId = '';
    state.toEditId = action.item.id;
    state.formControls.name = action.entity.name;
    state.formControls.taxNumber = action.entity.taxNumber;
    state.formControls.email = action.item.email;
    state.formControls.code = action.item.code;
    state.formControls.region = action.item.region;
    state.formControls.province = action.item.province;
    state.formControls.sector = action.item.sector;
    state.formControls.address = action.item.address;
    state.formControls.isInternalClient = action.item.isInternalClient;
    state.formControls.isDefaultClient = action.item.isDefaultClient;
    state.edit = true;
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    };
  },
  // api
  [CLIENTS.CREATE_CLIENT_SUCCESS](state, action) {
    state.formIsOpen = false;
    state.formControls.name = '';
    state.formControls.taxNumber = '';
    state.formControls.email = '';
    state.formControls.code = '';
    state.formControls.region = '';
    state.formControls.province = '';
    state.formControls.sector = '';
    state.formControls.address = '';
    state.formControls.isInternalClient = false;
    state.formControls.isDefaultClient = false;
    state.invalidControls = { ...defaultInvalidControls };
  },
  [CLIENTS.DELETE_CLIENT_SUCCESS](state, action) {
    state.deleteIsOpen = false;
    state.toDeleteId = '';
  },
  [CLIENTS.EDIT_CLIENT_SUCCESS](state, action) {
    state.formIsOpen = false;
    state.toDeleteId = '';
    state.toEditId = '';
    state.formControls.isInternalClient = false;
    state.formControls.isDefaultClient = false;
    state.edit = false;
  },
});

export default clients;
