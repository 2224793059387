import React from 'react';
// ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { truthty } from 'utils/functions';

const ConfirmDialog = (props) => {
  const {
    accept,
    disabled,
    loading,
    oneButton,
    disabledOnClose,
    open,
    cancel,
    title,
    message,
    fullScreen,
    children,
    maxWidth,
    alert,
    info,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={!disabledOnClose && cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={fullScreen}
      maxWidth={maxWidth || 'lg'}
      fullWidth
    >
      {truthty(alert) && (<Alert severity="warning">{alert}</Alert>)}
      {truthty(info) && (<Alert severity="info">{info}</Alert>)}
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {
          truthty(message) && (
            <>
              <DialogContentText id="alert-dialog-description py-3">
                {message}
              </DialogContentText>
              <br />
            </>
          )
        }
        {children}
      </DialogContent>
      { truthty(children) && <br />}
      <DialogActions>
        <Button
          onClick={() => cancel()}
          color="primary"
          disabled={loading}
        >
          Cancelar
        </Button>
        {
          !oneButton &&
            <>
              {
                loading ?
                  <CircularProgress
                    size={25}
                  /> :
                  <Button
                    onClick={accept}
                    color="primary"
                    disabled={loading || disabled}
                  >
                    Aceptar
                  </Button>
              }
            </>
        }
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
