import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import StyledTableCell from 'commons/components/StyledTableCell';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';


const useStyles = makeStyles({
  table: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    margin: '20px 0',
  },
  bottomText: {
    margin: '20px 20px',
    alignSelf: 'flex-end',
  },
});

 function addDot(cell) {
   if (typeof cell === 'string') {
    if (cell.includes("%")) {
      return cell
    }
    else if(cell.includes("-")) {
      return cell
    }
    let cell2 = cell.replace(/\.\d+$/, "");
    return cell2.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
   } else {
    let cellString = cell?.toString();
    if (cellString?.includes("%")) {
      return cellString.replace(/\.\d+(?=%)/, "");
    }
    let cellString2 = cellString?.replace(/\.\d+$/, "");
    return cellString2?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
   }
 };

export const FirstTable = ({ headers = [], rows = [], title = '', bottomText = '' }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.table} style={{ marginBottom: '15px' }}>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      <div className='table-print' style={{width: '97%'}}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
      <StyledTableCell align="left">{headers[0]}</StyledTableCell>
              {headers.slice(1).map((header) => (
                <StyledTableCell align="right"> {header}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row[0]}>
                <TableCell component="th" scope="row">
                {headers[0] === 'N° Proceso' ? (
                  <Link to={`/process/${row[0]}`} style={{ textDecoration: 'none', color: 'black' }}>
                    {row[0]}
                  </Link>
                ) : (
                  <>{row[0]}</>
                )}
                </TableCell>
                {row.length !== 9 ? (
                  row.slice(1).map((cell) => (
                    <TableCell align="right">{(addDot(cell))}</TableCell>
                  ))
                ) :
                    (row.slice(1,8).map((cell) => (
                      <TableCell align="right">{(addDot(cell))}</TableCell>
                    ))
                  )
                }
                {
                  row.length === 9 && (
                    <TableCell align="right">{(row[8])}</TableCell>
                  )
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <Typography variant="h6" className={classes.bottomText}>
        {bottomText}
      </Typography>
    </TableContainer>
  );
};
