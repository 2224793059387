import React, { useState } from 'react';
// MUI
import { Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Collapse, TablePagination, Box, IconButton, Typography } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles'
// Project
import SkeletonTable from '../../../../commons/components/SkeletonTable';
import CustomPagination from "../../../../commons/components/CustomPagination";
// Utils
import { getLocalDate } from '../../../../utils/DateFormat';
import { ModelNames } from "../../../../utils/ModelNames";
import { getIdentifier } from 'utils/services/versionHistory';
import { truthty } from "../../../../utils/functions";
import { withTranslation } from 'react-i18next';

const List = (props) => {
  const {
    classes,
    objects,
    loading,
    pagination,
    versionHistoriesList,
    handleChangeRowsPerPage,
    handleChangePage,
    t,
  } = props;

  const { users } = objects

  const columns = [
    { id: "createdAt", label: "Fecha y hora" },
    { id: "identifier", label: "Identificador"},
    { id: "modelName", label: "Módulo" },
    { id: "eventType", label: "Tipo de acción" },
    { id: "userId", label: "Usuario" },
    // { id: "dropDown", label: "" },
  ];

  const actionTypes = {
    'create': 'Creación',
    'update': 'Actualización',
    'destroy': 'Eliminación'
  }

  // const stateChanges = ['Anterior', 'Actual']

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#358a7c',
      color: 'white'
    }
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: "white",
      },
    },
  }))(TableRow);

  const RowTable = (props) => {
    const { item: { id, changes, recordId, eventType, modelName, userId, createdAt } } = props;
    // const [open, setOpen] = useState(false);
    let { localeDateTime: timestamp } = getLocalDate(createdAt);
    timestamp = timestamp.replaceAll("-", "/").replaceAll(",", "");

    let username = Object.values(users).find(user => user.id === userId)
    username = username?.id ? username.email : 'No Identificado';

    let attrChanged = JSON.parse(changes)
    delete attrChanged.createdAt
    delete attrChanged.updatedAt

    return (
      <>
        <StyledTableRow key={id}>
          <TableCell align="center">{timestamp}</TableCell>
          <TableCell align="center">{getIdentifier(recordId, modelName, attrChanged, t("identifier:name"))}</TableCell>
          <TableCell align="center">{ModelNames[modelName]}</TableCell>
          <TableCell align="center">{actionTypes[eventType]}</TableCell>
          <TableCell align="center">{username}</TableCell>
          {/* <TableCell>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell> */}
        </StyledTableRow>
        {/* <StyledTableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={columns.length}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{"Campos modificados"}</TableCell>
                      {Object.keys(attrChanged).map((title, index) => {
                        return <TableCell>{title}</TableCell>;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.values(attrChanged)[0].map((val, index) => {
                      return (
                        <TableRow>
                          <TableCell>{stateChanges[index]}</TableCell>
                          {Object.values(attrChanged).map((value) => {
                            return <TableCell>{value[index]}</TableCell>;
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </StyledTableRow> */}
      </>
    );
  }

  return (
    <Grid item container>
      <TableContainer>
        <Table stickyHeader style={{ border: "none" }}>
          <TableHead>
            <TableRow key={"table-head-1"}>
              {columns.map((column) => {
                return (
                  <StyledTableCell
                    key={`table-head-${column.id}`}
                    align="center"
                  >
                    {column.label}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              loading ? (<SkeletonTable times={pagination.rowsPerPage} columns={columns.length} />) :
                truthty(versionHistoriesList) ?
                  (
                    Object.values(versionHistoriesList)
                          .sort((a, b) => a.createdAt > b.createdAt ? -1 : 1 )
                          .map((versionHistory) => <RowTable item={versionHistory} />)
                  )
                : [...Array(pagination.rowsPerPage)].map((row, index) => {
                    return (<StyledTableRow>
                              <TableCell align="center" colSpan={columns.length}>{ index === 4 && 'No se han encontrado resultados'}</TableCell>
                           </StyledTableRow>)
                  })
            }
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={pagination.count}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Grid>
  );
}

export default withTranslation()(List);
