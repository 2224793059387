import camelize from 'camelcase-keys-deep';
export default class Product {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  unit() {
    let response = null;
    if (this.unitId) {
      response = this.env().Unit.findBy('id', this.unitId);
    }
    return response;
  }

  lotReceiptLots() {
    const lots = this.env().Lot.findAllBy('productId', this.id);

    const lrl = this.env().LotReceiptLot.all();
    const response = lrl.filter(l => lots.map(l => l.id).includes(l.lotId));
    return response;
  }

  fruit() {
    return this.env().Fruit.findBy('id', this.fruitId);
  }
}
