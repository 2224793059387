/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
// ui
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { truthty, fCurrency, falsy } from 'utils/functions';
import { FormHelperText } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

function productData(product, warehouseId, role, purchaseId) {
  const inventoryLines = product.allInventoryLines(warehouseId, purchaseId);
  if (inventoryLines.length > 1) {
    return 'Seleccione una adquisición';
  }
  if (inventoryLines.length === 1 && truthty(inventoryLines[0])) {
    const {
      initial, inputs, outputs, costValue
    } = inventoryLines[0];
    const stock = initial + inputs - outputs;
    return `Stock: ${stock} [${product.unit().code}]${role.name === 'admin' ? `, precio: ${fCurrency.format(costValue)}` : ''}`;
  }
  return 'No hay stock de este producto';
}

function getInventoryLineInfo(product, warehouseId, purchaseId) {
  const inventoryLines = product.allInventoryLines(warehouseId, purchaseId);
  for (const inventoryLine of inventoryLines) {
    if (truthty(inventoryLine)) {
      const { initial, inputs, outputs, costValue } = inventoryLine;
      const stock = initial + inputs - outputs;
      if (stock > 0) {
        return { stock, costValue };
      }
    }
  }
  return { stock: 0, costValue: 0 };
}

const SupplyProductForm = (props) => {
  const {
    toggleForm, isOpen, controls, changeControls, invalidControls, supplyProducts, warehouses, role, submit, purchases
  } = props;

  const [supplyProductsOptions, setSupplyProductsOptions] = useState(Object.values(supplyProducts).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)));

  useEffect(() => {
    let newSupplyProductOptions = Object.values(supplyProducts);

    if (controls.warehouseId) {
      const inventoryLines = warehouses[controls.warehouseId].allInventoryLines();
      const inventoryLinesSpIds = inventoryLines.map((invLine) => Number(invLine.supplyProductId));
      if (controls.supplyProductId && getInventoryLineInfo(supplyProducts[controls.supplyProductId], controls.warehouseId).stock <= 0) {
        changeControls({
          autocomplete: true,
          input: 'supplyProductId',
          value: '',
          module: 'supplyProducts',
        });
      }
      newSupplyProductOptions = Object.values(supplyProducts)
        .filter((sp) => inventoryLinesSpIds.includes(sp.id) && getInventoryLineInfo(supplyProducts[sp.id], controls.warehouseId).stock > 0);
    }

    setSupplyProductsOptions(newSupplyProductOptions.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)));
  }, [controls.warehouseId]);

  const [purchaseOptions, setPurchaseOptions] = useState(Object.values(purchases).sort((a, b) => (a.id > b.id ? 1 : -1)));

  useEffect(() => {
    let newPurchaseOptions = Object.values(purchases);

    if (controls.supplyProductId) {
      const inventoryLines = supplyProducts[controls.supplyProductId].allInventoryLines(controls.warehouseId);
      const inventoryLinesPurchaseIds = inventoryLines.map(invLine => Number(invLine.purchaseId));
      if (controls.purchaseId && getInventoryLineInfo(supplyProducts[controls.supplyProductId], controls.warehouseId, controls.purchaseId).stock <= 0) {
        changeControls({
          autocomplete: true,
          input: 'purchaseId',
          value: null,
          module: 'supplyProducts',
        });
      }
      newPurchaseOptions = Object.values(purchases)
      .filter((purchase) => inventoryLinesPurchaseIds.includes(purchase.id) && getInventoryLineInfo(supplyProducts[controls.supplyProductId], controls.warehouseId, purchase.id).stock > 0);
  
    }

    setPurchaseOptions(newPurchaseOptions.sort((a, b) => (a.id > b.id ? 1 : -1)));
  }, [controls.supplyProductId]);

  const warehouseOptions = Object.values(warehouses).filter((warehouse) => warehouse.supplyWarehouse);

  return (
    <Dialog fullWidth onClose={toggleForm} aria-labelledby="simple-dialog-title" open={isOpen}>
      <DialogTitle>Agregar insumo</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              fullWidth
              value={controls.warehouseId && Object.values(warehouses).find((wh) => wh.id === controls.warehouseId)}
              id="autocomplete-warehouse"
              options={warehouseOptions}
              getOptionLabel={(warehouse) => warehouse && warehouse.name}
              renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Bodega" margin="normal" />}
              onChange={(event, newValue) => {
                const e = {
                  autocomplete: true,
                  input: 'warehouseId',
                  value: newValue ? newValue.id : '',
                  module: 'supplyProducts',
                };
                changeControls(e);
              }}
            />
            {truthty(invalidControls.warehouseId) && <FormHelperText error>Debe ingresar una bodega</FormHelperText>}
          </Grid>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              value={controls.supplyProductId && Object.values(supplyProducts).find((sp) => sp.id === controls.supplyProductId)}
              disabled={falsy(controls.warehouseId)}
              id="autocomplete-suppliesProduct-purchases"
              options={supplyProductsOptions}
              getOptionLabel={(supplyProduct) => supplyProduct && supplyProduct.name}
              renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Insumo" margin="normal" />}
              onChange={(event, newValue) => {
                const e = {
                  autocomplete: true,
                  input: 'supplyProductId',
                  value: newValue ? newValue.id : '',
                  module: 'supplyProducts',
                };
                changeControls(e);
              }}
            />
            {truthty(invalidControls.supplyProductId) && <FormHelperText error>Debe ingresar un insumo</FormHelperText>}
            </Grid>
            <Grid item sm={6} xs={12}>
            <Autocomplete
              fullWidth
              value={controls.purchaseId && Object.values(purchases).find((purchase) => purchase.id === controls.purchaseId)}
              disabled={falsy(controls.supplyProductId) || !supplyProducts[controls.supplyProductId].traceable}
              id="autocomplete-purchases"
              options={purchaseOptions}
              getOptionLabel={(purchase) => purchase && purchase.id.toString()}
              renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="ID adquisición" margin="normal" />}
              onChange={(event, newValue) => {
                const e = {
                  autocomplete: true,
                  input: 'purchaseId',
                  value: newValue ? newValue.id : null,
                  module: 'supplyProducts',
                };
                changeControls(e);
              }}
            />
            {/* {truthty(invalidControls.purchaseId) &&  <FormHelperText error>Debe ingresar una adquisición</FormHelperText>} */}
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              inputProps={{ 'data-module': 'supplyProducts' }}
              value={controls.quantity}
              disabled={falsy(controls.supplyProductId) || (supplyProducts[controls.supplyProductId].traceable && falsy(controls.purchaseId))}
              onChange={changeControls}
              fullWidth
              id="quantity"
              label="Cantidad"
              type="number"
              error={invalidControls.quantity}
              helperText={controls.supplyProductId && controls.warehouseId
                && productData(supplyProducts[controls.supplyProductId], controls.warehouseId, role, controls.purchaseId)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleForm} data-module="supplyProducts" color="primary" variant="outlined">
          Cancelar
        </Button>
        <ButtonWithLoader
          // @ts-ignore
          onClick={submit}
          autoFocus
          buttonId="add-supply-product"
        >
          Agregar
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  );
};

export default SupplyProductForm;
