/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'processCategories/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_EDIT_PROCESS_CATEGORIES = `${PATH}TOGGLE_EDIT_PROCESS_CATEGORIES`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;

// Global constants for saga
export const PROCESS_CATEGORIES = {
  CREATE_PROCESS_CATEGORIES: `${PATH}CREATE_PROCESS_CATEGORIES`,
  CREATE_PROCESS_CATEGORIES_SUCCESS: `${PATH}CREATE_PROCESS_CATEGORIES_SUCCESS`,
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  DELETE_PROCESS_CATEGORIES: `${PATH}DELETE_PROCESS_CATEGORIES`,
  DELETE_PROCESS_CATEGORIES_SUCCESS: `${PATH}DELETE_PROCESS_CATEGORIES_SUCCESS`,
  DELETE_PROCESS_CATEGORIES_FAIL: `${PATH}DELETE_PROCESS_CATEGORIES_FAIL`,
  EDIT_PROCESS_CATEGORIES_SUCCESS: `${PATH}EDIT_PROCESS_CATEGORIES_SUCCESS`,
  EDIT_PROCESS_CATEGORIES: `${PATH}EDIT_PROCESS_CATEGORIES`,
};

// actions
export const processCategoriesActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  toggleEdit: createAction(TOGGLE_EDIT_PROCESS_CATEGORIES, 'item'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  // api
  getStateFromApi: createAction(PROCESS_CATEGORIES.GET_STATE_FROM_API),
  createProcessCategory: createAction(PROCESS_CATEGORIES.CREATE_PROCESS_CATEGORIES, 'controls'),
  editProcessCategory: createAction(PROCESS_CATEGORIES.EDIT_PROCESS_CATEGORIES, 'id', 'controls'),
  deleteProcessCategory: createAction(PROCESS_CATEGORIES.DELETE_PROCESS_CATEGORIES, 'id'),
};

const defaultInvalidInputs = {
  name: false,
};

const initialState = {
  formControls: {
    name: '',
  },
  invalidControls: {
    name: false,
  },
  formIsOpen: false,
  deleteIsOpen: false,
  toDeleteId: '',
  toEditId: '',
  edit: false,
  error: false,
};

const processCategories = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.edit = false;
    state.formControls.name = '';
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls,
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    };
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [TOGGLE_EDIT_PROCESS_CATEGORIES](state, action) {
    state.formIsOpen = true;
    state.toDeleteId = '';
    state.toEditId = action.item.id;
    state.formControls.name = action.item.name;
    state.edit = true;
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    };
  },
  // api
  [PROCESS_CATEGORIES.CREATE_PROCESS_CATEGORIES_SUCCESS](state, action) {
    state.formIsOpen = false;
    state.formControls.name = '';
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [PROCESS_CATEGORIES.DELETE_PROCESS_CATEGORIES_SUCCESS](state, action) {
    state.deleteIsOpen = false;
    state.toDeleteId = '';
  },
  [PROCESS_CATEGORIES.EDIT_PROCESS_CATEGORIES_SUCCESS](state, action) {
    state.formControls.name = '';
    state.invalidControls = { ...defaultInvalidInputs };
    state.formIsOpen = false;
    state.toDeleteId = '';
    state.toEditId = '';
    state.edit = false;
  },
});

export default processCategories;
