import React, { useState, useEffect } from 'react';
// material
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// utils
import { toISOString, getLocalDate, parseToDate } from '../../../utils/DateFormat';

const DatePicker = (props) => {
  const {
    value,
    type,
    inputProps,
    helperText,
    label,
    error,
    fullWidth,
    inputId,
    name,
    disabled,
    onChange,
    empty,
  } = props;

  const getParsedValue = (date) => {
    const { isoLocaleDate, localeTime } = getLocalDate(date);
    let result = isoLocaleDate;
    if (type === 'datetime-local') {
      result = `${isoLocaleDate}T${localeTime}`;
    }

    return result;
  };

  const [stateValue, setStateValue] = useState(getParsedValue(new Date()));

  const localOnChange = (event) => {
    setStateValue(event.target.value);
  };

  const validateValue = (date) => {
    let newValue = toISOString(date);
    if (inputProps) {
      if (inputProps.min && parseToDate(inputProps.min).date > parseToDate(date).date) {
        newValue = toISOString(inputProps.min);
      } else if (inputProps.max && parseToDate(inputProps.max).date < parseToDate(date).date) {
        newValue = toISOString(inputProps.max);
      } else {
        newValue = toISOString(date);
      }
    } else if (parseToDate(date).date > new Date('01-01-9999')) {
      newValue = toISOString(new Date());
    }

    return newValue;
  };

  useEffect(() => {
    if (value) {
      setStateValue(getParsedValue(value));
    }
  }, []);

  useEffect(() => {
    if (inputProps && (inputProps.min || inputProps.max) && value) {
      const newValue = validateValue(value);
      if (getParsedValue(value) !== getParsedValue(newValue)) {
        setStateValue(getParsedValue(newValue));
        onChange(toISOString(newValue), name || 'unnamed');
      }
    }
    if (!value && empty) {
      setStateValue('');
      onChange('', name || 'unnamed');
    } else if (value) {
      setStateValue(getParsedValue(value));
    }
  }, [value, inputProps]);

  const invalidDate = (date) => {
    if (parseToDate(date).date.toString() === 'Invalid Date') {
      if (empty) {
        return '';
      }
      return getParsedValue(new Date());
    }
    if (parseToDate(date).date > new Date().setFullYear(new Date().getFullYear() + 10)) {
      return getParsedValue(new Date());
    }
    return date;
  };

  const handleBlur = (event) => {
    const newValue = invalidDate(stateValue) ? validateValue(invalidDate(stateValue)) : '';
    if (newValue === '') {
      setStateValue('');
      onChange('', event.currentTarget.name);
    } else {
      setStateValue(getParsedValue(newValue));
      onChange(event, toISOString(newValue));
    }
  };

  return (
    <Grid item container>
      <Grid item xs>
        <TextField
          disabled={disabled}
          value={stateValue}
          onBlur={handleBlur}
          // @ts-ignore
          onChange={localOnChange}
          fullWidth={fullWidth}
          id={inputId || 'datepicker-simple'}
          label={label || 'Fecha'}
          error={error}
          helperText={helperText}
          type={type || 'date'}
          inputProps={inputProps}
          name={name || 'date-picker-unnamed'}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
};
export default DatePicker;
