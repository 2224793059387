import {
  put,
  spawn,
  takeLatest
} from 'redux-saga/effects';
import { VERSION_HISTORIES } from '../reducer';
import { appActions } from 'commons/reducer';
import apiRequest, { apiSuccess, objectToQueryString } from "utils/api";

function* getStateFromApi() {
  yield takeLatest([VERSION_HISTORIES.GET_STATE_FROM_API, VERSION_HISTORIES.CHANGE_PAGINATION], function* (action) {
    yield put(appActions.setLoader('get-versionHistory', true));

      const response = yield apiRequest(`api/v1/versionHistories?${objectToQueryString(
        {
          page: action.pagination.page,
          rowsPerPage: action.pagination.rowsPerPage,
          ...action.filters
        })}`, { method: 'get' });

      yield put(apiSuccess(VERSION_HISTORIES.GET_STATE_FROM_API_SUCCESS, response, { pagination: action.pagination }));
      yield put(appActions.setLoader('get-versionHistory', false));
  })
}

export default function* saga() {
  yield spawn(getStateFromApi);
}