/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
// ui
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// icons
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LaunchIcon from '@material-ui/icons/Launch';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AvTimerIcon from '@material-ui/icons/AvTimer';

const DialButton = (props) => {
  const {
    classes,
    speedDialToggle,
    open,
    disabledDepartures,
    toggleForm,
  } = props;

  const actions = [
    { icon: <AvTimerIcon />, name: 'Equipamientos', module: 'processEquipments' },
    { icon: <AddBoxIcon />, name: 'Insumos', module: 'supplyProducts' },
    { icon: <PersonAddIcon />, name: 'Personal', module: 'staff' },
    { icon: <LaunchIcon />, name: 'Salidas', module: 'departure', disabled: disabledDepartures },
    { icon: <ExitToAppIcon />, name: 'Entradas', module: 'entry' },
  ];

  return (
    <ClickAwayListener onClickAway={() => (open ? speedDialToggle() : null)}>
      <SpeedDial
        ariaLabel="process-dial-button"
        icon={<SpeedDialIcon />}
        className={classes.fab}
        onClick={speedDialToggle}
        open={open}
        direction="up"
      >
        {actions
          .filter((action) => !action.disabled)
          .map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              data-module={action.module}
              onClick={toggleForm}
              tooltipOpen
            />
          ))}
      </SpeedDial>
    </ClickAwayListener>
  );
};

export default DialButton;
