/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-unresolved */
import React from 'react';
// ui
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';
// components
import StyledCardHeader from 'commons/components/StyledCardHeader';
import StyledTableCell from 'commons/components/StyledTableCell';
// utils
import { formatRut, truthty, formatDate, falsy} from 'utils/functions';
import { withTranslation } from 'react-i18next';
// icons
import DeleteIcon from '@material-ui/icons/Delete';

const WorkShiftStaff = (props) => {
  const { classes, processStaffLines, processResources, t } = props;
  return (
    <>
      <Card className={classes.subModule}>
        <StyledCardHeader
          title="Personal"
          subheader={`Total: ${Object.values(processStaffLines).length} persona${Object.values(processStaffLines).length !== 1 ? 's' : ''}`}
        />
        <CardContent className={classes.processCardContent}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Nombre</StyledTableCell>
                    <StyledTableCell align="right">{props.t("identifier:name")}</StyledTableCell>
                    { falsy(props.process.closedAt) && <StyledTableCell align="right" />}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {processStaffLines
                    .sort((a, b) => a.employee().entity().name.toLowerCase()
                      .localeCompare(b.employee().entity().name.toLowerCase()))
                    .map((processStaffLine, index) => (
                      <TableRow key={`staff-line-${processStaffLine.id}`}>
                        <StyledTableCell component="th" scope="processStaffLine">
                          {processStaffLine.employee().entity().name}
                        </StyledTableCell>
                        <StyledTableCell align="right">{formatRut(processStaffLine.employee().entity().taxNumber, props.i18n.language)}</StyledTableCell>
                        {
                          falsy(props.process.closedAt) && (
                            <StyledTableCell align="right">
                              <IconButton
                                onClick={props.toggleDelete}
                                data-id={processStaffLine.id}
                                data-module="deleteProcessStaff"
                                size="small"
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </StyledTableCell>
                          )
                        }
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default withTranslation()(WorkShiftStaff);