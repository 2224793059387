// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';

import apiRequest, { apiSuccess } from 'utils/api';
import { CLIENTS } from '../reducer';
import { truthty } from 'utils/functions';
import { appActions } from 'commons/reducer';

function* getStateFromApi() {
  yield takeLatest(CLIENTS.GET_STATE_FROM_API, function* (action) {
    const response = yield apiRequest('api/v1/clients', { method: 'get' });
    yield put(apiSuccess(CLIENTS.GET_STATE_FROM_API_SUCCESS, response));
  });
}

function* createClient() {
  yield takeLatest(CLIENTS.CREATE_CLIENT, function* (action) {
    yield put(appActions.setLoader('create-edit-client', true));
    const response = yield apiRequest('api/v1/clients',
      {
        method: 'post',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(CLIENTS.CREATE_CLIENT_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-client', false));
  });
}

function* editClient() {
  yield takeLatest(CLIENTS.EDIT_CLIENT, function* (action) {
    yield put(appActions.setLoader('create-edit-client', true));
    const response = yield apiRequest(`api/v1/clients/${action.id}`,
      {
        method: 'put',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(CLIENTS.EDIT_CLIENT_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-client', false));
  });
}

function* deleteClient() {
  yield takeLatest(CLIENTS.DELETE_CLIENT, function* (action) {
    yield put(appActions.setLoader('delete-client', true));
    const response = yield apiRequest(`api/v1/clients/${action.id}`, { method: 'delete' });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    }
    const item = Object.values(response)[0];
    yield put(apiSuccess(CLIENTS.DELETE_CLIENT_SUCCESS, { item, type: 'clients' }));
    yield put(appActions.setLoader('delete-client', false));
  });
}

function* exportClients() {
  yield takeLatest(CLIENTS.EXPORT_CLIENT, function* (action) {
    yield put(appActions.setLoader('export-clients', true));
    const response = yield apiRequest('api/v1/clients/export', { method: 'get' }, false, 'clients.xlsx');
    yield put(apiSuccess(CLIENTS.EXPORT_CLIENT_SUCCESS, response));
    yield put(appActions.setLoader('export-clients', false));
  });
}

export default function* saga() {
  yield spawn(getStateFromApi);
  yield spawn(deleteClient);
  yield spawn(createClient);
  yield spawn(editClient);
  yield spawn(exportClients);
}
