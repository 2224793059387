import camelize from 'camelcase-keys-deep';
export default class Entity {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  allInventoryLines() {
    return this.env().InventoryLine.findAllBy('warehouseId', this.id);
  }

  allWarehouseMovements() {
    return this.env().WarehouseMovement.findAllBy('warehouseId', this.id);
  }
}
