const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paperAnchorLeft: {
    width: 300
  }
});

export default styles;
