import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectState, actionProps } from '../../../../utils/reduxActions';
import connectSaga from '../../../../utils/saga';
import saga from '../../saga';
// ui
import { Grid, Typography, Card, CardContent, List, Box } from '@material-ui/core';
// components
import Item from 'screens/Dispatches/components/ItemList/components/Item';
// icons
import CustomPagination from 'commons/components/CustomPagination';
import SkeletonList from 'commons/components/SkeletonList';
import { dispatchesActions } from 'screens/Dispatches/reducer';
import { truthty, clone } from 'utils/functions';
import { useIsMobileDevice } from 'utils/hooks/isMobileDevice';
import { Header } from 'commons/components/DataTable/Header';

const ItemList = (props) => {
  const {
    classes,
    pagination,
    loadingDispatches,
    objects,
    dispatchList,
    actions,
    inputSearch,
    headerList,
  } = props;

  const [dispatches, setDispatches] = useState([]);
  const isMobileDevice = useIsMobileDevice();

  useMemo(() => {
    setDispatches(Object.values(objects.dispatches).filter((d) => dispatchList.includes(d.id)));
  }, [dispatchList, objects.dispatches]);

  const handleChangeRowsPerPage = (e) => {
    actions.paginationControls(inputSearch, { ...pagination, rowsPerPage: e.target.value });
  };

  const handleChangePage = (e, val) => {
    actions.paginationControls(inputSearch, { ...pagination, page: val });
  };

  const clients = useMemo(() => {
    return [
      '',
      ...Object.values(objects.clients)
        .map((c) => {
          return { id: c.id, name: c.entity().name };
        })
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
    ];
  }, [objects.clients]);

  const suppliers = useMemo(() => {
    return [
      '',
      ...Object.values(objects.suppliers)
        .map((s) => {
          return { id: s.id, name: s.entity().name };
        })
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
    ];
  }, [objects.suppliers]);

  return (
    <Grid item xs={12}>
      <Card className={classes.root}>
        <CardContent>
          {truthty(dispatches) ? (
            <Grid container spacing={3} className={classes.container}>
              <Grid item xs={12}>
                {loadingDispatches ? (
                  <SkeletonList times={pagination.rowsPerPage} />
                ) : (
                  <List>
                    <Header headerList={headerList} isMobileDevice={isMobileDevice} />
                    {dispatches
                      .sort((a, b) => b.number - a.number)
                      .map((dispatch) => (
                        <Item
                          key={`dispatch-${dispatch.id}`}
                          cDispatch={dispatch}
                          classes={classes}
                          suppliers={suppliers}
                          clients={clients}
                          headerList={headerList}
                          isMobileDevice={isMobileDevice}
                        />
                      ))}
                  </List>
                )}
              </Grid>
              <CustomPagination
                rowsPerPage={pagination.rowsPerPage}
                page={pagination.page}
                count={pagination.count}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                loading={loadingDispatches}
              />
            </Grid>
          ) : (
            <Grid container item xs={12} alignItems="center" justifyContent="center">
              <Typography variant="h6" component="h2">
                No se han ingresado despachos
              </Typography>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

const withSaga = connectSaga({ key: 'sagaDispatches', saga });

const withConnect = connect(
  selectState(
    'dispatches.dispatchList',
    'dispatches.pagination',
    'dispatches.loadingDispatches',
    'dispatches.inputSearch',
    'app.objects',
  ),
  actionProps(clone(clone.OBJECT, { paginationControls: dispatchesActions.paginationControls })),
);

export default compose(withConnect)(withSaga(ItemList));
