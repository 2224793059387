import React from 'react';
// ui
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
// components
import DeleteModal from '../../../commons/components/DeleteModal';
import Filters from './Filters';
import PurchaseForm from './PurchaseForm';
import ItemTable from './ItemTable';
// utils
import { fCurrency } from '../../../utils/functions';

const PurchasesComponent = (props) => {
  const {
    actions,
    addServiceLine,
    changeControls,
    classes,
    clearFilters,
    closeForm,
    deleteLine,
    deleteIsOpen,
    edit,
    editLine,
    filter,
    filterPurchases,
    formControls,
    formIsOpen,
    handleClick,
    invalidControls,
    objects,
    onFilterChange,
    purchaseList,
    serviceLines,
    setEditLine,
    submit,
    submitDelete,
    supplyProductLines,
    toggleForm,
  } = props;

    const vatPercentage = Object.values(objects.accounts)[0]?.vatPercentage || 0.19;

  const filtersSection = (
    <Filters
      classes={classes}
      filter={filter}
      onFilterChange={onFilterChange}
      objects={objects}
      filterPurchases={filterPurchases}
      clearFilters={clearFilters}
    />
  );

  const purchaseListTotal = fCurrency.format(
    purchaseList.reduce((acum, p) => acum + p.getNetAmount() + p.iva + p.additionalTaxes, 0)
  );

  const purchaseListInfoSection = (
    <Grid item xs={12}>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={12} sm={6} md={3}>
          <div className={classes.totalLabel}>
            {`Cantidad: ${purchaseList.length}`}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className={classes.totalLabel}>
            {`Total: ${purchaseListTotal}`}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );

  const purchaseListTable = (
    <ItemTable
      classes={classes}
      handleClick={handleClick}
      purchaseList={purchaseList}
    />
  );

  const addPurchaseButton = (
    <Fab color="primary" aria-label="add" className={classes.fab} onClick={toggleForm}>
      <AddIcon />
    </Fab>
  );

  const createPurchaseForm = (
    <PurchaseForm
      isOpen={formIsOpen}
      toggleForm={toggleForm}
      changeControls={changeControls}
      controls={formControls}
      edit={edit}
      submit={submit}
      invalidControls={invalidControls}
      addServiceLine={addServiceLine}
      serviceLines={serviceLines}
      supplyProductLines={supplyProductLines}
      suppliers={objects.suppliers}
      supplyProducts={objects.supplyProducts}
      warehouses={objects.warehouses}
      deleteLine={deleteLine}
      setEditLine={setEditLine}
      editLine={editLine}
      clearEditLine={actions.clearEditLine}
      vatPercentage={vatPercentage}
    />
  );

  const deletePurchaseModal = (
    <DeleteModal isOpen={deleteIsOpen} toggleForm={closeForm} submit={submitDelete} buttonId="delete-purchase" />
  );

  return (
    <Container maxWidth="xl">
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h2" className={classes.marginTop}>
            Adquisiciones y egresos
          </Typography>
        </Grid>
        {filtersSection}
        {purchaseListInfoSection}
        {purchaseListTable}
      </Grid>
      {addPurchaseButton}

      {createPurchaseForm}
      {deletePurchaseModal}
    </Container>
  );
};

export default PurchasesComponent;
