import { withStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';

const StyledCardHeader = withStyles((theme) => ({
  root: {
    borderBottom: '3px ridge',
    borderBottomColor: theme.palette.primary.light,
    paddingTop: 8,
    paddingBottom: 8,
  },
}))(CardHeader);

export default StyledCardHeader;
