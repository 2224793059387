// @ts-nocheck
import React from 'react';
// material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
// utils
// containers
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
// icons
import FormInputs from './ReturnInputs';
import ToReturnLots from './ToReturnLots';
import ReturnedLots from './ReturnedLots';

const ReturnForm = props => (
  <Dialog fullWidth onClose={props.toggleForm} maxWidth="md" open={props.isOpen}>
    <DialogTitle>{`${props.edit ? 'Editar' : 'Crear'} Devolución`}</DialogTitle>
    <DialogContent>
      <Grid container spacing={1}>
        <FormInputs
          classes={props.classes}
          controls={props.controls}
          changeControls={props.changeControls}
          invalidControls={props.invalidControls}
          setDispatchLots={props.setDispatchLots}
        />
        <ToReturnLots
          classes={props.classes}
          controls={props.controls}
          dispatchLots={props.dispatchLots}
          addToReturnLots={props.addToReturnLots}
        />
        <ReturnedLots
          fruits={props.fruits}
          classes={props.classes}
          controls={props.controls}
          products={props.products}
          varieties={props.varieties}
          warehouses={props.warehouses}
          changeWeight={props.changeWeight}
          changeControls={props.changeControls}
          addReturnedLot={props.addReturnedLot}
          packagingTypes={props.packagingTypes}
          addToReturnLots={props.addToReturnLots}
          invalidControls={props.invalidControls}
          packagingQuantity={props.packagingQuantity}
          deleteReturnedLot={props.deleteReturnedLot}
        />
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.toggleForm} color="primary">
        Cancelar
      </Button>
      <ButtonWithLoader
        disabled={props.controls.returnedLots.length === 0}
        onClick={props.submit}
        autoFocus
        buttonId="create-edit-return"
      >
        {props.edit ? 'Editar' : 'Agregar'}
      </ButtonWithLoader>
    </DialogActions>
  </Dialog >
);

export default ReturnForm;
