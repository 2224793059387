// @ts-nocheck
import React, { useEffect } from 'react';
// material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, TableContainer, Paper, Table, TableHead, TableRow, TableBody } from '@material-ui/core';
// utils
import { fCurrency, fNumber } from 'utils/functions';
import moment from 'moment';
// components
import StyledTableCell from 'commons/components/StyledTableCell';
import { purchasesActions } from 'screens/Purchases/reducer';


const rowManagement = (warehouseMovements, adjustmentSupplierId, linesDialogType, inventoryLines, supplyProduct, toDate) => {


  if (linesDialogType === 'entries' || linesDialogType === 'departures' || (linesDialogType === '' && !supplyProduct?.traceable)) {
    return warehouseMovements.map(warehouseMovement => {
      return row(warehouseMovement, adjustmentSupplierId, linesDialogType)});
    }

  if (linesDialogType === '' && supplyProduct?.traceable) {
    return inventoryLines.map(inventoryLine => {
      return stockRow(inventoryLine, toDate)});
  }

}

const row = (warehouseMovement, adjustmentSupplierId, linesDialogType) => {
  let purchaseIdCell = null;
  const typeValue = warehouseMovement.type(adjustmentSupplierId);
  const dateValue = moment(warehouseMovement.date()).format('DD-MM-YYYY');
  
  if (typeValue === 'Sin proceso' || dateValue === 'Invalid date') {
    return null;
  }

  if (linesDialogType === 'entries' && warehouseMovement.supplyProduct().traceable) {

    purchaseIdCell = 
    <StyledTableCell> 
      {warehouseMovement.supplyProductLine().purchaseId} 
    </StyledTableCell>
  }
  if (linesDialogType === 'departures' && warehouseMovement.supplyProduct().traceable) {

      purchaseIdCell =
        <StyledTableCell>
          {warehouseMovement.supplyLine().purchaseId}
        </StyledTableCell>
  }
  
  return (
    <TableRow key={`warehouse-movement-${warehouseMovement.id}`}>
      <StyledTableCell component="th" scope="row">
        {warehouseMovement.warehouse().name}
      </StyledTableCell>
      <StyledTableCell>
        {warehouseMovement.supplyProduct().name}
      </StyledTableCell>
        {purchaseIdCell}
      <StyledTableCell>
        {warehouseMovement.type()}
      </StyledTableCell>
      <StyledTableCell style={{ width: '100px' }}>
        {moment(warehouseMovement.date()).format('DD-MM-YYYY')}
      </StyledTableCell>
      <StyledTableCell>
        {fNumber.format(warehouseMovement.line().quantity)}
      </StyledTableCell>
      <StyledTableCell align="right">
        {fCurrency.format(warehouseMovement.line().price)}
      </StyledTableCell>
    </TableRow>
  );

};

const stockRow = (inventoryLine, toDate) => {

    return (
      <TableRow key={`inventory-line-${inventoryLine.id}`}>
        <StyledTableCell component="th" scope="row">
          {inventoryLine.warehouse().name}
        </StyledTableCell>
        <StyledTableCell>
          {inventoryLine.product().name}
        </StyledTableCell>
        <StyledTableCell>
          {inventoryLine.purchaseId}
         </StyledTableCell>
        <StyledTableCell style={{ width: '100px' }}>
          {moment(toDate).format('DD-MM-YYYY')}
        </StyledTableCell>
        <StyledTableCell>
          {inventoryLine.initial + inventoryLine.inputs - inventoryLine.outputs} 
        </StyledTableCell>
        <StyledTableCell align="right">
          {fCurrency.format(inventoryLine.supplyProductLine().warehouseMovement().line().price)}
        </StyledTableCell>
      </TableRow>
    );
  }



const initialStockRow = (warehouseMovement, supplyProduct, toDate, inventoryLines) => {
  const warehouseId = warehouseMovement.id; 
  const supplyProductId = supplyProduct.id; 

  const initialStock = Object.values(inventoryLines)
    .filter(il => 
      il.warehouseId === warehouseId &&
      il.supplyProductId === supplyProductId
    )
    .reduce((acc, il) => acc + il.initial, 0);

  const costValues = Object.values(inventoryLines)
    .filter(il => 
        il.warehouseId === warehouseId &&
        il.supplyProductId === supplyProductId
    )
    .map(il => il.costValue);

  return (
    <TableRow key="initial-stock-row">
      <StyledTableCell component="th" scope="row">
        {warehouseMovement.name}
      </StyledTableCell>
      <StyledTableCell>
        {supplyProduct.name}
      </StyledTableCell>
        {supplyProduct.traceable ? <StyledTableCell>N/A</StyledTableCell> : null}
        {supplyProduct.traceable ? null : <StyledTableCell>Stock inicial</StyledTableCell>}
      <StyledTableCell style={{ width: '100px' }}>
        {`Hasta el`} <br /> {moment(toDate).format('DD-MM-YYYY')}
      </StyledTableCell>
      <StyledTableCell>
        {fNumber.format(initialStock)}
      </StyledTableCell>
      <StyledTableCell align="right">
      {costValues === 1 ? fCurrency.format(costValues): fCurrency.format(costValues[0])}
      </StyledTableCell>
    </TableRow>
  );
};

const header = (warehouseMovement, linesDialogType) => {
  
  const supplyProduct = warehouseMovement?.supplyProduct();
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell>Bodega</StyledTableCell>
        <StyledTableCell>Insumo</StyledTableCell>
        {supplyProduct?.traceable ? <StyledTableCell>ID Adquisición</StyledTableCell> : null}
        {(supplyProduct?.traceable && linesDialogType === '') ? null : <StyledTableCell>Tipo/Proceso</StyledTableCell>}
        <StyledTableCell>Fecha</StyledTableCell>
        <StyledTableCell>{linesDialogType === '' ? 'Stock' : 'Cantidad'}</StyledTableCell>
        <StyledTableCell align="right">Precio</StyledTableCell>
      </TableRow>
    </TableHead>
  );
};


const LineTable = props => {
  const filteredWarehouseMovements = Object.values(props.warehouseMovements)
  .filter(wm =>
    wm.warehouseId === props.linesDialog.warehouseId &&
    wm.supplyProductId === props.linesDialog.supplyProductId &&
    moment(wm.date()).isSameOrAfter(props.filterControls.fromDate) &&
    moment(wm.date()).isSameOrBefore(props.filterControls.toDate) &&
    (
      (props.linesDialog.type === 'entries' &&
        (wm.type(props.adjustmentSupplierId) === 'Compra' || wm.type(props.adjustmentSupplierId) === 'Ajuste: Aumento de Inventario')
      ) ||
      (props.linesDialog.type === 'departures' &&
        !(wm.type(props.adjustmentSupplierId) === 'Compra' || wm.type(props.adjustmentSupplierId) === 'Ajuste: Aumento de Inventario')
      ) ||
      (props.linesDialog.type === '')
    )
  )
  const warehouseMovement = filteredWarehouseMovements.length > 0 ? filteredWarehouseMovements[0] : null;
  const warehouse = warehouseMovement ? warehouseMovement.warehouse() : null;
  const supplyProduct = warehouseMovement ? warehouseMovement.supplyProduct() : null;

  const filteredInventoryLines = Object.values(props.inventoryLines)
  .filter(il =>
    il.warehouseId === props.linesDialog.warehouseId &&
    il.supplyProductId === props.linesDialog.supplyProductId
  );

  return (
  <Dialog
    fullWidth
    maxWidth="md"
    onClose={props.closeLinesDialog}
    open={props.linesDialog.isOpen}
  >
    <DialogTitle>Detalle de stock del producto</DialogTitle>
    <DialogContent>
      <Grid container item spacing={3} className={props.classes.container}>
        <Grid item xs={12} className={props.classes.tableContainer}>
          <TableContainer component={Paper}>
            <Table className={props.classes.table}>
              {header(warehouseMovement, props.linesDialog.type)}
              <TableBody>
              {props.linesDialog.type === '' && warehouse && supplyProduct &&
                initialStockRow(warehouse, supplyProduct, props.filterControls.toDate, props.inventoryLines)
              }
              {
              rowManagement(filteredWarehouseMovements, props.adjustmentSupplierId, props.linesDialog.type, filteredInventoryLines, supplyProduct, props.filterControls.toDatetoDate)
              }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeLinesDialog} color="primary">
        Cerrar
        </Button>
    </DialogActions>
  </Dialog>
)};

export default LineTable;

