import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Container, Grid, Typography } from '@material-ui/core';
import ItemList from 'screens/Lots/components/ItemList';
import Filters from 'screens/Lots/components/Filters';
import { selectState } from '../../../utils/reduxActions';
import connectSaga from '../../../utils/saga';
import saga from '../saga';
// ui
// components
import WarehouseMovementForm from './WarehouseMovementForm';
import { useIsMobileDevice } from 'utils/hooks/isMobileDevice';

const headerList = [
  { name: 'Número', width: '5%', mobileOrder: 1 },
  { name: 'Especie', width: '10%', mobileOrder: 2 },
  { name: 'Variedad', width: '10%', mobileOrder: 3 },
  { name: 'Producto', width: '10%', mobileOrder: 4 },
  { name: 'Fecha', width: '10%', mobileOrder: 5 },
  { name: 'Cantidad', width: '10%', mobileOrder: 6 },
  { name: 'Envases', width: '10%', mobileOrder: 7 },
  { name: 'Lote', width: '10%', mobileOrder: 8 },
  { name: 'Estado', width: '10%', mobileOrder: 9 },
  { name: '', width: '5%', mobileOrder: 10 },
];

const LotsComponent = (props) => {
  const warehouses = Object.values(props.objects.warehouses).filter((w) => !w.supplyWarehouse);
  const isMobileDevice = useIsMobileDevice();

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} className={props.classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h2" className={props.classes.marginTop}>
            Paks
          </Typography>
        </Grid>
        <Filters
          classes={props.classes}
          defaultFilters={props.defaultFilters}
          defaultPagination={props.defaultPagination}
        />
        <ItemList
          classes={props.classes}
          warehouses={warehouses}
          headerList={headerList}
          isMobileDevice={isMobileDevice}
        />
      </Grid>
      <WarehouseMovementForm warehouses={warehouses} />
    </Container>
  );
};

const withSaga = connectSaga({ key: 'sagaLots', saga });

const withConnect = connect(selectState('app.objects'), null);

export default compose(withConnect)(withSaga(LotsComponent));
