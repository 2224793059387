/* eslint-disable import/no-useless-path-segments */
// @ts-nocheck
import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// material
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
// Project
import { appActions } from '../../../commons/reducer';
import { actionProps, selectState } from '../../../utils/reduxActions';
import { ReactReduxContext } from '../../../config/configureStore';
import connectSaga from '../../../utils/saga';
import saga from '../../../commons/saga';
// components
import NavBar from '../../../commons/components/NavBar';
import SideBar from '../../../commons/components/SideBar';
import Footer from '../../../commons/components/Footer';
import FileExportComponent from "../../../commons/components/FileExport";
// utils
import { falsy } from '../../../utils/functions';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function logOut() {
  window.localStorage.clear();
  window.location.href = '/logout';
}

const Layout = ({
  // props
  zendesk,
  actions,
  children,
  device,
  deviceError,
  drawerIsOpen,
  error,
  errorMsg,
  history,
  role,
  scaleDevice,
  scaleDeviceError,
  searchPrinterLoading,
  searchScaleLoading,
  success,
  successMsg,
  queueProcess,
  queueData,
  exportingExcel,
  exportMsgIsOpen,
  exportExcelUrl,
}) => {
  useEffect(() => {
    if (falsy(deviceError)) {
      actions.searchPrinter();
    }
  }, [actions, deviceError]);

  useEffect(() => {
    if (falsy(scaleDeviceError)) {
      actions.searchWeightScale();
    }
  }, [actions, scaleDeviceError]);

  function toggleDrawer() {
    actions.toggleDrawer();
  }

  function toggleError() {
    actions.toggleError();
  }

  function toggleSuccess() {
    actions.toggleSuccess();
  }

  function handleItemClick(module) {
    return () => {
      history.push(module);
      actions.toggleDrawer();
    };
  }

  function searchPrinter() {
    actions.searchPrinter();
  }

  function searchWeightScale() {
    actions.searchWeightScale();
  }

  function clearNotifications() {
    actions.clearNotificationBox();
  }

  function loadFromNotifications(payload) {
    actions.loadFromNotification(payload);
  }

  function hideExport() {
    actions.toggleExportMsg();
  }

  return (
    <>
      <NavBar
        logOut={logOut}
        toggleDrawer={toggleDrawer}
        searchPrinter={searchPrinter}
        searchWeightScale={searchWeightScale}
        device={device}
        deviceError={deviceError}
        searchPrinterLoading={searchPrinterLoading}
        scaleDevice={scaleDevice}
        scaleDeviceError={scaleDeviceError}
        searchScaleLoading={searchScaleLoading}
        zendesk={zendesk}
        queueProcess={queueProcess}
        queueData={queueData}
        clearNotifications={clearNotifications}
        history={history}
        loadFromNotifications={loadFromNotifications}
      />
      <SideBar
        isOpen={drawerIsOpen}
        toggleDrawer={toggleDrawer}
        handleItemClick={handleItemClick}
        role={role}
      />
      <div className="content-container">
        <Grid container justifyContent="center">
          <Grid container item xs={11}>
            {children}
          </Grid>
        </Grid>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={error}
        autoHideDuration={5000}
        onClose={toggleError}>
        <Alert onClose={toggleError} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={success}
        autoHideDuration={5000}
        onClose={toggleSuccess}>
        <Alert onClose={toggleSuccess} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>
      <Footer />

      <FileExportComponent
        exportUrl={exportExcelUrl}
        hideExport={hideExport}
        open={exportMsgIsOpen}
        exportingFile={exportingExcel}>
      </FileExportComponent>
    </>
  );
};

// ------------------ CONFIG CONTAINER ------------------

const withSaga = connectSaga({ key: 'sagaApp', saga });

const withConnect = connect(
  selectState(
    'app.searchPrinterLoading',
    'app.deviceError',
    'app.device',
    'app.searchScaleLoading',
    'app.scaleDeviceError',
    'app.scaleDevice',
    'app.drawerIsOpen',
    'app.errorMsg',
    'app.error',
    'app.successMsg',
    'app.success',
    'app.role',
    'app.queueProcess',
    'app.queueData',
    'app.exportingExcel',
    'app.exportMsgIsOpen',
    'app.exportExcelUrl'
  ),
  actionProps(appActions),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withRouter,
  withConnect,
)(withSaga(Layout));
