/* eslint-disable react/jsx-filename-extension */
// @ts-nocheck
import React from 'react';
// material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from '@material-ui/core';
// containers
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';

const PaddockForm = (props) => (
  <Dialog fullWidth onClose={props.toggleForm} aria-labelledby="simple-dialog-title" open={props.isOpen}>
    <DialogTitle>{`${props.edit ? 'Editar' : 'Crear'} cuartel`}</DialogTitle>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            value={props.controls.name}
            onChange={props.changeControls}
            fullWidth
            id="name"
            label="Nombre"
            error={props.invalidControls.name}
            helperText={props.invalidControls.name && 'Debe ingresar un nombre'}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="fruit-label">Especie</InputLabel>
            <Select
              labelId="fruit-label"
              fullWidth
              value={props.controls.fruitId}
              onChange={props.changeControls}
              error={props.invalidControls.fruitId}
            >
              <MenuItem id="fruitId" value="">Seleccione una opción</MenuItem>
              {props.fruits.map((fruit) => (
                <MenuItem
                  key={`fruit-form-option-${fruit.id}`}
                  id="fruitId"
                  value={fruit.id}
                >
                  {fruit.name}
                </MenuItem>
              ))}
            </Select>
            {props.invalidControls.fruitId && <FormHelperText style={{ color: 'red' }}>Debe ingresar una especie</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="variety-label">Variedad</InputLabel>
            <Select
              labelId="variety-label"
              fullWidth
              value={props.controls.varietyId}
              onChange={props.changeControls}
              error={props.invalidControls.varietyId}
            >
              <MenuItem id="varietyId" value="">Seleccione una opción</MenuItem>
              {props.varieties.map((variety) => (
                <MenuItem
                  key={`variety-form-option-${variety.id}`}
                  id="varietyId"
                  value={variety.id}
                >
                  {variety.name}
                </MenuItem>
              ))}
            </Select>
            {props.invalidControls.varietyId && <FormHelperText style={{ color: 'red' }}>Debe ingresar una variedad</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="product-label">Producto</InputLabel>
            <Select
              labelId="product-label"
              fullWidth
              value={props.controls.productId}
              onChange={props.changeControls}
              error={props.invalidControls.productId}
            >
              <MenuItem id="productId" value="">Seleccione una opción</MenuItem>
              {Object.values(props.products).map((product) => (
                <MenuItem
                  key={`product-form-option-${product.id}`}
                  id="productId"
                  value={product.id}
                >
                  {product.name}
                </MenuItem>
              ))}
            </Select>
            {props.invalidControls.productId && <FormHelperText style={{ color: 'red' }}>Debe ingresar un producto</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.toggleForm} color="primary">
        Cancelar
      </Button>
      <ButtonWithLoader onClick={props.submit} autoFocus buttonId="create-edit-paddock">
        {props.edit ? 'Editar' : 'Agregar'}
      </ButtonWithLoader>
    </DialogActions>
  </Dialog>
);

export default PaddockForm;
