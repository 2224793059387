/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';


// Local constants
const PATH = 'units/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_EDIT_UNIT = `${PATH}TOGGLE_EDIT_UNIT`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;

// Global constants for saga
export const UNITS = {
  CREATE_UNIT: `${PATH}CREATE_UNIT`,
  CREATE_UNIT_SUCCESS: `${PATH}CREATE_UNIT_SUCCESS`,
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  DELETE_UNIT: `${PATH}DELETE_UNIT`,
  DELETE_UNIT_SUCCESS: `${PATH}DELETE_UNIT_SUCCESS`,
  DELETE_UNIT_FAIL: `${PATH}DELETE_UNIT_FAIL`,
  EDIT_UNIT_SUCCESS: `${PATH}EDIT_UNIT_SUCCESS`,
  EDIT_UNIT: `${PATH}EDIT_UNIT`,
  EXPORT_UNIT: `${PATH}EXPORT_UNIT`,
  EXPORT_UNIT_SUCCESS: `${PATH}EXPORT_UNIT_SUCCESS`,
};

// actions
export const unitsActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  toggleEdit: createAction(TOGGLE_EDIT_UNIT, 'item'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  // api
  getStateFromApi: createAction(UNITS.GET_STATE_FROM_API),
  createUnit: createAction(UNITS.CREATE_UNIT, 'controls'),
  editUnit: createAction(UNITS.EDIT_UNIT, 'id', 'controls'),
  deleteUnit: createAction(UNITS.DELETE_UNIT, 'id'),
  
  exportUnits: createAction(UNITS.EXPORT_UNIT),
};

const defaultInvalidInputs = {
  name: false,
  code: false,
};

const initialState = {
  formControls: {
    name: '',
    code: '',
  },
  invalidControls: {
    ...defaultInvalidInputs
  },
  formIsOpen: false,
  deleteIsOpen: false,
  toDeleteId: '',
  toEditId: '',
  edit: false,
  error: false,
};

const units = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.edit = false;
    state.formControls.name = '';
    state.formControls.code = '';
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {})
    };
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [TOGGLE_EDIT_UNIT](state, action) {
    state.formIsOpen = true;
    state.toDeleteId = '';
    state.toEditId = action.item.id;
    state.formControls.name = action.item.name;
    state.formControls.code = action.item.code;
    state.edit = true;
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    }
  },
  // api
  [UNITS.CREATE_UNIT_SUCCESS](state, action) {
    state.formIsOpen = false;
    state.formControls.name = '';
    state.formControls.code = '';
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [UNITS.DELETE_UNIT_SUCCESS](state, action) {
    state.deleteIsOpen = false;
    state.toDeleteId = '';
  },
  [UNITS.EDIT_UNIT_SUCCESS](state, action) {
    state.formControls.name = '';
    state.formControls.code = '';
    state.invalidControls = { ...defaultInvalidInputs };
    state.formIsOpen = false;
    state.toDeleteId = '';
    state.toEditId = '';
    state.edit = false;
  },
});

export default units;
