/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-unresolved */
import React from 'react';
// ui
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
// components
import StyledCardHeader from 'commons/components/StyledCardHeader';
import StyledTableCell from 'commons/components/StyledTableCell';
import { fCurrency, falsy } from 'utils/functions';
// icons
import DeleteIcon from '@material-ui/icons/Delete';

const SupplyProductLines = (props) => {
  const { classes, supplyLines } = props;
  return (
    <>
      <Card className={classes.subModule}>
        <StyledCardHeader
          title="Insumos"
          subheader={`Total: ${fCurrency.format(
            Object.values(supplyLines)
            .filter(sl => sl.processId === props.process.id)
            .reduce((acum, sl) => (
              acum + sl.line().quantity * sl.line().price
            ), 0)
          )
          }`}
        />
        <CardContent className={classes.processCardContent}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Producto</StyledTableCell>
                    <StyledTableCell align="right">Bodega</StyledTableCell>
                    <StyledTableCell align="center">ID Adquisición</StyledTableCell>
                    <StyledTableCell align="right">Cantidad</StyledTableCell>
                    <StyledTableCell align="right">Precio</StyledTableCell>
                    <StyledTableCell align="right">Subtotal</StyledTableCell>
                    {falsy(props.process.closedAt) && <StyledTableCell align="right" />}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(supplyLines)
                    .filter((sl) => sl.processId === props.process.id)
                    .map((sl) => (
                      <TableRow key={`departure-lot-${sl.id}`}>
                        <StyledTableCell component="th" scope="lot">
                          {sl.product()?.name}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {sl.warehouse()?.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {sl.purchaseId? sl.purchaseId : '-'}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {sl.line()?.quantity}
                          [
                          {sl.product()?.unit()?.code}
                          ]
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {fCurrency.format(sl.line()?.price)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {fCurrency.format(sl.line()?.quantity * sl.line()?.price)}
                        </StyledTableCell>
                        {
                          falsy(props.process.closedAt) &&
                          <StyledTableCell align="right">
                            <IconButton
                              onClick={props.toggleDelete}
                              data-id={sl.id}
                              data-module="deleteSupplyLine"
                              size="small"
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </StyledTableCell>
                        }
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default SupplyProductLines;