import React from 'react';
// ui
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
// components
import DeleteModal from 'commons/components/DeleteModal';
import ClientForm from './ClientForm';
import Filters from './Filters';
import ItemList from './ItemList';
import { truthty } from 'utils/functions';
import TableActionMenu from 'commons/components/TableActionMenu';

const ClientsComponent = props => (
  <Container maxWidth={false}>
    <Grid container spacing={3} className={props.classes.container}>
      <Grid item xs={12}>
        <Typography variant="h3" component="h2" className={props.classes.marginTop}>
          Clientes
        </Typography>
      </Grid>
      <Filters
        onFilterChange={props.onFilterChange}
        classes={props.classes}
        filter={props.filter}
      />
      <Grid container item>
          <Grid item xs={12} sm={4} md={3}>
            <TableActionMenu
              menuItems={[
                {
                  label: 'Exportar',
                  listItems: [{
                    label: 'Archivo Excel',
                    onClick: props.actions.exportClients,
                    params: [Object.values(props.clientList)],
                  }],
                },
              ]}
            />
          </Grid>
        </Grid>
      <ItemList
        classes={props.classes}
        toggleEdit={props.toggleEdit}
        toggleDelete={props.toggleDelete}
        clientList={props.clientList}
        anchorEl={props.anchorEl}
        handleClick={props.handleClick}
        handleClose={props.handleClose}
      />
    </Grid>
    <ClientForm
      isOpen={props.formIsOpen}
      toggleForm={props.toggleForm}
      changeControls={props.changeControls}
      onChange={props.actions.changeControls}
      controls={props.formControls}
      edit={props.edit}
      submit={props.submit}
      invalidControls={props.invalidControls}
    />
    <DeleteModal isOpen={props.deleteIsOpen} toggleForm={props.closeForm} submit={props.submitDelete} buttonId="delete-client" />
    <Fab color="primary" aria-label="add" className={props.classes.fab} onClick={props.toggleForm} >
      <AddIcon />
    </Fab>
    <Menu
      anchorEl={props.anchorEl}
      keepMounted
      open={truthty(props.anchorEl)}
      onClose={props.handleClose}
    >
      <MenuItem onClick={props.toggleEdit}>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        Editar
      </MenuItem>
      <MenuItem onClick={props.toggleDelete}>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        Eliminar
      </MenuItem>
    </Menu>
  </Container>
);

export default ClientsComponent;
