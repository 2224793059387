import camelize from 'camelcase-keys-deep';
export default class SupplyProduct {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  unit() {
    let response = null;
    if (this.unitId) {
      response =  this.env().Unit.findBy('id', this.unitId);
    }
    return response;
  }

  inventoryLine(warehouseId, purchaseId) {
    let response = null;
    if (this.id && warehouseId && !purchaseId) {
      response = this.env().InventoryLine.findBy('idSearch', Number(`${this.id}${warehouseId}`));
    }
    if (this.id && warehouseId && purchaseId) {
      response = this.env().InventoryLine.findBy('id', Number(`${this.id}${warehouseId}${purchaseId}`));
    }
    return response;
  }

  allInventoryLines(warehouseId, purchaseId) {
    let response = null;
    if (this.id && warehouseId && !purchaseId) {
      response = this.env().InventoryLine.findAllBy('idSearch', Number(`${this.id}${warehouseId}`));
    }
    if (this.id && warehouseId && purchaseId) {
      response = this.env().InventoryLine.findAllBy('id', Number(`${this.id}${warehouseId}${purchaseId}`));
    }
    return response;
  }

  allWarehouseMovements() {
    return this.env().WarehouseMovement.findAllBy('supplyProductId', this.id);
  }
}
