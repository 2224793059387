// @ts-nocheck
import React from 'react';
// MUI
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// ICONS
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
// PROJECT
import MaintainersPaginator from '../../../../commons/components/MaintainersPaginator';
import DeleteModal from '../../../../commons/components/DeleteModal';
import { truthty } from '../../../../utils/functions';

const List = (props) => {
  const {
    classes,
    toggleMenu,
    closeMenu,
    toggleEdit,
    toggleDelete,
    anchorEl,
    equipmentsList,
    deleteIsOpen,
    onDeleteEquipment,
  } = props;

  const getListItem = (warehouse, openMenuHandler) => (
    <ListItem key={`warehouse-${warehouse.id}`}>
      <ListItemAvatar>
        <Avatar className={classes.blue}>
          {(warehouse.name || 'p')[0].toUpperCase()}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={warehouse.name}
        secondary={`Código: ${warehouse.code}${warehouse.supplyWarehouse ? ', bodega de insumos' : ''}`}
      />
      <ListItemSecondaryAction>
        <IconButton
          data-id={warehouse.id}
          onClick={openMenuHandler}
        >
          <MoreVertIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <MaintainersPaginator
            classes={classes}
            list={equipmentsList}
            getListItem={getListItem}
            openMenuHandler={toggleMenu}
            closeMenuHandler={closeMenu}
            toggleEdit={toggleEdit}
            toggleDelete={toggleDelete}
          />
        </CardContent>
      </Card>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={truthty(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem onClick={toggleEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Editar
        </MenuItem>
        <MenuItem onClick={toggleDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Eliminar
        </MenuItem>
      </Menu>
      <DeleteModal isOpen={deleteIsOpen} toggleForm={toggleDelete} submit={onDeleteEquipment} buttonId="delete-equipment" />
    </>
  );
};

export default List;
