/* eslint-disable no-shadow */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
// @ts-nocheck
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
import { selectState, actionProps } from 'utils/reduxActions';
import { homeActions } from 'screens/Home/reducer';
import { ReactReduxContext } from 'config/configureStore';
import connectSaga from 'utils/saga';
import saga from './saga';
// utils
import { clone, falsy, onChangeFnc, truthty, validateFile } from 'utils/functions';
import { parseToDate } from 'utils/DateFormat';
import { processesActions } from 'screens/Processes/reducer';
import moment from 'moment';
// components
import ProcessesComponent from './components';
// styles
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { defaultProcesses } from 'utils/defaultProcesses';

const useStyles = makeStyles(styles);

const headerList = [
  { name: 'Número', width: '10%', mobileOrder: 1 },
  { name: 'Categoría', width: '10%', mobileOrder: 2 },
  { name: 'Tipo de proceso', width: '15%', mobileOrder: 2 },
  { name: 'Producto', width: '15%', mobileOrder: 3 },
  { name: 'Fecha de proceso', width: '10%', mobileOrder: 4 },
  { name: 'Creado por', width: '10%', mobileOrder: 6 },
  { name: 'Entradas', width: '10%', mobileOrder: 7 },
  { name: 'Salidas', width: '10%', mobileOrder: 8 },
  { name: 'Merma', width: '10%', mobileOrder: 9 },
  { name: '', width: '5%', mobileOrder: 10 },
];

const defaultFilters = {
  fromDate: parseToDate(new Date()).beginningOfMonth.toISOString(),
  toDate: parseToDate(new Date()).endOfMonth.toISOString(),
  processTypeId: '',
  workShiftTypeId: '',
  processCategoryId: '',
  productId: '',
  number: '',
  status: '',
  equipmentId: '',
  employeeIds: [],
};

const defaultPagination = {
  page: 0,
  rowsPerPage: 10,
  count: 0,
};

const Processes = (props) => {
  const [processList, setProcessList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    objects,
    filter,
    formIsOpen,
    actions,
    formControls,
    edit,
    invalidControls,
    deleteIsOpen,
    employeeCostsModal,
    file,
    updatedProcesses,
    processesIds,
    loaders,
    pagination,
    loadingProcess,
    socketId
  } = props;

  useEffect(() => {
    setProcessList(
      processesIds
        .map((id) => objects.processes[id])
        .filter((process) => truthty(process))
        .sort((a, b) => b.id - a.id),
    );
  }, [processesIds.length, objects]);

  useEffect(() => {
    const getPagination = pagination.page !== 0 ? pagination : defaultPagination;
    actions.getStateFromApi(filter, getPagination);
    // eslint-disable-next-line
  }, [actions]);

  // #region  --------------- Functions ---------------
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function toggleForm() {
    actions.toggleForm('formIsOpen');
  }

  function toggleEmployeeCostsModal() {
    actions.toggleForm('employeeCostsModal');
  }

  function closeForm() {
    actions.toggleForm('deleteIsOpen');
  }

  function toggleDelete() {
    handleClose();
    actions.toggleDelete(anchorEl.dataset.id);
  }

  function toggleEdit() {
    handleClose();
    const item = Object.values(objects.processes).find(
      (item) => item.id === Number(anchorEl.dataset.id),
    );
    actions.toggleEdit(item);
  }

  function changeControls(event, date) {
    onChangeFnc(actions.changeControls, event, date);
  }

  function submit() {
    if (falsy(formControls.processTypeId) || falsy(formControls.workShiftTypeId)) {
      actions.setInvalidControls({
        processTypeId: falsy(formControls.processTypeId),
        workShiftTypeId: falsy(formControls.workShiftTypeId),
      });
    } else if (edit) {
      actions.editProcess(props.toEditId, formControls);
    } else {
      actions.createProcess(formControls);
    }
  }

  function submitDelete() {
    actions.deleteProcess(props.toDeleteId);
  }

  function onFilterChange(event, date) {
    let input;
    let value;
    if (event.autocomplete) {
      input = event.input;
      value = event.value;
    } else {
      event.persist();
      input = falsy(event.target.type) ? event.currentTarget.id : event.target.id;
      value = event.target.type === 'date' ? date : event.target.value;
    }

    actions.changeFilter({
      [input]: value,
    });
  }

  function filterProcesses() {
    actions.getStateFromApi(filter, { ...defaultPagination });
  }

  function clearFilters() {
    actions.changeFilter({ ...defaultFilters });
    actions.paginationControls(defaultFilters, { ...defaultPagination });
  }

  function processOnClick(id) {
    return () => props.history.push(`/process/${id}`);
  }

  function changeEmployeeCostsFile(e) {
    const blob = e.target.files[0];
    const validFile = validateFile(blob, { extensions: ['xlsx', 'xls'] });
    if (validFile.status) {
      actions.changeEmployeeCostsFile(e.target.files[0]);
    } else {
      actions.employeeCostsRejected();
    }
    e.target.value = null;
  }

  function submitEmployeeCosts() {
    actions.submitEmployeeCosts(file, processesIds);
  }

  const classes = useStyles();

  // #endregion   --------------- Functions ---------------
  return (
    <ProcessesComponent
      classes={classes}
      filter={filter}
      onFilterChange={onFilterChange}
      objects={objects}
      toggleEdit={toggleEdit}
      toggleDelete={toggleDelete}
      processList={processList}
      formIsOpen={formIsOpen}
      toggleForm={toggleForm}
      changeControls={changeControls}
      actions={actions}
      formControls={formControls}
      edit={edit}
      submit={submit}
      invalidControls={invalidControls}
      filterProcesses={filterProcesses}
      clearFilters={clearFilters}
      submitDelete={submitDelete}
      closeForm={closeForm}
      deleteIsOpen={deleteIsOpen}
      handleClick={handleClick}
      handleClose={handleClose}
      anchorEl={anchorEl}
      processOnClick={processOnClick}
      toggleEmployeeCostsModal={toggleEmployeeCostsModal}
      employeeCostsModal={employeeCostsModal}
      changeEmployeeCostsFile={changeEmployeeCostsFile}
      submitEmployeeCosts={submitEmployeeCosts}
      file={file}
      updatedProcesses={updatedProcesses}
      loaders={loaders}
      pagination={pagination}
      loadingProcess={loadingProcess}
      headerList={headerList}
      socketId={socketId}
    />
  );
};

const withSaga = connectSaga({ key: 'sagaProcesses', saga });

const withConnect = connect(
  selectState(
    'processes.employeeCostsModal',
    'processes.updatedProcesses',
    'processes.invalidControls',
    'processes.processesIds',
    'processes.formControls',
    'processes.deleteIsOpen',
    'processes.toDeleteId',
    'processes.formIsOpen',
    'processes.toEditId',
    'processes.filter',
    'processes.file',
    'processes.pagination',
    'processes.loadingProcess',
    'processes.edit',
    'app.loaders',
    'app.objects',
    'app.socketId'
  ),
  actionProps(clone(clone.OBJECT, homeActions, processesActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(withStyles(styles), withRouter, withConnect)(withSaga(Processes));
