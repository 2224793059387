/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';


// Local constants
const PATH = 'workShiftTypes/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_EDIT_WORKSHIFT = `${PATH}TOGGLE_EDIT_WORKSHIFT`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;

// Global constants for saga
export const WORKSHIFTS = {
  CREATE_WORKSHIFT: `${PATH}CREATE_WORKSHIFT`,
  CREATE_WORKSHIFT_SUCCESS: `${PATH}CREATE_WORKSHIFT_SUCCESS`,
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  DELETE_WORKSHIFT: `${PATH}DELETE_WORKSHIFT`,
  DELETE_WORKSHIFT_SUCCESS: `${PATH}DELETE_WORKSHIFT_SUCCESS`,
  DELETE_WORKSHIFT_FAIL: `${PATH}DELETE_WORKSHIFT_FAIL`,
  EDIT_WORKSHIFT_SUCCESS: `${PATH}EDIT_WORKSHIFT_SUCCESS`,
  EDIT_WORKSHIFT: `${PATH}EDIT_WORKSHIFT`,
};

// actions
export const workShiftTypesActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  toggleEdit: createAction(TOGGLE_EDIT_WORKSHIFT, 'item'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  // api
  getStateFromApi: createAction(WORKSHIFTS.GET_STATE_FROM_API),
  createWorkShiftType: createAction(WORKSHIFTS.CREATE_WORKSHIFT, 'controls'),
  editWorkShiftType: createAction(WORKSHIFTS.EDIT_WORKSHIFT, 'id', 'controls'),
  deleteWorkShiftType: createAction(WORKSHIFTS.DELETE_WORKSHIFT, 'id')
};

const defaultInvalidInputs = {
  name: false,
};

const initialState = {
  formControls: {
    name: '',
  },
  invalidControls: {
    name: false,
  },
  formIsOpen: false,
  deleteIsOpen: false,
  toDeleteId: '',
  toEditId: '',
  edit: false,
  error: false,
};

const workShiftTypes = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.edit = false;
    state.formControls.name = '';
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {})
    };
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [TOGGLE_EDIT_WORKSHIFT](state, action) {
    state.formIsOpen = true;
    state.toDeleteId = '';
    state.toEditId = action.item.id;
    state.formControls.name = action.item.name;
    state.edit = true;
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    }
  },
  // api
  [WORKSHIFTS.CREATE_WORKSHIFT_SUCCESS](state, action) {
    state.formIsOpen = false;
    state.formControls.name = '';
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [WORKSHIFTS.DELETE_WORKSHIFT_SUCCESS](state, action) {
    state.deleteIsOpen = false;
    state.toDeleteId = '';
  },
  [WORKSHIFTS.EDIT_WORKSHIFT_SUCCESS](state, action) {
    state.formControls.name = '';
    state.invalidControls = { ...defaultInvalidInputs };
    state.formIsOpen = false;
    state.toDeleteId = '';
    state.toEditId = '';
    state.edit = false;
  },
});

export default workShiftTypes;
