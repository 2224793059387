/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
// @ts-nocheck
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
import { selectState, actionProps } from 'utils/reduxActions';
import { homeActions } from 'screens/Home/reducer';
import { ReactReduxContext } from 'config/configureStore';
import connectSaga from 'utils/saga';
import { packagingTypesActions } from 'screens/PackagingTypes/reducer';
import saga from './saga';
// utils
import { clone, falsy } from 'utils/functions';
// components
import PackagingTypesComponent from './components';
// styles
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import styles from './styles';

const useStyles = makeStyles(styles);

const PackagingTypes = (props) => {
  const [packagingTypeList, setPackagingTypeList] = useState([]);
  const [filter, setFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    objects,
    actions,
    formIsOpen,
    edit,
    invalidControls,
    deleteIsOpen,
    formControls,
  } = props;

  useEffect(() => {
    setPackagingTypeList(Object.values(objects.packagingTypes).filter((item) => item.name.toLowerCase().includes(filter.toLowerCase())));
  }, [objects.packagingTypes, filter]);

  useEffect(() => {
    actions.getStateFromApi();
  }, [actions]);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function toggleForm() {
    actions.toggleForm('formIsOpen');
  }

  function closeForm() {
    actions.toggleForm('deleteIsOpen');
  }

  function toggleDelete() {
    actions.toggleDelete(anchorEl.dataset.id);
    handleClose();
  }

  function toggleEdit() {
    const item = objects.packagingTypes[anchorEl.dataset.id];
    actions.toggleEdit(item);
    handleClose();
  }

  function changeControls(event) {
    actions.changeControls({ [event.currentTarget.id]: event.target.value }, { [event.currentTarget.id]: false });
  }

  function submit() {
    if (
      falsy(formControls.name)
      || falsy(formControls.weight)
    ) {
      actions.setInvalidControls({
        name: falsy(formControls.name),
        weight: falsy(formControls.weight),
      });
    } else if (edit) {
      actions.editPackagingType(props.toEditId, formControls);
    } else {
      actions.createPackagingType(formControls);
    }
  }

  function submitDelete() {
    actions.deletePackagingType(props.toDeleteId);
  }

  function onFilterChange(event) {
    setFilter(event.target.value);
  }

  const classes = useStyles();

  return (
    <PackagingTypesComponent
      classes={classes}
      filter={filter}
      onFilterChange={onFilterChange}
      toggleEdit={toggleEdit}
      toggleDelete={toggleDelete}
      packagingTypeList={packagingTypeList}
      formIsOpen={formIsOpen}
      toggleForm={toggleForm}
      changeControls={changeControls}
      actions={actions}
      edit={edit}
      submit={submit}
      invalidControls={invalidControls}
      deleteIsOpen={deleteIsOpen}
      closeForm={closeForm}
      submitDelete={submitDelete}
      formControls={formControls}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
    />
  );
};

const withSaga = connectSaga({ key: 'sagaPackagingTypes', saga });

const withConnect = connect(
  selectState(
    'packagingTypes.invalidControls',
    'packagingTypes.formControls',
    'packagingTypes.deleteIsOpen',
    'packagingTypes.toDeleteId',
    'packagingTypes.formIsOpen',
    'packagingTypes.toEditId',
    'packagingTypes.edit',
    'app.objects',
  ),
  actionProps(clone(clone.OBJECT, homeActions, packagingTypesActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect,
)(withSaga(PackagingTypes));
