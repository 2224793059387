// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import { takeLatest, spawn, put } from 'redux-saga/effects';

import apiRequest, { apiSuccess } from 'utils/api';
import { PROCESS_CATEGORIES } from '../reducer';
import { truthty, falsy } from 'utils/functions';
import { appActions } from 'commons/reducer';

function* getStateFromApi() {
  yield takeLatest(PROCESS_CATEGORIES.GET_STATE_FROM_API, function* (action) {
    const response = yield apiRequest('api/v1/processCategories', { method: 'get' });
    yield put(apiSuccess(PROCESS_CATEGORIES.GET_STATE_FROM_API_SUCCESS, response));
  });
}

function* createProcessCategory() {
  yield takeLatest(PROCESS_CATEGORIES.CREATE_PROCESS_CATEGORIES, function* (action) {
    yield put(appActions.setLoader('create-edit-processCategory', true));
    const response = yield apiRequest('api/v1/processCategories', {
      method: 'post',
      body: JSON.stringify(action.controls),
    });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PROCESS_CATEGORIES.CREATE_PROCESS_CATEGORIES_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-procesCategory', false));
  });
}

function* editProcessCategory() {
  yield takeLatest(PROCESS_CATEGORIES.EDIT_PROCESS_CATEGORIES, function* (action) {
    yield put(appActions.setLoader('create-edit-procesCategory', true));
    const response = yield apiRequest(`api/v1/processCategories/${action.id}`, {
      method: 'put',
      body: JSON.stringify(action.controls),
    });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PROCESS_CATEGORIES.EDIT_PROCESS_CATEGORIES_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-processCategory', false));
  });
}

function* deleteProcessCategory() {
  yield takeLatest(PROCESS_CATEGORIES.DELETE_PROCESS_CATEGORIES, function* (action) {
    yield put(appActions.setLoader('delete-processCategory', true));
    const response = yield apiRequest(`api/v1/processCategories/${action.id}`, {
      method: 'delete',
    });
    if (falsy(response.error)) {
      const item = Object.values(response)[0];
      yield put(
        apiSuccess(PROCESS_CATEGORIES.DELETE_PROCESS_CATEGORIES_SUCCESS, {
          item,
          type: 'processCategories',
        }),
      );
    } else {
      yield put(appActions.setError(response.errorMsg));
    }
    yield put(appActions.setLoader('delete-processCategories', false));
  });
}

export default function* saga() {
  yield spawn(getStateFromApi);
  yield spawn(deleteProcessCategory);
  yield spawn(createProcessCategory);
  yield spawn(editProcessCategory);
}
