/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
// @ts-ignore
import produce from 'immer';
import { withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { falsy, clone, validateRut, validateEmail } from '../../utils/functions';
import connectSaga from '../../utils/saga';
import { clientsActions } from './reducer';
import { ReactReduxContext } from '../../config/configureStore';
import { homeActions } from '../Home/reducer';
import { selectState, actionProps } from '../../utils/reduxActions';
import saga from './saga';
// components
import ClientsComponent from './components';
// styles
import styles from './styles';

// @ts-ignore
const useStyles = makeStyles(styles);

const Clients = (props) => {
  const account = Object.values(props.objects.accounts)[0] || {};
  const [clientList, setClientList] = useState([]);
  const [filter, setFilter] = useState({ name: '', taxNumber: '' });
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const filteredEntitiesIds = Object.values(props.objects.entities)
      .filter((item) => item.name.toLowerCase().includes(filter.name.toLowerCase()) && item.taxNumber.toLowerCase().includes(filter.taxNumber.toLowerCase()))
      .map((item) => item.id);
    const filteredClients = Object.values(props.objects.clients).filter((item) => filteredEntitiesIds.includes(item.entityId)).sort((a, b) => a.entity().name.toLowerCase().localeCompare(b.entity().name.toLowerCase()));
    setClientList(filteredClients);
  }, [props.objects.entities, props.objects.clients, filter]);

  useEffect(() => {
    props.actions.getStateFromApi();
  }, [props.actions]);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function toggleForm() {
    props.actions.toggleForm('formIsOpen');
  }

  function closeForm() {
    props.actions.toggleForm('deleteIsOpen');
  }

  function toggleDelete() {
    handleClose();
    props.actions.toggleDelete(anchorEl.dataset.id);
  }

  function toggleEdit() {
    handleClose();
    const client = Object.values(props.objects.clients)
      .find((item) => item.id === Number(anchorEl.dataset.id));
    const entity = props.objects.entities[client.entityId];
    props.actions.toggleEdit(entity, client);
  }

  function changeControls(event) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    props.actions.changeControls({ [event.target.id]: value }, { [event.target.id]: false });
  }

  function submit() {
    if (
      falsy(props.formControls.name)
      || falsy(props.formControls.taxNumber)
      || validateRut(props.formControls.taxNumber, account?.country)
      || (props.formControls.email ? !validateEmail(props.formControls.email) : false)) {
      props.actions.setInvalidControls({
        name: falsy(props.formControls.name),
        taxNumber: falsy(props.formControls.taxNumber)
          ? true
          : validateRut(props.formControls.taxNumber, account?.country),
        email: props.formControls.email ? !validateEmail(props.formControls.email) : false,
      });
    } else if (props.edit) {
      props.actions.editClient(props.toEditId, props.formControls);
    } else {
      props.actions.createClient(props.formControls);
    }
  }

  function submitDelete() {
    props.actions.deleteClient(props.toDeleteId);
  }

  function onFilterChange(event) {
    event.persist();
    setFilter(produce((draft) => {
      draft[event.target.id] = event.target.value;
    }));
  }

  const classes = useStyles();

  return (
    <ClientsComponent
      classes={classes}
      filter={filter}
      onFilterChange={onFilterChange}
      clientList={clientList}
      toggleEdit={toggleEdit}
      toggleDelete={toggleDelete}
      formIsOpen={props.formIsOpen}
      toggleForm={toggleForm}
      changeControls={changeControls}
      actions={props.actions}
      edit={props.edit}
      submit={submit}
      closeForm={closeForm}
      submitDelete={submitDelete}
      invalidControls={props.invalidControls}
      deleteIsOpen={props.deleteIsOpen}
      formControls={props.formControls}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
    />
  );
};

const withSaga = connectSaga({ key: 'sagaClients', saga });

const withConnect = connect(
  selectState(
    'clients.invalidControls',
    'clients.formControls',
    'clients.deleteIsOpen',
    'clients.toDeleteId',
    'clients.formIsOpen',
    'clients.toEditId',
    'clients.edit',
    'app.objects',
  ),
  actionProps(clone(clone.OBJECT, homeActions, clientsActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect,
)(withSaga(Clients));
