import React, { useState } from 'react';
// MATERIALS
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Typography from '@material-ui/core/Typography';
import AllInbox from '@material-ui/icons/AllInbox';

const defaultState = {
  isOpen: false,
  anchorEl: null,
};

const TableActionMenu = (props) => {
  const [state, setState] = useState(defaultState);

  const {
    menuItems,
  } = props;

  const handleClick = (event) => {
    setState({
      isOpen: !state.isOpen,
      anchorEl: event.target,
    });
  };

  const loadMenu = (labelMenu, listItem) => (
    [
      <MenuItem disabled><Typography variant="caption" component="caption">{labelMenu.toUpperCase()}</Typography></MenuItem>,
      listItem.map((item) => (
        <MenuItem
          disabled={item.disabled}
          onClick={() => {
            if (item.params) {
              item.onClick(...item.params);
            } else {
              item.onClick();
            }
            setState({ isOpen: false, anchorEl: null });
          }}
        >
          <Tooltip title={item.titleTooltip ? item.titleTooltip : ''}><div>{item.label}</div></Tooltip>
        </MenuItem>
      )),
    ]
  );

  const loadedMenuItems = menuItems.map((listMenu) => {
    const { label, listItems } = listMenu;
    return loadMenu(label, listItems);
  });

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="primary"
        variant="contained"
        fullWidth
        size="small"
        startIcon={<AllInbox />}
      >
        ACCIONES
      </Button>
      <Popover
        open={state.isOpen}
        anchorEl={state.anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClick}>
            <MenuList>
              {loadedMenuItems}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popover>
    </>
  );
};

export default TableActionMenu;
