// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest,
  spawn,
  put,
  fork,
  all,
  takeEvery
} from 'redux-saga/effects';

import apiRequest, { get, post, apiSuccess, update } from 'utils/api';
import controlledCall from 'utils/services/controlledSaga';
import { truthty } from 'utils/functions';
import { appActions } from 'commons/reducer';

import {
  EQUIPMENTS,
} from '../reducer';

function* getStateFromApi() {
  yield takeEvery(EQUIPMENTS.GET_STATE_FROM_API, function* (action) {
    yield controlledCall(get, 'api/v1/equipments', undefined, EQUIPMENTS.GET_STATE_FROM_API_SUCCESS);
  });
}

function* createEquipment() {
  yield takeEvery(EQUIPMENTS.CREATE_EQUIPMENT, function* (action) {
    yield controlledCall(post, 'api/v1/equipments/', action.controls, EQUIPMENTS.CREATE_EQUIPMENT_SUCCESS);
  });
}

function* deleteEquipment() {
  yield takeEvery(EQUIPMENTS.DELETE_EQUIPMENT, function* (action) {
    yield put(appActions.setLoader('delete-equipments', true));
    const response = yield apiRequest(`api/v1/equipments/${action.id}`, { method: 'delete' });

    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(EQUIPMENTS.DELETE_EQUIPMENT_SUCCESS, { response }));
    }

    yield put(appActions.setLoader('delete-equipments', false));
  });
}

function* updateEquipment() {
  yield takeEvery(EQUIPMENTS.UPDATE_EQUIPMENT, function* (action) {
    yield controlledCall(update, `api/v1/equipments/${action.controls.id}`, action.controls, EQUIPMENTS.UPDATE_EQUIPMENT_SUCCESS);
  });
}

export default function* saga() {
  yield all([
    fork(getStateFromApi),
    fork(createEquipment),
    fork(deleteEquipment),
    fork(updateEquipment),
  ]);
}
