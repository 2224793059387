import React from 'react';
// MUI
import withStyles from '@material-ui/core/styles/withStyles';
import teal from '@material-ui/core/colors/teal';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  tableHeader: {
    backgroundColor: teal[600],
  },
  tableHeaderCell: {
    color: 'white',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  longRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    minWidth: 200,
  },
  onClickCell: {
    '&:hover': {
      color: teal[900],
      textDecoration: `underline ${teal[900]}`,
    },
    cursor: 'pointer',
  },
});

const TableComponent = (props) => {
  const {
    input,
    rows,
    classes,
    headers,
  } = props;

  return (
    <>
      <Table size="medium">
        <TableHead>
          <TableRow className={classes.tableHeader}>
            {
              (headers || [])
                .filter((header) => header !== false)
                .map((header) => {
                  const label = typeof header === 'string' ? header : header.label;
                  const tooltip = typeof header === 'string' ? '' : header.tooltip;

                  return (
                    <Tooltip title={tooltip} placement="bottom-start" arrow>
                      <TableCell align="center" className={classes.tableHeaderCell} key={`header-${header.label}`}>{label}</TableCell>
                    </Tooltip>
                  );
                })
            }
          </TableRow>
        </TableHead>
        <TableBody>
          { input ? input(classes) : [] }
          {
            rows
              ? rows.map((row) => (
                <TableRow className={classes.row}>
                  {
                    row.map((obj) => {
                      if (obj.tooltip) {
                        return (
                          <Tooltip title={obj.tooltip || null}>
                            <TableCell className={obj.onClick ? classes.onClickCell : ''} align="center" onClick={obj.onClick}>{obj.label}</TableCell>
                          </Tooltip>
                        );
                      }

                      return <TableCell className={obj.onClick ? classes.onClickCell : ''} onClick={obj.onClick} align="center">{obj.label}</TableCell>;
                    })
                  }
                </TableRow>
              )) : []
          }
        </TableBody>
      </Table>
    </>
  );
};

export default withStyles(styles)(TableComponent);
