/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';

import apiRequest, { apiSuccess } from 'utils/api';
import { truthty } from 'utils/functions';
import { LOGIN } from '../reducer';

function* login() {
  yield takeLatest(LOGIN.LOGIN_USER, function* (action) {
    yield apiRequest('auth/oauth2', { method: 'get' });
  });
}

function* getUser() {
  yield takeLatest(LOGIN.GET_USER, function* () {
    const response = yield apiRequest('api/v1/users/user', { method: 'get' }, true);
    if (truthty(response)) {
      yield put(apiSuccess(LOGIN.GET_USER_SUCCESS, response.User));
      // window.localStorage.setItem('user', JSON.stringify(response.User));
      // window.localStorage.setItem('role', JSON.stringify(response.Role));
    } else {
      yield put(apiSuccess(LOGIN.GET_USER_FAIL));
    }
  });
}

export default function* root() {
  yield spawn(login);
  yield spawn(getUser);
}
