/* eslint-disable no-shadow */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-param-reassign */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Container,
  Fab,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import MaintainersPaginator from '../../commons/components/MaintainersPaginator';
import DeleteModal from '../../commons/components/DeleteModal';
import { productsActions } from './reducer';
import { clone, truthty, falsy } from '../../utils/functions';
import styles from './styles';
import ProductForm from './components/productForm';
import saga from './saga';
import connectSaga from '../../utils/saga';
import { ReactReduxContext } from '../../config/configureStore';
import { homeActions } from '../Home/reducer';
import { selectState, actionProps } from '../../utils/reduxActions';
import TableActionMenu from '../../commons/components/TableActionMenu';

const useStyles = makeStyles(styles);

const Products = (props) => {
  const {
    objects,
    actions,
    formIsOpen,
    formControls,
    edit,
    invalidControls,
    deleteIsOpen,
  } = props;

  const [productList, setProductList] = useState([]);
  const [filter, setFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setProductList(Object.values(objects.products).filter((item) => item.name.toLowerCase().includes(filter.toLowerCase())).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
  }, [objects.products, filter]);

  useEffect(() => {
    actions.getStateFromApi();
  }, [actions]);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function toggleForm() {
    actions.toggleForm('formIsOpen');
  }

  function closeForm() {
    actions.toggleForm('deleteIsOpen');
  }

  function toggleDelete() {
    handleClose();
    actions.toggleDelete(anchorEl.dataset.id);
  }

  function toggleEdit() {
    handleClose();
    const item = Object.values(objects.products)
      .find((item) => item.id === Number(anchorEl.dataset.id));
    actions.toggleEdit(item);
  }

  function changeControls(event) {
    if (event.autocomplete) {
      actions.changeControls({ [event.input]: event.value }, { [event.input]: false });
    } else {
      actions.changeControls({ [event.currentTarget.id]: event.target.value }, { [event.currentTarget.id]: false });
    }
  }

  function submit() {
    if (
      falsy(formControls.name)
      || falsy(formControls.code)
    ) {
      actions.setInvalidControls({
        name: falsy(formControls.name),
        code: falsy(formControls.code),
      });
    } else if (edit) {
      actions.editProduct(props.toEditId, formControls);
    } else {
      actions.createProduct(formControls);
    }
  }

  function submitDelete() {
    actions.deleteProduct(props.toDeleteId);
  }

  function onFilterChange(event) {
    setFilter(event.target.value);
  }

  const classes = useStyles();

  const getListItem = (product, openMenuHandler, closeMenuHandler, toggleEdit, toggleDelete) => (
    <ListItem key={`product-${product.id}`}>
      <ListItemAvatar>
        <Avatar className={classes.blue}>
          {(product.name || 'p')[0].toUpperCase()}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={product.name}
        secondary={product.code ? `Codigo: ${product.code}` : ''}
      />
      <ListItemSecondaryAction>
        <IconButton
          onClick={openMenuHandler}
          data-id={product.id}
        >
          <MoreVertIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h2" className={classes.marginTop}>
            Productos
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.root}>
            <CardHeader title="Filtro" />
            <CardContent>
              <Grid container spacing={3} className={classes.container}>
                <Grid container item xs={4}>
                  <TextField fullWidth id="standard-basic" label="Nombre" value={filter} onChange={onFilterChange} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid container item>
          <Grid item xs={12} sm={4} md={3}>
            <TableActionMenu
              menuItems={[
                {
                  label: 'Exportar',
                  listItems: [{
                    label: 'Archivo Excel',
                    onClick: actions.exportProducts,
                    params: [Object.values(objects.products)],
                  }],
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.root}>
            <CardContent>
              {truthty(objects.products) ? (
                <MaintainersPaginator
                  classes={classes}
                  list={productList}
                  getListItem={getListItem}
                  openMenuHandler={handleClick}
                  closeMenuHandler={handleClose}
                  toggleEdit={toggleEdit}
                  toggleDelete={toggleDelete}
                />
              ) : (
                <Grid container item xs={12} alignItems="center" justifyContent="center">
                  <Typography variant="h6" component="h2">
                    No se han ingresado productos
                  </Typography>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <ProductForm
        isOpen={formIsOpen}
        toggleForm={toggleForm}
        changeControls={changeControls}
        controls={formControls}
        units={objects.units}
        edit={edit}
        submit={submit}
        invalidControls={invalidControls}
        varieties={objects.varieties}
      />
      <DeleteModal isOpen={deleteIsOpen} toggleForm={closeForm} submit={submitDelete} buttonId="delete-product" />
      <Fab color="primary" aria-label="add" className={classes.fab} onClick={toggleForm}>
        <AddIcon />
      </Fab>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={truthty(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={toggleEdit}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Editar
        </MenuItem>
        <MenuItem onClick={toggleDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Eliminar
        </MenuItem>
      </Menu>
    </Container>
  );
};

const withSaga = connectSaga({ key: 'sagaProducts', saga });

const withConnect = connect(
  selectState(
    'products.invalidControls',
    'products.formControls',
    'products.deleteIsOpen',
    'products.toDeleteId',
    'products.formIsOpen',
    'products.toEditId',
    'products.edit',
    'app.objects',
  ),
  actionProps(clone(clone.OBJECT, homeActions, productsActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect,
)(withSaga(Products));
