// @ts-nocheck
import React from 'react';
// material
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// utils
import { truthty } from 'utils/functions';
// icons
import { Paper, List } from '@material-ui/core';
import LotListItem from '../LotListItem';

const ToReturnLots = props => (
  <Grid item container sm={6} xs={12} justifyContent="flex-start" direction="column" alignItems="stretch">
    <Grid item>
      <Typography gutterBottom variant="h6" component="h6">
        Paks en devolución
      </Typography>
    </Grid>
    <Grid item>
      <Paper style={{ padding: 30, margin: 10 }}>
        <List dense>
          {props.dispatchLots.map(l => (
            <LotListItem
              key={`to-return-lot-${l.id}`}
              lot={l}
              product={l.product()}
              classes={props.classes}
              addToReturnLots={props.addToReturnLots}
              checked={truthty(props.controls.toReturnLots[l.id])}
              checkbox
            />
          ))}
        </List>
      </Paper>
    </Grid>
  </Grid>
);

export default ToReturnLots;
