import { call, put } from 'redux-saga/effects';
import { appActions } from 'commons/reducer';
import { createAction } from 'utils/reducer';

export default function* controlledCall(toYield, path, body = undefined, succesFunction, failFunction = appActions.setError, isOpenFile = false) {
  const result = yield call(toYield, path, body);
  const successAction = typeof succesFunction === 'string' ? createAction(succesFunction, 'payload') : succesFunction;
  const failAction = typeof failFunction === 'string' ? createAction(succesFunction, 'msg') : failFunction;

  if (isOpenFile) {
    if (!result.ok) {
      const data = yield result.json();
      yield put(failFunction(data));
    }
  } else if (result.error) {
    yield put(failAction(result.errorMsg));
  } else {
    yield put(successAction(result));
  }

  return result;
}
