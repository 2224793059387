import { EXPORTING_EXCEL, TOGGLE_EXPORT_MSG } from '../../commons/reducer';
import { LOTS } from '../../screens/Lots/reducer'
import store from '../../index';

export default function (filter = {}, socketId = '') {
  store.dispatch({
    type: LOTS.GET_LOT_REPORT,
    filter,
    socketId
  });
  store.dispatch({
    type: EXPORTING_EXCEL
  })
  store.dispatch({
    type: TOGGLE_EXPORT_MSG
  })
}
