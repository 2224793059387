/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React from 'react';

// ui
import { Container, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
// components
import DeleteModal from 'commons/components/DeleteModal';
import ItemList from 'screens/Processes/components/ItemList';
import Filters from 'screens/Processes/components/Filters';
import EmployeeCostsModal from 'screens/Processes/components/EmployeeCostsModal';
import ProcessForm from './ProcessForm';
import TableActionMenu from 'commons/components/TableActionMenu';
import { Header } from 'commons/components/DataTable/Header';
import { useIsMobileDevice } from 'utils/hooks/isMobileDevice';

const ProcessesComponent = (props) => {
  const {
    classes,
    filter,
    onFilterChange,
    objects,
    filterProcesses,
    clearFilters,
    toggleEmployeeCostsModal,
    toggleEdit,
    toggleDelete,
    processList,
    handleClick,
    handleClose,
    anchorEl,
    processOnClick,
    employeeCostsModal,
    changeEmployeeCostsFile,
    submitEmployeeCosts,
    file,
    updatedProcesses,
    formIsOpen,
    changeControls,
    toggleForm,
    actions,
    formControls,
    edit,
    submit,
    invalidControls,
    deleteIsOpen,
    closeForm,
    submitDelete,
    anchorElId,
    loaders,
    pagination,
    loadingProcess,
    headerList,
    socketId
  } = props;

  const isMobileDevice = useIsMobileDevice();

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h2" className={classes.marginTop}>
            Procesos
          </Typography>
        </Grid>
        <Filters
          classes={classes}
          filter={filter}
          onFilterChange={onFilterChange}
          objects={objects}
          filterProcesses={filterProcesses}
          clearFilters={clearFilters}
          toggleEmployeeCostsModal={toggleEmployeeCostsModal}
          socketId={socketId}
        />
        <ItemList
          classes={classes}
          toggleEdit={toggleEdit}
          toggleDelete={toggleDelete}
          processList={processList}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorEl={anchorEl}
          anchorElId={anchorElId}
          processOnClick={processOnClick}
          loaders={loaders}
          pagination={pagination}
          filter={filter}
          actions={actions}
          loadingProcess={loadingProcess}
          headerList={headerList}
          isMobileDevice={isMobileDevice}
        />
      </Grid>
      <EmployeeCostsModal
        classes={classes}
        toggleModal={toggleEmployeeCostsModal}
        isOpen={employeeCostsModal}
        changeEmployeeCostsFile={changeEmployeeCostsFile}
        submitEmployeeCosts={submitEmployeeCosts}
        processListTotal={pagination.count}
        file={file}
        updatedProcesses={updatedProcesses}
        processes={objects.processes}
      />
      <ProcessForm
        isOpen={formIsOpen}
        toggleForm={toggleForm}
        changeControls={changeControls}
        onChange={actions.changeControls}
        controls={formControls}
        edit={edit}
        submit={submit}
        invalidControls={invalidControls}
        processTypes={objects.processTypes}
        workShiftTypes={objects.workShiftTypes}
        processCategories={objects.processCategories}
      />
      <DeleteModal
        isOpen={deleteIsOpen}
        toggleForm={closeForm}
        submit={submitDelete}
        buttonId="delete-paddock"
      />
      <DeleteModal
        isOpen={deleteIsOpen}
        toggleForm={closeForm}
        submit={submitDelete}
        buttonId="delete-paddock"
      />
      <Fab color="primary" aria-label="add" className={classes.fab} onClick={toggleForm}>
        <AddIcon />
      </Fab>
    </Container>
  );
};

export default ProcessesComponent;
