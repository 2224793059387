import React from 'react';
// MUI
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

const ProcessEquipments = (props) => {
  const {
    processEquipments,
    equipments,
    toggleDelete,
    process,
  } = props;

  const onDelete = (processEquipmentId) => {
    const e = {
      currentTarget: {
        dataset: {
          id: processEquipmentId,
          module: 'deleteProcessEquipment',
        },
      },
    };

    toggleDelete(e);
  };

  return (
    <Grid container spacing={1}>
      {Object.values(processEquipments).map((progressEquipment) => (
        <Grid item>
          <Chip
            label={equipments[progressEquipment.equipmentId]?.name}
            style={process?.closedAt ? {} : { backgroundColor: '#ffc400' }}
            onDelete={process?.closedAt ? null : () => onDelete(progressEquipment.id)}
            color="secondary"
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProcessEquipments;
