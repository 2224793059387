import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

// Project
import { selectState, actionProps } from 'utils/reduxActions';
import { employeesActions } from 'screens/Employees/reducer';
import { homeActions } from 'screens/Home/reducer';
import { ReactReduxContext } from 'config/configureStore';
import connectSaga from 'utils/saga';
import saga from './saga';
// components
import EmployeesComponent from './components';
// utils
import { clone, truthty, falsy, validateRut } from 'utils/functions';
import produce from 'immer';
// styles
import {  withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const Employees = props => {
  const account = Object.values(props.objects.accounts)[0] || {};
  const [employeeList, setEmployeeList] = useState([]);
  const [filter, setFilter] = useState({ name: '', taxNumber: '', subcontracted: '' });
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const filteredEntitiesIds = Object.values(props.objects.entities)
      .filter(item => item.name.toLowerCase().includes(filter.name.toLowerCase()) && item.taxNumber.toLowerCase().includes(filter.taxNumber.toLowerCase()))
      .map(item => item.id);
    const filteredEmployees = Object.values(props.objects.employees)
      .filter(item => filteredEntitiesIds.includes(item.entityId) && (filter.subcontracted === '' || truthty(item.subcontracted) === filter.subcontracted))
      .sort((a, b) => a.entity().name.toLowerCase().localeCompare(b.entity().name.toLowerCase()));
    setEmployeeList(filteredEmployees);
  }, [props.objects.entities, props.objects.employees, filter]);

  useEffect(() => {
    props.actions.getStateFromApi();
  }, [props.actions]);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  };

  function toggleForm() {
    props.actions.toggleForm('formIsOpen');
  }

  function closeForm() {
    props.actions.toggleForm('deleteIsOpen');
  }

  function toggleDelete() {
    handleClose();
    props.actions.toggleDelete(anchorEl.dataset.id);
  }

  function toggleEdit() {
    handleClose();
    const employee = Object.values(props.objects.employees)
      .find(item => item.id === Number(anchorEl.dataset.id));
    const entity = props.objects.entities[employee.entityId];
    props.actions.toggleEdit(entity, employee);
  }

  function changeControls(event) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    props.actions.changeControls({ [event.target.id]: value }, { [event.target.id]: false });
  }

  function submit() {
    if (falsy(props.formControls.name)
        || falsy(props.formControls.taxNumber)
        || validateRut(props.formControls.taxNumber, account?.country)) {
      props.actions.setInvalidControls({
        name: falsy(props.formControls.name),
        taxNumber: falsy(props.formControls.taxNumber)
          ? true
          : validateRut(props.formControls.taxNumber, account?.country),
      });
    } else if (props.edit) {
      props.actions.editEmployee(props.toEditId, props.formControls);
    } else {
      props.actions.createEmployee(props.formControls);
    }
  }

  function submitDelete() {
    props.actions.deleteEmployee(props.toDeleteId);
  }

  function onFilterChange(event) {
    event.persist();
    const id = event.target.type ? event.target.id : event.currentTarget.id;
    setFilter(produce(draft => {
      draft[id] = event.target.value;
    }));
  }

  const classes = useStyles();

  return (
    <EmployeesComponent
      classes={classes}
      filter={filter}
      onFilterChange={onFilterChange}
      employeeList={employeeList}
      toggleEdit={toggleEdit}
      toggleDelete={toggleDelete}
      formIsOpen={props.formIsOpen}
      toggleForm={toggleForm}
      changeControls={changeControls}
      actions={props.actions}
      formControls={props.formControls}
      edit={props.edit}
      submit={submit}
      invalidControls={props.invalidControls}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      closeForm={closeForm}
      submitDelete={submitDelete}
      deleteIsOpen={props.deleteIsOpen}
    />
  );
};

const withSaga = connectSaga({ key: 'sagaEmployees', saga });

const withConnect = connect(
  selectState(
    'employees.invalidControls',
    'employees.formControls',
    'employees.deleteIsOpen',
    'employees.toDeleteId',
    'employees.formIsOpen',
    'employees.toEditId',
    'employees.edit',
    'app.objects',
  ),
  actionProps(clone(clone.OBJECT, homeActions, employeesActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect,
)(withSaga(Employees));
