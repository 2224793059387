import { blueGrey } from '@material-ui/core/colors';

export const tableStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  active: {
    color: 'white!important'
  },
  icon: {
    color: 'white!important'
  },
});

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(10),
    right: theme.spacing(6),
  },
  blue: {
    color: theme.palette.getContrastText(blueGrey[500]),
    backgroundColor: blueGrey[500],
    fontFamily: [
      'Roboto'
    ]
  },
  filterButton: {
    marginTop: '16px'
  },
  marginTop: {
    marginTop: '24px'
  },
  tableContainer: {
    overflow: 'auto',
    maxWidth: '98vw',
  },
});

export default styles;
