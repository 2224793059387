import { EXPORTING_EXCEL, EXPORT_EXCEL, TOGGLE_EXPORT_MSG } from '../../commons/reducer';
import store from '../../index';

export default function (filters = {}, path = '', fileName = '', socketId = '') {
  store.dispatch({
    type: EXPORT_EXCEL,
    filters,
    path,
    fileName,
    socketId
  });
  store.dispatch({
    type: EXPORTING_EXCEL
  })
  store.dispatch({
    type: TOGGLE_EXPORT_MSG
  })
}
