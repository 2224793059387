/* eslint-disable import/order */
// @ts-nocheck
/* eslint-disable import/no-unresolved */
import { createStore, compose, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { createContext } from 'react';
import localForage from 'localforage';
// project
import { clone } from 'utils/functions';
import enhancers, { sagaMiddleware } from './middleware';
// reducers
import app from 'commons/reducer';
import login from 'screens/Login/reducer';
import products from 'screens/Products/reducer';
import suppliers from 'screens/Suppliers/reducer';
import clients from 'screens/Clients/reducer';
import workShiftTypes from 'screens/WorkShiftTypes/reducer';
import processCategories from 'screens/ProcessCategories/reducer';
import paddocks from 'screens/Paddocks/reducer';
import processTypes from 'screens/ProcessTypes/reducer';
import supplyProducts from 'screens/SupplyProducts/reducer';
import employees from 'screens/Employees/reducer';
import purchases from 'screens/Purchases/reducer';
import units from 'screens/Units/reducer';
import varieties from 'screens/Varieties/reducer';
import warehouses from 'screens/Warehouses/reducer';
import processes from 'screens/Processes/reducer';
import process from 'screens/Process/reducer';
import packagingTypes from 'screens/PackagingTypes/reducer';
import fruits from 'screens/Fruits/reducer';
import receipts from 'screens/Receipts/reducer';
import lots from 'screens/Lots/reducer';
import inventoryLines from 'screens/InventoryLines/reducer';
import dispatches from 'screens/Dispatches/reducer';
import productsReports from 'screens/ProductsReport/reducer';
import balance from 'screens/Balance/reducer';
import returns from 'screens/Returns/reducer';
import equipments from 'screens/Equipments/reducer';
import versionHistories from 'screens/VersionHistory/reducer';
import lotResume from 'screens/LotResume/reducer';
import lotTraceability from 'screens/LotTraceability/reducer';
//-----------------------------------------------------------

export const ReactReduxContext = createContext({});

export default function configureStore(initialState = {}) {
  const persistConfig = {
    key: 'root',
    storage: localForage,
    whitelist: [],
  };

  // create reducer
  const reducers = combineReducers(
    clone(clone.OBJECT, {
      versionHistories,
      equipments,
      productsReports,
      balance,
      packagingTypes,
      inventoryLines,
      workShiftTypes,
      processCategories,
      supplyProducts,
      processTypes,
      dispatches,
      warehouses,
      processes,
      varieties,
      purchases,
      employees,
      suppliers,
      products,
      paddocks,
      receipts,
      process,
      clients,
      returns,
      fruits,
      login,
      units,
      lots,
      app,
      lotResume,
      lotTraceability,
    }),
  );

  const pReducer = persistReducer(persistConfig, reducers);
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  const store = createStore(pReducer, initialState, composeEnhancers(enhancers));

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedSagas = {}; // Saga registry

  return store;
}
