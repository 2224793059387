// @ts-nocheck
import React, { useState } from 'react';
// material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, TableContainer, Paper, Table, TableHead, TableRow, TableBody } from '@material-ui/core';
// utils
import { fCurrency, fNumber } from 'utils/functions';
import moment from 'moment';
// components
import StyledTableCell from 'commons/components/StyledTableCell';

const CostMarginTable = props => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
    props.resetSelectedProductId();
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      // open={props.showCostMarginTable}
      open={open}
      onClose={handleClose}
    >
    <DialogTitle>Detalle costo del producto</DialogTitle>
      <DialogContent>
        <Grid container item spacing={3} className='{props.classes.container}'>
          <Grid item xs={12} className={props.classes.containerTable}>
            <TableContainer component={Paper}>
              <Table className='{props.classes.table}'>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Concepto de costo</StyledTableCell>
                    <StyledTableCell>Costo Total</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.accumulatedCost[props.selectedProductId] && (
                    <TableRow key={props.selectedProductId}>
                      <StyledTableCell>Personal</StyledTableCell>
                      <StyledTableCell>
                        {fCurrency.format(props.accumulatedCost[props.selectedProductId].employeeCost)}
                      </StyledTableCell>
                    </TableRow>
                  )}
                  {props.accumulatedCost[props.selectedProductId] && (
                    <TableRow key={props.selectedProductId}>
                      <StyledTableCell>Insumos</StyledTableCell>
                      <StyledTableCell>
                        {fCurrency.format(props.accumulatedCost[props.selectedProductId].supplyCosts)}
                      </StyledTableCell>
                    </TableRow>
                  )}
                  {props.accumulatedCost[props.selectedProductId] && (
                    <TableRow key={props.selectedProductId}>
                      <StyledTableCell>Merma</StyledTableCell>
                      <StyledTableCell>
                        {fCurrency.format(props.accumulatedCost[props.selectedProductId].wasteCost)}
                      </StyledTableCell>
                    </TableRow>
                  )}
                  {props.accumulatedCost[props.selectedProductId] && (
                    <TableRow key={props.selectedProductId}>
                      <StyledTableCell>Costo de materia prima</StyledTableCell>
                      <StyledTableCell>
                        {fCurrency.format(props.accumulatedCost[props.selectedProductId].receiptCost)}
                      </StyledTableCell>
                    </TableRow>
                  )}
                  {props.accumulatedCost[props.selectedProductId] && (
                    <TableRow key={props.selectedProductId}>
                      <StyledTableCell>Costo Total</StyledTableCell>
                      <StyledTableCell>
                        {fCurrency.format(props.accumulatedCost[props.selectedProductId].accumulatedCost)}
                      </StyledTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )};

export default CostMarginTable;
