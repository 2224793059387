import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ReactReduxContext } from 'config/configureStore';
import { actionProps, selectState } from 'utils/reduxActions';
import { appActions } from 'commons/reducer';
import { clone } from 'utils/functions';
import styles from './styles';
import { withStyles } from '@material-ui/core';

const Logout = props => {
  useEffect(() => {
    props.actions.logout();
  }, [props.actions]);

  return (<div></div>);
}

const withConnect = connect(
  selectState(
    'app.user',
  ),
  actionProps(clone(clone.OBJECT, appActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect,
)(Logout);