import { blueGrey } from '@material-ui/core/colors';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(10),
    right: theme.spacing(6),
  },
  blue: {
    color: theme.palette.getContrastText(blueGrey[500]),
    backgroundColor: blueGrey[500],
    fontFamily: ['Roboto'],
  },
  marginTop: {
    marginTop: '24px',
  },
  filterButton: {
    marginTop: '16px',
  },
  greenChip: {
    backgroundColor: theme.palette.primary.light,
    marginRight: '24px',
    maxWidth: '100%',
  },
  blueChip: {
    backgroundColor: theme.palette.info.light,
    marginRight: '8px',
    maxWidth: '100%',
  },
});

export default styles;
