/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-unresolved */
// @ts-nocheck
import React from 'react';
// material
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';

// icons
import MenuIcon from '@material-ui/icons/Menu';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
// styles
import { falsy, truthty } from 'utils/functions';
import styles from './styles';
import { useIsMobileDevice } from 'utils/hooks/isMobileDevice';

const useStyles = makeStyles(styles);

const NavBar = (props) => {
  const classes = useStyles();
  const {
    toggleDrawer,
    searchPrinter,
    device,
    deviceError,
    searchPrinterLoading,
    searchWeightScale,
    searchScaleLoading,
    scaleDevice,
    scaleDeviceError,
    withoutLogout,
    logOut,
    zendesk,
    queueProcess,
    queueData,
    clearNotifications,
    history,
    loadFromNotifications,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMobileDevice = useIsMobileDevice();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const trazabilityRedirect = () => {
    /* history.push({
      pathname: `/lotTraceability?lotIds[]=${queueData?.lotIds}`,
      state: { fromNoti: true },
    }); */
    /* history.push({
      pathname: '/lotTraceability',
      search: `?lotIds[]=${queueData?.lotIds}`,
      state: { fromNoti: true },
    }); */
    loadFromNotifications({ fromNoti: true });
    history.push(`/lotTraceability?lotIds[]=${queueData?.lotIds}`);
  };

  const handleZendesk = () => {
    // zendesk.startZendesk();
    zendesk.chasqikCreateMessenger();
  }

  return (
    <span className='no-print'>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            onClick={toggleDrawer}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Pakit
          </Typography>
          {truthty(searchPrinter) & !isMobileDevice ? (
            <Chip
              icon={
                device && truthty(device.productName) ? (
                  <CheckCircleOutlineIcon />
                ) : (
                  <ErrorOutlineIcon />
                )
              }
              label={device && truthty(device.productName) ? device.productName : deviceError}
              clickable
              color="secondary"
              className={classes.printStatus}
              onDelete={searchPrinter}
              deleteIcon={searchPrinterLoading ? <CircularProgress size={20} /> : <RefreshIcon />}
            />
          ) : null}
          {truthty(searchWeightScale) & !isMobileDevice ? (
            <Chip
              icon={
                scaleDevice && truthty(scaleDevice.productName) ? (
                  <CheckCircleOutlineIcon />
                ) : (
                  <ErrorOutlineIcon />
                )
              }
              label={
                scaleDevice && truthty(scaleDevice.productName)
                  ? scaleDevice.productName
                  : scaleDeviceError
              }
              clickable
              color="secondary"
              className={classes.printStatus}
              onDelete={searchWeightScale}
              deleteIcon={searchScaleLoading ? <CircularProgress size={20} /> : <RefreshIcon />}
            />
          ) : null}
          <IconButton>
            <Badge color="secondary" badgeContent={queueData?.lotIds ? 1 : 0} onClick={handleClick}>
              <NotificationsIcon fontSize="large" style={{ color: '#fff' }} />
            </Badge>
          </IconButton>

          {queueData?.lotIds && queueData?.lotIds.length > 0 && (
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              style={{ marginTop: 50 }}
              onClose={handleClose}>
              <MenuItem className={classes.menuItem} onClick={trazabilityRedirect}>
                {`La carga de trazabilidad pak ${queueData?.lotIds.map(
                  (id) => id,
                )} se ha completado`}
              </MenuItem>
              <MenuItem className={classes.cleanMenuItem} onClick={clearNotifications}>
                <Grid className={classes.cleanIconContainer}>
                  <DeleteIcon />
                  <span>LIMPIAR</span>
                </Grid>
              </MenuItem>
            </Menu>
          )}

          {truthty(zendesk) && falsy(zendesk.isShowingZendeskButton) && (
            <Tooltip title="Conversa con nosotros">
              <IconButton color="inherit" onClick={handleZendesk}>
                <ContactSupportOutlinedIcon
                  fontSize="large"
                  style={{
                    width: 40,
                    height: 40,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          <div id="zendeskDiv" />
          {!withoutLogout && (
            <Button onClick={logOut} color="inherit">
              Cerrar sesión
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </span>
  );
};

export default NavBar;
