import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectState, actionProps } from '../../../../../../utils/reduxActions';
import connectSaga from '../../../../../../utils/saga';
import { dispatchesActions } from '../../../../reducer';
import { appActions } from 'commons/reducer';
import saga from '../../../../saga';
import { clone } from '../../../../../../utils/functions';
import { AssignmentOutlined, HouseOutlined, AssignmentIndOutlined, CalendarTodayOutlined, LayersOutlined } from '@material-ui/icons';
import './styles.css';
// ui
import {
  ListItemSecondaryAction,
  ListItemAvatar,
  ListItemText,
  ListItem,
  Avatar,
  Grid,
  Typography,
  Link,
  Box,
} from '@material-ui/core';
// utils
import moment from 'moment';
// commons
import CollapsedItem from 'commons/components/CollapsedItem';
import ItemMenu from '../ItemMenu';
import Paths from 'utils/paths';
import { withRouter } from 'react-router-dom';
import { Row } from 'commons/components/DataTable/Row';
import { TruncateText } from 'commons/components/DataTable/TruncateText';
import { TruncateTextToolTip } from 'commons/components/DataTable/TruncateTextToolTip';

const Item = (props) => {
  const {
    cDispatch: dispatch,
    classes,
    objects,
    suppliers,
    clients,
    actions,
    account,
    device,
    history,
    headerList,
    isMobileDevice,
  } = props;
  console.log(isMobileDevice);

  const [isOpen, setIsOpen] = useState(null);
  const itemResources = useMemo(() => {
    const lots = dispatch.lots();
    const lotNumbers = [...new Set(lots.map((l) => l.lotNumbers()).flat())].sort((a, b) => a - b);
    const receipts = [...new Set(lots.map((l) => l.originReceipts()).flat())];
    const supplierIds = [...new Set(receipts.map((r) => r.supplierId))];
    const suppliersNames = suppliers
      .filter((s) => supplierIds.includes(s.id))
      .map((s) => s.name)
      .join(', ');
    const clientsName = clients.find((c) => c.id === dispatch.client().id)?.name;
    const dispatchNumber = dispatch.number;
    const date = moment(dispatch.process().date).format('DD-MM-YYYY');
    return { lots, date, dispatchNumber, lotNumbers, suppliersNames, clientsName };
  }, [dispatch]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDelete = () => {
    handleClose();
    actions.toggleDelete(anchorEl.dataset.id);
  };

  const toggleEdit = (id) => {
    handleClose();
    const item = Object.values(objects.dispatches).find(
      (item) => item.id === Number(id || anchorEl.dataset.id),
    );
    const process = Object.values(objects.processes).find((p) => p.id === item.processId);
    item['date'] = process.date;
    const lots = Object.values(objects.lots).filter(
      (lot) => lot.destinyProcessId === item.processId,
    );
    actions.toggleEdit(item, lots);
  };

  const printFnc = () => {
    const labelData = { lotIds: dispatch.lots().map((l) => l.id), accountId: account?.id };
    actions.printLabel(device, labelData);
  };

  const goToReception = (lotNumbers) => {
    const url = `${Paths.RECEIPTS}?lotNumber=${lotNumbers.join(',')}`;
    history.push(url);
  };

  const linkToReception = (lotNumbers, text) => {
    return (
      <Link style={{ cursor: 'pointer' }} onClick={() => goToReception(lotNumbers)}>
        {text}
      </Link>
    );
  };

  const getOptions = () => (
    <ItemMenu
      anchorEl={anchorEl}
      cDispatch={dispatch}
      handleClick={(e) => {
        e.stopPropagation();
        handleClick(e);
      }}
      handleClose={(e) => {
        e.stopPropagation();
        handleClose(e);
      }}
      toggleDelete={toggleDelete}
      toggleEdit={toggleEdit}
      printFnc={printFnc}
    />
  );

  const getArrayItem = () => [
    {
      value: `#${itemResources.dispatchNumber}`,
      icon: <AssignmentOutlined />,
    },
    {
      value: lotNumbersList(itemResources.lotNumbers),
      icon: <LayersOutlined />,
    },
    {
      value: <TruncateTextToolTip lines={3} text={itemResources.suppliersNames} />,
      icon: <HouseOutlined />,
    },
    {
      value: <TruncateText lines={2} text={itemResources.clientsName} />,
      icon: <AssignmentIndOutlined />,
    },
    {
      value: itemResources.date,
      icon: <CalendarTodayOutlined />,
    },
    {
      value: <Box className="row-options">{getOptions()}</Box>,
    },
  ];

  const lotNumbersList = (lotNumbers) => {
    const MAX_LOT_NUMBERS = 3;
    if (lotNumbers.length > MAX_LOT_NUMBERS) {
      let lotNumberText = `${lotNumbers.slice(0, MAX_LOT_NUMBERS).join(', ')}... `;
      return (
        <>
          {lotNumberText}
          {linkToReception(lotNumbers, `Ver más`)}
        </>
      );
    } else {
      return <>{linkToReception(lotNumbers, lotNumbers.join(', '))}</>;
    }
  };

  return (
    <>
      <Row
        row={getArrayItem()}
        headerList={headerList}
        handleOnClick={() => toggleEdit(dispatch.id)}
        isMobileDevice={isMobileDevice}
      />
    </>
  );
};

const withSaga = connectSaga({ key: 'sagaDispatches', saga });

const withConnect = connect(
  selectState('app.objects', 'app.account', 'app.device'),
  actionProps(
    clone(clone.OBJECT, {
      printLabel: appActions.printLabel,
      toggleEdit: dispatchesActions.toggleEdit,
      toggleDelete: dispatchesActions.toggleDelete,
    }),
  ),
);

export default compose(withRouter, withConnect)(withSaga(Item));
