// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';

import apiRequest, { apiSuccess } from 'utils/api';
import { VARIETIES } from '../reducer';
import { truthty } from 'utils/functions';
import { appActions } from 'commons/reducer';

function* getStateFromApi() {
  yield takeLatest(VARIETIES.GET_STATE_FROM_API, function* (action) {
    const response = yield apiRequest('api/v1/varieties', { method: 'get' });
    yield put(apiSuccess(VARIETIES.GET_STATE_FROM_API_SUCCESS, response));
  });
}

function* createVariety() {
  yield takeLatest(VARIETIES.CREATE_VARIETY, function* (action) {
    yield put(appActions.setLoader('create-edit-variety', true));
    const response = yield apiRequest('api/v1/varieties',
      {
        method: 'post',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(VARIETIES.CREATE_VARIETY_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-variety', false));
  });
}

function* editVariety() {
  yield takeLatest(VARIETIES.EDIT_VARIETY, function* (action) {
    yield put(appActions.setLoader('create-edit-variety', true));
    const response = yield apiRequest(`api/v1/varieties/${action.id}`,
      {
        method: 'put',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(VARIETIES.EDIT_VARIETY_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-variety', false));
  });
}

function* deleteVariety() {
  yield takeLatest(VARIETIES.DELETE_VARIETY, function* (action) {
    yield put(appActions.setLoader('delete-variety', true));
    const response = yield apiRequest(`api/v1/varieties/${action.id}`, { method: 'delete' });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    }
    const item = Object.values(response)[0];
    yield put(apiSuccess(VARIETIES.DELETE_VARIETY_SUCCESS, { item, type: 'varieties' }));
    yield put(appActions.setLoader('delete-variety', false));
  });
}

function* exportVarieties() {
  yield takeLatest(VARIETIES.EXPORT_VARIETIES, function* (action) {
    yield put(appActions.setLoader('export-varieties', true));
    const response = yield apiRequest('api/v1/varieties/export', { method: 'get' }, false, 'varieties.xlsx');
    yield put(apiSuccess(VARIETIES.EXPORT_VARIETIES_SUCCESS, response));
    yield put(appActions.setLoader('export-varieties', false));
  });
}

export default function* saga() {
  yield spawn(getStateFromApi);
  yield spawn(deleteVariety);
  yield spawn(createVariety);
  yield spawn(editVariety);
  yield spawn(exportVarieties);
}
