import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';

export const TruncateText = ({ text = '', lines = 1, classes = null, style = {} }) => {
  const typographyStyles = {
    ...{
      display: '-webkit-box',
      WebkitLineClamp: lines,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      maxWidth: '100%',
    },
    ...style,
  };

  return (
    <Typography style={typographyStyles} className={classes}>
      {text}
    </Typography>
  );
};
