// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  fork,
  all,
  takeEvery,
} from 'redux-saga/effects';

import { get, objectToQueryString } from 'utils/api';
import controlledCall from 'utils/services/controlledSaga';

import {
  LOT_TRACEABILITY,
} from '../reducer';

function* getStateFromApi() {
  yield takeEvery(LOT_TRACEABILITY.GET_STATE_FROM_API, function* (action) {
    yield controlledCall(get, 'api/v1/lotTraceability', objectToQueryString(action.controls), LOT_TRACEABILITY.GET_STATE_FROM_API_SUCCESS);
  });
}

export default function* saga() {
  yield all([
    fork(getStateFromApi),
  ]);
}
