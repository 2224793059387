import camelize from 'camelcase-keys-deep';
export default class InventoryLine { // warehousemovement
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  product() {
    let response = null;
    if (this.supplyProductId) {
      response = this.env().SupplyProduct.findBy('id', this.supplyProductId);
    }
    return response;
  }

  warehouse() {
    let response = null;
    if (this.warehouseId) {
      response = this.env().Warehouse.findBy('id', this.warehouseId);
    }
    return response;
  }

  supplyProductLine() {
    let response = null;
    if (this.purchaseId) {
      response = this.env().SupplyProductLine.findBy('purchaseId', this.purchaseId);
    }
    return response;
  }
}
