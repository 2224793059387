/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';


// Local constants
const PATH = 'processTypes/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_EDIT_PROCESS_TYPE = `${PATH}TOGGLE_EDIT_PROCESS_TYPE`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;

// Global constants for saga
export const PROCESS_TYPES = {
  CREATE_PROCESS_TYPE: `${PATH}CREATE_PROCESS_TYPE`,
  CREATE_PROCESS_TYPE_SUCCESS: `${PATH}CREATE_PROCESS_TYPE_SUCCESS`,
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  DELETE_PROCESS_TYPE: `${PATH}DELETE_PROCESS_TYPE`,
  DELETE_PROCESS_TYPE_SUCCESS: `${PATH}DELETE_PROCESS_TYPE_SUCCESS`,
  DELETE_PROCESS_TYPE_FAIL: `${PATH}DELETE_PROCESS_TYPE_FAIL`,
  EDIT_PROCESS_TYPE_SUCCESS: `${PATH}EDIT_PROCESS_TYPE_SUCCESS`,
  EDIT_PROCESS_TYPE: `${PATH}EDIT_PROCESS_TYPE`,
  EXPORT_PROCESS_TYPE: `${PATH}EXPORT_PROCESS_TYPE`,
  EXPORT_PROCESS_TYPE_SUCCESS: `${PATH}EXPORT_PROCESS_TYPE_SUCCESS`,
};

// actions
export const processTypesActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  toggleEdit: createAction(TOGGLE_EDIT_PROCESS_TYPE, 'item'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  // api
  getStateFromApi: createAction(PROCESS_TYPES.GET_STATE_FROM_API),
  createProcessType: createAction(PROCESS_TYPES.CREATE_PROCESS_TYPE, 'controls'),
  editProcessType: createAction(PROCESS_TYPES.EDIT_PROCESS_TYPE, 'id', 'controls'),
  deleteProcessType: createAction(PROCESS_TYPES.DELETE_PROCESS_TYPE, 'id'),

  exportProcessTypes: createAction(PROCESS_TYPES.EXPORT_PROCESS_TYPE),
};

const defaultInvalidInputs = {
  name: false,
};

const defaultControls = {
  name: '',
  allowMultipleLots: false,
  allowMultipleFruits: false,
};

const initialState = {
  formControls: { ...defaultControls },
  invalidControls: { ...defaultInvalidInputs },
  formIsOpen: false,
  deleteIsOpen: false,
  toDeleteId: '',
  toEditId: '',
  edit: false,
  error: false,
};

const processTypes = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.edit = false;
    state.formControls = { ...defaultControls };
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {})
    };
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [TOGGLE_EDIT_PROCESS_TYPE](state, action) {
    state.formIsOpen = true;
    state.toDeleteId = '';
    state.toEditId = action.item.id;
    state.formControls.name = action.item.name;
    state.formControls.allowMultipleLots = action.item.allowMultipleLots;
    state.formControls.allowMultipleFruits = action.item.allowMultipleFruits;
    state.edit = true;
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    }
  },
  // api
  [PROCESS_TYPES.CREATE_PROCESS_TYPE_SUCCESS](state, action) {
    state.formIsOpen = false;
    state.formControls = { ...defaultControls };
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [PROCESS_TYPES.DELETE_PROCESS_TYPE_SUCCESS](state, action) {
    state.deleteIsOpen = false;
    state.toDeleteId = '';
  },
  [PROCESS_TYPES.EDIT_PROCESS_TYPE_SUCCESS](state, action) {
    state.formControls = { ...defaultControls };
    state.invalidControls = { ...defaultInvalidInputs };
    state.formIsOpen = false;
    state.toDeleteId = '';
    state.toEditId = '';
    state.edit = false;
  },
});

export default processTypes;
