import React, { Fragment, useState } from 'react';
// ui
import Grid from '@material-ui/core/Grid';
// material ui
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, LinearProgress, TableSortLabel } from '@material-ui/core';
// utils
import { truthty, fCurrency, fNumber } from 'utils/functions';
// components
import StyledTableCell from 'commons/components/StyledTableCell';

const row = (il, getLines, loaders) => (
  <Fragment key={`inventory-line-loader-${il.id}`}>
    {loaders[`get-lines-${il.supplyProductId}${il.warehouseId}`] &&
      <TableRow>
        <StyledTableCell component="th" scope="row" colSpan={9} style={{ padding: 0 }}>
          <LinearProgress style={{ width: '100%' }} />
        </StyledTableCell>
      </TableRow>
    }
    <TableRow key={`inventory-line-${il.id}`}>
      <StyledTableCell component="th" scope="row">
        {il.purchaseId ? il.product.name : il.product().name}
      </StyledTableCell>
      <StyledTableCell>
        {il.purchaseId ? il.productUnit : il.product().unit().name}
      </StyledTableCell>
      <StyledTableCell>
        {il.purchaseId ? il.warehouse.name : il.warehouse().name}
      </StyledTableCell>
      <StyledTableCell>
        {fNumber.format(il.initial)}
      </StyledTableCell>
      <StyledTableCell>
        <Button
          data-warehouse-id={il.warehouseId}
          data-supply-product-id={il.supplyProductId}
          data-type="entries"
          style={{ padding: 0 }}
          onClick={getLines}
        >
          {fNumber.format(il.inputs)}
        </Button>
      </StyledTableCell>
      <StyledTableCell>
        <Button
          data-warehouse-id={il.warehouseId}
          data-supply-product-id={il.supplyProductId}
          data-type="departures"
          style={{ padding: 0 }}
          onClick={getLines}
        >
          {fNumber.format(il.outputs)}
        </Button>
      </StyledTableCell>
      <StyledTableCell>
        <Button
          data-warehouse-id={il.warehouseId}
          data-supply-product-id={il.supplyProductId}
          data-type=""
          style={{ padding: 0 }}
          onClick={getLines}
        >
          {fNumber.format(il.initial + il.inputs - il.outputs)}
        </Button>
      </StyledTableCell>
      <StyledTableCell>
        {il.costValue ? fCurrency.format(il.costValue) : '$0.00'}
      </StyledTableCell>
      <StyledTableCell align="right">
        {il.costValue ? fCurrency.format((il.initial + il.inputs - il.outputs) * il.costValue) : '$0.00'}
      </StyledTableCell>
    </TableRow>
  </Fragment>
);

function descendingComparator(a, b, orderBy) {
  if (orderBy === 1 || orderBy === 2) {
    const fnc = orderBy === 1 ? 'product' : 'warehouse';

  const getName = (obj) => {
    const value = obj[fnc];
    return typeof value === 'function' ? obj[fnc]().name : obj[fnc].name;
  };

  const value = a[fnc];

  if (getName(b) < getName(a)) {
    return -1;
  }
  if (getName(b) > getName(a)) {
    return 1;
  }
  return 0;
  } else {
    const valA = orderBy === 3 ?
      a.initial + a.inputs - a.outputs :
      orderBy === 4 ?
        a.costValue :
        (a.initial + a.inputs - a.outputs) * a.costValue;
    const valB = orderBy === 3 ?
      b.initial + b.inputs - b.outputs :
      orderBy === 4 ?
        b.costValue :
        (b.initial + b.inputs - b.outputs) * b.costValue;
    if (valB < valA) {
      return -1;
    }
    if (valB > valA) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const InventoryTable = props => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(1);

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  const groupedInventoryLines = Object.values(props.inventoryLines)
  .filter(il => props.inventoryLineIds.includes(il.id) && il.purchaseId)
  .reduce((acc, il) => {
    const key = il.idSearch;
    if (!acc[key]) {
      acc[key] = { ...il, inputs: 0, outputs: 0, cost: 0, count: 0, costValue: 0 };
    }
    acc[key].product = il.product();
    acc[key].warehouse = il.warehouse();
    acc[key].productUnit = il.product().unit().name;
    acc[key].initial += il.initial;
    acc[key].cost += il.costValue * (il.initial + il.inputs - il.outputs); ;
    acc[key].count += il.initial + il.inputs - il.outputs;
    acc[key].costValue = acc[key].cost/acc[key].count;
    acc[key].inputs += il.inputs;
    acc[key].outputs += il.outputs;
    return acc;
  }, {});

  const groupedInventoryLinesArray = Object.values(groupedInventoryLines);

  const nonTraceableInventoryLines = Object.values(props.inventoryLines)
    .filter(il => props.inventoryLineIds.includes(il.id) && !il.purchaseId);

  const combinedInventoryLines = [...groupedInventoryLinesArray, ...nonTraceableInventoryLines];

  const inventoryLinesSorted = Object.values(combinedInventoryLines).sort((a, b) => {
    const orderLines = getComparator(order, orderBy)(a, b);
    if (orderLines !== 0) return orderLines;
    return a - b;
  });


  return (
    <Grid item xs={12}>
      {!truthty(props.purchaseList) ? (
        <Grid container item spacing={3} className={props.classes.container}>
          <Grid item xs={12} className={props.classes.tableContainer}>
            <TableContainer component={Paper}>
              <Table className={props.classes.table}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell sortDirection={orderBy === 1 ? order : false}>
                      <TableSortLabel
                        classes={props.tableClasses}
                        active={orderBy === 1}
                        direction={orderBy === 1 ? order : 'asc'}
                        onClick={() => handleRequestSort(1)}
                      >
                        Producto
                    </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>Unidad</StyledTableCell>
                    <StyledTableCell sortDirection={orderBy === 2 ? order : false}>
                      <TableSortLabel
                        classes={props.tableClasses}
                        active={orderBy === 2}
                        direction={orderBy === 2 ? order : 'asc'}
                        onClick={() => handleRequestSort(2)}
                      >
                        Bodega
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>Inicial</StyledTableCell>
                    <StyledTableCell>Entradas</StyledTableCell>
                    <StyledTableCell>Salidas</StyledTableCell>
                    <StyledTableCell sortDirection={orderBy === 3 ? order : false}>
                      <TableSortLabel
                        classes={props.tableClasses}
                        active={orderBy === 3}
                        direction={orderBy === 3 ? order : 'asc'}
                        onClick={() => handleRequestSort(3)}
                      >
                        Stock
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell sortDirection={orderBy === 4 ? order : false}>
                      <TableSortLabel
                        classes={props.tableClasses}
                        active={orderBy === 4}
                        direction={orderBy === 4 ? order : 'asc'}
                        onClick={() => handleRequestSort(4)}
                      >
                        Valor unitario
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell sortDirection={orderBy === 5 ? order : false} align="right">
                      <TableSortLabel
                        classes={props.tableClasses}
                        active={orderBy === 5}
                        direction={orderBy === 5 ? order : 'asc'}
                        onClick={() => handleRequestSort(5)}
                      >
                        SubTotal
                      </TableSortLabel>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inventoryLinesSorted.map(il => row(il, props.getLines, props.loaders))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      ) : (
          <Card className={props.classes.root}>
            <CardContent>
              <Grid container item xs={12} alignItems="center" justifyContent="center">
                <Typography variant="h6" component="h2">
                  No se encontraron productos.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        )}
    </Grid>
  );
};

export default InventoryTable;
