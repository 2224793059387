/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-unresolved */
// @ts-nocheck
import React from 'react';
// material
import logo from 'utils/images/123x50.png';
import pakitLogo from 'utils/images/Logo-Pakit-big.png';
import Grid from '@material-ui/core/Grid';

const Footer = () => (
  <div className="footer" style={{ paddingLeft: '20px' }}>
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item>
        <img src={pakitLogo} alt="logo" className="footer-pakit-logo" />
        <img src={logo} alt="logo" className="footer-logo" />
      </Grid>
    </Grid>
  </div>
);

export default Footer;
