/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '../../../utils/reducer';

// Local constants
const PATH = 'equipments/';
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const TOGGLE_EDIT = `${PATH}TOGGLE_EDIT`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const INVALID_FORM_CONTROLS_CHANGED = `${PATH}INVALID_FORM_CONTROLS_CHANGED`;
const FORM_CONTROLS_CHANGED = `${PATH}FORM_CONTROLS_CHANGED`;

export const EQUIPMENTS = {
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,

  CREATE_EQUIPMENT: `${PATH}CREATE_EQUIPMENT`,
  CREATE_EQUIPMENT_SUCCESS: `${PATH}CREATE_EQUIPMENT_SUCCESS`,

  UPDATE_EQUIPMENT: `${PATH}UPDATE_EQUIPMENT`,
  UPDATE_EQUIPMENT_SUCCESS: `${PATH}UPDATE_EQUIPMENT_SUCCESS`,

  DELETE_EQUIPMENT: `${PATH}DELETE_EQUIPMENT`,
  DELETE_EQUIPMENT_SUCCESS: `${PATH}DELETE_EQUIPMENT_SUCCESS`,
};

export const equipmentActions = {
  toggleForm: createAction(TOGGLE_FORM),
  toggleEdit: createAction(TOGGLE_EDIT, 'item'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  invalidFormControlsChanged: createAction(INVALID_FORM_CONTROLS_CHANGED, 'controls'),
  formControlsChanged: createAction(FORM_CONTROLS_CHANGED, 'controls'),
  getStateFromApi: createAction(EQUIPMENTS.GET_STATE_FROM_API),
  createEquipment: createAction(EQUIPMENTS.CREATE_EQUIPMENT, 'controls'),
  updateEquipment: createAction(EQUIPMENTS.UPDATE_EQUIPMENT, 'controls'),
  deleteEquipment: createAction(EQUIPMENTS.DELETE_EQUIPMENT, 'id'),
};

const initialState = {
  formIsOpen: false,
  deleteIsOpen: false,
  toDeleteId: '',
  formControls: {
    id: '',
    name: '',
    code: '',
  },
  invalidFormControls: {
    name: false,
    code: false,
  },
};

const reducer = createReducer(initialState, {
  [TOGGLE_FORM](state) {
    state.formIsOpen = !state.formIsOpen;
    state.formControls = initialState.formControls;
    state.invalidFormControls = initialState.invalidFormControls;
  },
  [TOGGLE_EDIT](state, action) {
    const { id, name, code } = action.item;
    state.formIsOpen = true;
    state.formControls.id = id;
    state.formControls.name = name;
    state.formControls.code = code;
    state.invalidFormControls = initialState.invalidFormControls;
  },
  [INVALID_FORM_CONTROLS_CHANGED](state, action) {
    state.invalidFormControls = { ...initialState.invalidFormControls, ...action.controls };
  },
  [FORM_CONTROLS_CHANGED](state, action) {
    state.formControls = { ...state.formControls, ...action.controls };
  },
  [EQUIPMENTS.CREATE_EQUIPMENT_SUCCESS](state) {
    state.formControls = initialState.formControls;
    state.formIsOpen = false;
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [EQUIPMENTS.DELETE_EQUIPMENT_SUCCESS](state) {
    state.deleteIsOpen = false;
    state.toDeleteId = '';
  },
  [EQUIPMENTS.UPDATE_EQUIPMENT_SUCCESS](state) {
    state.formControls = initialState.formControls;
    state.formIsOpen = false;
  },
});

export default reducer;
