import React, { useState, useEffect } from 'react';

/* Project */
import { selectState } from 'utils/reduxActions';
import connectScreen from 'utils/connectScreen';
import getQueryParams from 'utils/services/getQueryParams';
import Components from './components';
import { lotTraceabilityActions } from './reducer';
import saga from './saga';
import styles from './styles';
import { appActions } from 'commons/reducer';
import { useLocation } from 'react-router-dom';

function LotTraceability(props) {
  const [dataSet, setDataSet] = useState([]);
  const [dataLabels, setDataLabels] = useState([]);
  const location = useLocation();

  const {
    actions,
    lotTraceability: { loading, traceabilityReport, controls },
    classes,
    history,
    queueProcess,
    queueData,
    loadFromNoti,
    socketId,
  } = props;

  useEffect(() => {
    const params = getQueryParams();
    actions.changeControls(params);

    if (!loadFromNoti) {
      actions.getStateFromApi({ ...params, socketId });
    }

    return () => {
      actions.loadFromNotification({ fromNoti: false });
      actions.resetQueueNotificationStatus();
    };
  }, [loadFromNoti]);

  const refreshData = () => {
    actions.getStateFromApi({ ...controls, redo: true, socketId });
  };

  const createDataSets = () => {
    const newDataSet = [];
    traceabilityReport.forEach((step, stepIndex) => {
      const nextStep = traceabilityReport[stepIndex + 1];
      const previousStep = traceabilityReport[stepIndex - 1];

      step.forEach((processInfo, processIndex) => {
        const nullArray = new Array(stepIndex).fill(null);
        const currentPosition = processIndex;
        const { processType, processId, documentNumber, fruitNames, returned } = processInfo;

        let label = `${documentNumber || processId} ${processType}`;
        if (fruitNames) label += ` (${fruitNames})`;
        if (returned) label += ' * (DEVUELTO)';

        let url = `/process/${processId}`;
        let nextPositions = [];
        let previousPositions = [];
        let currentBackGroundColor = '#8BC34A';
        let currentBorderColor = '#8BC34A';
        let currentColor = '#FFFFFF';

        if (processType === 'Despacho') {
          currentBackGroundColor = '#2979FF';
          currentBorderColor = '#2979FF';
          url = `/dispatches?dispatchNumber=${documentNumber}`;
        } else if (processType === 'Recepción') {
          currentBackGroundColor = '#FFFFFF';
          currentBorderColor = '#2979FF';
          currentColor = '#2979FF';
          url = `/receipts?lotNumber=${documentNumber}`;
        }

        if (processInfo.current) {
          currentBorderColor = 'red';
        }

        const hiddePointStyle = {
          label: null,
          borderWidth: 1,
          borderColor: '#358A7C',
          showLabel: false,
          fill: false,
          pointRadius: -1,
          pointHoverRadius: -1,
        };

        const displayPointStyle = {
          label,
          currentBorderColor,
          currentBackGroundColor,
          currentColor,
          pointBorderColor: -1,
          pointBackgroundColor: -1,
          showLabel: true,
          fill: false,
        };

        // Primero se diagraman los puntos con sus colores pero sin recorridos
        newDataSet.push({
          ...displayPointStyle,
          label,
          showLabel: true,
          data: [...nullArray, currentPosition],
          url,
        });

        // Luego se diagraman las lineas con tramos de puntos y dichos puntos estan ocultos, solo se ven las lineas
        if (nextStep && processInfo.nextProcessId) {
          const nextProcessesInfos = nextStep.filter((info) =>
            processInfo.nextProcessId.includes(info.processId),
          );
          nextPositions = nextProcessesInfos.map((info) =>
            nextStep.findIndex((pinfo) => pinfo.processId === info.processId),
          );
          nextPositions.forEach((position) => {
            newDataSet.push({
              data: [...nullArray, currentPosition, position],
              ...hiddePointStyle,
            });
          });
        }

        if (previousStep && processInfo.previousProcessId) {
          const previousProcessesInfos = previousStep.filter((info) =>
            processInfo.previousProcessId.includes(info.processId),
          );
          previousPositions = previousProcessesInfos.map((info) =>
            previousStep.findIndex((pinfo) => pinfo.processId === info.processId),
          );
          nullArray.pop();

          previousPositions.forEach((position) => {
            newDataSet.push({
              pointRadius: -1,
              pointHoverRadius: -1,
              data: [...nullArray, position, currentPosition],
              ...hiddePointStyle,
            });
          });
        }
      });
    });

    setDataLabels(new Array(traceabilityReport.length).fill(''));
    setDataSet(newDataSet);
  };

  useEffect(() => {
    if (traceabilityReport.length > 0 && !loading) {
      createDataSets();
    } else {
      setDataSet([]);
    }
  }, [traceabilityReport, loading]);

  const pointOnClick = (data) => {
    history.push(data.url);
  };

  return (
    <Components
      loading={loading}
      dataSet={dataSet}
      dataLabels={dataLabels}
      controls={controls}
      classes={classes}
      pointOnClick={pointOnClick}
      queueProcess={queueProcess}
      refreshData={refreshData}
    />
  );
}

export default connectScreen(
  'LotTraceability',
  LotTraceability,
  saga,
  { ...lotTraceabilityActions, ...appActions },
  selectState(
    'lotTraceability',
    'app.objects',
    'app.queueProcess',
    'app.queueData',
    'app.loadFromNoti',
    'app.socketId',
  ),
  styles,
);
