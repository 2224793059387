import React from 'react';
// material ui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
// icons
import SearchIcon from '@material-ui/icons/Search';
// components
import ButtonWithLoader from '../../../../commons/containers/ButtonWithLoader';
import DatePicker from '../../../../commons/components/DatePicker';

const Filters = (props) => {
  const {
    classes,
    filter,
    onFilterChange,
    objects,
    clearFilters,
    filterPurchases,
  } = props;

  return (
    <Grid item xs={12}>
      <Card className={classes.root}>
        <CardHeader title="Filtro" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item container spacing={3} className={classes.container}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id="date-type-label">Filtrar por fecha</InputLabel>
                  <Select
                    labelId="date-type-label"
                    fullWidth
                    value={filter.dateType}
                    onChange={onFilterChange}
                  >
                    <MenuItem id="dateType" value="issue_date">Contable</MenuItem>
                    <MenuItem id="dateType" value="expiration_date">Vencimiento</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <DatePicker
                  inputId="fromDate"
                  label="Desde"
                  fullWidth
                  value={filter.fromDate}
                  onChange={onFilterChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <DatePicker
                  inputId="toDate"
                  label="Hasta"
                  fullWidth
                  value={filter.toDate}
                  onChange={onFilterChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  value={filter.supplierId ? Object.values(objects.suppliers).find((s) => s.id === filter.supplierId) : null}
                  id="autocomplete-suppliers-filters-purchases"
                  options={Object.values(objects.suppliers).sort((a, b) => (a.entity().name.toLowerCase() > b.entity().name.toLowerCase() ? 1 : -1))}
                  getOptionLabel={(supplier) => supplier && supplier.entity().name}
                  renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Proveedor" margin="normal" />}
                  onChange={(event, newValue) => {
                    const e = {
                      autocomplete: true,
                      input: 'supplierId',
                      value: newValue ? newValue.id : '',
                    };
                    onFilterChange(e);
                  }}
                />
                {/* <FormControl fullWidth> */}
                {/* <InputLabel id="supplier-label">Proveedor</InputLabel> */}
                {/* <Select
                    labelId="supplier-label"
                    fullWidth
                    value={filter.supplierId}
                    onChange={onFilterChange}
                  >
                    <MenuItem id="supplierId" value="">Todos</MenuItem>
                    {Object.values(objects.suppliers).map((supplier) => (
                      <MenuItem
                        key={`supplier-filter-option-${supplier.id}`}
                        id="supplierId"
                        value={supplier.id}
                      >
                        {supplier.entity().name}
                      </MenuItem>
                    ))}
                  </Select> */}
                {/* </FormControl> */}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField fullWidth id="documentNumber" label="Número de documento" value={filter.documentNumber} onChange={onFilterChange} />
              </Grid>
            </Grid>
            <Grid container item spacing={3} justifyContent="flex-end">
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <ButtonWithLoader
                  fullWidth
                  onClick={clearFilters}
                  id="clear-purchases"
                  buttonId="clear-purchases"
                  className={classes.filterButton}
                  size="small"
                >
                  Limpiar
                </ButtonWithLoader>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <ButtonWithLoader
                  fullWidth
                  onClick={filterPurchases}
                  buttonId="get-purchases"
                  className={classes.filterButton}
                  size="small"
                  startIcon={<SearchIcon />}
                >
                  Buscar
                </ButtonWithLoader>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Filters;
