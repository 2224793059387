// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';

import apiRequest, { apiSuccess } from 'utils/api';
import { WAREHOUSES } from '../reducer';
import { truthty } from 'utils/functions';
import { appActions } from 'commons/reducer';

function* getStateFromApi() {
  yield takeLatest(WAREHOUSES.GET_STATE_FROM_API, function* (action) {
    const response = yield apiRequest('api/v1/warehouses', { method: 'get' });
    yield put(apiSuccess(WAREHOUSES.GET_STATE_FROM_API_SUCCESS, response));
  });
}

function* createWarehouseType() {
  yield takeLatest(WAREHOUSES.CREATE_WAREHOUSE, function* (action) {
    yield put(appActions.setLoader('create-edit-warehouse', true));
    const response = yield apiRequest('api/v1/warehouses',
      {
        method: 'post',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(WAREHOUSES.CREATE_WAREHOUSE_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-warehouse', false));
  });
}

function* editWarehouseType() {
  yield takeLatest(WAREHOUSES.EDIT_WAREHOUSE, function* (action) {
    yield put(appActions.setLoader('create-edit-warehouse', true));
    const response = yield apiRequest(`api/v1/warehouses/${action.id}`,
      {
        method: 'put',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(WAREHOUSES.EDIT_WAREHOUSE_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-warehouse', false));
  });
}

function* deleteWarehouseType() {
  yield takeLatest(WAREHOUSES.DELETE_WAREHOUSE, function* (action) {
    yield put(appActions.setLoader('delete-warehouse', true));
    const response = yield apiRequest(`api/v1/warehouses/${action.id}`, { method: 'delete' });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    }
    const item = Object.values(response)[0];
    yield put(apiSuccess(WAREHOUSES.DELETE_WAREHOUSE_SUCCESS, { item, type: 'warehouses' }));
    yield put(appActions.setLoader('delete-warehouse', false));
  });
}

function* exportWarehouse() {
  yield takeLatest(WAREHOUSES.EXPORT_WAREHOUSE, function* (action) {
    yield put(appActions.setLoader('export-warehouses', true));
    const response = yield apiRequest('api/v1/warehouses/export', { method: 'get' }, false, 'warehouses.xlsx');
    yield put(apiSuccess(WAREHOUSES.EXPORT_WAREHOUSE_SUCCESS, response));
    yield put(appActions.setLoader('export-warehouses', false));
  });
}

export default function* saga() {
  yield spawn(getStateFromApi);
  yield spawn(deleteWarehouseType);
  yield spawn(createWarehouseType);
  yield spawn(editWarehouseType);
  yield spawn(exportWarehouse);
}
