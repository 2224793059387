/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createReducer, createAction } from 'utils/reducer';
import { LOGIN } from 'screens/Login/reducer';
import { PRODUCTS } from 'screens/Products/reducer';
import { PROVIDERS } from 'screens/Suppliers/reducer';
import { CLIENTS } from 'screens/Clients/reducer';
import { WORKSHIFTS } from 'screens/WorkShiftTypes/reducer';
import { PADDOCKS } from 'screens/Paddocks/reducer';
import { PROCESS_TYPES } from 'screens/ProcessTypes/reducer';
import { SUPPLY_PRODUCTS } from 'screens/SupplyProducts/reducer';
import { EMPLOYEES } from 'screens/Employees/reducer';
import { PURCHASES } from 'screens/Purchases/reducer';
import { UNITS } from 'screens/Units/reducer';
import { VARIETIES } from 'screens/Varieties/reducer';
import { WAREHOUSES } from 'screens/Warehouses/reducer';
import { PROCESSES } from 'screens/Processes/reducer';
import { PROCESS } from 'screens/Process/reducer';
import { PACKAGING_TYPES } from 'screens/PackagingTypes/reducer';
import { FRUITS } from 'screens/Fruits/reducer';
import { RECEIPTS } from 'screens/Receipts/reducer';
import { LOTS } from 'screens/Lots/reducer';
import { INVENTORY_LINES } from 'screens/InventoryLines/reducer';
import { DISPATCHES } from 'screens/Dispatches/reducer';
import { PRODUCTS_REPORT } from 'screens/ProductsReport/reducer';
import { EQUIPMENTS } from 'screens/Equipments/reducer';
import { VERSION_HISTORIES } from 'screens/VersionHistory/reducer';
import { LOT_RESUME } from 'screens/LotResume/reducer';
// utils
import { normalizeObj, removeManyKeysByFilter, truthty } from 'utils/functions';
import { RETURNS } from 'screens/Returns/reducer';
import { LOT_TRACEABILITY } from 'screens/LotTraceability/reducer';
import { PROCESS_CATEGORIES } from 'screens/ProcessCategories/reducer';
import { Edit } from '@material-ui/icons';

// GLOBAL
export const GET_STATE_FROM_API = 'app/GET_API_FROM_STATE';
export const GET_STATE_FROM_API_SUCCESS = 'app/GET_API_FROM_STATE_SUCCESS';
export const LOG_OUT = 'app/LOG_OUT';
export const TOGGLE_DRAWER = 'app/TOGGLE_DRAWER';
export const TOGGLE_ERROR = 'app/TOGGLE_ERROR';
export const TOGGLE_SUCCESS = 'app/TOGGLE_SUCCESS';
export const SET_ERROR = 'app/SET_ERROR';
export const SET_SUCCESS = 'app/SET_SUCCESS';
export const SET_LOADER = 'app/SET_LOADER';
export const SEARCH_PRINTER = 'app/SEARCH_PRINTER';
export const SEARCH_PRINTER_SUCCESS = 'app/SEARCH_PRINTER_SUCCESS';
export const SEARCH_PRINTER_FAIL = 'app/SEARCH_PRINTER_FAIL';
export const PRINT_LABEL = 'app/PRINT_LABEL';
export const PRINT_LABEL_PAKS = 'app/PRINT_LABEL_PAKS'
export const PRINT_RECEPTION_LABEL = 'app/PRINT_RECEPTION_LABEL';
export const SEARCH_SCALE = 'app/SEARCH_SCALE';
export const SEARCH_SCALE_SUCCESS = 'app/SEARCH_SCALE_SUCCESS';
export const SEARCH_SCALE_FAIL = 'app/SEARCH_SCALE_FAIL';
export const READ_SCALE = 'app/READ_SCALE';
export const READ_SCALE_SUCCESS = 'app/READ_SCALE_SUCCESS';
export const READ_SCALE_FAIL = 'app/READ_SCALE_FAIL';
export const CAPTURE_WEIGHT = 'app/CAPTUTRE_WEIGHT';
export const GET_USER = 'app/GET_USER';
export const GET_USER_SUCCESS = 'app/GET_USER_SUCCESS';
export const GET_USER_FAIL = 'app/GET_USER_FAIL';
export const QUEUE_NOTIFICATION_STATUS = 'app/QUEUE_NOTIFICATION_STATUS';
export const QUEUE_NOTIFICATION_STATUS_RESET = 'app/QUEUE_NOTIFICATION_STATUS_RESET';
export const CLEAR_NOTIFICATION_BOX = 'app/CLEAR_NOTIFICATION_BOX';
export const LOAD_FROM_NOTIFICATION = 'app/LOAD_FROM_NOTIFICATION';
// sockets
export const ACTIVATE = 'app/ACTIVATE';
export const CONNECT_SOCKET_SUCCESS = 'app/CONNECT_SOCKET_SUCCESS';
export const OBJECTS_FROM_SOCKET_RECEIVED = 'app/OBJECTS_FROM_SOCKET_RECEIVED';
export const DELETE_OBJECTS_FROM_SOCKET_RECEIVED = 'app/DELETE_OBJECTS_FROM_SOCKET_RECEIVED';
export const SET_CONECTION_SOCKET_ID = 'app/SET_CONECTION_SOCKET_ID';

export const EXPORT_EXCEL = 'app/EXPORT_EXCEL';
export const EXPORT_EXCEL_SUCCESS = 'app/EXPORT_EXCEL_SUCCESS';

export const EXPORTING_EXCEL = 'app/EXPORTING_EXCEL'
export const EXPORT_EXCEL_READY = 'app/EXPORT_EXCEL_READY'
export const TOGGLE_EXPORT_MSG = 'app/TOGGLE_EXPORT_MSG'

export const EDIT_WEIGHT_SCALE = 'app/EDIT_WEIGHT_SCALE';

export const appActions = {
  // sockets
  activate: createAction(ACTIVATE),
  connectSocketSuccess: createAction(CONNECT_SOCKET_SUCCESS, 'socket'),
  objectsFromSocketReceived: createAction(OBJECTS_FROM_SOCKET_RECEIVED, 'payload'),
  deleteObjectsFromSocketReceived: createAction(DELETE_OBJECTS_FROM_SOCKET_RECEIVED, 'payload'), // api fetch
  setSocketId: createAction(SET_CONECTION_SOCKET_ID, 'socketId'),
  logout: createAction(LOG_OUT),
  setUser: createAction(LOGIN.GET_USER_SUCCESS, 'user', 'role'),
  getUser: createAction(GET_USER), // others
  toggleError: createAction(TOGGLE_ERROR),
  toggleSuccess: createAction(TOGGLE_SUCCESS),
  toggleDrawer: createAction(TOGGLE_DRAWER),
  setError: createAction(SET_ERROR, 'msg'),
  setSuccess: createAction(SET_SUCCESS, 'msg'),
  setLoader: createAction(SET_LOADER, 'id', 'state'),
  searchPrinter: createAction(SEARCH_PRINTER),
  printLabel: createAction(PRINT_LABEL, 'device', 'labelData'),
  printReceptionLabel: createAction(PRINT_RECEPTION_LABEL, 'device', 'labelData'),
  printLabelPaks: createAction(PRINT_LABEL_PAKS, 'device', 'labelData'),
  searchWeightScale: createAction(SEARCH_SCALE),
  readScale: createAction(READ_SCALE, 'device', 'fnc', 'lastWeight'),
  captureWeight: createAction(CAPTURE_WEIGHT, 'fnc'),
  getAppStateFromApi: createAction(GET_STATE_FROM_API),
  lotTraceabilitySocketSuccess: createAction(
    LOT_TRACEABILITY.GET_STATE_FROM_API_SUCCESS,
    'payload',
  ),
  productsReportSocketSuccess: createAction(PRODUCTS_REPORT.GET_STATE_FROM_API_SUCCESS, 'payload'),
  queueNotificationStatus: createAction(QUEUE_NOTIFICATION_STATUS, 'payload'),
  resetQueueNotificationStatus: createAction(QUEUE_NOTIFICATION_STATUS_RESET),
  clearNotificationBox: createAction(CLEAR_NOTIFICATION_BOX),
  loadFromNotification: createAction(LOAD_FROM_NOTIFICATION, 'payload'),
  exportingExcel: createAction(EXPORTING_EXCEL),
  exportExcelReady: createAction(EXPORT_EXCEL_READY, 'url'),
  toggleExportMsg: createAction(TOGGLE_EXPORT_MSG),
  editWeightScale: createAction(EDIT_WEIGHT_SCALE, 'scaleWeight', 'fnc'),

};

const defaultControls = {
  lotId: '',
  addPaksloading: false,
  accountId: null,
  lotIds: [],
  numPaks: null,
};

const initialState = {
  drawerIsOpen: false,
  loadingUser: true,
  socketId: null,
  errorMsg: '',
  error: false,
  successMsg: '',
  success: false,
  exportingExcel: false,
  exportExcelUrl: {},
  exportMsgIsOpen: false,
  exportExcelButton: true,
  objects: {
    accounts: {},
    products: {},
    suppliers: {},
    entities: {},
    clients: {},
    units: {},
    workShiftTypes: {},
    processCategories: {},
    paddocks: {},
    processTypes: {},
    purchases: {},
    supplyProducts: {},
    employees: {},
    varieties: {},
    warehouses: {},
    processes: {},
    processStaffLines: {},
    packagingTypes: {},
    fruits: {},
    receipts: {},
    receiptLots: {},
    lots: {},
    lotReceiptLots: {},
    inventoryLines: {},
    dispatches: {},
    returns: {},
    users: {},
    roles: {},
    equipments: {},
    processEquipments: {},
    versionHistories: {},
  },
  account: {},
  user: {},
  role: {},
  loaders: {},
  device: {},
  deviceError: '',
  searchPrinterLoading: false,
  scaleDevice: {},
  scaleDeviceError: '',
  searchScaleLoading: false,
  readingScale: false,
  queueProcess: 'loading',
  queueData: {},
  loadFromNoti: false,
  scaleWeight: '',
};

const mergeObjects = (state, action) => {
  const newObjects = {};
  Object.keys({ ...state.objects, ...action.payload.objects }).forEach((key) => {
    newObjects[key] = {
      ...state.objects[key],
      ...(action.payload.objects[key] || {}),
    };
  });
  state.objects = newObjects;
};

const deleteObject = (state, action) => {
  state.objects[action.payload.type] = normalizeObj(
    Object.values(state.objects[action.payload.type]).filter(
      (item) => item.id !== action.payload.item.id,
    ),
  );
};

const deleteObjects = (state, action) => {
  Object.keys(action.payload.response.ids).forEach((key) => {
    state.objects[key] = removeManyKeysByFilter(
      state.objects[key],
      action.payload.response.ids[key],
    );
  });
};

const app = createReducer(initialState, {
  // sockets
  [OBJECTS_FROM_SOCKET_RECEIVED]: mergeObjects,
  [DELETE_OBJECTS_FROM_SOCKET_RECEIVED]: deleteObjects, // success
  [LOT_RESUME.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [PRODUCTS.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [PRODUCTS.CREATE_PRODUCT_SUCCESS]: mergeObjects,
  [PRODUCTS.EDIT_PRODUCT_SUCCESS]: mergeObjects,
  [PRODUCTS.DELETE_PRODUCT_SUCCESS]: deleteObject,
  [PROVIDERS.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [PROVIDERS.CREATE_PROVIDER_SUCCESS]: mergeObjects,
  [PROVIDERS.EDIT_PROVIDER_SUCCESS]: mergeObjects,
  [PROVIDERS.DELETE_PROVIDER_SUCCESS]: deleteObject,
  [CLIENTS.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [CLIENTS.CREATE_CLIENT_SUCCESS]: mergeObjects,
  [CLIENTS.EDIT_CLIENT_SUCCESS]: mergeObjects,
  [CLIENTS.DELETE_CLIENT_SUCCESS]: deleteObject,
  [WORKSHIFTS.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [WORKSHIFTS.CREATE_WORKSHIFT_SUCCESS]: mergeObjects,
  [WORKSHIFTS.EDIT_WORKSHIFT_SUCCESS]: mergeObjects,
  [WORKSHIFTS.DELETE_WORKSHIFT_SUCCESS]: deleteObject,
  [PROCESS_CATEGORIES.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [PROCESS_CATEGORIES.CREATE_PROCESS_CATEGORIES_SUCCESS]: mergeObjects,
  [PROCESS_CATEGORIES.EDIT_PROCESS_CATEGORIES_SUCCESS]: mergeObjects,
  [PROCESS_CATEGORIES.DELETE_PROCESS_CATEGORIES_SUCCESS]: deleteObject,
  [PADDOCKS.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [PADDOCKS.CREATE_PADDOCK_SUCCESS]: mergeObjects,
  [PADDOCKS.EDIT_PADDOCK_SUCCESS]: mergeObjects,
  [PADDOCKS.DELETE_PADDOCK_SUCCESS]: deleteObject,
  [PROCESS_TYPES.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [PROCESS_TYPES.CREATE_PROCESS_TYPE_SUCCESS]: mergeObjects,
  [PROCESS_TYPES.EDIT_PROCESS_TYPE_SUCCESS]: mergeObjects,
  [PROCESS_TYPES.DELETE_PROCESS_TYPE_SUCCESS]: deleteObject,
  [SUPPLY_PRODUCTS.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [SUPPLY_PRODUCTS.CREATE_SUPPLY_PRODUCT_SUCCESS]: mergeObjects,
  [SUPPLY_PRODUCTS.EDIT_SUPPLY_PRODUCT_SUCCESS]: mergeObjects,
  [SUPPLY_PRODUCTS.DELETE_SUPPLY_PRODUCT_SUCCESS]: deleteObject,
  [EMPLOYEES.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [EMPLOYEES.CREATE_EMPLOYEE_SUCCESS]: mergeObjects,
  [EMPLOYEES.EDIT_EMPLOYEE_SUCCESS]: mergeObjects,
  [EMPLOYEES.DELETE_EMPLOYEE_SUCCESS]: deleteObject,
  [UNITS.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [UNITS.CREATE_UNIT_SUCCESS]: mergeObjects,
  [UNITS.EDIT_UNIT_SUCCESS]: mergeObjects,
  [UNITS.DELETE_UNIT_SUCCESS]: deleteObject,
  [FRUITS.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [FRUITS.CREATE_FRUIT_SUCCESS]: mergeObjects,
  [FRUITS.EDIT_FRUIT_SUCCESS]: mergeObjects,
  [FRUITS.DELETE_FRUIT_SUCCESS]: deleteObject,
  [WAREHOUSES.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [WAREHOUSES.CREATE_WAREHOUSE_SUCCESS]: mergeObjects,
  [WAREHOUSES.EDIT_WAREHOUSE_SUCCESS]: mergeObjects,
  [WAREHOUSES.DELETE_WAREHOUSE_SUCCESS]: deleteObject,
  [VARIETIES.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [VARIETIES.CREATE_VARIETY_SUCCESS]: mergeObjects,
  [VARIETIES.EDIT_VARIETY_SUCCESS]: mergeObjects,
  [VARIETIES.DELETE_VARIETY_SUCCESS]: deleteObject,
  [LOTS.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [LOTS.SUBMIT_WAREHOUSE_MOVEMENT_SUCCESS]: mergeObjects,
  [RETURNS.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [RETURNS.CREATE_RETURN_SUCCESS]: mergeObjects,
  [RETURNS.DELETE_RETURN_SUCCESS]: deleteObjects,
  [PACKAGING_TYPES.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [PACKAGING_TYPES.CREATE_PACKAGING_TYPE_SUCCESS]: mergeObjects,
  [PACKAGING_TYPES.EDIT_PACKAGING_TYPE_SUCCESS]: mergeObjects,
  [PACKAGING_TYPES.DELETE_PACKAGING_TYPE_SUCCESS]: deleteObject,
  [RECEIPTS.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [RECEIPTS.CREATE_RECEIPT_SUCCESS]: mergeObjects,
  [RECEIPTS.EDIT_RECEIPT_SUCCESS]: mergeObjects,
  [RECEIPTS.EDIT_RECEIPT_DATA_SUCCESS]: mergeObjects,
  [RECEIPTS.CREATE_RECEIPT_LOTS_SUCCESS]: mergeObjects,
  [RECEIPTS.DELETE_RECEIPT_SUCCESS]: deleteObject,
  [RECEIPTS.DELETE_LOT_SUCCESS]: deleteObject,
  [PROCESS.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [PROCESS.ADD_PROCESS_ITEM_SUCCESS]: mergeObjects,
  [PROCESS.ADD_SUPPLY_LINE_SUCCESS]: mergeObjects,
  [PROCESS.CLOSE_PROCESS_SUCCESS]: mergeObjects,
  [PROCESS.LOAD_PROCESS_RESOURCES_SUCCESS]: mergeObjects,
  [PROCESS.DELETE_PROCESS_ITEM_SUCCESS]: deleteObject,
  [PROCESS.DELETE_SUPPLY_LINE_SUCCESS]: deleteObjects,
  [PROCESS.DELETE_PROCESS_EQUIPMENT_SUCCESS]: deleteObjects,
  [PROCESSES.SUBMIT_EMPLOYEE_COSTS_SUCCESS]: mergeObjects,
  [PROCESSES.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [PROCESSES.CREATE_PROCESS_SUCCESS]: mergeObjects,
  [PROCESSES.EDIT_PROCESS_SUCCESS]: mergeObjects,
  [PROCESSES.DELETE_PROCESS_SUCCESS]: deleteObject,
  [DISPATCHES.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [DISPATCHES.CREATE_DISPATCH_SUCCESS]: mergeObjects,
  [DISPATCHES.NEW_DISPATCH_SUCCESS]: mergeObjects,
  [DISPATCHES.EDIT_DISPATCH_SUCCESS]: mergeObjects,
  [DISPATCHES.DELETE_DISPATCH_SUCCESS](state, action) {
    mergeObjects(state, action);
    deleteObject(state, {
      payload: { type: 'dispatches', item: { id: action.payload.dispatchToDelete.id } },
    });
  },
  [PURCHASES.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [PURCHASES.CREATE_PURCHASE_SUCCESS]: mergeObjects,
  [PURCHASES.DELETE_PURCHASE_SUCCESS]: deleteObject,
  [PROCESS.EDIT_LOT_SUCCESS]: mergeObjects,
  [INVENTORY_LINES.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [INVENTORY_LINES.GET_LINES_SUCCESS]: mergeObjects,
  [VERSION_HISTORIES.GET_STATE_FROM_API_SUCCESS]: mergeObjects,

  [PURCHASES.EDIT_PURCHASE_SUCCESS](state, action) {
    mergeObjects(state, action);
    action.payload.deletedServiceLineIds.forEach((id) => {
      deleteObject(state, { payload: { type: 'serviceLines', item: { id } } });
    });
    action.payload.deletedSupplyProductLineIds.forEach((id) => {
      deleteObject(state, { payload: { type: 'supplyProductLines', item: { id } } });
    });
  }, // others
  [GET_STATE_FROM_API_SUCCESS]: (state, action) => {
    mergeObjects(state, action);
    if (truthty(action.payload.objects.accounts)) {
      [state.account] = Object.values(action.payload.objects.accounts);
    }
  },
  [EQUIPMENTS.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [EQUIPMENTS.CREATE_EQUIPMENT_SUCCESS]: mergeObjects,
  [EQUIPMENTS.DELETE_EQUIPMENT_SUCCESS]: deleteObjects,
  [EQUIPMENTS.UPDATE_EQUIPMENT_SUCCESS]: mergeObjects,
  [LOGIN.GET_USER_SUCCESS](state, action) {
    state.user = action.user;
    state.role = action.role;
    state.loadingUser = false;
  },
  [LOGIN.GET_USER](state) {
    state.loadingUser = true;
  },
  [LOGIN.GET_USER_FAIL](state) {
    state.user = {};
    state.role = {};
    state.loadingUser = false;
  },
  [GET_USER_SUCCESS](state, action) {
    const [firstUser] = Object.values(action.payload.user);
    const [firstRole] = Object.values(action.payload.role);
    state.user = firstUser;
    state.role = firstRole;
    state.objects.users = { ...state.objects.users, ...action.payload.user };
    state.objects.roles = { ...state.objects.roles, ...action.payload.role };
    state.loadingUser = false;
  },
  [GET_USER](state) {
    state.loadingUser = true;
  },
  [GET_USER_FAIL](state) {
    state.user = {};
    state.role = {};
    state.loadingUser = false;
  },
  [LOG_OUT](state) {
    state.user = {};
    state.role = {};
  },
  [TOGGLE_DRAWER](state) {
    state.drawerIsOpen = !state.drawerIsOpen;
  },
  [TOGGLE_ERROR](state) {
    state.error = false;
    state.errorMsg = !state.errorMsg;
  },
  [SET_ERROR](state, action) {
    state.error = true;
    state.errorMsg = action.msg;
  },
  [TOGGLE_SUCCESS](state) {
    state.success = false;
    state.successMsg = !state.successMsg;
  },
  [SET_SUCCESS](state, action) {
    state.success = true;
    state.successMsg = action.msg;
  },
  [SET_LOADER](state, action) {
    state.loaders[action.id] = action.state;
  },
  [SEARCH_PRINTER](state) {
    state.searchPrinterLoading = true;
  },
  [SEARCH_PRINTER_SUCCESS](state, action) {
    state.device = action.device;
    state.deviceError = '';
    state.searchPrinterLoading = false;
  },
  [SEARCH_PRINTER_FAIL](state, action) {
    state.device = {};
    state.deviceError = action.msg;
    state.searchPrinterLoading = false;
  },
  [SEARCH_SCALE](state) {
    state.searchScaleLoading = true;
  },
  [SEARCH_SCALE_SUCCESS](state, action) {
    state.scaleDevice = action.device;
    state.scaleDeviceError = '';
    state.searchScaleLoading = false;
  },
  [SEARCH_SCALE_FAIL](state, action) {
    state.scaleDevice = {};
    state.scaleDeviceError = action.msg;
    state.searchScaleLoading = false;
  },
  [SET_CONECTION_SOCKET_ID](state, action) {
    state.socketId = action.socketId;
  },
  [READ_SCALE](state) {
    state.readingScale = true;
  },
  [READ_SCALE_SUCCESS](state) {
    state.readingScale = false;
  },
  [READ_SCALE_FAIL](state, action) {
    state.error = true;
    state.errorMsg = action.msg;
    state.readingScale = false;
  },
  [CAPTURE_WEIGHT](state, action) { 
    state.scaleWeight = action.scaleWeight;
  },

  [QUEUE_NOTIFICATION_STATUS](state, action) {
    state.queueProcess = action.payload.status;
    state.queueData = action.payload.data;
  },
  [QUEUE_NOTIFICATION_STATUS_RESET](state, action) {
    state.queueProcess = 'loading';
    state.queueData = {};
  },
  [CLEAR_NOTIFICATION_BOX](state, action) {
    state.queueData = {};
  },
  [LOAD_FROM_NOTIFICATION](state, action) {
    state.loadFromNoti = action.payload.fromNoti;
  },
  [EXPORTING_EXCEL](state) {
    state.exportingExcel = !state.exportingExcel
  },
  [EXPORT_EXCEL_READY](state, action) {
    state.exportExcelUrl = action.url
  },
  [TOGGLE_EXPORT_MSG](state) {
    state.exportMsgIsOpen = !state.exportMsgIsOpen
    state.exportExcelButton =! state.exportExcelButton
  },
  // [EDIT_WEIGHT_SCALE](state){

  // }

});

export default app;
