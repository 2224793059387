import camelize from 'camelcase-keys-deep';
export default class Paddock {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  fruit() {
    let response = {};
    if (this.fruitId) {
      response = this.env().Fruit.findBy('id', this.fruitId);
    }
    return response;
  }

  variety() {
    let response = {};
    if (this.varietyId) {
      response = this.env().Variety.findBy('id', this.varietyId);
    }
    return response;
  }

  product() {
    let response = {};
    if (this.productId) {
      response = this.env().Product.findBy('id', this.productId);
    }
    return response;
  }
}
