import camelize from 'camelcase-keys-deep';
export default class Dispatch {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  client() {
    let response = null;
    if (this.clientId) {
      response = this.env().Client.findBy('id', this.clientId);
    }
    return response;
  }

  process() {
    let response = null;
    if (this.processId) {
      response = this.env().Process.findBy('id', this.processId);
    }
    return response;
  }

  clientName() {
    let response = null;
    if (this.clientId) {
      response = this.env().Client.findBy('id', this.clientId);
    }
    return response.entity().name;
  }

  lots() {
    let response = [];
    if (this.processId) {
      response = this.env().Lot.findAllBy('destinyProcessId', this.processId);
    }
    return response;
  }
}
