/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
// React imports
import React from 'react';
// Material icons import
import CloudUpload from '@material-ui/icons/CloudUpload';
// Material imports
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

// @ts-ignore
const useStyles = makeStyles(styles);

const FileInput = (props) => {
  const {
    onChange,
    name,
    accept,
    text
  } = props;

  const classes = useStyles();

  return (
    <>
      <input
        className={classes.fileInput}
        onChange={onChange}
        name={name}
        id={name}
        accept={accept}
        type="file"
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
      >
        <label
          htmlFor="fileUpload"
          style={{
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
          }}
        >
          <CloudUpload />
          <span style={{ marginLeft: '10px' }}>
            {text}
          </span>
        </label>
      </Button>
    </>
  );
};
export default FileInput;
