// @ts-nocheck
import React, { useState, useEffect } from 'react';
// material
import TextField from '@material-ui/core/TextField';
import { formatRut, unformat, validateRut } from 'utils/functions';
import { withTranslation } from 'react-i18next';

const TaxNumberField = (props) => {
  const {
    value,
    fullWidth,
    inputId,
    label,
    error,
    helperText,
    t,
    i18n
  } = props;

  const [formattedValue, setFormattedValue] = useState('');

  useEffect(() => {
    setFormattedValue(formatRut(value.replace(/[^\d|K|k]/g, ''), i18n.language));
  }, [value]);

  function changeControls(event) {
    setFormattedValue(formatRut(event.target.value.replace(/[^\d|K|k]/g, ''), i18n.language));
    props.onChange({ [event.target.id]: unformat(event.target.value) }, { [event.target.id]: false });
  }

  return (
    <TextField
      value={formattedValue}
      onChange={changeControls}
      fullWidth={fullWidth}
      id={inputId || 'taxNumber'}
      label={label || t("identifier:name")}
      error={error}
      helperText={helperText}
    />
  );
};

export default withTranslation()(TaxNumberField);
