import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Chip, TextField } from '@material-ui/core';

const defaultStyle = {
  chipWrapper: {
    maxWidth: '100%',
    maxHeight: '65px',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginBottom: '10px',
  },
};

export const MultipleSelectorFreeSolo = ({
  onChange,
  defaultValue,
  value,
  label = '',
  placeholder = '',
  style = defaultStyle,
  id = '',
}) => {
  
  useEffect(() => {}, [value]);

  return (
    <Autocomplete
      multiple
      autoSelect
      id={id}
      options={[]}
      onChange={onChange}
      value={value}
      freeSolo
      renderTags={(value, getTagProps) => {
        return (
          <div className="chip-wrapper" style={style.chipWrapper}>
            {value.map((option, index) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            ))}
          </div>
        );
      }}
      renderInput={(params) => (
        <TextField type="number" {...params} label={label} placeholder={placeholder} />
      )}
    />
  );
};
