import { createAction, createReducer } from "utils/reducer";

// Local constants
const PATH = 'versionHistories/';

// Global constants for saga
export const VERSION_HISTORIES = {
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  CHANGE_PAGINATION: `${PATH}CHANGE_PAGINATION`,
}

// actions
export const versionHistoryActions = {
  // api
  paginationControls: createAction(VERSION_HISTORIES.CHANGE_PAGINATION, 'filters', 'pagination'),
  getStateFromApi: createAction(VERSION_HISTORIES.GET_STATE_FROM_API, 'filters', 'pagination'),
}

const initialState = {
  versionHistoriesIdList: [],
  error: false,
  pagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0
  },
  loading: false,
};

const versionHistories = createReducer(initialState, {

  // api
  [VERSION_HISTORIES.GET_STATE_FROM_API](state, action) {
    state.loading = true
  },
  [VERSION_HISTORIES.GET_STATE_FROM_API_SUCCESS](state, action) {
    state.loading = false;
    state.versionHistoriesIdList = action.payload.versionHistories;
    state.pagination = {
      ...state.pagination,
      ...(action.pagination || {}),
      count: action.payload.count
    }
  },
  [VERSION_HISTORIES.CHANGE_PAGINATION](state, action) {
    state.loading = true;
    state.pagination = {
      ...state.pagination,
      ...action.pagination,
    }
  }
});

export default versionHistories;