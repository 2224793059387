import React, { useState } from 'react';
// MUI
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const Form = (props) => {
  const {
    filterControls,
    classes,
    onFilterChange,
  } = props;

  return (
    <>
      <Card className={classes.root}>
        <CardHeader title="Filtro" />
        <CardContent>
          <Grid container spacing={3} className={classes.container}>
            <Grid container item sm={4} xs={12}>
              <TextField fullWidth id="name" label="Nombre" value={filterControls.name} onChange={onFilterChange} />
            </Grid>
            <Grid container item sm={4} xs={12}>
              <TextField fullWidth id="code" label="Código" value={filterControls.code} onChange={onFilterChange} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default Form;
