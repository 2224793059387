import camelize from 'camelcase-keys-deep';
export default class ServiceLine {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  line() {
    let response = null;
    if (this.lineId) {
      response = this.env().Line.findBy('id', this.lineId);
    }
    return response;
  }
}
