import React, { useState, useEffect } from 'react';
import connectScreen from '../../utils/connectScreen';
import saga from './saga';
import styles from './styles';
import { equipmentActions } from './reducer';
import { selectState } from '../../utils/reduxActions';
// Project
import EquipmentComponents from './components';
import { falsy, truthty } from 'utils/functions';

const defaultFilterControls = {
  name: '',
  code: '',
};

const Equipments = (props) => {
  const [filterControls, setFilterControls] = useState(defaultFilterControls);
  const [equipmentsList, setEquipmentList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    actions,
    objects,
    classes,
    equipments,
  } = props;

  useEffect(() => {
    actions.getStateFromApi();
  }, []);

  useEffect(() => {
    const filteredList = Object.values(objects.equipments)
      .filter((item) => (item.name.toLowerCase().includes(filterControls.name.toLowerCase())
      && item.code.toLowerCase().includes(filterControls.code.toLowerCase())));

    setEquipmentList(filteredList);
  }, [objects.equipments, filterControls]);

  const getInvalidControls = () => {
    const newInvalidControls = {};
    Object.keys(equipments?.invalidFormControls).forEach((key) => {
      if (falsy(equipments?.formControls[key])) {
        newInvalidControls[key] = true;
      }
    });

    return newInvalidControls;
  };

  const onFilterChange = (event) => setFilterControls({ ...filterControls, [event.target.id]: event.target.value });
  const toggleForm = () => actions.toggleForm();
  const toggleMenu = (event) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const toggleEdit = () => {
    closeMenu();
    const item = objects.equipments[anchorEl.dataset.id];
    actions.toggleEdit(item);
  };

  const toggleDelete = () => {
    closeMenu();
    actions.toggleDelete(anchorEl ? anchorEl.dataset.id : '');
  };

  const onDeleteEquipment = () => actions.deleteEquipment(equipments.toDeleteId);

  const submitForm = () => {
    const invalidFormControlsObj = getInvalidControls();

    actions.invalidFormControlsChanged(invalidFormControlsObj);

    if (falsy(invalidFormControlsObj)) {
      if (truthty(equipments.formControls.id)) {
        actions.updateEquipment(equipments.formControls);
      } else {
        actions.createEquipment(equipments.formControls);
      }
    }
  };

  const formControlsChanged = (event) => actions.formControlsChanged({ [event.target.id]: event.target.value });

  return (
    <EquipmentComponents
      classes={classes}
      filterControls={filterControls}
      onFilterChange={onFilterChange}
      toggleForm={toggleForm}
      toggleEdit={toggleEdit}
      formControlsChanged={formControlsChanged}
      submitForm={submitForm}
      toggleMenu={toggleMenu}
      closeMenu={closeMenu}
      toggleDelete={toggleDelete}
      anchorEl={anchorEl}
      objects={objects}
      equipmentsList={equipmentsList}
      onDeleteEquipment={onDeleteEquipment}
      {...equipments}
    />
  );
};

export default connectScreen(
  'Equipments',
  Equipments,
  saga,
  equipmentActions,
  selectState('equipments', 'app.objects'),
  styles,
);
