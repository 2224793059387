/* eslint-disable */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import "./i18n";
// sentry
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
// utils
import configureStore, { ReactReduxContext } from './config/configureStore';
import 'fontsource-roboto';
// components
import App from './App';
// styles
import './commons/assets/styles.scss';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  environment: process.env.REACT_APP_LOCAL_SENTRY_KEY ? 'local' : 'production',
  integrations: [new TracingIntegrations.BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: Number(process.env.REACT_APP_TRACES_SAMPLE_RATE) || 0.1,
});

const store = configureStore();
const persistor = persistStore(store);

const AppWithProfiler = Sentry.withProfiler(() => (
  <Provider store={store} context={ReactReduxContext}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App context={ReactReduxContext} />
      </BrowserRouter>
    </PersistGate>
  </Provider>
));

const renderApp = () => render(
  <AppWithProfiler />,
  document.querySelector('#root'),
);

renderApp();

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    // Support hot reloading of components
    // and display an overlay for runtime errors
    module.hot.accept('./App', () => {
      renderApp();
    });
  }
}

if ('serviceWorker' in window.navigator) {
  if (process.env.NODE_ENV === 'production') {
    window.addEventListener('load', () => {
      window.navigator.serviceWorker.register('/sw.js');
    });
  }
} else {
  console.warn('serviceWorker not available');
}

export default store;
