import camelize from 'camelcase-keys-deep';
export default class SupplyProduct {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  warehouse() {
    let response = null;
    if (this.warehouseId) {
      response =  this.env().Warehouse.findBy('id', this.warehouseId);
    }
    return response;
  }

  supplyProduct() {
    let response = null;
    if (this.supplyProductId) {
      response =  this.env().SupplyProduct.findBy('id', this.supplyProductId);
    }
    return response;
  }
  
  warehouseMovement() {
    let response = null;
    if (this.warehouseMovementId) {
      response =  this.env().WarehouseMovement.findBy('id', this.warehouseMovementId);
    }
    return response;
  }
  
  line() {
    let response = null;
    if (this.warehouseMovementId) {
      const warehouseMovement = this.env().WarehouseMovement.findBy('id', this.warehouseMovementId);
      if (warehouseMovement) {
        response = this.env().Line.findBy('id', warehouseMovement.lineId);
      }
    }
    return response;
  }
}
