const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  printStatus: {
    maxWidth: '28%',
    marginRight: theme.spacing(2),
    fontFamily: ['Roboto'],
  },
  cleanMenuItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  cleanIconContainer: {
    display: 'flex',
    alignItems: 'center',
    color: '#004d40',
    fontWeight: 'bold',
  },
});

export default styles;
