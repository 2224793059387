/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useMemo, useState } from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
// icons
import SearchIcon from '@material-ui/icons/Search';
// components
import DatePicker from '../../../../commons/components/DatePicker';
import ButtonWithLoader from '../../../../commons/containers/ButtonWithLoader';
import TableActionMenu from '../../../../commons/components/TableActionMenu';
import exportExcel from '../../../../utils/services/exportExcel';

import saga from '../../saga';
import { compose } from 'redux';
import moment from 'moment';
import { actionProps, selectState } from 'utils/reduxActions';
import { connect } from 'react-redux';
import connectSaga from 'utils/saga';
import produce from 'immer';
import { withRouter } from 'react-router-dom';

import { clone, falsy } from 'utils/functions';
import { receiptsActions } from 'screens/Receipts/reducer';
import { Chip } from '@material-ui/core';
import getQueryParams from 'utils/services/getQueryParams';
import { MultipleSelectorFreeSolo } from 'commons/components/MultipleSelectorFreeSolo';

const style = {
  chipWrapper: {
    maxWidth: '100%',
    maxHeight: '65px',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginBottom: '10px',
  },
};

const Filters = (props) => {
  const { actions, classes, objects, pagination, defaultFilters, defaultPagination, history, socketId } =
    props;

  const [filter, setFilter] = useState({ ...defaultFilters });

  const defaultLotNumber = useMemo(() => {
    const { lotNumber } = getQueryParams();
    return lotNumber ? lotNumber.split(',') : [];
  }, []);

  const onFilterChange = (event, date) => {
    let input;
    let value;
    if (event.autocomplete) {
      input = event.input;
      value = event.value;
    } else {
      event.persist();
      input = falsy(event.target.type) ? event.currentTarget.id : event.target.id;
      value = event.target.type === 'date' ? date : event.target.value;
    }

    setFilter(
      produce((draft) => {
        draft[input] = value;
      }),
    );
  };

  const onLotNumberChange = (event, lots) => {
    setFilter(
      produce((draft) => {
        draft.lotNumber = lots;
      }),
    );
  };

  useEffect(() => {
    onLotNumberChange(null, defaultLotNumber);
  }, []);

  const clearFilters = () => {
    setFilter({ ...defaultFilters });
    actions.paginationControls(defaultFilters, { ...defaultPagination });
  };

  const submitFilter = () => {
    actions.changeFilters(filter);
    actions.getStateFromApi(filter, { ...pagination, page: 0 });
  };

  function onCheckboxChange(date) {
    setFilter(
      produce((draft) => {
        draft.harvestDate = date;
      }),
    );
  }

  const suppliersOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.suppliers).sort((a, b) =>
        a.entity().name.toLowerCase() > b.entity().name.toLowerCase() ? 1 : -1,
      ),
    ];
  }, [objects.suppliers]);

  const clientsOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.clients)
        .filter((client) => client.isInternalClient)
        .sort((a, b) => a.entity().name.toLowerCase() - b.entity().name.toLowerCase()),
    ];
  }, [objects.clients]);

  let productsOptions = useMemo(() => {
    return ['', ...Object.values(objects?.products).sort((a, b) => a.name - b.name)];
  }, [objects.products]);

  let varietiesOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.varieties).sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      ),
    ];
  }, [objects.varieties]);

  const fruitsOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.fruits).sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      ),
    ];
  }, [objects.fruits]);

  varietiesOptions = filter.fruitId
    ? varietiesOptions.filter((variety) => variety.fruitId === filter.fruitId)
    : varietiesOptions;

  productsOptions = filter.varietyId
    ? productsOptions.filter((product) => product.varietyId === filter.varietyId)
    : filter.fruitId
    ? productsOptions.filter((product) =>
        varietiesOptions.find((variety) => product.varietyId === variety.id),
      )
    : productsOptions;

  return (
    <Grid item container spacing={3}>
      <Grid item xs={12}>
        <Card className={classes.root}>
          <CardHeader title="Filtro" />
          <CardContent>
            <Grid container spacing={3} className={classes.container} justifyContent="flex-end">
              <Grid item container spacing={3}>
                <Grid item sm={4} xs={12}>
                  <DatePicker
                    inputId="fromDate"
                    label="Desde"
                    fullWidth
                    value={filter.fromDate}
                    onChange={onFilterChange}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <DatePicker
                    inputId="toDate"
                    label="Hasta"
                    fullWidth
                    value={filter.toDate}
                    onChange={onFilterChange}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <MultipleSelectorFreeSolo
                    id="lotNumber"
                    label="Números de Lote"
                    placeholder="Números de Lote"
                    value={filter.lotNumber}
                    defaultValue={defaultLotNumber}
                    onChange={onLotNumberChange}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="paddock-label">Cuartel</InputLabel>
                    <Select
                      labelId="paddock-label"
                      fullWidth
                      value={filter.paddockId}
                      onChange={onFilterChange}>
                      <MenuItem id="paddockId" value="">
                        Todos
                      </MenuItem>
                      {Object.values(objects.paddocks)
                        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                        .map((paddock) => (
                          <MenuItem
                            key={`paddock-option-${paddock.id}`}
                            id="paddockId"
                            value={paddock.id}>
                            {paddock.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    fullWidth
                    id="dispatchOrderNumber"
                    label="Guía de despacho"
                    value={filter.dispatchOrderNumber}
                    onChange={onFilterChange}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Autocomplete
                    value={
                      filter.supplierId && suppliersOptions.find((s) => s.id === filter.supplierId)
                    }
                    id="autocomplete-suppliers-filters-receipts"
                    options={suppliersOptions}
                    getOptionLabel={(supplier) => supplier && supplier.entity().name}
                    renderInput={(params) => (
                      <TextField
                        style={{ margin: 0 }}
                        {...params}
                        label="Proveedor"
                        margin="normal"
                      />
                    )}
                    onChange={(event, newValue) => {
                      const e = {
                        autocomplete: true,
                        input: 'supplierId',
                        value: newValue ? newValue.id : '',
                      };
                      onFilterChange(e);
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Autocomplete
                    value={filter.clientId && clientsOptions.find((c) => c.id === filter.clientId)}
                    id="autocomplete-clients-filters-receipts"
                    options={clientsOptions}
                    getOptionLabel={(client) => client && client.entity().name}
                    renderInput={(params) => (
                      <TextField
                        style={{ margin: 0 }}
                        {...params}
                        label="Cliente interno"
                        margin="normal"
                      />
                    )}
                    onChange={(event, newValue) => {
                      const e = {
                        autocomplete: true,
                        input: 'clientId',
                        value: newValue ? newValue.id : '',
                      };
                      onFilterChange(e);
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    fullWidth
                    id="harvester"
                    label="Responsable"
                    value={filter.harvester}
                    onChange={onFilterChange}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <DatePicker
                    disableBox
                    onCheckboxChange={onCheckboxChange}
                    inputId="harvestDate"
                    label="Fecha de cosecha"
                    fullWidth
                    value={filter.harvestDate}
                    onChange={onFilterChange}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Autocomplete
                    value={filter.fruitId && fruitsOptions.find((f) => f.id === filter.fruitId)}
                    id="autocomplete-suppliers-filters-receipts"
                    options={fruitsOptions}
                    getOptionLabel={(fruit) => fruit && fruit.name}
                    renderInput={(params) => (
                      <TextField
                        style={{ margin: 0 }}
                        {...params}
                        label="Especie"
                        margin="normal"
                      />
                    )}
                    onChange={(event, newValue) => {
                      const e = {
                        autocomplete: true,
                        input: 'fruitId',
                        value: newValue ? newValue.id : '',
                      };
                      onFilterChange(e);
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Autocomplete
                    value={
                      filter.varietyId && varietiesOptions.find((v) => v.id === filter.varietyId)
                    }
                    id="autocomplete-suppliers-filters-receipts"
                    options={varietiesOptions}
                    getOptionLabel={(variety) => variety && variety.name}
                    renderInput={(params) => (
                      <TextField
                        style={{ margin: 0 }}
                        {...params}
                        label="Variedad"
                        margin="normal"
                      />
                    )}
                    onChange={(event, newValue) => {
                      const e = {
                        autocomplete: true,
                        input: 'varietyId',
                        value: newValue ? newValue.id : '',
                      };
                      onFilterChange(e);
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Autocomplete
                    value={
                      filter.productId && productsOptions.find((p) => p.id === filter.productId)
                    }
                    id="autocomplete-suppliers-filters-receipts"
                    options={productsOptions}
                    getOptionLabel={(product) => product && product.name}
                    renderInput={(params) => (
                      <TextField
                        style={{ margin: 0 }}
                        {...params}
                        label="Producto"
                        margin="normal"
                      />
                    )}
                    onChange={(event, newValue) => {
                      const e = {
                        autocomplete: true,
                        input: 'productId',
                        value: newValue ? newValue.id : '',
                      };
                      onFilterChange(e);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={3} justifyContent="flex-end">
                <Grid item xs={12} sm={4} md={3}>
                  <ButtonWithLoader
                    // @ts-ignore
                    fullWidth
                    onClick={clearFilters}
                    autoFocus
                    buttonId="filter-receipts"
                    className={classes.filterButton}
                    size="small">
                    Limpiar
                  </ButtonWithLoader>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <ButtonWithLoader
                    // @ts-ignore
                    fullWidth
                    onClick={submitFilter}
                    autoFocus
                    buttonId="filter-receipts"
                    className={classes.filterButton}
                    size="small"
                    startIcon={<SearchIcon />}>
                    Buscar
                  </ButtonWithLoader>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={12} sm={4} md={3}>
          <TableActionMenu
            menuItems={[
              {
                label: 'Exportar',
                listItems: [
                  {
                    label: 'Archivo Excel',
                    onClick: exportExcel,
                    params: [filter, 'receipts/export', 'Recepciones', socketId],
                  },
                ],
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const withSaga = connectSaga({ key: 'sagaReceipts', saga });

const withConnect = connect(
  selectState('receipts.pagination', 'app.objects', 'app.socketId'),
  actionProps(clone(clone.OBJECT, receiptsActions)),
);

export default compose(withRouter, withConnect)(withSaga(Filters));
