// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';

import apiRequest, { apiSuccess } from 'utils/api';
import { PADDOCKS } from '../reducer';
import { truthty } from 'utils/functions';
import { appActions } from 'commons/reducer';

function* getStateFromApi() {
  yield takeLatest(PADDOCKS.GET_STATE_FROM_API, function* (action) {
    const response = yield apiRequest('api/v1/paddocks', { method: 'get' });
    yield put(apiSuccess(PADDOCKS.GET_STATE_FROM_API_SUCCESS, response));
  });
}

function* createPaddockType() {
  yield takeLatest(PADDOCKS.CREATE_PADDOCK, function* (action) {
    yield put(appActions.setLoader('create-edit-paddockType', true));
    const response = yield apiRequest('api/v1/paddocks',
      {
        method: 'post',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PADDOCKS.CREATE_PADDOCK_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-paddockType', false));
  });
}

function* editPaddockType() {
  yield takeLatest(PADDOCKS.EDIT_PADDOCK, function* (action) {
    yield put(appActions.setLoader('create-edit-paddockType', true));
    const response = yield apiRequest(`api/v1/paddocks/${action.id}`,
      {
        method: 'put',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PADDOCKS.EDIT_PADDOCK_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-paddockType', false));
  });
}

function* deletePaddockType() {
  yield takeLatest(PADDOCKS.DELETE_PADDOCK, function* (action) {
    yield put(appActions.setLoader('delete-paddockType', true));
    const response = yield apiRequest(`api/v1/paddocks/${action.id}`, { method: 'delete' });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    }
    const item = Object.values(response)[0];
    yield put(apiSuccess(PADDOCKS.DELETE_PADDOCK_SUCCESS, { item, type: 'paddocks' }));
    yield put(appActions.setLoader('delete-paddockType', false));
  });
}

export default function* saga() {
  yield spawn(getStateFromApi);
  yield spawn(deletePaddockType);
  yield spawn(createPaddockType);
  yield spawn(editPaddockType);
}
