import React, { useEffect, useState } from 'react';
import {
  Grid,
  List,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { falsy, truthty } from '../../../utils/functions';

const MaintainersPaginator = ({
  classes,
  list = [],
  getListItem,
  openMenuHandler,
  closeMenuHandler,
  toggleEdit,
  toggleDelete,
  anchorEl,
  itemOnClickHandler,
  loading,
}) => {
  const [paginatedList, setPaginatedList] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const filteredListCount = Math.ceil(list.length / 20);
    setCount(filteredListCount);
  }, [list.length]);

  useEffect(() => {
    setPage(1);
  }, [loading]);

  useEffect(() => {
    if (truthty(list)) {
      if (page !== 0) {
        const from = (page - 1) * 20;
        const to = (page * 20);
        setPaginatedList(list.slice(from, to));
      }
    } else {
      setPaginatedList([]);
    }
  }, [list, page]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <List>
            {paginatedList
              .map((item) => (getListItem(item, openMenuHandler, closeMenuHandler, toggleEdit, toggleDelete, anchorEl, itemOnClickHandler)))}
          </List>
        </Grid>
      </Grid>
      <Pagination count={count} page={page} onChange={handleChangePage} />
    </>
  );
};

export default MaintainersPaginator;
