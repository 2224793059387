// @ts-nocheck
import React from 'react';
// material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';

const SupplyProductForm = props => (
  <Dialog fullWidth onClose={props.toggleForm} aria-labelledby="simple-dialog-title" open={props.isOpen}>
    <DialogTitle>{`${props.edit ? 'Editar' : 'Crear'} producto de insumo`}</DialogTitle>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            value={props.controls.name}
            onChange={props.changeControls}
            fullWidth
            id="name"
            label="Nombre"
            error={props.invalidControls.name}
            helperText={props.invalidControls.name && 'Debe ingresar un nombre'}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="unit-label">Unidad</InputLabel>
            <Select
              labelId="unit-label"
              fullWidth
              value={props.controls.unitId}
              onChange={props.changeControls}
              error={props.invalidControls.unitId}
            >
              {Object.values(props.units).map(item => (
                <MenuItem id="unitId" key={`unit-${item.id}`} value={item.id}>{`${item.name} [${item.code}]`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={props.controls.traceable}
                onChange={props.changeControls}
                fullWidth
                id="traceable"
                disabled={props.edit}
              />
            )}
            label="Trazable"
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.toggleForm} color="primary">
        Cancelar
        </Button>
      <ButtonWithLoader onClick={props.submit} autoFocus buttonId="create-edit-supplyProduct">
        {props.edit ? 'Editar' : 'Agregar'}
      </ButtonWithLoader>
    </DialogActions>
  </Dialog >
);

export default SupplyProductForm;
