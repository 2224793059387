import React from 'react';
// MUI
import { Container, Grid, Typography } from '@material-ui/core';
// PROJECT
import Filters from './Filters';
import List from './List';

const VersionHistoryComponents = (props) => {
  const {
    classes,
    objects,
    pagination,
    filterControls,
    onFilterChange,
    clearFilters,
    submitFilter,
    versionHistoriesList,
    handleChangeRowsPerPage,
    handleChangePage,
    loading
  } = props;

  return (
    <Container maxWitdh={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h2" className={classes.marginTop}>
            Historial de cambios
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Filters
            classes={classes}
            filterControls={filterControls}
            onFilterChange={onFilterChange}
            clearFilters={clearFilters}
            submitFilter={submitFilter}
          />
        </Grid>
        <Grid item xs={12}>
          <List
            classes={classes}
            objects={objects}
            versionHistoriesList={versionHistoriesList}
            loading={loading}
            pagination={pagination}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default VersionHistoryComponents;