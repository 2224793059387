import React from 'react';
// Material
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// Untils
import { truthty, formatDate, falsy } from 'utils/functions';
import { getLocalDate } from 'utils/DateFormat';
// Project
import ProcessEquipments from '../ProcessEquipments';

const Headers = (props) => {
  const {
    classes,
    process,
    multipleLotsProcess,
    uniqueLot,
    toggleClose,
    changeControls,
    controls,
    classes: { closeProcessButton },
    isAdmin,
    products,
    processEquipments,
    equipments,
    toggleDelete,
    onBackAction,
  } = props;

  function formatDateTime(date) {   
    const options = { 
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // Formato de 24 horas
    };
    return new Intl.DateTimeFormat('es-ES', options).format(date);
  }

  if (!truthty(process)) return null;

  const processCategory = process.processCategories();

  return (
    <Grid item container spacing={2}>
      <Grid container item>
        <Grid item xs={12}>
          <Button
            startIcon={<ArrowBackIosIcon />}
            style={{ marginTop: 20 }}
            variant="outlined"
            color="primary"
            onClick={onBackAction}>
            VOLVER
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ color: '#47AA4D' }} className={classes.marginTop}>
            Nº
            {process.id} {processCategory ? ` - ${processCategory.name}` : ''}
          </Typography>
          <Grid item xs={12}></Grid>
          <Typography variant="h3" component="h3">
            Proceso de {truthty(process) && process.processType().name.toLowerCase()}
            {!multipleLotsProcess && (
              <Tooltip title="Este proceso está restringido a un lote">
                <InfoOutlinedIcon style={{ marginLeft: 8 }} />
              </Tooltip>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
        <Typography variant="h5" component="span" className={classes.marginTop}>
        <span style={{ fontWeight: 'bold' }}>Turno:</span> 
        {` ${truthty(process) && process.workShiftType()?.name?.toLowerCase()}`}
        <span style={{ fontWeight: 'bold' }}> | Fecha de inicio:</span>
        {` ${truthty(process) && formatDateTime(new Date(process.date))}`}
        {truthty(uniqueLot) && 
        <span><span style={{ fontWeight: 'bold' }}> | Lote Número:</span> {uniqueLot.lotNumbers()}</span>}
        {truthty(process.closedAt) && 
        <span><span style={{ fontWeight: 'bold' }}> | Fecha de finalización:</span> {formatDateTime(new Date(process.closedAt))}</span>}

      </Typography>
        </Grid>
      </Grid>
      <Grid item container justifyContent="flex-start" spacing={3}>
        <Grid container justifyContent="flex-start" item xs={12} sm={6}>
          <Grid container item xs={12} sm={6}>
            <ProcessEquipments
              classes={classes}
              process={process}
              processEquipments={processEquipments}
              equipments={equipments}
              toggleDelete={toggleDelete}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" item xs={12} sm={6}>
          <Grid item xs={12} sm={6} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  id="isGroupedInfo"
                  checked={controls.isGroupedInfo}
                  onChange={changeControls}
                  color="primary"
                  inputProps={{ 'data-module': 'headers' }}
                />
              }
              label="Agrupar información"
            />
          </Grid>
          {(isAdmin || falsy(process.closedAt)) && (
            <Grid item xs={12} sm={6} md={4}>
              <Button
                className={falsy(process.closedAt) && closeProcessButton}
                fullWidth
                variant="contained"
                color={falsy(process.closedAt) ? 'default' : 'primary'}
                onClick={toggleClose}>
                {truthty(process.closedAt) ? 'ABRIR PROCESO' : 'FINALIZAR PROCESO'}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Headers;
