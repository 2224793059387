import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectState, actionProps } from '../../../../../../utils/reduxActions';
import { dispatchesActions } from '../../../../reducer';
import connectSaga from '../../../../../../utils/saga';
import saga from '../../../../saga';
// ui
import { ListItemIcon, Menu, MenuItem, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PrintIcon from '@material-ui/icons/Print';
// utils
import { truthty, clone } from '../../../../../../utils/functions';

const ItemMenu = (props) => {
  const {
    cDispatch: dispatch,
    handleClick,
    handleClose,
    toggleDelete,
    toggleEdit,
    printFnc,
    anchorEl,
  } = props;

return (<>
    <IconButton
    onClick={handleClick}
    data-id={dispatch.id}
  >
    <MoreVertIcon data-id={dispatch.id}/>
  </IconButton>
  {truthty(anchorEl) && Number(anchorEl.dataset.id) === dispatch.id && (
    <Menu
    anchorEl={anchorEl}
    keepMounted
    open={truthty(anchorEl)}
    onClose={handleClose}
  >
    <MenuItem onClick={printFnc}>
      <ListItemIcon>
        <PrintIcon />
      </ListItemIcon>
      Imprimir paks
    </MenuItem>
    <MenuItem onClick={toggleDelete}>
      <ListItemIcon>
        <DeleteIcon />
      </ListItemIcon>
      Eliminar
    </MenuItem>
  </Menu>)}</>
  )
}

export default ItemMenu;
