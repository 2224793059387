// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';

import apiRequest, { apiSuccess } from 'utils/api';
import { PRODUCTS } from '../reducer';
import { truthty } from 'utils/functions';
import { appActions } from 'commons/reducer';

function* getStateFromApi() {
  yield takeLatest(PRODUCTS.GET_STATE_FROM_API, function* (action) {
    const response = yield apiRequest('api/v1/products', { method: 'get' });
    yield put(apiSuccess(PRODUCTS.GET_STATE_FROM_API_SUCCESS, response));
  });
}

function* createProduct() {
  yield takeLatest(PRODUCTS.CREATE_PRODUCT, function* (action) {
    yield put(appActions.setLoader('create-edit-product', true));
    const response = yield apiRequest('api/v1/products',
      {
        method: 'post',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PRODUCTS.CREATE_PRODUCT_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-product', false));
  });
}

function* editProduct() {
  yield takeLatest(PRODUCTS.EDIT_PRODUCT, function* (action) {
    yield put(appActions.setLoader('create-edit-product', true));
    const response = yield apiRequest(`api/v1/products/${action.id}`,
      {
        method: 'put',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PRODUCTS.EDIT_PRODUCT_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-product', false));
  });
}

function* deleteProduct() {
  yield takeLatest(PRODUCTS.DELETE_PRODUCT, function* (action) {
    yield put(appActions.setLoader('delete-product', true));
    const response = yield apiRequest(`api/v1/products/${action.id}`, { method: 'delete' });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    }
    const item = Object.values(response)[0];
    yield put(apiSuccess(PRODUCTS.DELETE_PRODUCT_SUCCESS, { item, type: 'products' }));
    yield put(appActions.setLoader('delete-product', false));
  });
}

function* exportProducts() {
  yield takeLatest(PRODUCTS.EXPORT_PRODUCTS, function* (action) {
    yield put(appActions.setLoader('export-products', true));
    const response = yield apiRequest('api/v1/products/export', { method: 'get' }, false, 'productos.xlsx');
    yield put(apiSuccess(PRODUCTS.EXPORT_PRODUCTS_SUCCESS, response));
    yield put(appActions.setLoader('export-products', false));
  });
}

export default function* saga() {
  yield spawn(getStateFromApi);
  yield spawn(deleteProduct);
  yield spawn(createProduct);
  yield spawn(editProduct);
  yield spawn(exportProducts);
}
