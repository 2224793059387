import camelize from 'camelcase-keys-deep';
export default class ProcessStaffLine {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  employee() {
    let response = null;
    if (this.employeeId) {
      response =  this.env().Employee.findBy('id', this.employeeId);
    }
    return response;
  }
}
