import React from 'react';
// imports
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
// material icons
import CloseIcon from '@material-ui/icons/Close';
import { CloudDownload } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
// project imports

const FileExportComponent = props => {
  const {
    exportUrl,
    hideExport,
    open,
    exportingFile
  } = props;
  const message = exportUrl && !exportingFile ? 'Puede descargar el archivo' : 'El archivo se está generando';
  const downloadFile = (url) => {
    window.open(url)
    hideExport();
  }

  return (
    <Grid item container>
      <Grid item xs>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={open}
          message={<span id="message-id">{message}</span>}
          action={[
            <IconButton
              key="download"
              color="inherit"
              // eslint-disable-next-line no-extra-parens
              onClick={() => (exportUrl && !exportingFile ? downloadFile(exportUrl) : '')}
            >
              { exportUrl && !exportingFile ? <CloudDownload /> : <CircularProgress style={{ color: 'white' }} size={20} />}
            </IconButton>,
            <IconButton
              key="close"
              color="inherit"
              onClick={hideExport}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default FileExportComponent;
