/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';
import moment from 'moment';

// Local constants
const PATH = 'receipts/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_EDIT_RECEIPT = `${PATH}TOGGLE_EDIT_RECEIPT`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;

// Global constants for saga
export const RECEIPTS = {
  CREATE_RECEIPT: `${PATH}CREATE_RECEIPT`,
  CREATE_RECEIPT_SUCCESS: `${PATH}CREATE_RECEIPT_SUCCESS`,
  CREATE_RECEIPT_LOTS: `${PATH}CREATE_RECEIPT_LOTS`,
  CREATE_RECEIPT_LOTS_SUCCESS: `${PATH}CREATE_RECEIPT_LOTS_SUCCESS`,
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  DELETE_RECEIPT: `${PATH}DELETE_RECEIPT`,
  DELETE_RECEIPT_SUCCESS: `${PATH}DELETE_RECEIPT_SUCCESS`,
  EDIT_RECEIPT_SUCCESS: `${PATH}EDIT_RECEIPT_SUCCESS`,
  EDIT_RECEIPT: `${PATH}EDIT_RECEIPT`,
  EDIT_RECEIPT_DATA: `${PATH}EDIT_RECEIPT_DATA`,
  EDIT_RECEIPT_DATA_SUCCESS: `${PATH}EDIT_RECEIPT_DATA_SUCCESS`,
  CHANGE_PAGINATION: `${PATH}CHANGE_PAGINATION`,
  TOGGLE_DELETE_LOT: `${PATH}TOGGLE_DELETE_LOT`,
  DELETE_LOT: `${PATH}DELETE_LOT`,
  DELETE_LOT_SUCCESS: `${PATH}DELETE_LOT_SUCCESS`,
  CHANGE_FILTERS: `${PATH}CHANGE_FILTERS`,
};

// actions
export const receiptsActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  toggleEdit: createAction(TOGGLE_EDIT_RECEIPT, 'item', 'repeat'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  // api
  changeFilters: createAction(RECEIPTS.CHANGE_FILTERS, 'filter'),
  paginationControls: createAction(RECEIPTS.CHANGE_PAGINATION, 'filters', 'pagination'),
  getStateFromApi: createAction(RECEIPTS.GET_STATE_FROM_API, 'filters', 'pagination'),
  createReceipt: createAction(RECEIPTS.CREATE_RECEIPT, 'controls'),
  createReceiptLots: createAction(RECEIPTS.CREATE_RECEIPT_LOTS, 'receiptId', 'lots', 'device'),
  editReceipt: createAction(RECEIPTS.EDIT_RECEIPT, 'id', 'controls', 'totalWeight'),
  editReceiptData: createAction(RECEIPTS.EDIT_RECEIPT_DATA, 'id', 'controls'),
  deleteReceipt: createAction(RECEIPTS.DELETE_RECEIPT, 'id'),
  toggleDeleteLot: createAction(RECEIPTS.TOGGLE_DELETE_LOT, 'id'),
  deleteLot: createAction(RECEIPTS.DELETE_LOT, 'id'),
};

const defaultInvalidInputs = {
  lotNumber: false,
  supplierId: false,
  harvester: false,
  dispatchOrderNumber: false,
  paddockId: false,
  fruitId: false,
  varietyId: false,
  productId: false,
  packagingTypeId: false,
  quantity: false,
  packagingQuantity: false,
  price: false,
  lots: false,
  warehouseId: false,
};

const defaultControls = {
  date: new Date().toISOString(),
  harvestDate: new Date().toISOString(),
  lotNumber: '',
  supplierId: '',
  harvester: '',
  dispatchOrderNumber: '',
  paddockId: '',
  fruitId: '',
  varietyId: '',
  productId: '',
  warehouseId: '',
  packagingTypeId: '',
  quantity: '',
  packagingQuantity: '',
  price: '',
  clientId: '',
  lots: [],
  receiptId: null,
  multipleLots: false,
  hasPallet: false,
  isOpenDeleteLot: false,
  toDeleteLotId: '',
  addLotsloading: false,
};

const initialState = {
  formControls: { ...defaultControls },
  invalidControls: { ...defaultInvalidInputs },
  receiptIdList: [],
  formIsOpen: false,
  deleteIsOpen: false,
  toDeleteId: '',
  toEditId: '',
  lastLotNumber: 1,
  edit: false,
  error: false,
  loading: true,
  pagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0,
  },
  inputSearch: {},
};

const receipts = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.edit = false;
    state.formControls = { ...defaultControls };
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls,
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    };
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [TOGGLE_EDIT_RECEIPT](state, action) {
    state.formIsOpen = true;
    state.toDeleteId = '';
    state.toEditId = action.item.id;
    const lot = action.item.process().departureLots()[0] || {};
    state.formControls = {
      ...defaultControls,
      date: moment(action.item.process().date).toISOString(),
      harvestDate: moment(action.item.harvestDate).toISOString(),
      lotNumber: action.item.receiptLot().number,
      supplierId: action.item.supplierId,
      harvester: action.item.harvester,
      dispatchOrderNumber: action.item.dispatchOrderNumber,
      paddockId: action.item.receiptLot().paddockId,
      fruitId: lot.fruitId || '',
      varietyId: lot.varietyId || '',
      productId: lot.productId || '',
      price: action.item.receiptLot().price,
      receiptId: action.item.id,
      clientId: action.item.clientId,
    };
    state.edit = !action.repeat;
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    };
  },
  // api
  [RECEIPTS.CHANGE_PAGINATION](state, action) {
    state.loading = true;
    state.pagination = {
      ...state.pagination,
      ...action.pagination,
    };
  },
  [RECEIPTS.CHANGE_FILTERS](state, action) {
    state.inputSearch = {
      ...state.inputSearch,
      ...action.filter,
    };
  },
  [RECEIPTS.GET_STATE_FROM_API](state, action) {
    state.loading = true;
  },
  [RECEIPTS.GET_STATE_FROM_API_SUCCESS](state, action) {
    state.lastLotNumber = action.payload.lastReceiptLot[0] ? action.payload.lastReceiptLot[0].number : 1;
    state.receiptIdList = action.payload.receipts;
    state.loading = false;
    state.pagination = {
      ...state.pagination,
      ...(action.pagination || {}),
      count: action.payload.count,
    };
  },
  [RECEIPTS.CREATE_RECEIPT_SUCCESS](state, action) {
    state.invalidControls = { ...defaultInvalidInputs };
    state.receiptIdList = [
      ...state.receiptIdList,
      ...action.payload.receipts,
    ];
    state.formControls.fruitId = '';
    state.formControls.varietyId = '';
    state.formControls.productId = '';
    state.formIsOpen = true;
    [state.formControls.receiptId] = action.payload.receipts;
    state.formControls.lotNumber = action.payload.createdLotNumber;
    state.lastLotNumber = action.payload.createdLotNumber;
  },
  [RECEIPTS.CREATE_RECEIPT_LOTS](state, action) {
    state.formControls = {
      ...state.formControls,
      addLotsloading: true
    }
  },
  [RECEIPTS.CREATE_RECEIPT_LOTS_SUCCESS](state, action) {
    state.formControls = {
      ...state.formControls,
      warehouseId: '',
      packagingTypeId: '',
      quantity: '',
      packagingQuantity: '',
      addLotsloading: false,
    };
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [RECEIPTS.DELETE_RECEIPT_SUCCESS](state, action) {
    state.deleteIsOpen = false;
    state.toDeleteId = '';
    state.pagination.count -= 1;
    state.receiptIdList = state.receiptIdList.filter((receipt) => receipt !== action.payload.item.id);
  },
  [RECEIPTS.EDIT_RECEIPT_SUCCESS](state, action) {
    state.formControls = { ...defaultControls };
    state.invalidControls = { ...defaultInvalidInputs };
    state.formIsOpen = false;
    state.toDeleteId = '';
    state.toEditId = '';
    state.edit = false;
  },
  [RECEIPTS.EDIT_RECEIPT_DATA_SUCCESS](state, action) {
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [RECEIPTS.TOGGLE_DELETE_LOT](state, action) {
    state.formControls.isOpenDeleteLot = !state.formControls.isOpenDeleteLot;
    state.formControls.toDeleteLotId = action.id;
  },
  [RECEIPTS.DELETE_LOT_SUCCESS](state, action) {
    state.formControls.isOpenDeleteLot = false;
    state.formControls.toDeleteLotId = null;
  },
});

export default receipts;
