/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
// material
import {
  Typography, TableContainer, Table, TableHead, TableRow, TableBody, Paper,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
// components
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import StyledTableCell from 'commons/components/StyledTableCell';
import FileInput from 'commons/components/FileInput';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// utils
import { truthty, fCurrency, falsy } from 'utils/functions';

const InventoryAdjustmentModal = (props) => {
  const {
    toggleModal,
    isOpen,
    classes,
    processListTotal,
    file,
    inventoryType,
    exportAdjustmentInventoryLines,
    filterControls,
    changeInventoryFile,
    submitInventoryAdjustment,
    updatedInventory,
  } = props;

  const [downloading, setDownloading] = useState(false);

  const handleExport = async () => {
    if (inventoryType === 'supply' && !downloading) {
      setDownloading(true); 
  
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulación de una solicitud de exportación que tarda 2 segundos
        exportAdjustmentInventoryLines(filterControls);
        console.log('Archivo exportado exitosamente.');
      } catch (error) {
        console.error('Error al exportar:', error);
      } finally {
        setDownloading(false); 
      }
    }
  };
  
  async function handleSubmit() {
    try {
      await submitInventoryAdjustment();
      
    } catch (error) {
      console.error("Error al enviar el ajuste de inventario:", error);
    }
  }

  return (
    <Dialog fullWidth onClose={toggleModal} aria-labelledby="simple-dialog-title" open={isOpen}>
        <DialogTitle>Importar ajuste de inventario</DialogTitle>
        <DialogContent>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    <Grid item container justifyContent="center">
                        <Grid item>
                        <Button
                                startIcon={<CloudDownloadIcon />}
                                onClick={handleExport}
                                disabled={downloading}
                                color="primary"
                                variant="contained"
                                >
                                DESCARGAR PLANILLA
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <FileInput
                    onChange={changeInventoryFile}
                    name="fileUpload"
                    id="fileUpload"
                    accept=".xlsx,.xls,.csv"
                    text="Importar Excel con ajuste de inventario"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography style={{ textAlign: 'center', marginBottom: 16 }}>
                    {file ? file.name : ''}
                    </Typography>
                </Grid>
            </Grid>
            {truthty(updatedInventory) && (
                <Grid item xs={12}>
                <Grid item xs={12}>
                    <Typography variant="body1">
                    Los ajustes fueron cargados exitosamente.
                    </Typography>
                </Grid>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Insumo</StyledTableCell>
                                <StyledTableCell>Bodega</StyledTableCell>
                                <StyledTableCell align="right">Nuevo stock</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {updatedInventory.map((item, index) => (
                            <TableRow key={`updated-inventory-${index}`}>
                            <StyledTableCell component="th">
                                {item[0]} 
                            </StyledTableCell>
                            <StyledTableCell scope="process">
                                {item[1]} 
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                {item[2]} 
                            </StyledTableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            )}
        </DialogContent>
        <DialogActions>
            <Button onClick={toggleModal} color="primary" variant="outlined">
            Cerrar
            </Button>
            <ButtonWithLoader
                onClick={handleSubmit}
                autoFocus
                buttonId="submit-inventory-adjustment"
                disabled={!file}
                >
                Enviar
            </ButtonWithLoader>
        </DialogActions>
    </Dialog>
  );
};

export default InventoryAdjustmentModal;