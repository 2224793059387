/* eslint-disable no-shadow */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-param-reassign */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';

// Project

import { Container, withStyles } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CardHeader from '@material-ui/core/CardHeader';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
// styles
import produce from 'immer';
import DeleteModal from '../../commons/components/DeleteModal';
import MaintainersPaginator from '../../commons/components/MaintainersPaginator';
import WarehouseForm from './components/WarehouseForm';
import { warehousesActions } from './reducer';
import { clone, truthty, falsy } from '../../utils/functions';
import connectSaga from '../../utils/saga';
import { ReactReduxContext } from '../../config/configureStore';
import styles from './styles';
import saga from './saga';
import { homeActions } from '../Home/reducer';
import { selectState, actionProps } from '../../utils/reduxActions';
import TableActionMenu from 'commons/components/TableActionMenu';

const useStyles = makeStyles(styles);

const Warehouses = (props) => {
  const [warehouseList, setWarehouseList] = useState([]);
  const [filter, setFilter] = useState({ name: '', code: '', supplyWarehouse: '' });
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    objects,
    actions,
    formIsOpen,
    formControls,
    edit,
    invalidControls,
    deleteIsOpen,
  } = props;

  useEffect(() => {
    setWarehouseList(Object.values(objects.warehouses)
      .filter((item) => (
        item.name.toLowerCase().includes(filter.name.toLowerCase())
        && item.code.toLowerCase().includes(filter.code.toLowerCase())
        && (filter.supplyWarehouse === '' || truthty(item.supplyWarehouse) === filter.supplyWarehouse)
      ))
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
  }, [objects.warehouses, filter]);

  useEffect(() => {
    actions.getStateFromApi();
  }, [actions]);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function toggleForm() {
    actions.toggleForm('formIsOpen');
  }

  function closeForm() {
    actions.toggleForm('deleteIsOpen');
  }

  function toggleDelete() {
    handleClose();
    actions.toggleDelete(anchorEl.dataset.id);
  }

  function toggleEdit() {
    handleClose();
    const item = Object.values(objects.warehouses)
      .find((item) => item.id === Number(anchorEl.dataset.id));
    actions.toggleEdit(item);
  }

  function changeControls(event) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    actions.changeControls({ [event.target.id]: value }, { [event.target.id]: false });
  }

  function submit() {
    if (
      falsy(formControls.name)
      || falsy(formControls.code)
    ) {
      actions.setInvalidControls({
        name: falsy(formControls.name),
        code: falsy(formControls.code),
      });
    } else if (edit) {
      actions.editWarehouse(props.toEditId, formControls);
    } else {
      actions.createWarehouse(formControls);
    }
  }

  function submitDelete() {
    actions.deleteWarehouse(props.toDeleteId);
  }

  function onFilterChange(event) {
    event.persist();
    const id = event.target.type ? event.target.id : event.currentTarget.id;
    setFilter(produce((draft) => {
      draft[id] = event.target.value;
    }));
  }

  const classes = useStyles();

  const getListItem = (warehouse, openMenuHandler, closeMenuHandler, toggleEdit, toggleDelete) => (
    <ListItem key={`warehouse-${warehouse.id}`}>
      <ListItemAvatar>
        <Avatar className={classes.blue}>
          {(warehouse.name || 'p')[0].toUpperCase()}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={warehouse.name}
        secondary={`Código: ${warehouse.code}${warehouse.supplyWarehouse ? ', bodega de insumos' : ''}`}
      />
      <ListItemSecondaryAction>
        <IconButton
          data-id={warehouse.id}
          onClick={openMenuHandler}
        >
          <MoreVertIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h2" className={classes.marginTop}>
            Bodegas
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.root}>
            <CardHeader title="Filtro" />
            <CardContent>
              <Grid container spacing={3} className={classes.container}>
                <Grid container item sm={4} xs={12}>
                  <TextField fullWidth id="name" label="Nombre" value={filter.name} onChange={onFilterChange} />
                </Grid>
                <Grid container item sm={4} xs={12}>
                  <TextField fullWidth id="code" label="Código" value={filter.code} onChange={onFilterChange} />
                </Grid>
                <Grid container item sm={4} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="wharehouse-type-label">Tipo de Bodega</InputLabel>
                    <Select
                      labelId="wharehouse-type-label"
                      fullWidth
                      value={filter.supplyWarehouse}
                      onChange={onFilterChange}
                    >
                      <MenuItem id="supplyWarehouse" value="">Todos</MenuItem>
                      <MenuItem id="supplyWarehouse" value>Bodegas de insumo</MenuItem>
                      <MenuItem id="supplyWarehouse" value={false}>Bodega de productos</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid container item>
          <Grid item xs={12} sm={4} md={3}>
            <TableActionMenu
              menuItems={[
                {
                  label: 'Exportar',
                  listItems: [{
                    label: 'Archivo Excel',
                    onClick: actions.exportWarehouses,
                    params: [Object.values(objects.warehouses)],
                  }],
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.root}>
            <CardContent>
              {truthty(objects.warehouses) ? (
                <MaintainersPaginator
                  classes={classes}
                  list={warehouseList}
                  getListItem={getListItem}
                  openMenuHandler={handleClick}
                  closeMenuHandler={handleClose}
                  toggleEdit={toggleEdit}
                  toggleDelete={toggleDelete}
                />
              ) : (
                <Grid container item xs={12} alignItems="center" justifyContent="center">
                  <Typography variant="h6" component="h2">
                    No se han ingresado bodegas
                  </Typography>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <WarehouseForm
        isOpen={formIsOpen}
        toggleForm={toggleForm}
        changeControls={changeControls}
        controls={formControls}
        warehouses={objects.warehouses}
        edit={edit}
        submit={submit}
        invalidControls={invalidControls}
      />
      <DeleteModal isOpen={deleteIsOpen} toggleForm={closeForm} submit={submitDelete} buttonId="delete-warehouse" />
      <Fab color="primary" aria-label="add" className={classes.fab} onClick={toggleForm}>
        <AddIcon />
      </Fab>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={truthty(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={toggleEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Editar
        </MenuItem>
        <MenuItem onClick={toggleDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Eliminar
        </MenuItem>
      </Menu>
    </Container>
  );
};

const withSaga = connectSaga({ key: 'sagaWarehouse', saga });

const withConnect = connect(
  selectState(
    'warehouses.invalidControls',
    'warehouses.formControls',
    'warehouses.deleteIsOpen',
    'warehouses.toDeleteId',
    'warehouses.formIsOpen',
    'warehouses.toEditId',
    'warehouses.edit',
    'app.objects',
  ),
  actionProps(clone(clone.OBJECT, homeActions, warehousesActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect,
)(withSaga(Warehouses));
