import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import connectSaga from 'utils/saga';
import saga from 'screens/ProductsReport/saga';
import { selectState } from 'utils/reduxActions';
// ui
import { Grid, Card, CardContent, IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
// components
import StyledCardHeader from 'commons/components/StyledCardHeader';
// utils
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables, ChartDataLabels);

let renderedMarginChart;

const labelFormatted = (content) => {
  let label = content.split(' ');
  let formatLabel = [];
  for (let i = 0; i < Math.ceil(label.length / 2); i++) {
    formatLabel.push(label[2 * i] + (2 * i + 1 >= label.length ? '' : ' ' + label[2 * i + 1]));
  }
  return formatLabel;
};

const ScrollArrows = () => {
  const steps = 3;

  const selectedData = (position) => {
    renderedMarginChart.options.scales.x.min += position;
    renderedMarginChart.options.scales.x.max += position;
  };

  const scrollChart = (direction) => {
    const maxDisplay = renderedMarginChart.data.labels.length - 1;
    selectedData(direction === 'left' ? -1 * steps : steps);

    if (direction === 'left') {
      if (renderedMarginChart.options.scales.x.min <= 0) {
        renderedMarginChart.options.scales.x.min = 0;
        renderedMarginChart.options.scales.x.max = steps;
      }
    } else {
      if (renderedMarginChart.options.scales.x.max >= maxDisplay) {
        renderedMarginChart.options.scales.x.min = maxDisplay - steps;
        renderedMarginChart.options.scales.x.max = maxDisplay;
      }
    }
    renderedMarginChart.update();
  };

  return (
    <>
      <IconButton onClick={() => scrollChart('left')}>
        <ChevronLeft />
      </IconButton>
      <IconButton onClick={() => scrollChart('right')}>
        <ChevronRight />
      </IconButton>
    </>
  );
};

const MarginChart = (props) => {
  const {
    productionGraphData: data,
    objects: { products },
    classes,
    marginData,
    productCosts,
    accumulatedCost,
    productCosts2,
    productsFiltered,
  } = props;

  const productsToLoad = productsFiltered.length > 0 ? Object.keys(productCosts).filter((key) => productsFiltered.includes(parseInt(key))) : Object.keys(productCosts) ;

  const config = {
    type: 'bar',
    data: {
      labels: productsToLoad.map((pId) => labelFormatted(products[pId].name)),
      datasets: [
        {
          label: 'Ventas',
          data: productsToLoad
            .map(
              (d) =>
                marginData[d]?.value -
                Object.values(marginData[d]?.returns || {}).reduce((acum, r) => acum + r.value, 0),
            )
            .map((v) => v.toFixed(0)),
          backgroundColor: '#D6E9C6',
        },
        {
          label: 'Costos',
          data: productsToLoad.map( id => productCosts[id]?.toFixed(0)),
          backgroundColor: '#EBCCD1',
        },
      ],
    },
    plugins: [ChartDataLabels],
    options: {
      locale: 'en-DE',
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        tooltip: {
          intersect: false,
          mode: 'index',
        },
        datalabels: {
          font: {
            weight: 'bold',
          },
          formatter: function (value, context) {
            return '$ ' + new Intl.NumberFormat('en-DE').format(Number(value));
          },
        },
      },
      scales: {
        x: {
          min: 0,
          max: 3,
          ticks: {
            font: {
              size: 10,
            },
          },
        },
        y: {
          display: true,
          ticks: {
            callback: (value, index, values) => {
              return '$ ' + new Intl.NumberFormat('en-DE').format(value);
            },
            beginAtZero: true,
          },
        },
      },
    },
  };

  useEffect(() => {
    const ctx = document.getElementById('margin-chart');
    if (renderedMarginChart) renderedMarginChart.destroy();
    renderedMarginChart = new Chart(ctx, config);
    renderedMarginChart.resize();
  }, [data, marginData, products, productCosts, accumulatedCost,productsToLoad]);

  return (
    <Grid item sm={5} xs={12}>
      <Card className={classes.marginChartSubModule}>
        <StyledCardHeader title="Costo por producto" action={<ScrollArrows />} />
        <CardContent className={classes.cardContent}>
          <div style={{ height: '100%', overflow: 'auto' }}>
            <canvas id="margin-chart" style={{ minHeight: 300, position: 'relative' }}></canvas>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

const withSaga = connectSaga({ key: 'sagaProductsReport', saga });

const withConnect = connect(
  selectState(
    'productsReports.productionGraphData',
    'productsReports.marginData',
    'productsReports.productCosts',
    'productsReports.accumulatedCost',
    'productsReports.objects',
    'productsReports.productsFiltered'
  ),
  null,
);

export default compose(withConnect)(withSaga(MarginChart));
