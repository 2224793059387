/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

/* Material UI */
import { ThemeProvider } from '@material-ui/core/styles';

/* React Router */
import { withRouter, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
/* Redux */
import { compose } from 'redux';
import { connect, Provider } from 'react-redux';

/* Socket IO */
import socketIOClient, { io } from 'socket.io-client';

/* Project */
import { appActions } from 'commons/reducer';
import { actionProps, selectState } from 'utils/reduxActions';
import { ReactReduxContext } from 'config/configureStore';
import connectSaga from 'utils/saga';
import PrivateRoute from 'utils/hooks/PrivateRoute';
import saga from 'commons/saga';
import Login from 'screens/Login';
import { falsy, getItemInStorage, truthty } from 'utils/functions';
import LoadingScreen from 'commons/components/LoadingScreen';
import Logout from 'screens/Logout';
import store from 'index';
import environments from 'config/environments';
import AppRoutes from './routes';
import AppTheme from './theme';
// import jwt from 'jsonwebtoken';

function App(props) {
  const [isShowingZendeskButton, setIsShowingZendeskButton] = useState(false);

  const { user, actions, loadingUser, role, account, i18n, socketId } = props;

  useEffect(() => {
    if (falsy(user)) {
      actions.getUser();
    }

    actions.activate();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user) {
      actions.getAppStateFromApi();
    }
  }, []);

  useEffect(() => {
    if (truthty(account)) {
      window.document.title = account.companyName || 'Pakit';
      i18n.changeLanguage(account.country.toUpperCase());
    }
  }, [account]);

  const authSocketPayload = useMemo(() => {
    const userToken = getItemInStorage('user')?.token;
    return { token: userToken };
  }, [user]);

  const webSocketInstance = useCallback(() => {
    const payload = { auth: { token: authSocketPayload.token } };
    return environments.WS_ENDPOINT
      ? socketIOClient(environments.WS_ENDPOINT, payload)
      : io(payload);
  }, [authSocketPayload]);

  const socket = useMemo(() => {
    if (user) return webSocketInstance();

    return null;
  }, [user]);

  useEffect(() => {
    if (socket) {
      console.log('socket => ', socket);

      socket.on('connect', () => {
        console.log('[Socket] Connected', { socketId: socket.id });

        actions.setSocketId(socket.id);

        socket.emit('[APP] Suscribe');

        socket.on(`id-${socket.id}:[Queue Report]`, (data) => {
          console.info({ queueNotification: data });
          actions.productsReportSocketSuccess({ productsReport: data.result });
          actions.queueNotificationStatus({ status: 'completed', data: data });
        });

        socket.on(`id-${socket.id}:[Queue Traceability]`, (data) => {
          console.info({ queueNotification: data });
          actions.lotTraceabilitySocketSuccess({ traceabilityReport: data.result });
          actions.queueNotificationStatus({ status: 'completed', data: data });
        });

        socket.on(`id-${socket.id}:[Queue ProcessesExport]`, (data) => {
          console.info({ queueNotification: data });
          actions.exportExcelReady( data.result );
          actions.exportingExcel();
        });

        socket.on(`id-${socket.id}:[Queue ReceiptsExport]`, (data) => {
          console.info({ queueNotification: data });
          actions.exportExcelReady( data.result );
          actions.exportingExcel();
        });

        socket.on(`id-${socket.id}:[Queue LotsExport]`, (data) => {
          console.info({ queueNotification: data });
          actions.exportExcelReady( data.result );
          actions.exportingExcel();
        });

      });

      socket.on('[Notification]', (data) => {
        console.info({ notification: data });
      });

      socket.on('exception', (exception) => {
        console.log('[Socket] Exception', { exception });
      });

      socket.on('disconnect', () => {
        console.log('[Socket] Disconnected');
      });

      return () => {
        socket.disconnect();
      };
    }

    return () => {};
  }, [socket]);

  const [zendeskShows, setZendeskShows] = useState(window.location.hostname.split('.')[0] === 'demo' ? null : false);
  const [zendeskUnreadIndicator, setZendeskUnreadIndicator] = useState(0);

  //Function in charge of open and close the message
  const chasqikCreateMessenger = () => {
    if (zendeskShows) {
      zE('messenger', 'close');
      setZendeskShows(false);
      // eslint-disable-next-line no-undef
    } else {
      setZendeskShows(true);
      // eslint-disable-next-line no-undef
      zE('messenger', 'open');
      // setZendeskUnreadIndicator(0);
    }
  };

  return loadingUser ? (
    <LoadingScreen />
  ) : (
    <ThemeProvider theme={AppTheme}>
      <Provider store={store}>
        {AppRoutes.map((route) => (
          <PrivateRoute
            key={`router-${route.module}`}
            zendesk={{
              chasqikCreateMessenger,
            }}
            exact
            role={role}
            module={route.module}
            component={route.component}
          />
        ))}
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/" component={Login} />
      </Provider>
    </ThemeProvider>
  );
}

// ------------------ CONFIG CONTAINER ------------------

const withSaga = connectSaga({ key: 'sagaApp', saga });

const withConnect = connect(
  selectState('app.loadingUser', 'app.role', 'app.user', 'app.account', 'app.socketId'),
  actionProps(appActions),
  null,
  { context: ReactReduxContext },
);

export default compose(withTranslation(), withRouter, withConnect)(withSaga(App));
