/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-filename-extension */
// @ts-nocheck
import React from 'react';
// material
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
// icons
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
// styles
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { fCurrency } from '../../../../utils/functions';
import styles from './styles';
// containers
import ButtonWithLoader from '../../../../commons/containers/ButtonWithLoader';
// components
import DatePicker from '../../../../commons/components/DatePicker';
import StyledTableCell from '../../../../commons/components/StyledTableCell';
// utils

const useStyles = makeStyles(styles);

const PurchaseForm = (props) => {
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    serviceLines,
    supplyProductLines,
    controls,
    toggleForm,
    isOpen,
    edit,
    changeControls,
    invalidControls,
    suppliers,
    supplyProducts,
    clearEditLine,
    editLine,
    addServiceLine,
    warehouses,
    submit,
    vatPercentage,
  } = props;

  // total amount
  let total = 0;
  serviceLines.forEach((line) => {
    total += line.quantity * line.price;
  });

  supplyProductLines.forEach((line) => {
    total += line.quantity * line.price;
  });

  if (controls.iva) {
    total *= (1 + vatPercentage); //vatPercentage is a float with 0.xx value, by default is 0.19
  }

  total += Number(controls.additionalTaxes);
  total = Math.round((total + Number.EPSILON) * 100) / 100;

  return (
    <Dialog
      fullWidth
      onClose={toggleForm}
      maxWidth="md"
      open={isOpen}
      fullScreen={fullScreen}
    >
      <DialogTitle>{`${edit ? 'Editar' : 'Crear'} adquisición o egreso`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6" component="h6">
              Datos generales
            </Typography>
          </Grid>
          <Grid item md={4} xs={6}>
            <DatePicker
              inputId="issueDate"
              label="Fecha de emisión"
              type="date"
              fullWidth
              value={controls.issueDate}
              onChange={changeControls}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <DatePicker
              inputId="expirationDate"
              label="Fecha de vencimiento"
              type="date"
              fullWidth
              value={controls.expirationDate}
              onChange={changeControls}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <Autocomplete
              id="autocomplete-suppliers-filters-purchases"
              options={Object.values(suppliers).sort((a, b) => (a.entity().name.toLowerCase() > b.entity().name.toLowerCase() ? 1 : -1))}
              getOptionLabel={(supplier) => supplier && supplier.entity().name}
              renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Proveedor" margin="normal" />}
              onChange={(event, newValue) => {
                const e = {
                  autocomplete: true,
                  input: 'supplierId',
                  value: newValue ? newValue.id : '',
                };
                changeControls(e);
              }}
            />
            {props.invalidControls.supplierId && <FormHelperText style={{ color: 'red' }}>Debe ingresar un Proveedor</FormHelperText>}
            {/* <FormControl fullWidth>
              <InputLabel id="supplier-label">Proveedor</InputLabel>
              <Select
                labelId="supplier-label"
                fullWidth
                value={controls.supplierId}
                onChange={changeControls}
                error={invalidControls.supplierId}
              >
                <MenuItem id="supplierId" value="" />
                {Object.values(suppliers).map((supplier) => (
                  <MenuItem
                    key={`supplier-form-option-${supplier.id}`}
                    id="supplierId"
                    value={supplier.id}
                  >
                    {supplier.entity().name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              fullWidth
              id="documentNumber"
              label="Número de documento"
              type="number"
              value={controls.documentNumber}
              onChange={changeControls}
              error={invalidControls.documentNumber}
              helperText={invalidControls.documentNumber && 'Debe ingresar un número de documento'}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6" component="h6">
              Egresos
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                defaultValue="top"
                onChange={changeControls}
                value={controls.lineForm}
              >
                <FormControlLabel
                  value="supplyProduct"
                  control={<Radio color="primary" id="lineForm" />}
                  label="Insumo"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="service"
                  control={<Radio color="primary" id="lineForm" />}
                  label="Servicio"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {controls.lineForm === 'service' ? (
            <Grid item md={3} xs={6}>
              <TextField
                fullWidth
                id="description"
                label="Descripción"
                value={controls.description}
                onChange={changeControls}
                error={invalidControls.description}
                helperText={invalidControls.description && 'Debe ingresar una descripción'}
              />
            </Grid>
          ) : (
            <Grid item md={3} xs={6}>
              <Autocomplete
                value={controls.supplyProductId && Object.values(supplyProducts).find((sp) => sp.id === controls.supplyProductId)}
                id="autocomplete-suppliesProduct-purchases"
                options={Object.values(supplyProducts).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))}
                getOptionLabel={(supplyProduct) => supplyProduct && supplyProduct.name}
                renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Insumo" margin="normal" />}
                onChange={(event, newValue) => {
                  const e = {
                    autocomplete: true,
                    input: 'supplyProductId',
                    value: newValue ? newValue.id : '',
                  };
                  changeControls(e);
                }}
              />
              {/* <FormControl fullWidth>
                <InputLabel id="supplier-label">Insumo</InputLabel>
                <Select
                  labelId="supplier-label"
                  fullWidth
                  value={controls.supplyProductId}
                  onChange={changeControls}
                  error={invalidControls.supplyProductId}
                >
                  <MenuItem id="supplyProductId" value="">Ninguno</MenuItem>
                  {Object.values(supplyProducts).map((supplyProduct) => (
                    <MenuItem
                      key={`supply-product-form-option-${supplyProduct.id}`}
                      id="supplyProductId"
                      value={supplyProduct.id}
                    >
                      {supplyProduct.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              {props.invalidControls.supplyProductId && <FormHelperText style={{ color: 'red' }}>Debe ingresar un insumo</FormHelperText>}
            </Grid>
          )}
          <Grid item md={2} xs={6}>
            <TextField
              fullWidth
              id="quantity"
              label="Cantidad"
              type="number"
              value={controls.quantity}
              onChange={changeControls}
              error={invalidControls.quantity}
              helperText={invalidControls.quantity && 'Debe ingresar una cantidad valida'}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <TextField
              fullWidth
              id="price"
              label="Precio"
              type="number"
              value={controls.price}
              onChange={changeControls}
              error={invalidControls.price}
              helperText={invalidControls.price && 'Debe ingresar un precio valido'}
            />
          </Grid>
          {controls.toEditServiceLineIndex !== '' ? (
            <>
              <Grid item md={2} xs={3}>
                <Button
                  fullWidth
                  size="small"
                  onClick={clearEditLine}
                  startIcon={<ClearIcon />}
                  variant="contained"
                  className={classes.filterButton}
                  color="primary"
                >
                  Limpiar
                </Button>
              </Grid>
              <Grid item md={2} xs={3}>
                <Button
                  fullWidth
                  size="small"
                  startIcon={<EditIcon />}
                  onClick={editLine}
                  variant="contained"
                  className={classes.filterButton}
                  color="primary"
                >
                  Editar
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item md={3} xs={6}>
              <Button
                fullWidth
                size="small"
                startIcon={<AddIcon />}
                onClick={addServiceLine}
                variant="contained"
                className={classes.filterButton}
                color="primary"
              >
                Agregar
              </Button>
            </Grid>
          )}
          {controls.lineForm !== 'service' && (
            <Grid item md={3} xs={6}>
              <FormControl fullWidth>
                <InputLabel id="supplier-label">Bodega</InputLabel>
                <Select
                  labelId="supplier-label"
                  fullWidth
                  value={controls.warehouseId}
                  onChange={changeControls}
                  error={invalidControls.warehouseId}
                >
                  <MenuItem id="warehouseId" value="">Ninguna</MenuItem>
                  {Object.values(warehouses)
                    .filter((warehouse) => warehouse.supplyWarehouse)
                    .map((warehouse) => (
                      <MenuItem
                        key={`warehouse-form-option-${warehouse.id}`}
                        id="warehouseId"
                        value={warehouse.id}
                      >
                        {warehouse.name}
                      </MenuItem>
                    ))}
                </Select>
                {props.invalidControls.warehouseId && <FormHelperText style={{ color: 'red' }}>Debe ingresar una bodega</FormHelperText>}
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <TableContainer component={Paper} className={classes.tableRoot}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Producto/Servicio</StyledTableCell>
                    <StyledTableCell align="right">Bodega</StyledTableCell>
                    <StyledTableCell align="right">Cantidad</StyledTableCell>
                    <StyledTableCell align="right">Precio</StyledTableCell>
                    <StyledTableCell align="right">Subtotal</StyledTableCell>
                    <StyledTableCell align="right" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {serviceLines.map((row, index) => (
                    <TableRow key={`${row.description}-${index}`}>
                      <StyledTableCell component="th" scope="row">
                        {row.description}
                      </StyledTableCell>
                      <StyledTableCell align="right" />
                      <StyledTableCell align="right">{row.quantity}</StyledTableCell>
                      <StyledTableCell align="right">{fCurrency.format(row.price)}</StyledTableCell>
                      <StyledTableCell align="right">{fCurrency.format(row.quantity * row.price)}</StyledTableCell>
                      <StyledTableCell align="right">
                        <IconButton data-index={index} data-type="serviceLines" onClick={props.setEditLine}><EditIcon /></IconButton>
                        <IconButton data-index={index} data-type="serviceLines" onClick={props.deleteLine}><DeleteIcon /></IconButton>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                  {supplyProductLines.map((row, index) => (
                    <TableRow key={`${row.description}-${index}`}>
                      <StyledTableCell component="th" scope="row">
                        {supplyProducts[row.supplyProductId].name}
                      </StyledTableCell>
                      <StyledTableCell align="right">{warehouses[row.warehouseId].name}</StyledTableCell>
                      <StyledTableCell align="right">{row.quantity}</StyledTableCell>
                      <StyledTableCell align="right">{fCurrency.format(row.price)}</StyledTableCell>
                      <StyledTableCell align="right">{fCurrency.format(row.quantity * row.price)}</StyledTableCell>
                      <StyledTableCell align="right">
                        <IconButton data-index={index} data-type="supplyProductLines" onClick={props.setEditLine}><EditIcon /></IconButton>
                        <IconButton data-index={index} data-type="supplyProductLines" onClick={props.deleteLine}><DeleteIcon /></IconButton>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <TextField
                  fullWidth
                  id="additionalTaxes"
                  label="Otros impuestos"
                  type="number"
                  value={controls.additionalTaxes}
                  onChange={changeControls}
                  size="small"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox id="iva" checked={controls.iva} onChange={changeControls} />
                }
                  label="IVA"
                />
              </Grid>
              <Grid item>
                <div className={classes.totalLabel}>
                  Total:
                  {' '}
                  {fCurrency.format(total)}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleForm} color="primary" variant="outlined">
          Cancelar
        </Button>
        <ButtonWithLoader onClick={submit} autoFocus buttonId="create-edit-purchase">
          {edit ? 'Editar' : 'Crear'}
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  );
};

export default PurchaseForm;
