// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';

import apiRequest, { apiSuccess, post, get } from 'utils/api';
import { PURCHASES, purchasesActions } from '../reducer';
import { truthty } from 'utils/functions';
import { objectToQueryString } from 'utils/api';
import { appActions } from 'commons/reducer';
import controlledCall from 'utils/services/controlledSaga';

function* getStateFromApi() {
  yield takeLatest(PURCHASES.GET_STATE_FROM_API, function* (action) {
    yield put(appActions.setLoader('get-purchases', true));
    yield controlledCall(get, 'api/v1/purchases', objectToQueryString(action.filters), purchasesActions.getStateFromApiSuccess);
    yield put(appActions.setLoader('get-purchases', false));
  });
}

function* createPurchase() {
  yield takeLatest(PURCHASES.CREATE_PURCHASE, function* (action) {
    yield put(appActions.setLoader('create-edit-purchase', true));
    const body = {
      documentNumber: action.controls.documentNumber,
      expirationDate: action.controls.expirationDate,
      issueDate: action.controls.issueDate,
      supplierId: action.controls.supplierId,
      serviceLines: action.serviceLines,
      supplyProductLines: action.supplyProductLines,
      additionalTaxes: truthty(action.controls.additionalTaxes) ? action.controls.additionalTaxes : 0,
      iva: action.controls.iva,
    };
    yield controlledCall(post, 'api/v1/purchases', body, purchasesActions.createPurchaseSuccess);
    yield put(appActions.setLoader('create-edit-purchase', false));
  });
}

function* editPurchase() {
  yield takeLatest(PURCHASES.EDIT_PURCHASE, function* (action) {
    yield put(appActions.setLoader('create-edit-purchase', true));
    const response = yield apiRequest(`api/v1/purchases/${action.id}`,
      {
        method: 'put',
        body: JSON.stringify({
          documentNumber: action.controls.documentNumber,
          expirationDate: action.controls.expirationDate,
          issueDate: action.controls.issueDate,
          supplierId: action.controls.supplierId,
          serviceLines: action.serviceLines,
          supplyProductLines: action.supplyProductLines,
          additionalTaxes: truthty(action.controls.additionalTaxes) ? action.controls.additionalTaxes : 0,
          iva: action.controls.iva,
        }),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PURCHASES.EDIT_PURCHASE_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-purchase', false));
  });
}

function* deletePurchase() {
  yield takeLatest(PURCHASES.DELETE_PURCHASE, function* (action) {
    yield put(appActions.setLoader('delete-purchase', true));
    const response = yield apiRequest(`api/v1/purchases/${action.id}`, { method: 'delete' });
    if (response.error) {
      yield put(appActions.setError('Hubo un error al eliminar la compra. Por favor, inténtalo de nuevo.'));
    } else {
      yield put(apiSuccess(PURCHASES.DELETE_PURCHASE_SUCCESS, { item: response, type: 'purchases' }));
    }

    yield put(appActions.setLoader('delete-purchase', false));
  });
};

export default function* saga() {
  yield spawn(getStateFromApi);
  yield spawn(deletePurchase);
  yield spawn(createPurchase);
  yield spawn(editPurchase);
}
