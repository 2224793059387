// @ts-nocheck
import React from 'react';
// material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import TaxNumberField from 'commons/components/TaxNumberField';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';

const EmployeeForm = props => (
  <Dialog fullWidth onClose={props.toggleForm} aria-labelledby="simple-dialog-title" open={props.isOpen}>
    <DialogTitle>{`${props.edit ? 'Editar' : 'Crear'} empleado`}</DialogTitle>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            value={props.controls.name}
            onChange={props.changeControls}
            fullWidth
            id="name"
            label="Nombre"
            error={props.invalidControls.name}
            helperText={props.invalidControls.name && 'Debe ingresar un nombre'}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TaxNumberField
            value={props.controls.taxNumber}
            onChange={props.onChange}
            fullWidth
	    label={props.t("identifier:name")}
            error={props.invalidControls.taxNumber}
            helperText={props.invalidControls.taxNumber && props.t("identifier:validate_message")}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.controls.subcontracted}
                onChange={props.changeControls}
                id="subcontracted"
                color="primary"
              />
            }
            label="Contratista"
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.toggleForm} color="primary">
        Cancelar
        </Button>
      <ButtonWithLoader onClick={props.submit} autoFocus buttonId="create-edit-employee">
        {props.edit ? 'Editar' : 'Agregar'}
      </ButtonWithLoader>
    </DialogActions>
  </Dialog>
);

export default withTranslation()(EmployeeForm);
