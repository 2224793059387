// @ts-nocheck
import React, { Fragment } from 'react';
// material
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// containers
import DatePicker from 'commons/components/DatePicker';
// icons
import AddIcon from '@material-ui/icons/Add';

const FormInputs = props => (
  <Fragment>
    <Grid item sm={6} xs={12}>
      <DatePicker
        inputId="date"
        label="fecha"
        fullWidth
        value={props.controls.date}
        onChange={props.changeControls}
      />
    </Grid>
    <Grid item sm={6} xs={12}>
      <TextField
        value={props.controls.description}
        onChange={props.changeControls}
        fullWidth
        id="description"
        label="Motivo"
        error={props.invalidControls.description}
        helperText={props.invalidControls.description && 'Debe ingresar un motivo'}
      />
    </Grid>
    <Grid item sm={6} xs={12}>
      <TextField
        type="number"
        value={props.controls.dispatchNumber}
        onChange={props.changeControls}
        fullWidth
        id="dispatchNumber"
        label="Número de despacho"
        error={props.invalidControls.dispatchNumber}
        helperText={props.invalidControls.dispatchNumber && 'Debe ingresar un número de despacho'}
      />
    </Grid>
    <Grid item sm={6} xs={12}>
      <Button
        fullWidth
        size="small"
        startIcon={<AddIcon />}
        onClick={props.setDispatchLots}
        variant="contained"
        className={props.classes.filterButton}
        color="primary"
      >
        Buscar
        </Button>
    </Grid>
  </Fragment>
);

export default FormInputs;
