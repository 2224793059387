// @ts-nocheck
import React from 'react';
// material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TaxNumberField from 'commons/components/TaxNumberField';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import {withTranslation} from 'react-i18next';

const ClientForm = props => (
  <Dialog fullWidth onClose={props.toggleForm} aria-labelledby="simple-dialog-title" open={props.isOpen}>
    <DialogTitle>{`${props.edit ? 'Editar' : 'Crear'} cliente`}</DialogTitle>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            value={props.controls.name}
            onChange={props.changeControls}
            fullWidth
            id="name"
            label="Nombre"
            error={props.invalidControls.name}
            helperText={props.invalidControls.name && 'Debe ingresar un nombre'}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TaxNumberField
            value={props.controls.taxNumber}
            onChange={props.onChange}
            label={props.t("identifier:name")}
            fullWidth
            error={props.invalidControls.taxNumber}
            helperText={props.invalidControls.taxNumber && props.t("identifier:validate_message")}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={props.controls.email}
            onChange={props.changeControls}
            fullWidth
            id="email"
            type="email"
            label="Email"
            error={props.invalidControls.email}
            helperText={props.invalidControls.email && 'Debe ingresar un email válido'}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={props.controls.code}
            onChange={props.changeControls}
            fullWidth
            id="code"
            label="Código"
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={props.controls.region}
            onChange={props.changeControls}
            fullWidth
            id="region"
            label="Región"
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={props.controls.province}
            onChange={props.changeControls}
            fullWidth
            id="province"
            label="Provincia"
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={props.controls.sector}
            onChange={props.changeControls}
            fullWidth
            id="sector"
            label="Sector"
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={props.controls.address}
            onChange={props.changeControls}
            fullWidth
            id="address"
            label="Dirección"
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={props.controls.isInternalClient}
                onChange={props.changeControls}
                fullWidth
                id="isInternalClient"
                disabled={props.controls.isDefaultClient}
              />
            )}
            label="Cliente interno"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={props.controls.isDefaultClient}
                onChange={props.changeControls}
                fullWidth
                id="isDefaultClient"
                disabled={props.controls.isInternalClient}
              />
            )}
            label="Cliente por defecto"
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.toggleForm} color="primary">
        Cancelar
        </Button>
      <ButtonWithLoader onClick={props.submit} autoFocus buttonId="create-edit-client">
        {props.edit ? 'Editar' : 'Agregar'}
      </ButtonWithLoader>
    </DialogActions>
  </Dialog>
);

export default withTranslation()(ClientForm);
