/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';
import moment from 'moment';

// Local constants
const PATH = 'dispatches/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_EDIT_DISPATCH = `${PATH}TOGGLE_EDIT_DISPATCH`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;
const CHANGE_PRODUCT_VALUES = `${PATH}CHANGE_PRODUCT_VALUES`;

// Global constants for saga
export const DISPATCHES = {
  CREATE_DISPATCH: `${PATH}CREATE_DISPATCH`,
  CREATE_DISPATCH_SUCCESS: `${PATH}CREATE_DISPATCH_SUCCESS`,
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  DELETE_DISPATCH: `${PATH}DELETE_DISPATCH`,
  DELETE_DISPATCH_SUCCESS: `${PATH}DELETE_DISPATCH_SUCCESS`,
  EDIT_DISPATCH_SUCCESS: `${PATH}EDIT_DISPATCH_SUCCESS`,
  EDIT_DISPATCH: `${PATH}EDIT_DISPATCH`,
  CHANGE_PAGINATION: `${PATH}CHANGE_PAGINATION`,
  EXPORT_DISPATCHES: `${PATH}EXPORT_DISPATCHES`,
  EXPORT_DISPATCHES_SUCCESS: `${PATH}EXPORT_DISPATCHES_SUCCESS`,
  NEW_DISPATCH: `${PATH}NEW_DISPATCH`,
  NEW_DISPATCH_SUCCESS: `${PATH}NEW_DISPATCH_SUCCESS`,
  CHANGE_FILTERS: `${PATH}CHANGE_FILTERS`,
};

// actions
export const dispatchesActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  toggleEdit: createAction(TOGGLE_EDIT_DISPATCH, 'item', 'lots'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  changeProductValues: createAction(CHANGE_PRODUCT_VALUES, 'controls'),
  // api
  changeFilters: createAction(DISPATCHES.CHANGE_FILTERS, 'filter'),
  paginationControls: createAction(DISPATCHES.CHANGE_PAGINATION, 'filters', 'pagination'),
  getStateFromApi: createAction(DISPATCHES.GET_STATE_FROM_API, 'filters', 'pagination'),
  newDispatch: createAction(DISPATCHES.NEW_DISPATCH, 'modal'),
  createDispatch: createAction(DISPATCHES.CREATE_DISPATCH, 'controls'),
  editDispatch: createAction(DISPATCHES.EDIT_DISPATCH, 'id', 'controls'),
  deleteDispatch: createAction(DISPATCHES.DELETE_DISPATCH, 'id'),
  exportDispatches: createAction(DISPATCHES.EXPORT_DISPATCHES, 'controls'),
};

const defaultInvalidInputs = {
  date: false,
  dispatchNumber: false,
  clientId: false,
  lots: false,
  productValues: false,
};

const defaultControls = {
  date: moment().toISOString(),
  dispatchNumber: '',
  clientId: '',
  lots: [],
  productValues: {},
  observation: '',
};

const initialState = {
  formControls: { ...defaultControls },
  invalidControls: { ...defaultInvalidInputs },
  dispatchList: [],
  formIsOpen: false,
  deleteIsOpen: false,
  toDeleteId: '',
  toEditId: '',
  edit: false,
  error: false,
  pagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0,
  },
  loadingDispatches: false,
  lastDispatch: 1,
  inputSearch: {},
};

const dispatches = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.edit = false;
    state.formControls = { ...defaultControls };
    state.invalidControls = { ...defaultInvalidInputs };
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls,
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    };
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [TOGGLE_EDIT_DISPATCH](state, action) {
    state.formIsOpen = true;
    state.toEditId = action.item.id;
    state.formControls = {
      date: moment(action.item.date).toISOString(),
      dispatchNumber: action.item.number,
      clientId: action.item.clientId,
      lots: action.lots,
      observation: action.item.observation,
      productValues: action.lots.reduce((acum, lot) => {
        acum[lot.productId] = lot.price;
        return acum;
      }, {}),
    };
    state.edit = true;
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    };
  },
  [CHANGE_PRODUCT_VALUES](state, action) {
    state.formControls.productValues = {
      ...state.formControls.productValues,
      ...action.controls,
    };
    state.invalidControls.productValues = false;
  },
  // api
  [DISPATCHES.GET_STATE_FROM_API](state, action) {
    state.loadingDispatches = true;
  },
  [DISPATCHES.GET_STATE_FROM_API_SUCCESS](state, action) {
    state.lastDispatch = action.payload?.lastDispatch?.[0]?.number || 1;
    state.dispatchList = action.payload.dispatches;
    state.pagination = {
      ...state.pagination,
      ...(action.pagination || {}),
      count: action.payload.count,
    };
    state.loadingDispatches = false;
  },
  [DISPATCHES.CREATE_DISPATCH_SUCCESS](state, action) {
    state.lastDispatch += 1;
    state.formIsOpen = false;
    state.formControls = { ...defaultControls };
    state.invalidControls = { ...defaultInvalidInputs };
    state.pagination.count = state.pagination.count + 1;
    if (state.pagination.page === 0 && state.pagination.count > state.pagination.rowsPerPage) {
      state.dispatchList = [...action.payload.dispatches, ...state.dispatchList.slice(0, -1)];
    } else if (state.pagination.page === 0) {
      state.dispatchList = [...action.payload.dispatches, ...state.dispatchList];
    } 
  },
  [DISPATCHES.DELETE_DISPATCH_SUCCESS](state, action) {
    state.deleteIsOpen = false;
    state.toDeleteId = '';
    state.pagination.count = state.pagination.count - 1;
    state.dispatchList = state.dispatchList.filter(
      (dispatch) => dispatch !== action.payload.dispatchToDelete.id,
    );
  },
  [DISPATCHES.EDIT_DISPATCH_SUCCESS](state, action) {
    state.formControls = { ...defaultControls };
    state.invalidControls = { ...defaultInvalidInputs };
    state.formIsOpen = false;
    state.toDeleteId = '';
    state.toEditId = '';
    state.edit = false;
  },
  [DISPATCHES.CHANGE_PAGINATION](state, action) {
    state.loadingDispatches = true;
    state.pagination = {
      ...state.pagination,
      ...action.pagination,
    };
  },
  [DISPATCHES.CHANGE_FILTERS](state, action) {
    state.inputSearch = {
      ...state.inputSearch,
      ...action.filter,
    };
  },
});

export default dispatches;
