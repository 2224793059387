import camelize from 'camelcase-keys-deep';
export default class VersionHistories {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  user() {
    let response = null;
    if(this.userId) {
      response = this.env().User.findBy('id', this.userId)
    }
    return response;
  }
}