// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';

import apiRequest, { apiSuccess, objectToQueryString } from 'utils/api';
import { PROCESSES } from '../reducer';
import { truthty } from 'utils/functions';
import { appActions } from 'commons/reducer';

function* getStateFromApi() {
  yield takeLatest([PROCESSES.GET_STATE_FROM_API, PROCESSES.CHANGE_PAGINATION], function* (action) {
    yield put(appActions.setLoader('get-process', true));
    const response = yield apiRequest(`api/v1/processes?${objectToQueryString({
      page: action.pagination.page,
      rowsPerPage: action.pagination.rowsPerPage,
      ...action.filters,
    })}`, { method: 'get' });
    yield put(apiSuccess(PROCESSES.GET_STATE_FROM_API_SUCCESS, response));
    yield put(appActions.setLoader('get-process', false));
  });
}

function* createProcess() {
  yield takeLatest(PROCESSES.CREATE_PROCESS, function* (action) {
    yield put(appActions.setLoader('create-edit-process', true));
    const response = yield apiRequest('api/v1/processes',
      {
        method: 'post',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PROCESSES.CREATE_PROCESS_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-process', false));
  });
}

function* editProcess() {
  yield takeLatest(PROCESSES.EDIT_PROCESS, function* (action) {
    yield put(appActions.setLoader('create-edit-process', true));
    const response = yield apiRequest(`api/v1/processes/${action.id}`,
      {
        method: 'put',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PROCESSES.EDIT_PROCESS_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-process', false));
  });
}

function* deleteProcess() {
  yield takeLatest(PROCESSES.DELETE_PROCESS, function* (action) {
    yield put(appActions.setLoader('delete-process', true));
    const response = yield apiRequest(`api/v1/processes/${action.id}`, { method: 'delete' });
    if (response.error) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PROCESSES.DELETE_PROCESS_SUCCESS, { item: response, type: 'processes' }));
    }
    yield put(appActions.setLoader('delete-process', false));
  });
}

function* submitEmployeeCosts() {
  yield takeLatest(PROCESSES.SUBMIT_EMPLOYEE_COSTS, function* (action) {
    yield put(appActions.setLoader('submit-employee-costs', true));
    const data = new FormData();
    data.append('files', action.file, action.file.name);
    action.processesIds.forEach(id => data.append('processesIds[]', id));
    const response = yield apiRequest('api/v1/processes/addEmployeeCosts',
      {
        method: 'post',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      });
    if (truthty(response.error)) {
      yield put({ type: PROCESSES.SUBMIT_EMPLOYEE_COSTS_FAIL });
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PROCESSES.SUBMIT_EMPLOYEE_COSTS_SUCCESS, response));
    }
    yield put(appActions.setLoader('submit-employee-costs', false));
  });
}

export default function* saga() {
  yield spawn(submitEmployeeCosts);
  yield spawn(getStateFromApi);
  yield spawn(deleteProcess);
  yield spawn(createProcess);
  yield spawn(editProcess);
}
