// @ts-nocheck
import React from 'react';
// material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
// containers
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';

const VarietyForm = (props) => {
  const {
    isOpen,
    toggleForm,
    changeControls,
    controls,
    edit,
    submit,
    invalidControls,
    fruits,
  } = props;

  return (
    <Dialog fullWidth onClose={toggleForm} aria-labelledby="simple-dialog-title" open={isOpen}>
      <DialogTitle>{`${edit ? 'Editar' : 'Crear'} variedad`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <TextField
              value={controls.name}
              onChange={changeControls}
              fullWidth
              id="name"
              label="Nombre"
              error={invalidControls.name}
              helperText={invalidControls.name && 'Debe ingresar un nombre'}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              value={controls.fruitId ? fruits[controls.fruitId] : ''}
              id="autocomplete-fruit-varieties"
              options={Object.values(fruits).sort((a, b) => a.name - b.name)}
              getOptionLabel={(fruit) => fruit?.name || ''}
              renderInput={(params) => (
                <TextField
                  style={{ margin: 0 }}
                  {...params}
                  label="Especie"
                  margin="normal"
                  error={invalidControls.fruitId}
                  helperText={invalidControls.fruitId && 'Debe ingresar una especie'}
                />
              )}
              onChange={(event, newValue) => {
                const e = {
                  autocomplete: true,
                  input: 'fruitId',
                  value: newValue ? newValue.id : null,
                };
                changeControls(e);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleForm} color="primary">
          Cancelar
        </Button>
        <ButtonWithLoader onClick={submit} autoFocus buttonId="create-edit-variety">
          {edit ? 'Editar' : 'Agregar'}
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  );
};

export default VarietyForm;
