import camelize from 'camelcase-keys-deep';
export default class Lot {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  product() {
    let response = null;
    if (this.productId) {
      response = this.env().Product.findBy('id', this.productId);
    }
    return response;
  }

  warehouse() {
    let response = null;
    if (this.productId) {
      response = this.env().Warehouse.findBy('id', this.warehouseId);
    }
    return response;
  }

  variety() {
    let response = null;
    if (this.varietyId) {
      response = this.env().Variety.findBy('id', this.varietyId);
    }
    return response;
  }

  fruit() {
    let response = null;
    if (this.fruitId) {
      response = this.env().Fruit.findBy('id', this.fruitId);
    }
    return response;
  }

  packagingType() {
    let response = null;
    if (this.packagingTypeId) {
      response = this.env().PackagingType.findBy('id', this.packagingTypeId);
    }
    return response;
  }

  originProcess() {
    let response = null;
    if (this.originProcessId) {
      response = this.env().Process.findBy('id', this.originProcessId);
    }
    return response;
  }

  destinyProcess() {
    let response = null;
    if (this.destinyProcessId) {
      response = this.env().Process.findBy('id', this.destinyProcessId);
    }
    return response;
  }

  lotReceiptLots() {
    return this.env().LotReceiptLot.findAllBy('lotId', this.id);
  }

  lotNumbers() {
    const lotReceiptLots = this.lotReceiptLots();
    return [...new Set(lotReceiptLots.map(l => l.receiptLot().number))];
  }

  receiptLotCost() {
    const lotReceiptLots = this.lotReceiptLots();
    return lotReceiptLots.reduce((acum, lrl) => (
      acum +
      lrl.receiptLot().price *
      lrl.percentage *
      lrl.receiptLot().lots().reduce((acum, lot) => acum + lot.quantity, 0)
    ), 0);
  }

  return() {
    let response = null;
    if (this.destinyProcessId) {
      response = this.env().Return.findBy('dispatchProcessId', this.destinyProcessId);
    }
    return response;
  }

  originReceipts() {
    const lotReceiptLots = this.lotReceiptLots();
    const receipts = lotReceiptLots.reduce((acum, lotReceiptLot) => {
      const receiptLot = lotReceiptLot.receiptLot();
      const receipt = receiptLot.receipt();
      const receiptIds = acum.map((r) => r.id);
      if (receipt && !receiptIds.includes(receipt.id)) {
        acum.push(receipt);
      }

      return acum;
    }, []);

    return receipts;
  }
}
