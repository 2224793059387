/* eslint-disable import/no-unresolved */
// @ts-nocheck
import React from 'react';
import { ReactReduxContext } from 'config/configureStore';
import { Route, Redirect } from 'react-router-dom';
import Layout from 'commons/containers/Layout';
import Permits from 'utils/permits';
import Paths from 'utils/paths';

const PrivateRoute = ({ zendesk, component: Component, ...rest }) => (
  <Route
    path={Paths[rest.module]}
    exact={rest.exact}
    component={() => (
      Permits[rest.module].includes(rest.role.name) ? (
        <Layout zendesk={zendesk}>
          <Component context={ReactReduxContext} />
        </Layout>
      ) : <Redirect to="/logout" />
    )}
  />
);

export default PrivateRoute;
