const identifyWith = {
  id: ['Process', 'Lot', 'Employees', 'ProcessStaffLine', 'Line', 'ProcessEquipment', 'ProcessStaffLine', 'PurchaseLine', 'ServiceLine', 'SupplyLine', 'SupplyProductLine', 'WarehouseMovement'],
  name: ['Fruit', 'Product', 'SupplyProduct', 'PackagingType', 'ProcessType', 'WorkShiftType', 'Unit', 'Variety', 'TaxableDocumentType', 'Paddock'],
  code: ['Warehouse', 'Equipment'],
  email: ['Client', 'Supplier', 'User'],
  taxNumber: ['Entity'],
  number: ['Dispatch', 'ReceipLot', 'TaxableDocument'],
  dispatchOrderNumber: ['Receipt'],
  companyName: ['Account'],
  special1: ['LotReceiptLot']
}

const getIdentifier = (recordId, modelName, changes, taxNumberText = '') => {
  let identifier;
  if(identifyWith.id.includes(modelName)) {
    identifier = recordId
  } else if(identifyWith.name.includes(modelName) && changes?.name) {
    identifier = changes.name[0] || changes.name[1]
  } else if(identifyWith.code.includes(modelName) && changes?.code) {
    identifier = `Codigo: ${changes.code[0] || changes.code[1]}`
  } else if(identifyWith.email.includes(modelName) && changes?.email) {
    identifier = changes.email[0] || changes.email[1]
  } else if(identifyWith.taxNumber.includes(modelName) && changes?.taxNumber) {
    identifier = `${taxNumberText}: ${changes.taxNumber[0] || changes.taxNumber[1]}`
  } else if(identifyWith.number.includes(modelName) && changes?.number) {
    identifier = `Nº: ${changes.number[0] || changes.number[1]}`
  } else if(identifyWith.dispatchOrderNumber.includes(modelName) && changes?.dispatchOrderNumber) {
    identifier = `Orden: ${changes.dispatchOrderNumber[0] || changes.dispatchOrderNumber[1]}`
  } else if(identifyWith.companyName.includes(modelName) && changes?.companyName) {
    identifier = changes.companyName[0] || changes.companyName[1]
  } else if(identifyWith.special1.includes(modelName) && changes?.lotId && changes?.percentage) {
    identifier = `Pak Nº: ${changes.lotId[0] || changes.lotId[1]} - ${((changes.percentage[0] || changes.percentage[1])*100).toFixed(2)}%`
  }

  if(!identifier) identifier = recordId

  return identifier;
}

module.exports = { getIdentifier }
