import camelize from 'camelcase-keys-deep';
export default class Process {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  receipt() {
    return this.env().Receipt.findBy('processId', this.id);
  }

  processType() {
    let response = null;
    if (this.processTypeId) {
      response = this.env().ProcessType.findBy('id', this.processTypeId);
    }
    return response;
  }

  workShiftType() {
    let response = null;
    if (this.workShiftTypeId) {
      response = this.env().WorkShiftType.findBy('id', this.workShiftTypeId);
    }
    return response;
  }

  processCategories() {
    let response = null;
    if (this.processCategoryId) {
      response = this.env().ProcessCategories.findBy('id', this.processCategoryId);
    }
    return response;
  }

  departureLots() {
    const lots = this.env().Lot.findAllBy('originProcessId', this.id);
    return lots;
  }

  entryLots() {
    const lots = this.env().Lot.findAllBy('destinyProcessId', this.id);
    return lots;
  }

  cost() {
    const supplyLines = this.env().SupplyLine.findAllBy('processId', this.id);
    const lines = supplyLines.map((sl) => sl.warehouseMovement().line());
    const supplyCosts = lines.reduce((acum, l) => acum + l.quantity * l.price, 0);

    return supplyCosts + this.employeeCost;
  }

  wasteCost() {
    const entryLots = this.entryLots();
    const entriesQuantity = entryLots.reduce((acum, l) => acum + l.quantity, 0);
    const departuresQuantity = this.departureLots().reduce((acum, l) => acum + l.quantity, 0);
    const wastePercentage = (entriesQuantity - departuresQuantity) / entriesQuantity;

    const entryLotIds = entryLots.map((l) => l.id);
    const entryLrl = this.env()
      .LotReceiptLot.all()
      .filter((lrl) => entryLotIds.includes(lrl.lotId));

    const wasteCost = entryLrl.reduce(
      (acum, lrl) =>
        acum +
        lrl.receiptLot().price *
          lrl.receiptLot().totalQuantity() *
          lrl.percentage *
          wastePercentage,
      0,
    );

    return wasteCost;
  }

  createdByEmployee() {
    let versionHistory = this.env().VersionHistory.findAllBy('recordId', this.id);
    versionHistory = versionHistory.find(
      (item) => item.eventType === 'create' && item.modelName === 'Process',
    );
    const user = versionHistory ? this.env().User.findBy('id', versionHistory.userId) : null;
    return user ? user.email : '-';
  }
}
