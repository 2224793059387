import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import connectSaga from 'utils/saga';
import saga from 'screens/Balance/saga';
import { selectState } from 'utils/reduxActions';
import { useSelector } from 'react-redux';
// ui
import {
  Card,
  CardContent,
  CircularProgress,
  Container,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Filters from 'screens/Balance/components/Filters';
import InfoForm from 'screens/Balance/components/InfoForm';
import { FirstTable } from './FirstTable';
import { getLocalDate } from 'utils/DateFormat';

const getLotState = (lot) => {
  let status = '';
  if (lot.warehouseId && lot.price === null && lot.destinyProcessId === null) {
    status = 'En bodega';
  } else if (lot.price !== null) {
    status = 'Despachado';
  } else if (lot.destinyProcessId !== null && lot.price === null) {
    status = 'Procesado';
  } else if (lot.destinyProcessId === null && lot.warehouseId === null) {
    status = 'Procesado sin destino';
  }
  return status;
};

const getLotTable = (lotsFiltered) => {
  const title = 'DISTRIBUCIÓN TOTAL DE KILOS EN PAK (TRAZABILIDAD)'
  const headers = ['Proceso', 'Pak', 'Estado', 'Fecha', 'Cliente', 'Producto', 'Tipo de empaque', 'Kilos']
  const rows = [];
  let totalLotsWeight = 0
  lotsFiltered?.map((lot) => {
    if (getLotState(lot) === 'Despachado') {
      totalLotsWeight += lot.quantity
      rows.push([
        lot.originProcessId,
        lot.id,
        'Despachado',
        getLocalDate(new Date(lot.destinyProcess?.date)).localeDate,
        lot.destinyProcess.Dispatch.Client.Entity.name,
        lot.Product.name,
        lot.PackagingType.name,
        lot.quantity
      ])
    } else if (getLotState(lot) === 'En bodega') {
      totalLotsWeight += lot.quantity
      rows.push([
        lot.originProcessId,
        lot.id,
        'En bodega',
        '-',
        '-',
        lot.Product.name,
        lot.PackagingType.name,
        lot.quantity
      ])
    }
  })
  const bottomText = `TOTAL KILOS PROCESADOS DE TARJAS SELECCIONADAS: ${Math.floor(totalLotsWeight).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
  return { title, headers, rows, bottomText }
}


const BalanceComponent = (props) => {
  const {
    actions,
    classes,
    loading,
    info,
    defaultFilters,
    tables,
    objects,
    filteredRls,
    formControls,
  } = props;

  // @ts-ignore
  // const selectedLotsIds = useSelector((state) => state.balance.filter.lotNumber);
  const selectedLotsIds = useSelector((state) => state.balance.formControls.lotNumber);
  
  // @ts-ignore
  const lots = useSelector((state) => state.balance.info.lotsInfo);
  const filteredLots = lots?.filter((lot) => selectedLotsIds.includes(lot.id))
  const lotsTableInfo = getLotTable(filteredLots);

  return (
      <Container maxWidth={false} className='container-print'>
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h3" component="h2" className={classes.marginTop}>
              Reporte de Balance de masa
            </Typography>
          </Grid>
          <Filters
            actions={actions}
            defaultFilters={defaultFilters}
            classes={classes}
            objects={objects}
            filteredRls={ filteredRls}
          />
          {loading ? (
            <Alert severity="info">
              <Grid className={classes.alert}>
                <span style={{ marginRight: 20 }}>
                  El sistema está generando el reporte, por favor espere
                </span>
                <CircularProgress size={20} thickness={5.0} />
              </Grid>
            </Alert>
          ) : tables.length === 0 ? (
            <Alert severity="info">
              <Grid className={classes.alert}>
                <span style={{ marginRight: 20 }}>
                  Ingrese un lote para realizar la búsqueda
                </span>
              </Grid>
            </Alert>
          ) : (
            <div className='balance-container' style={{ width: '100%' }}>
              <Card id="balance-container-tables" className={`${classes.root}`}>
                <CardContent>
                  <InfoForm
                    classes={classes}
                    objects={objects}
                    actions={actions}
                    filteredRls={filteredRls}
                    productorsName={tables[0].rows.map(row => row[6])}
                    suppliers={info.suppliers}
                    varieties={tables[0].rows.map(row => row[4])}
                    productsName={tables[0].rows.map(row => row[3])}
                  />
                </CardContent>
              </Card>

              <Card id="balance-container-tables" className={classes.root}>
                <CardContent>
                  {tables.map((table, index) => (
                      <FirstTable
                        headers={table.headers}
                        rows={table.rows}
                        title={table.title}
                        bottomText={table.bottomText}
                      />
                  ))}
                  {filteredLots.length > 0 ?
                  (<FirstTable
                    headers={lotsTableInfo.headers}
                    rows={lotsTableInfo.rows}
                    title={lotsTableInfo.title}
                    bottomText={lotsTableInfo.bottomText}
                  />) :
                  ('')}
                </CardContent>
              </Card>

            </div>
          )}
        </Grid>
      </Container>

  );
};

const withSaga = connectSaga({ key: 'sagaBalance', saga });

const withConnect = connect(selectState('balance.tables', 'balance.info', 'balance.loading', 'balance.formControls'), null);

export default compose(withConnect)(withSaga(BalanceComponent));