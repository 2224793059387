/* eslint-disable import/no-unresolved */
import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
// icons
import SearchIcon from '@material-ui/icons/Search';
// components
import DatePicker from 'commons/components/DatePicker';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';

const Filters = (props) => {
  const {
    classes,
    filter,
    onFilterChange,
    objects,
    clearFilters,
    submitFilter,
  } = props;

  return (
    <Grid item xs={12}>
      <Card className={classes.root}>
        <CardHeader title="Filtro" />
        <CardContent>
          <Grid container spacing={3} className={classes.container} justifyContent="flex-end">
            <Grid item container spacing={3} justifyContent="flex-end">
              <Grid item sm={3} xs={12}>
                <DatePicker
                  inputId="fromDate"
                  label="Desde"
                  fullWidth
                  value={filter.fromDate}
                  onChange={onFilterChange}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <DatePicker
                  inputId="toDate"
                  label="Hasta"
                  fullWidth
                  value={filter.toDate}
                  onChange={onFilterChange}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <TextField fullWidth id="dispatchOrderNumber" label="Guía de despacho" value={filter.dispatchOrderNumber} onChange={onFilterChange} />
              </Grid>
              <Grid item sm={3} xs={12}>
                {/* <FormControl fullWidth>
                  <InputLabel id="client-label">Cliente</InputLabel>
                  <Select
                    labelId="client-label"
                    fullWidth
                    value={filter.clientId}
                    onChange={onFilterChange}
                  >
                    <MenuItem id="clientId" value="">Todos</MenuItem>
                    {Object.values(objects.clients)
                      .sort((a, b) => a.entity().name.toLowerCase().localeCompare(b.entity().name.toLowerCase()))
                      .map((client) => <MenuItem key={`client-option-${client.id}`} id="clientId" value={client.id}>{client.entity().name}</MenuItem>)}
                  </Select>
                </FormControl> */}
                <Autocomplete
                  value={filter.clientId && Object.values(objects.clients).find((client) => client.id === filter.clientId)}
                  id="autocomplete-clients-filters-dispatches"
                  options={Object.values(objects.clients).sort((a, b) => (a.entity().name.toLowerCase() > b.entity().name.toLowerCase() ? 1 : -1))}
                  getOptionLabel={(client) => client && client.entity().name}
                  renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Cliente" margin="normal" />}
                  onChange={(event, newValue) => {
                    const e = {
                      autocomplete: true,
                      input: 'clientId',
                      value: newValue ? newValue.id : '',
                    };
                    onFilterChange(e);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item justifyContent="flex-end" spacing={3}>
              <Grid item xs={12} sm={4} md={3}>
                <ButtonWithLoader
                  fullWidth
                  onClick={clearFilters}
                  autoFocus
                  buttonId="filter-returns"
                  className={classes.filterButton}
                  size="small"
                >
                  Limpiar
                </ButtonWithLoader>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <ButtonWithLoader
                  fullWidth
                  onClick={submitFilter}
                  autoFocus
                  buttonId="filter-returns"
                  className={classes.filterButton}
                  size="small"
                  startIcon={<SearchIcon />}
                >
                  Buscar
                </ButtonWithLoader>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Filters;
