// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';

import apiRequest, { apiSuccess } from 'utils/api';
import { PROVIDERS } from '../reducer';
import { truthty, falsy } from 'utils/functions';
import { appActions } from 'commons/reducer';

function* getStateFromApi() {
  yield takeLatest(PROVIDERS.GET_STATE_FROM_API, function* (action) {
    const response = yield apiRequest('api/v1/suppliers', { method: 'get' });
    yield put(apiSuccess(PROVIDERS.GET_STATE_FROM_API_SUCCESS, response));
  });
}

function* createSupplier() {
  yield takeLatest(PROVIDERS.CREATE_PROVIDER, function* (action) {
    yield put(appActions.setLoader('create-edit-supplier', true));
    const response = yield apiRequest('api/v1/suppliers',
      {
        method: 'post',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PROVIDERS.CREATE_PROVIDER_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-supplier', false));
  });
}

function* editSupplier() {
  yield takeLatest(PROVIDERS.EDIT_PROVIDER, function* (action) {
    yield put(appActions.setLoader('create-edit-supplier', true));
    const response = yield apiRequest(`api/v1/suppliers/${action.id}`,
      {
        method: 'put',
        body: JSON.stringify(action.controls),
      });
    if (truthty(response.error)) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(PROVIDERS.EDIT_PROVIDER_SUCCESS, response));
    }
    yield put(appActions.setLoader('create-edit-supplier', false));
  });
}

function* deleteSupplier() {
  yield takeLatest(PROVIDERS.DELETE_PROVIDER, function* (action) {
    yield put(appActions.setLoader('delete-supplier', true));
    const response = yield apiRequest(`api/v1/suppliers/${action.id}`, { method: 'delete' });
    if (falsy(response.error)) {
      const item = Object.values(response)[0];
      yield put(apiSuccess(PROVIDERS.DELETE_PROVIDER_SUCCESS, { item, type: 'suppliers' }));
    } else {
      yield put(appActions.setError(response.errorMsg));
    }
    yield put(appActions.setLoader('delete-supplier', false));
  });
}

function* exportSuppliers() {
  yield takeLatest(PROVIDERS.EXPORT_PROVIDER, function* (action) {
    yield put(appActions.setLoader('export-suppliers', true));
    const response = yield apiRequest('api/v1/suppliers/export', { method: 'get' }, false, 'suppliers.xlsx');
    yield put(apiSuccess(PROVIDERS.EXPORT_PROVIDER_SUCCESS, response));
    yield put(appActions.setLoader('export-suppliers', false));
  });
}

export default function* saga() {
  yield spawn(getStateFromApi);
  yield spawn(deleteSupplier);
  yield spawn(createSupplier);
  yield spawn(editSupplier);
  yield spawn(exportSuppliers);
}
