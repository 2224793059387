/* eslint-disable no-param-reassign */
// @ts-nocheck
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
import { selectState, actionProps } from 'utils/reduxActions';
import { ReactReduxContext } from 'config/configureStore';
import connectSaga from 'utils/saga';
import { lotsActions } from 'screens/Lots/reducer';
import { appActions } from '../../commons/reducer';
import saga from './saga';
// utils
import { clone } from 'utils/functions';
import moment from 'moment';
// components
import LotsComponent from './components';
// styles
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import styles from './styles';

const useStyles = makeStyles(styles);

const defaultFilters = {
  fromDate: moment().startOf('month').toISOString(),
  toDate: moment().endOf('month').toISOString(),
  paddockId: '',
  fruitId: '',
  varietyId: '',
  processTypeId: '',
  productId: [],
  statusId: '',
  receiptLotNumber: [],
  lotNumber: [],
  lotId: '',
};

const defaultPagination = {
  page: 0,
  rowsPerPage: 10,
  count: 0,
};

const Lots = (props) => {
  useEffect(() => {
    props.actions.changeFilters(defaultFilters);
    props.actions.getStateFromApi(defaultFilters, defaultPagination);
  }, []);

  const classes = useStyles();

  return (
    <LotsComponent
      classes={classes}
      defaultFilters={defaultFilters}
      defaultPagination={defaultPagination}
    />
  );
};

const withSaga = connectSaga({ key: 'sagaLots', saga });

const withConnect = connect(
  selectState('app.loaders', 'app.objects'),
  actionProps(clone(clone.OBJECT, appActions, lotsActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(withStyles(styles), withConnect)(withSaga(Lots));
