/* eslint-disable no-param-reassign */
// @ts-nocheck
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
import { selectState, actionProps } from 'utils/reduxActions';
import { homeActions } from 'screens/Home/reducer';
import { ReactReduxContext } from 'config/configureStore';
import connectSaga from 'utils/saga';
import { returnsActions } from 'screens/Returns/reducer';
import { appActions } from 'commons/reducer';
import saga from './saga';
import { parseToDate } from 'utils/DateFormat';
// utils
import { clone, falsy, onChangeFnc, truthty } from 'utils/functions';
import moment from 'moment';
// components
import ReturnsComponent from './components';
// styles
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import produce from 'immer';
import {useIsMobileDevice} from 'utils/hooks/isMobileDevice';

const useStyles = makeStyles(styles);

const headerList = [
  { name: 'Guía despacho', width: '15%', mobileOrder: 1 },
  { name: 'Cliente', width: '20%', mobileOrder: 1 },
  { name: 'Fecha despacho', width: '20%', mobileOrder: 1 },
  { name: 'Fecha devolución', width: '20%', mobileOrder: 1 },
  { name: 'Motivo', width: '20%', mobileOrder: 1 },
  { name: '', width: '5%', mobileOrder: 1 },
];

const defaultFilters = {
  fromDate: parseToDate().beginningOfMonth,
  toDate: parseToDate().endOfMonth,
  dispatchOrderNumber: '',
  clientId: '',
};

const Returns = (props) => {
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({ ...defaultFilters });
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobileDevice = useIsMobileDevice();

  const {
    actions,
    returnIdList,
    objects,
    account
  } = props;

  useEffect(() => {
    actions.getStateFromApi(defaultFilters);
  }, [actions]);

  useEffect(() => {
    if (props.filterReturns === false) {
      setLoading(false);
    }
  }, [props.filterReturns]);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  };

  function toggleForm() {
    props.actions.toggleForm('formIsOpen');
  }

  function closeForm() {
    props.actions.toggleForm('deleteIsOpen');
  }

  function toggleDelete() {
    handleClose();
    props.actions.toggleDelete(anchorEl.dataset.id);
  }

  function changeControls(event, date) {
    onChangeFnc(props.actions.changeControls, event, date);
  }

  function onFilterChange(event, date) {
    let input;
    let value;
    if (event.autocomplete) {
      input = event.input;
      value = event.value;
    } else {
      event.persist();
      input = falsy(event.target.type) ? event.currentTarget.id : event.target.id;
      value = event.target.type === 'date' ? date : event.target.value;
    }
    setFilter(produce((draft) => {
      draft[input] = value;
    }));
  }

  function clearFilters() {
    setFilter({ ...defaultFilters })
  }

  function submitFilter() {
    props.actions.getStateFromApi(filter);
  }

  function setDispatchLots() {
    const dispatch = Object.values(props.objects.dispatches).find(p => p.number === Number(props.formControls.dispatchNumber));
    props.actions.setDispatchLots(truthty(dispatch) ? dispatch.lots() : []);
  }

  function submit() {
    if (
      falsy(props.formControls.description) ||
      falsy(props.formControls.dispatchNumber) ||
      falsy(props.formControls.returnedLots)
    ) {
      props.actions.setInvalidControls({
        description: falsy(props.formControls.description),
        dispatchNumber: falsy(props.formControls.dispatchNumber),
        returnedLots: falsy(props.formControls.returnedLots),
      });
    } else {
      props.actions.createReturn(props.formControls, props.device);
    }
  }

  function printFnc() {
    const returnItem = Object.values(props.objects.returns).find(item => item.id === Number(anchorEl.dataset.id));
    const labelData = { lotIds: returnItem.returnedlots().map(l => l.id), accountId: account?.id }
    props.actions.printLabel(props.device, labelData);
    setAnchorEl(null)
  }

  function addToReturnLots(event) {
    const lot = props.objects.lots[event.currentTarget.dataset.id];
    props.actions.changeControls({
      toReturnLots: {
        [event.currentTarget.dataset.id]: event.target.checked,
      },
      fruitId: event.target.checked ? lot.fruitId : '',
      varietyId: event.target.checked ? lot.varietyId : '',
      productId: event.target.checked ? lot.productId : '',
      packagingTypeId: event.target.checked ? lot.packagingTypeId : '',
      quantity: event.target.checked ? lot.quantity : '',
      packagingQuantity: event.target.checked ? lot.packagingQuantity : '',
      warehouseId: event.target.checked ? lot.warehouseId : '',
    }, { toReturnLots: false });
  }

  function addReturnedLot() {
    if (
      falsy(props.formControls.warehouseId) ||
      falsy(props.formControls.fruitId) ||
      falsy(props.formControls.varietyId) ||
      falsy(props.formControls.productId) ||
      falsy(props.formControls.packagingTypeId) ||
      falsy(props.formControls.packagingQuantity) ||
      falsy(props.formControls.quantity) ||
      falsy(props.formControls.toReturnLots)
    ) {
      props.actions.setInvalidControls({
        fruitId: falsy(props.formControls.fruitId),
        varietyId: falsy(props.formControls.varietyId),
        productId: falsy(props.formControls.productId),
        packagingTypeId: falsy(props.formControls.packagingTypeId),
        quantity: falsy(props.formControls.quantity),
        packagingQuantity: falsy(props.formControls.packagingQuantity),
        warehouseId: falsy(props.formControls.warehouseId),
        toReturnLots: falsy(props.formControls.toReturnLots),
      });
    } else {
      const [fromLot] = Object.keys(props.formControls.toReturnLots);
      props.actions.changeControls({
        fruitId: '',
        varietyId: '',
        productId: '',
        packagingTypeId: '',
        packagingQuantity: '',
        quantity: '',
        warehouseId: '',
        toReturnLots: {},
        returnedLots: [
          ...props.formControls.returnedLots,
          {
            fruitId: props.formControls.fruitId,
            varietyId: props.formControls.varietyId,
            productId: props.formControls.productId,
            packagingTypeId: props.formControls.packagingTypeId,
            packagingQuantity: props.formControls.packagingQuantity,
            quantity: props.formControls.quantity,
            warehouseId: props.formControls.warehouseId,
            datetime: moment().toISOString(),
            fromLot
          },
        ]
      }, { toReturnLots: false });
    }
  }

  function deleteReturnedLot(event) {
    props.actions.changeControls({
      returnedLots: props.formControls.returnedLots
        .filter((rl, index) =>
          index !== Number(event.currentTarget.dataset.id)
        )
    }, {});
  }

  function changeWeight(weight) {
    props.actions.changeControls({ quantity: weight }, { quantity: false });
  }

  function submitDelete() {
    props.actions.deleteReturn(props.toDeleteId);
  }

  const classes = useStyles();

  const returns = Object.values(objects.returns).filter((r) => returnIdList.includes(r.id));

  return (
    <ReturnsComponent
      classes={classes}
      formIsOpen={props.formIsOpen}
      toggleForm={toggleForm}
      changeControls={changeControls}
      actions={props.actions}
      objects={props.objects}
      submit={submit}
      invalidControls={props.invalidControls}
      closeForm={closeForm}
      formControls={props.formControls}
      dispatchLots={props.dispatchLots}
      setDispatchLots={setDispatchLots}
      addToReturnLots={addToReturnLots}
      addReturnedLot={addReturnedLot}
      deleteReturnedLot={deleteReturnedLot}
      changeWeight={changeWeight}
      toggleDelete={toggleDelete}
      handleClick={handleClick}
      anchorEl={anchorEl}
      handleClose={handleClose}
      deleteIsOpen={props.deleteIsOpen}
      submitDelete={submitDelete}
      submitFilter={submitFilter}
      printFnc={printFnc}
      filter={filter}
      onFilterChange={onFilterChange}
      returns={returns}
      clearFilters={clearFilters}
      loading={loading}
      headerList={headerList}
      isMobileDevice={isMobileDevice}
    />
  );
};

const withSaga = connectSaga({ key: 'sagaReturns', saga });

const withConnect = connect(
  selectState(
    'returns.invalidControls',
    'returns.deleteIsOpen',
    'returns.dispatchLots',
    'returns.returnIdList',
    'returns.formControls',
    'returns.toDeleteId',
    'returns.formIsOpen',
    'returns.filterControls',
    'app.loaders.filterReturns',
    'app.objects',
    'app.device',
    'app.account',
  ),
  actionProps(clone(clone.OBJECT, homeActions, returnsActions, appActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect,
)(withSaga(Returns));
