import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectState } from '../../../utils/reduxActions';
import connectSaga from '../../../utils/saga';
import saga from '../saga';
// ui
import { Box, Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
// components
import DeleteModal from 'commons/components/DeleteModal';
import ItemList from 'screens/Dispatches/components/ItemList';
import Filters from 'screens/Dispatches/components/Filters';
import DispatchForm from 'screens/Dispatches/components/DispatchForm';

const DispatchesComponent = (props) => {
  const {
    classes,
    objects,
    formIsOpen,
    toggleForm,
    deleteIsOpen,
    closeForm,
    submitDelete,
    defaultFilters,
    defaultPagination,
    headerList,
  } = props;

  const [clientsOptions, setClientsOptions] = useState([]);
  const [suppliersOptions, setSuppliersOptions] = useState([]);
  const [productsOptions, setProductsOptions] = useState([]);

  useMemo(() => {
    setClientsOptions([
      '',
      ...Object.values(objects.clients)
        .map((c) => {
          return { id: c.id, name: c.entity().name };
        })
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
    ]);
    setSuppliersOptions([
      '',
      ...Object.values(objects.suppliers)
        .map((s) => {
          return { id: s.id, name: s.entity().name };
        })
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
    ]);
    setProductsOptions([
      '',
      ...Object.values(objects.products).sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      ),
    ]);
  }, []);

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h2" className={classes.marginTop}>
            Despachos
          </Typography>
        </Grid>
        <Filters
          classes={classes}
          defaultFilters={defaultFilters}
          defaultPagination={defaultPagination}
        />
        <Grid container xs={12}>
          <ItemList classes={classes} headerList={headerList} />
        </Grid>
      </Grid>
      {formIsOpen && <DispatchForm toggleForm={toggleForm} />}
      <DeleteModal
        isOpen={deleteIsOpen}
        toggleForm={closeForm}
        submit={submitDelete}
        buttonId="delete-dispatch"
      />
      <Fab color="primary" aria-label="add" className={classes.fab} onClick={toggleForm}>
        <AddIcon />
      </Fab>
    </Container>
  );
};

const withSaga = connectSaga({ key: 'sagaDispatches', saga });

const withConnect = connect(
  selectState('dispatches.formIsOpen', 'dispatches.deleteIsOpen', 'app.objects'),
  null,
);

export default compose(withConnect)(withSaga(DispatchesComponent));
