import camelize from 'camelcase-keys-deep';
export default class SupplyLine {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  warehouse() {
    let response = null;
    if (this.warehouseMovementId) {
      const warehouseMovement =  this.env().WarehouseMovement.findBy('id', this.warehouseMovementId);
      response = this.env().Warehouse.findBy('id', warehouseMovement.warehouseId);
    }
    return response;
  }

  warehouseMovement() {
    let response = null;
    if (this.warehouseMovementId) {
      response =  this.env().WarehouseMovement.findBy('id', this.warehouseMovementId);
    }
    return response;
  }

  product() {
    let response = null;
    if (this.warehouseMovementId) {
      const warehouseMovement =  this.env().WarehouseMovement.findBy('id', this.warehouseMovementId);
      response = this.env().SupplyProduct.findBy('id', warehouseMovement.supplyProductId);
    }
    return response;
  }

  line() {
    let response = null;
    if (this.warehouseMovementId) {
      const warehouseMovement =  this.env().WarehouseMovement.findBy('id', this.warehouseMovementId);
      response = this.env().Line.findBy('id', warehouseMovement.lineId);
    }
    return response;
  }

  process() {
    let response = null;
    if (this.processId) {
      response = this.env().Process.findBy('id', this.processId);
    }
    return response;
  }
}
