/* eslint-disable react/jsx-filename-extension */
import React from 'react';
// ui
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
// components
import ConfirmDialog from '../../../commons/components/ConfirmDialog';
import ItemList from './ItemList';
import Filters from './Filters';
import DeleteModal from '../../../commons/components/DeleteModal';
import ReceiptForm from './ReceiptForm';
import { getLocalDate } from 'utils/DateFormat';
import { clone, truthty } from 'utils/functions';
import { compose } from 'redux';
import { actionProps, selectState } from 'utils/reduxActions';
import connectSaga from 'utils/saga';
import { connect } from 'react-redux';
import saga from '../saga';
import { receiptsActions } from '../reducer';

const ReceiptsComponent = (props) => {
  const {
    classes,
    filter,
    objects,
    defaultFilters,
    defaultPagination,
    receiptList,
    anchorEl,
    handleClick,
    handleClose,
    pagination,
    handleChangeRowsPerPage,
    handleChangePage,
    formIsOpen,
    toggleForm,
    actions,
    formControls,
    deleteLot,
    printLotFnc,
    deleteIsOpen,
    closeForm,
    submitDelete,
    toggleDeleteLot,
    headerList,
  } = props;

  const receiptFormModal = (
    <ReceiptForm
      classes={classes}
      toggleForm={toggleForm}
      deleteLot={deleteLot}
      printLotFnc={printLotFnc}
      toggleDeleteLot={toggleDeleteLot}
    />
  );

  const deleteModal = (
    <DeleteModal
      isOpen={deleteIsOpen}
      toggleForm={closeForm}
      submit={submitDelete}
      buttonId="delete-receipt"
    />
  );

  const toDeleteLot = formControls.toDeleteLotId ? objects.lots[formControls.toDeleteLotId] : null;

  const deleteLotModal = (
    <ConfirmDialog
      open={formControls.isOpenDeleteLot}
      title={toDeleteLot && toDeleteLot.destinyProcess() ? 'Error al eliminar' : 'Eliminar Pak'}
      message={
        toDeleteLot && toDeleteLot.destinyProcess()
          ? ''
          : `Estás seguro de eliminar el pak N° ${formControls.toDeleteLotId}?`
      }
      maxWidth="sm"
      accept={() => actions.deleteLot(formControls.toDeleteLotId)}
      cancel={() => toggleDeleteLot()}
      oneButton={truthty(toDeleteLot?.destinyProcess())}>
      {toDeleteLot && toDeleteLot.destinyProcess() ? (
        <Typography>{`El pak se encuentra ingresada en el proceso ${
          toDeleteLot.destinyProcess().id
        } con fecha ${getLocalDate(toDeleteLot.destinyProcess().date).localeDate}`}</Typography>
      ) : (
        ''
      )}
    </ConfirmDialog>
  );

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h2" className={classes.marginTop}>
            Recepciones
          </Typography>
        </Grid>
        <Filters
          classes={classes}
          filter={filter}
          objects={objects}
          defaultFilters={defaultFilters}
          defaultPagination={defaultPagination}
        />
        <ItemList
          classes={classes}
          receiptList={receiptList}
          anchorEl={anchorEl}
          handleClick={handleClick}
          handleClose={handleClose}
          objects={objects}
          pagination={pagination}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          headerList={headerList}
        />
      </Grid>
      <Fab color="primary" aria-label="add" className={classes.fab} onClick={toggleForm}>
        <AddIcon />
      </Fab>

      {formIsOpen && receiptFormModal}
      {deleteModal}
      {deleteLotModal}
    </Container>
  );
};

const withSaga = connectSaga({ key: 'sagaReceipts', saga });

const withConnect = connect(
  selectState('receipts.formControls', 'receipts.formIsOpen', 'app.objects'),
  actionProps(clone(clone.OBJECT, { deleteLot: receiptsActions.deleteLot })),
);

export default compose(withConnect)(withSaga(ReceiptsComponent));
