/* eslint-disable import/no-unresolved */
// @ts-nocheck
import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectState, actionProps } from '../../../../utils/reduxActions';
import connectSaga from '../../../../utils/saga';
import saga from '../../saga';
import produce from 'immer';
import { dispatchesActions } from 'screens/Dispatches/reducer';
import { falsy, clone } from 'utils/functions';

import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
// icons
import SearchIcon from '@material-ui/icons/Search';
// components
import DatePicker from 'commons/components/DatePicker';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import TableActionMenu from 'commons/components/TableActionMenu';

const Filters = (props) => {
  const {
    classes,
    objects,
    inputSearch,
    actions,
    defaultFilters,
    defaultPagination,
  } = props;

  const [filter, setFilter] = useState({ ...inputSearch });

  useEffect(() => {
    setFilter({ ...inputSearch });
  }, [inputSearch]);

  const onFilterChange = (event, date) => {
    let input;
    let value;
    if (event.autocomplete) {
      input = event.input;
      value = event.value;
    } else {
      event.persist();
      input = falsy(event.target.type) ? event.currentTarget.id : event.target.id;
      value = event.target.type === 'date' ? date : event.target.value;
    }
    setFilter(produce((draft) => { draft[input] = value }));
  }

  const submitFilter = () => {
    actions.changeFilters(filter)
    actions.getStateFromApi(filter, { ...defaultPagination });
  }

  const clearFilters = () => {
    setFilter({ ...defaultFilters });
    actions.changeFilters({ ...defaultFilters });
    actions.paginationControls(defaultFilters, { ...defaultPagination });
  }

  const exportDispatches = () => {
    actions.exportDispatches(filter);
  }

  const clientsOptions = useMemo(() => {
    return ["", ...Object.values(objects.clients).map((c) => {return { id: c.id, name: c.entity().name }}).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))]
  }, [objects.clients]);

  const suppliersOptions = useMemo(() => {
    return ["", ...Object.values(objects.suppliers).map((s) => { return { id: s.id, name: s.entity().name }}).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))]
  }, [objects.suppliers])

  const productsOptions = useMemo(() => {
    return ["", ...Object.values(objects.products).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))]
  }, [objects.products])

  return (
    <Grid item container spacing={3}>
      <Grid item xs={12}>
        <Card className={classes.root}>
          <CardHeader title="Filtro" />
          <CardContent>
            <Grid container spacing={3} className={classes.container} justifyContent="flex-end">
              <Grid container item spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <DatePicker
                    inputId="fromDate"
                    label="Desde"
                    fullWidth
                    value={filter.fromDate}
                    onChange={onFilterChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <DatePicker
                    inputId="toDate"
                    label="Hasta"
                    fullWidth
                    value={filter.toDate}
                    onChange={onFilterChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    value={filter.clientId && clientsOptions.find((c) => c.id === filter.clientId)}
                    id="autocomplete-clients-filters-dispatches"
                    options={clientsOptions}
                    getOptionLabel={(client) => client && client.name}
                    renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Cliente" margin="normal" />}
                    onChange={(event, newValue) => {
                      const e = {
                        autocomplete: true,
                        input: 'clientId',
                        value: newValue ? newValue.id : '',
                      };
                      onFilterChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    value={filter.supplierId && suppliersOptions.find((s) => s.id === filter.supplierId)}
                    id="autocomplete-supplier-filters-dispatches"
                    options={suppliersOptions}
                    getOptionLabel={(supplier) => supplier && supplier.name}
                    renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Productor" margin="normal" />}
                    onChange={(event, newValue) => {
                      const e = {
                        autocomplete: true,
                        input: 'supplierId',
                        value: newValue ? newValue.id : '',
                      };
                      onFilterChange(e);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={3} justifyContent="flex-end">
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    value={filter.productId && productsOptions.find((p) => p.id === filter.productId)}
                    id="autocomplete-product-filters-dispatches"
                    options={productsOptions}
                    getOptionLabel={(product) => product && product.name}
                    renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Productos" margin="normal" />}
                    onChange={(event, newValue) => {
                      const e = {
                        autocomplete: true,
                        input: 'productId',
                        value: newValue ? newValue.id : '',
                      };
                      onFilterChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField fullWidth id="originProcessNumber" label="Número de proceso de origen" value={filter.originProcessNumber} onChange={onFilterChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField fullWidth id="receiptLotNumber" label="Número de lote" value={filter.receiptLotNumber} onChange={onFilterChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField fullWidth id="dispatchNumber" label="Número de despacho" value={filter.dispatchNumber} onChange={onFilterChange} />
                </Grid>
              </Grid>
              <Grid item container spacing={3} justifyContent="flex-end">
                <Grid item xs={12} sm={6} md={3}>
                  <ButtonWithLoader
                    fullWidth
                    onClick={clearFilters}
                    autoFocus
                    buttonId="filter-dispatches"
                    className={classes.filterButton}
                    size="small"
                  >
                    Limpiar
                  </ButtonWithLoader>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <ButtonWithLoader
                    fullWidth
                    onClick={submitFilter}
                    autoFocus
                    buttonId="filter-dispatches"
                    className={classes.filterButton}
                    size="small"
                    startIcon={<SearchIcon />}
                  >
                    Buscar
                  </ButtonWithLoader>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TableActionMenu
          menuItems={[
            {
              label: 'Exportar',
              listItems: [{
                label: 'Archivo Excel',
                onClick: exportDispatches,
                params: [filter],
              }],
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

const withSaga = connectSaga({ key: 'sagaDispatches', saga });

const withConnect = connect(selectState('dispatches.inputSearch', 'app.objects'),
  actionProps(clone(clone.OBJECT, { getStateFromApi: dispatchesActions.getStateFromApi, changeFilters: dispatchesActions.changeFilters, paginationControls: dispatchesActions.paginationControls, exportDispatches: dispatchesActions.exportDispatches })));

export default compose(withConnect)(withSaga(React.memo(Filters)))
