import Purchases from 'screens/Purchases';
import Processes from 'screens/Processes';
import Process from 'screens/Process';
import Receipts from 'screens/Receipts';
import Lots from 'screens/Lots';
import Dispatches from 'screens/Dispatches';
import Returns from 'screens/Returns';
import InventoryLines from 'screens/InventoryLines';
import ProductsReport from 'screens/ProductsReport';
import Balance from 'screens/Balance';
import VersionHistory from 'screens/VersionHistory';
import LotResume from 'screens/LotResume';
import LotTraceability from 'screens/LotTraceability';
import Products from 'screens/Products';
import Suppliers from 'screens/Suppliers';
import Clients from 'screens/Clients';
import WorkShiftTypes from 'screens/WorkShiftTypes';
import Paddocks from 'screens/Paddocks';
import ProcessTypes from 'screens/ProcessTypes';
import SupplyProducts from 'screens/SupplyProducts';
import Employees from 'screens/Employees';
import Units from 'screens/Units';
import Varieties from 'screens/Varieties';
import Warehouses from 'screens/Warehouses';
import PackagingTypes from 'screens/PackagingTypes';
import Fruits from 'screens/Fruits';
import Equipments from 'screens/Equipments';
import ProcessCategories from 'screens/ProcessCategories';

const AppRoutes = [
  { module: 'HOME', component: Processes },
  { module: 'PURCHASES', component: Purchases },
  { module: 'PROCESSES', component: Processes },
  { module: 'PROCESS', component: Process },
  { module: 'RECEIPTS', component: Receipts },
  { module: 'LOTS', component: Lots },
  { module: 'DISPATCHES', component: Dispatches },
  { module: 'RETURNS', component: Returns },
  /* Reports */
  { module: 'INVENTORY_LINES', component: InventoryLines },
  { module: 'PRODUCTS_REPORT', component: ProductsReport },
  { module: 'BALANCE', component: Balance },
  { module: 'VERSION_HISTORY', component: VersionHistory },
  { module: 'LOT_RESUME', component: LotResume },
  { module: 'LOT_TRACEABILITY', component: LotTraceability },
  /* Maintainers */
  { module: 'PRODUCTS', component: Products },
  { module: 'SUPPLIERS', component: Suppliers },
  { module: 'CLIENTS', component: Clients },
  { module: 'WORKSHIFTS', component: WorkShiftTypes },
  { module: 'PROCESS_CATEGORIES', component: ProcessCategories },
  { module: 'PADDOCKS', component: Paddocks },
  { module: 'PROCESS_TYPES', component: ProcessTypes },
  { module: 'SUPPLY_PRODUCTS', component: SupplyProducts },
  { module: 'EMPLOYEES', component: Employees },
  { module: 'UNITS', component: Units },
  { module: 'VARIETIES', component: Varieties },
  { module: 'WAREHOUSES', component: Warehouses },
  { module: 'PACKAGING_TYPES', component: PackagingTypes },
  { module: 'FRUITS', component: Fruits },
  { module: 'EQUIPMENTS', component: Equipments },
];

export default AppRoutes;
